import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

class FlashNotification extends React.Component {
  state = { open: false, message: null }

  handleClose() {
    this.props.clearErrors && this.props.clearErrors()
  }

  handleExit() {
    this.setState({ open: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.message !== null }, () => {
      if (this.state.open) this.setState({ message: nextProps.message })
    })
  }

  render() {
    return (
      <Snackbar
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={this.state.message}
      />
    )
  }
}

export default FlashNotification
