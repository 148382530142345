import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import maleIcon from '../../files/images/maleIcon'

class TooltipModal extends React.Component {
  renderTooltipTitle() {
    return (
      <div>
        <DialogTitle id="alert-dialog-title">Tooltip</DialogTitle>
        <Divider />
      </div>
    )
  }

  renderCustomerInfo() {
    const customerInCurrentRoute = { fill:  "#35c900" }
    const customerInOtherRoute = { fill:  "#0052cc" }
    const customerWithoutRoute = { fill: "red" }
    const iconStyles = { height: 17, width: 18 }
    return (
      <React.Fragment>
        <Grid item className="tooltip-row">
          <i>{maleIcon(iconStyles, customerInCurrentRoute)}</i>
          <p> - Customer <strong>belongs to current route</strong>.</p>
        </Grid>
        <Grid item className="tooltip-row">
          <i>{maleIcon(iconStyles, customerInOtherRoute)}</i>
          <p> - Customer <strong>belongs to other route</strong>.</p>
        </Grid>
        <Grid item className="tooltip-row">
          <i>{maleIcon(iconStyles, customerWithoutRoute)}</i>
          <p> - Customer <strong>belongs to no route</strong>.</p>
        </Grid>
        {this.renderRouteCustomerInfo(iconStyles)}
      </React.Fragment>
    )
  }

  renderRouteCustomerInfo(iconStyles) {
    if (window.tenant === 'den-norske-isbilen') {
      return (
        <Grid item className="tooltip-row">
          <i>{maleIcon(iconStyles, { fill: "black" })}</i>
          <p> - Customer <strong>has a route number of this route</strong>.</p>
        </Grid>
      )
    }
  }

  renderTooltipContent(routeMinimumSalesEfficientCount) {
    return (
      <DialogContent>
        <Grid container className="mt-2">
          <p>Route <strong>minimum sales efficient count is {routeMinimumSalesEfficientCount}</strong>.</p>
            <Grid item  className="tooltip-row">
              <i className="material-icons tooltip-pin-green">location_on</i>
              <p> - Sales count is <strong>greater than or equal with minimum sales efficient count</strong>.</p>
            </Grid>
            <Grid item className="tooltip-row">
              <i className="material-icons tooltip-pin-orange">location_on</i>
              <p> - Sales count is <strong>greater than 0 and less than minimum sales efficient count</strong>.</p>
            </Grid>
            <Grid item className="tooltip-row">
              <i className="material-icons tooltip-pin-red">location_on</i>
              <p> - Sales count is <strong>equal with 0</strong>.</p>
            </Grid>
            {this.renderCustomerInfo()}
            <Grid item>
              <p>* Please note that we use <strong>50 meters radius</strong> to map sales transactions with route stops!</p>
            </Grid>
        </Grid>
      </DialogContent>
    )
  }

  render() {
    const { open, close, routeMinimumSalesEfficientCount } = this.props
    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.renderTooltipTitle()}
        {this.renderTooltipContent(routeMinimumSalesEfficientCount)}
        <DialogActions>
          <Button onClick={close} color="primary">
            Thanks
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default TooltipModal
