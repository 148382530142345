import React from 'react'
import moment from 'moment'

export default class TimePopup extends React.Component {
  render() {
    const {
      planEventRoutePoint: { google_estimate_time, original_google_estimate_time, point_visited }
    } = this.props
    const time = google_estimate_time || original_google_estimate_time
    const timeFrom = moment(time).subtract(10, 'minutes').format('HH:mm')
    const timeTo = moment(time).add(10, 'minutes').format('HH:mm')

    if (point_visited) return null

    return (
      <div className='time-popup'>
        {timeFrom} - {timeTo}
      </div>
    )
  }
}
