import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const DeposTableRow = ({depo, index, classes}) => {
  return (
    <TableRow hover>
      <TableCell>{depo.id}</TableCell>
      <TableCell>{depo.title}</TableCell>
      <TableCell>{depo.address}</TableCell>
      <TableCell>{depo.description}</TableCell>
      <TableCell>
        <Link className="mdl-button mdl-js-button mdl-button--icon" to={Routes.edit_admin_depos_path(tenant, depo.id)} >
          <ModeEdit />
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(DeposTableRow)
