import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ProductsForm from './ProductsForm'
import ProductsTable from './ProductsTable'

const ProductsComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/inventory/depo/:depoId/products/:productId/edit' component={ProductsForm} />
        <Route path='/:tenant/inventory/depo/:depoId/products/new' component={ProductsForm} />
        <Route path='/:tenant/inventory/depo/:depoId/products' component={ProductsTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default ProductsComponent
