import React from  'react'
import { Link } from 'react-router-dom'

const BackButton = ({to, onClick}) => {
  const style = {
    position: 'absolute',
    left: 20,
    top: 20,
    marginRight: 30,
    zIndex: 999,
  }

  return (
    <Link id="back-button" className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored" to={to} style={style} onClick={onClick}>
      <i className="material-icons">keyboard_backspace</i>
    </Link>
  )
}

export default BackButton
