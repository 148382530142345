import React from 'react'
import PlanEventPointIcon from '../shared/PlanEventPointIcon'
import Tooltip from '@material-ui/core/Tooltip'
import axios from 'axios'
import { setFlash } from '../../files/actions/index'

class PlanEventPopup extends React.Component {
  state = {}

  removeFromRoute(routePoint) {
    const { planEvent } = this.props
    axios.delete(
      Routes.destroy_by_address_route_points_path(tenant, depoId, planEvent.route_id),
      { params: { address: routePoint.address } }
    ).then(res => {
      this.props.setFlash("Route Point Deleted")
    }).catch(err => {
      this.props.setFlash("Already deleted")
    })
  }

  addToRoute(routePoint, index) {
    const { planEvent } = this.props
    axios.post(
      Routes.route_points_path(tenant, depoId, planEvent.route_id, { format: 'json' }),
      { route_point: this.newRoutePointData(routePoint, index) }
    ).then(res => {
      this.props.setFlash("Route Point created")
    }).catch(err => {
      this.props.setFlash("Could not create new route point " + err)
    })
  }

  newRoutePointData({start_at, stop_location, address}, index) {
    return {
      arrival_time: start_at,
      point: stop_location,
      address: address,
      position: index
    }
  }

  renderPointAction(routePoint, index) {
    if(routePoint.point_visited !== undefined) {
      return (
        <a className="remove-from-route" onClick={this.removeFromRoute.bind(this, routePoint)}>
          <i className="material-icons">close</i>
          <span>Remove from route</span>
        </a>
      )
    } else {
      return (
        <a className="add-to-route" onClick={this.addToRoute.bind(this, routePoint, index)}>
          <i className="material-icons">playlist_add</i>
          <span>Add to route</span>
        </a>
      )
    }
  }

  renderTooltip(type) {
    const tooltipText = type == 'gps_tracker' ? "Time is calculated using cars GPS tracker." : "Time is submitted by driver using mobile application."
    return <Tooltip title={tooltipText}><i className="material-icons tooltip-info">info</i></Tooltip>
  }

  render() {
    const { routePoint, index, color } = this.props
    return (
      <div className="plan-event-popup-container">
        <div className="point-icon">
          <PlanEventPointIcon revenue={routePoint.total_revenue} index={index} color={color} width={"92"} height={"90"} />
        </div>
        <h6>{routePoint.address}</h6>
        <p>Planned: <b>{routePoint.original_google_estimate_time || routePoint.start_at}</b></p>
        <p>Visited: <b>{routePoint.actual_arrival_time || routePoint.start_at}</b></p>

        <p>Stopped for (GPS tracker {this.renderTooltip("gps_tracker")}):<b> {routePoint.duration || 0} min</b></p>

        <p>Stopped for (Mobile app {this.renderTooltip("driver_app")}): <b> {routePoint.timer_duration || 0} min</b></p>
        <p>Clients: <b>{routePoint.subscribers || 0}</b></p>
        <p>Total revenue: <b>{routePoint.total_revenue || 0}</b></p>
        {this.renderPointAction(routePoint, index)}
      </div>
    )
  }
}

export default PlanEventPopup
