const GOOGLE_EVENTS = {
  firstPopupLoaded: '1-1st-popup-loaded',
  popupTypeIn: '2-1st-popup-address-input',
  popupChooseAddress: '3-1st-popup-address-submit',
  closeFirstPopup: '4-1st-popup-close',
  mapInteraction: '6-map-interaction',
  navBarTypeIn: '7-address-input',
  loadMoreStops: '8-see-more-schedule',
  clickSubscribeToStop: '10-start-of-subscription',
  goToWebsite: '11-go-to-hjemis',
  phoneNumberInput: '13-phone-input',
  disclaimerChecked: '14-disclaimer-checked',
  closePhonePopup: '15-2nd-popup-close',
  subscribeToReceiveSms: '16-subscription-done',
  changeStop: 'change-stop',
  navBarChooseAddress: 'address-input-submit',
  facebookTypeIn: 'facebookTypeIn',
  help: 'help',
  trackingViewLoaded: 'tracking-view-loaded',
  trackingCarIconClicked: 'tracking-center-car-icon-clicked',
  trackingPointIconClicked: 'tracking-center-point-icon-clicked',
  trackingStopClicked: 'tracking-marker-clicked',
  trackingHelpClicked: 'tracking-help-clicked',
  trackingMenuOpened: 'tracking-menu-opened',
  trackingMenuClosed: 'tracking-menu-closed',
  trackingChangeStopClicked: 'tracking-change-stop-clicked',
  trackingProductLinkClicked: 'tracking-product-link-clicked',
  trackingUnsubscribeClicked: 'tracking-unsubscribe-clicked',
  trackingCustomerHasUnsubscribed: 'tracking-customer-unsubscribed',
  feedbackButtonClicked: 'feedback-button-clicked'
}

const GOOGLE_EVENT_LABELS = {
  firstPopupLoaded: '1st Popup Loaded',
  popupTypeIn: '1st Popup Address Input',
  popupChooseAddress: '1st Popup Address Submit',
  closeFirstPopup: '1st Popup Close',
  mapInteraction: 'Interaction on Map',
  navBarTypeIn: 'Address Input',
  loadMoreStops: 'See More Schedule',
  clickSubscribeToStop: 'Start of Subscription',
  goToWebsite: 'Go to Hjem Is',
  phoneNumberInput: 'Phone Input',
  disclaimerChecked: 'Disclaimer Checked',
  closePhonePopup: '2nd Popup Close',
  subscribeToReceiveSms: 'Subscription Done',
  changeStop: 'Change stop',
  navBarChooseAddress: 'Address input submit',
  facebookTypeIn: 'Facebook address input',
  help: 'help',
  trackingViewLoaded: 'Tracking view loaded',
  trackingCarIconClicked: 'Tracking center by car clicked',
  trackingPointIconClicked: 'Tracking center by point clicked',
  trackingStopClicked: 'Tracking stop marker clicked',
  trackingHelpClicked: 'Tracking help clicked',
  trackingMenuOpened: 'Tracking menu opened',
  trackingMenuClosed: 'Tracking menu closed',
  trackingChangeStopClicked: 'Tracking change stop clicked',
  trackingProductLinkClicked: 'Tracking product link clicked',
  trackingUnsubscribeClicked: 'Tracking unsubscribe clicked',
  trackingCustomerHasUnsubscribed: 'Tracking customer has unsubscribed',
  feedbackButtonClicked: 'Customer clicked feedback button'
}

const logGoogleEvent = (action) => {
  console.log(action, GOOGLE_EVENTS[action], GOOGLE_EVENT_LABELS[action])
  window.gtag &&
    window.gtag('event', GOOGLE_EVENTS[action], {
      event_category: 'subscription',
      event_label: GOOGLE_EVENT_LABELS[action]
    })
}

export default logGoogleEvent
