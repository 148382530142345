import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

class PlanEventsTableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleClient =this. handleClient.bind(this)
  }

  handleClient(event) {
    this.props.history.push(
      Routes.plan_event_path(window.tenant, window.depoId, this.props.planEvent.id)
    )
  }

  render() {
    const { classes, planEvent: { id, route_name, car_internal_number, user_email, date, total_revenue, currency } } = this.props

    return (
      <TableRow hover>
        <TableCell onClick={this.handleClient}>{id}</TableCell>
        <TableCell onClick={this.handleClient}>{route_name}</TableCell>
        <TableCell onClick={this.handleClient}>{car_internal_number}</TableCell>
        <TableCell onClick={this.handleClient}>{user_email}</TableCell>
        <TableCell onClick={this.handleClient}>{date}</TableCell>
        <TableCell onClick={this.handleClient}>{total_revenue.toFixed(2)} {currency}</TableCell>
        <TableCell>
          <Link className="mdl-button mdl-js-button mdl-button--icon" to={Routes.edit_plan_events_path(tenant, depoId, id)} >
            <ModeEdit />
          </Link>
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(PlanEventsTableRow)
