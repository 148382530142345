import React from 'react'

import FeedbackComponent from './FeedbackComponent'
import ErrorCatcher from '../shared/ErrorCatcher'

const FeedbackIndex = () => {
  return (
    <ErrorCatcher>
      <FeedbackComponent />
    </ErrorCatcher>
  )
}

export default FeedbackIndex
