export const SET_MAIN_TITLE = "SET_MAIN_TITLE"
export const SET_FLASH = "SET_FLASH"
export const START_LOADING = "START_LOADING"
export const STOP_LOADING = "STOP_LOADING"
export const LOAD_RESOURCES = "LOAD_RESOURCES"
export const LOAD_ROAD_POINTS = "LOAD_ROAD_POINTS"
export const UPDATE_RESOURCE = "UPDATE_RESOURCE"
export const CREATE_ROUTE_POINT_FROM_MAP = "CREATE_ROUTE_POINT_FROM_MAP"
export const COPY_RESOURCE = "COPY_RESOURCE"
export const ADD_POINT = "ADD_POINT"
export const UPDATE_POINT = "UPDATE_POINT"
export const UPDATE_SIBLINGS = "UPDATE_SIBLINGS"
export const CLEAR_ROUTE_POINTS = "CLEAR_ROUTE_POINTS"
export const LOAD_CAR_STOPS = "LOAD_CAR_STOPS"
export const LOAD_CAR_ROUTES = "LOAD_CAR_ROUTES"
export const LOAD_CARS = "LOAD_CARS"
export const LOAD_USERS = "LOAD_USERS"
export const LOAD_ROUTES = "LOAD_ROUTES"
export const LOAD_ROUTE = "LOAD_ROUTE"
export const LOAD_PLAN_EVENT = "LOAD_PLAN_EVENT"
export const LOAD_DEPOS = "LOAD_DEPOS"
export const LOAD_USER_HTTP_REQUESTS = "LOAD_USER_HTTP_REQUESTS"
export const DELETE_RESOURCE = "DELETE_RESOURCE"
export const PLAN_EVENTS_FOR_CALENDAR = "PLAN_EVENTS_FOR_CALENDAR"
export const LOAD_ORDER_TRANSACTIONS = "LOAD_ORDER_TRANSACTIONS"
export const ZOOM_ON_POINT = "ZOOM_ON_POINT"
export const UPDATE_AFTER_MOVE = "UDPATE_AFTER_MOVE"
export const PLACE_ROUTE_POINTS_ON_MAP = "PLACE_ROUTE_POINTS_ON_MAP"
export const CLEAR_ROUTE_POINTS_ON_MAP = "CLEAR_ROUTE_POINTS_ON_MAP"
export const LOAD_PRINT_PREVIEW_STATUS = "LOAD_PRINT_PREVIEW_STATUS"
export const UPDATE_PRINT_GRID_ROW_COUNT = "UPDATE_PRINT_GRID_ROW_COUNT"
export const UPDATE_PRINT_GRID_COLUMN_COUNT = "UPDATE_PRINT_GRID_COLUMN_COUNT"
export const SET_MAP_PRINTING_STATUS = "SET_MAP_PRINTING_STATUS"
export const CANCEL_PRINT_PREVIEW = "CANCEL_PRINT_PREVIEW"
export const LOAD_UNPLANNED_SALES = "LOAD_UNPLANNED_SALES"
export const CLEAR_UNPLANNED_SALES = "CLEAR_UNPLANNED_SALES"
export const LOAD_ROUTE_MINIMUM_SALES_EFFICIENT_COUNT = "LOAD_ROUTE_MINIMUM_SALES_EFFICIENT_COUNT"
export const LOAD_STOP_TYPES = "LOAD_STOP_TYPES"
export const LOAD_CUSTOMERS = "LOAD_CUSTOMERS"
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS"
export const LOAD_SIMILAR_ROUTES = "LOAD_SIMILAR_ROUTES"
