import React from 'react'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import IntegrationReactSelect from '../forms/IntegrationReactSelect'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import each from 'lodash/each'

const styles = {
  modalPaper: {
    width: '30%',
    margin: 'auto',
    padding: 20
  }
}

class MoveCustomersToPointModal extends React.Component {
  state = {
    points: this.props.routePoints,
    routePointId: this.props.selectedPoint.pointId,
    address: this.props.selectedPoint.address,
    selectedCustomers: this.props.selectedCustomers,
    selectedCustomerRoutes: this.props.selectedCustomerRoutes
  }

  async loadRoutePointsOptions(q, callback) {
    this.options = this.pointOptions()

    callback(this.options)
  }

  renderCustomersToMove() {
    const { selectedCustomerRoutes } = this.state

    return selectedCustomerRoutes.map((customer, index) => {
      return (
        <li key={`modalCustomer-${index}`}>
          {customer.address}
          <ul>
            {this.renderCustomerRoutes(customer)}
          </ul>
        </li>
      )
    })
  }

  renderCustomerRoutes(customer) {
    if (customer.active_routes.length > 0) {
      return customer.active_routes.map(route => {
        return (
          <li key={route.id}>{route.name}</li>
        )
      })
    }
  }

  onChange(routePointId) {
    let address = null
    if (routePointId) {
      address = this.options.find(option => option.value === routePointId).label
    }

    this.setState({ routePointId, address })
  }

  moveCustomers() {
    const customerAddresses = this.state.selectedCustomers.map(c => c.address)
    const { routePointId } = this.state

    axios.post(
      Routes.update_multiple_admin_customers_path(window.tenant, { format: 'json' }),
      { customer: { customer_addresses: customerAddresses, point_id: routePointId } }
    ).then(res => {
      this.props.close()
      this.reloadCustomers()
    })
  }

  closeModal() {
    this.setState({ selectedCustomerRoutes: [] })
    this.props.close()
  }

  reloadCustomers() {
    const { selectedCustomers, points, routePointId } = this.state
    const selectedPointIndex = points.findIndex(p => p.id === routePointId)
    const selectedPoint = points[selectedPointIndex]
    const customerAddresses = this.state.selectedCustomers.map(c => c.address)
    const customers = this.props.customers.slice(0)

    each(selectedCustomers, selectedCustomer => {
      const index = customers.findIndex(c => c.address === selectedCustomer.address)
      const customer = customers[index]
      customer.address = selectedPoint.address
      customer.point = selectedPoint.point.coordinates
      customer.selected = false
    })
    this.props.loadCustomers(customers)
  }

  pointOptions() {
    return this.state.points.map(point => {
      return ({ value: point.id, label: point.address })
    })
  }

  render() {
    const { open, close } = this.props
    const { routePointId, address } = this.state

    return (
      <Modal open={open} onBackdropClick={this.closeModal.bind(this)}>
        <Paper style={styles.modalPaper}>
          <Typography variant="h5">Selected Point to move to</Typography>
          <IntegrationReactSelect
            name='route-point-select'
            loadOptions={this.loadRoutePointsOptions.bind(this)}
            additionalStyles={{ fontSize: 14 }}
            cache={false}
            autoload={false}
            onChange={this.onChange.bind(this)}
            value={routePointId}
            defaultOptions={{ value: routePointId, label: address }}/>
          <Typography variant="subheading">Selected Customers to Move</Typography>
          <ul>
            {this.renderCustomersToMove()}
          </ul>
          <div className="action-buttons">
            <Button name="move-customers" variant="contained" onClick={this.moveCustomers.bind(this)}>Move customers</Button>
            <Button name="cancel-customers" onClick={this.closeModal.bind(this)}>Cancel</Button>
          </div>
        </Paper>
      </Modal>
    )
  }
}

export default MoveCustomersToPointModal
