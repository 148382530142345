import React from 'react'
import { connect } from 'react-redux'

const titleStyle = {
  marginTop: 10,
  marginBottom: 10,
}

class MainTitleClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div style={this.props.style || titleStyle}>
        <span className="mdl-layout-title">
          {this.props.mainTitle || <span>&nbsp;</span>}
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    mainTitle: state.mainTitle
  }
}

const MainTitle = connect(
  mapStateToProps,
  null
)(MainTitleClass)

export default MainTitle
