import React from 'react'

import DeposComponent from './DeposComponent'
import TitleContainer from '../../shared/TitleContainer'

const AdminDeposIndex = () => {
  return (
    <TitleContainer>
      <DeposComponent />
    </TitleContainer>
  )
}

export default AdminDeposIndex
