import React from 'react'
import ModalWrapper from '../shared/Modal'
import moment from 'moment'

class VersionChangelog extends React.Component {
  renderVersions() {
    const { order, order: { versions } } = this.props

    const stopIds = versions.map(version => version.serialized_object && version.serialized_object.stop_id)
    const depoIds = versions.map(version => version.serialized_object && version.serialized_object.depo_id)
    const deliveryDates = versions.map(version => version.serialized_object && version.serialized_object.delivery_date)
    const routeIds = versions.map(version => version.serialized_object && version.serialized_object.route_id)
    const timeChanged = versions.map(version => version.serialized_object && moment(version.serialized_object.updated_at).format("DD-MM-YYYY HH:mm"))
    const comments = versions.map(version => version.serialized_object && version.serialized_object.comment)
    const statuses = versions.map(version => version.serialized_object && version.serialized_object.status)
    const users = versions.map(version => version.user.full_name)

    return (
      <div>
        <p><b>Stop ID: </b>{stopIds.join(" => ")} {' => '}<b style={{ color: 'green' }}>{order.stop_id}</b></p>
        <p><b>Depo ID: </b>{depoIds.join(" => ")} {' => '}<b style={{ color: 'green' }}>{order.depo_id}</b></p>
        <p><b>Delivery Date: </b>{deliveryDates.join(" => ")} {' => '}<b style={{ color: 'green' }}>{order.delivery_date}</b></p>
        <p><b>Route ID: </b>{routeIds.join(" => ")} {' => '}<b style={{ color: 'green' }}>{order.route_id}</b></p>
        <p><b>Time changed: </b>{timeChanged.join(" => ")}{' => '}<b style={{ color: 'green' }}>{moment(order.updated_at).format("DD-MM-YYYY HH:mm")}</b></p>
        <p><b>Comment: </b>{comments.join(" => ")} {' => '}<b style={{ color: 'green' }}>{order.comment}</b></p>
        <p><b>Status: </b>{statuses.join(" => ")} {' => '}<b style={{ color: 'green' }}>{order.status}</b></p>
        <p><b>Users: </b>{users.join(" => ")}</p>
      </div>
    )
  }

  render() {
    const { order } = this.props
    return (
      <ModalWrapper
        classes="changelog-modal"
        style={{ width: '500px', height: '550px' }}
        open={order.changelogOpen || false}
        title="Changelog"
        close={this.props.handleModalClose.bind(this, order)}>
        {this.renderVersions()}
      </ModalWrapper>
    )
  }
}

export default VersionChangelog
