import React from 'react'
import ErrorCatcher from '../../shared/ErrorCatcher'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import B2BCustomersTable from './B2BCustomersTable'

const AdminB2BCustomersIndex = () => {
  return (
    <ErrorCatcher>
      <BrowserRouter>
        <Switch>
          <Route path='/:tenant/admin/b2b_customers' component={B2BCustomersTable} />
          <Route path='/:tenant/admin/b2b_customers/:id/edit' component={B2BCustomersTable} />
        </Switch>
      </BrowserRouter>
    </ErrorCatcher>
  )
}

export default AdminB2BCustomersIndex
