import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const UserHttpRequestsTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Performed at</TableCell>
        <TableCell>User</TableCell>
        <TableCell>User's Role</TableCell>
        <TableCell>URL</TableCell>
        <TableCell>Method</TableCell>
        <TableCell>HTTP Status</TableCell>
        <TableCell>Controller</TableCell>
        <TableCell>Action</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default UserHttpRequestsTableHead
