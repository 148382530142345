import React from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Collapse from '@material-ui/core/Collapse'
import snakeCase from 'lodash/snakeCase'

const styles = {
  idTableCell: {
    padding: '4px 4px 4px 16px !important'
  },
  formTableRow: {
    height: 'auto'
  },
  formTableCellCollapsed: {
    border: 'none'
  },
  formTableCell: {
    padding: '0 !important'
  }
}

class SharedTableRow extends React.Component {
  renderForm(dataItem) {
    if (this.props.formComponent) {
      return (
        <TableRow style={{ backgroundColor: 'white', height: 'auto' }}>
          <TableCell style={{ padding: 0, border: !dataItem.formOpen && 'none' }} colSpan={this.props.formColspan}>
            <Collapse in={dataItem.formOpen} timeout="auto" unmountOnExit>
              {this.props.formComponent(dataItem)}
            </Collapse>
          </TableCell>
        </TableRow>
      )
    }
  }

  toggleForm(e) {
    const { dataItem } = this.props
    if (e.target.type !== 'checkbox') {
      this.props.toggleForm && this.props.toggleForm(dataItem)
    }
  }

  renderTableCellContent(dataItem, columnItem) {
    if (columnItem === 'Actions') {
      return dataItem[columnItem.toLowerCase()].map((action, dataIndex) => {
        return (
          <React.Fragment key={dataIndex}>
            {action}
          </React.Fragment>
        )
      })
    } else if (columnItem === 'Labels') {
      return dataItem["labels"]
    } else {
      const value = dataItem[snakeCase(columnItem).toLowerCase()]

      if (Array.isArray(value)) {
        return (
          <React.Fragment>
            {value.map(val => <div style={{ minWidth: 80 }} key={val}>{val}</div>)}
          </React.Fragment>
        )
      } else {
        if (columnItem === '#') {
          return dataItem['id']
        } else if (snakeCase(columnItem).toLowerCase() === 'additional_driving_distance') {
          if (value > 10) {
            return <span style={{ marginLeft: 0 }} className="tag-label warning-label">{value}</span>
          } else {
            return value
          }
        } else {
          return value
        }
      }
    }
  }

  render() {
    const { columns, dataItem, dataIndex, additionalRowStyles } = this.props

    return (
      <TableBody style={additionalRowStyles && additionalRowStyles(dataItem)}>
        <TableRow hover className={dataItem.className} onClick={this.toggleForm.bind(this)}>
          {columns.map((columnItem, columnIndex) => {
            return (
              <TableCell style={{ padding: '5px 5px 5px 5px' }} key={dataIndex + columnIndex}>
                {this.renderTableCellContent(dataItem, columnItem)}
              </TableCell>
            )
          })}
        </TableRow>
        {this.renderForm(dataItem)}
      </TableBody>
    )
  }
}


export default SharedTableRow
