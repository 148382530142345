import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import { setMainTitle, startLoading, stopLoading, loadResources, setFlash } from '../../../files/actions/index'
import DeposTableHead from './DeposTableHead'
import DeposTableRow from './DeposTableRow'
import NewButton from '../../shared/NewButton'

class DeposTableClass extends React.Component {
  componentDidMount() {
    this.props.setMainTitle('Depos')
    this.props.startLoading()

    axios.get(this.url()).then(res => {
      this.props.loadDepos(res.data)
    }).catch(error => {
      this.props.setFlash(`${error} (${this.url()})`)
    }).then(
      this.props.stopLoading
    )
  }

  url() {
    return Routes.admin_depos_path(window.tenant, {format: 'json'})
  }

  renderTableBody() {
    return (
      <TableBody>
        {this.props.depos.map((depo, index) => {
          return <DeposTableRow depo={depo} key={index} />
        })}
      </TableBody>
    )
  }

  render() {
    return (
      <div>
        <NewButton to={Routes.new_admin_depos_path(tenant)} />
        <Table className='resources-table'>
          <DeposTableHead />
          {this.renderTableBody()}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    depos: state.resources
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    setFlash: flash => dispatch(setFlash(flash)),
    loadDepos: depos => dispatch(loadResources(depos)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const DeposTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeposTableClass)

export default DeposTable
