import React from 'react'
import SmsCampaignsComponent from './SmsCampaignsComponent'
import TitleContainer from '../../shared/TitleContainer'
const AdminSmsCampaignsIndex = () => {
  return (
    <TitleContainer>
      <SmsCampaignsComponent />
    </TitleContainer>
  )
}

export default AdminSmsCampaignsIndex
