import React from 'react'
import { connect } from 'react-redux'
import { startLoading, stopLoading } from '../../files/actions/index'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import Print from '@material-ui/icons/Print'
import MoneyOff from '@material-ui/icons/MoneyOff'

import PlanEventTemplatesTableHead from './PlanEventTemplatesTableHead'
import PlanEventTemplatesTableRow from './PlanEventTemplatesTableRow'

import printStyles from '../../files/printingAssets.js.erb'
const printableContainerId = "plan-event-template-print-container"

class PlanEventTemplatesTableClass extends React.Component {
  printTable() {
    this.props.startLoading()
    const { year, week } = this.props
    printJS({
      printable: printableContainerId,
      type: 'html',
      documentTitle: `Calendar ${year}-${week}`,
      scanStyles: false,
      css: printStyles,
      onLoadingEnd: this.props.stopLoading,
      style: '@media print { @page { size: landscape; } } '
    })
  }

  printWithoutRevenue() {
    this.props.startLoading()
    const { year, week } = this.props
    printJS({
      printable: printableContainerId,
      type: 'html',
      documentTitle: `Calendar ${year}-${week}`,
      scanStyles: false,
      css: printStyles,
      onLoadingEnd: this.props.stopLoading,
      style: '@media print { .fun-revenue { display: none; } @page { size: landscape; } }'
    })
  }

  renderPrintButton() {
   return (
      <>
        {isHjemis && <IconButton style={{ position: 'absolute', zIndex: 1, top: 85 }} disabled={!this.props.printingAvailable} onClick={this.printWithoutRevenue.bind(this)}>
          <Print fontSize="small"/>
          <MoneyOff color={"secondary"} style={{height: 18, position: 'absolute', left: 43}}/>
        </IconButton>}
        <IconButton style={{ position: 'absolute', zIndex: 1, top: 120}} disabled={!this.props.printingAvailable} onClick={this.printTable.bind(this)}>
          <Print />
        </IconButton>
      </>
    )
  }

  renderTable() {
    const { week, year, cars, planEvents } = this.props
    return (
      <div id={printableContainerId} style={{ overflowX: 'scroll', height: '100%' }}>
        <Table style={{ overflow: 'auto', marginBottom: 120 }}>
          <PlanEventTemplatesTableHead year={year} week={week} planEvents={planEvents} />
          <TableBody style={{ borderRight: 'solid 1px lightgray' }}>
            {cars && cars.map(car => {
              return <PlanEventTemplatesTableRow
                week={week}
                car={car}
                year={year}
                key={`y${year}-w${week}-c${car.id}`}
              />
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPrintButton()}
        {this.renderTable()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return { cars: state.cars, printingAvailable: !!state.planEvents, planEvents: state.planEvents }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventTemplatesTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEventTemplatesTableClass)

export default PlanEventTemplatesTable
