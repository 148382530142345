import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import MovableInput from '../shared/MovableInput'
import MaterialDatePicker from '../../shared/MaterialDatePicker'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import debounce from 'lodash/debounce'

class ProductsBalanceRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
      future_inventory_date: this.props.product.future_inventory_date,
      future_inventory_quantity: this.props.product.future_inventory_quantity
    }

    this.updateFutureDataDebounced = debounce(this.updateFutureData, 300)
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.product.checked !== undefined && this.setState({ checked: props.product.checked })
  }

  handeCheckboxChange() {
    this.setState({ checked: !this.state.checked }, () => {
      this.props.toggleSingleProductCheck({ id: this.props.product.id, checked: this.state.checked })
    })
  }

  renderPackageValue() {
    return this.state.checked ? this.props.product.filledPackages : ""
  }

  renderAmountValue() {
    return this.state.checked ? this.props.product.filledAmount : ""
  }

  renderCheckbox(productId) {
    const { checked } = this.state
    return (
      <Checkbox
        className={`product-${productId}-checkbox`}
        checked={this.props.product.checked || checked}
        onChange={this.handeCheckboxChange.bind(this)} />
    )
  }

  renderMovableInput(itemKey, tabIndex, recalculate) {
    const updatableDuringUpdate = [{ key: "checked", value: true }]
    if (recalculate) {
      updatableDuringUpdate.push({ key: "recalculateFilledAmount", value: true })
    }
    const inputValue = itemKey == "filledPackages" ? this.renderPackageValue() : this.renderAmountValue()

    return (
      <MovableInput
        item={this.props.product}
        items={this.props.products}
        itemKey={itemKey}
        parentItemsState="products"
        tabIndex={tabIndex}
        inputValue={inputValue}
        updatableDuringUpdate={updatableDuringUpdate}
        updateProductsByKeysWithValues={this.props.updateProductsByKeysWithValues.bind(this)}
        updateStateByKeysWithValues={this.props.updateStateByKeysWithValues.bind(this)}
        getStateOfKey={this.props.getStateOfKey.bind(this)}
        clearMovementStates={this.props.clearMovementStates.bind(this)}/>
    )
  }

  invalidRow(balance) {
    if (balance < 0) {
      return { backgroundColor: 'red' }
    }
  }

  updateFutureData() {
    const { future_inventory_date, future_inventory_quantity } = this.state

    axios.put(Routes.inventory_warehouse_product_balance_path(tenant, depoId, this.props.product.balance_id, { format: 'json' }), {
      warehouse_product_balance: { future_inventory_date, future_inventory_quantity }
    }).then(res => {
      this.props.setFlashMessage(`${this.props.product.sku} was updated`)
    }).catch(err => {
      this.props.setFlashMessage(`There was a problem updating ${this.props.product.sku} ${err}`)
    })
  }

  handleDateChange(value) {
    this.setState({ future_inventory_date: moment(value).format('DD-MM-YYYY') }, () => {
      this.updateFutureData()
    })
  }

  handleInputChange(e) {
    this.setState({ future_inventory_quantity: e.target.value }, this.updateFutureDataDebounced)
  }

  renderDatePicker() {
    return (
      <MaterialDatePicker
        classNames="w-50"
        fieldName="future_inventory_date"
        key={`future-date-${this.state.future_inventory_date}`}
        value={this.state.future_inventory_date || ''}
        onDateChange={this.handleDateChange.bind(this)}
        position="bottom-right-position"
        inputProps={{
          variant: "outlined",
          size: "small"
        }}
      />
    )
  }

  renderFutureInput() {
    return (
      <TextField
        variant="outlined"
        size="small"
        type="number"
        value={this.state.future_inventory_quantity || ''}
        onChange={this.handleInputChange.bind(this)}
        placeholder="Future quantity"
      />
    )
  }

  renderDepoFutureColumns() {
    if (this.props.warehouseData.owner_type === 'Depo') {
      return (
        <>
          <TableCell>{this.renderFutureInput()}</TableCell>
          <TableCell>{this.renderDatePicker()}</TableCell>
        </>
      )
    }
  }

  render() {
    const { id, sku, description, minimum_required_balance, balance_amount, actual_balance } = this.props.product

    const { tabIndex } = this.props
    return (
      <TableRow style={this.invalidRow(actual_balance)}>
        <TableCell style={{ width: 10 }}>{this.renderCheckbox(id)}</TableCell>
        <TableCell>{sku}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{minimum_required_balance}</TableCell>
        <TableCell>{balance_amount}</TableCell>
        <TableCell>{actual_balance}</TableCell>
        {this.renderDepoFutureColumns()}
        <TableCell>
          {this.renderMovableInput("filledPackages", tabIndex * 2, true)}
        </TableCell>
        <TableCell>
          {this.renderMovableInput("filledAmount", tabIndex * 2 + 1, false)}
        </TableCell>
      </TableRow>
    )
  }
}

export default ProductsBalanceRow
