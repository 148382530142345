import React from 'react'

class PlanEventPointIcon extends React.Component {
  render() {
    const { revenue, index, color, width, height } = this.props

    return(
      <svg width={width || "61px"} height={height || "56px"} viewBox="0 0 61 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter x="-13.2%" y="-10.2%" width="126.4%" height="128.6%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
        <g id="Admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="-desktop0" transform="translate(-858.000000, -206.000000)">
            <g id="Group-5" transform="translate(862.000000, 208.000000)">
              <g id="Group-3" fill={color} stroke="#FFFFFF" strokeWidth="2">
                <path d="M26.1377449,46.6604832 L31.3279377,41.4702904 L42.0403445,41.4702904 C47.1407744,41.4702904 51.2754898,37.3355751 51.2754898,32.2351452 C51.2754898,28.798584 49.3827827,25.6970204 46.4104502,24.0971569 L45.8198913,23.7792874 L45.8898644,23.1122757 C45.9631045,22.4141229 46,21.7094161 46,21 C46,9.954305 37.045695,1 26,1 C14.954305,1 6,9.954305 6,21 C6,21.7561668 6.04191851,22.5069656 6.12508193,23.2501559 L6.19788885,23.9007953 L5.63066942,24.2277427 C2.78808344,25.8662198 1,28.8942814 1,32.2351452 C1,37.3355751 5.13471535,41.4702904 10.2351452,41.4702904 L20.5333385,41.4702904 L20.9475521,41.4702904 L26.1377449,46.6604832 Z" id="Combined-Shape"></path>
              </g>
              <text fontFamily="SFProText-Semibold, SF Pro Text" fontSize="10" fontWeight="500" fill="#FFFFFF">
                <tspan x={revenue.toString().length > 2 ? "14.19287109" : "16.19287109"} y="35">{revenue} Kr</tspan>
              </text>
              <text fontFamily="SFProText-Semibold, SF Pro Text" fontSize="12" fontWeight="500" fill="#FFFFFF">
                <tspan x={index.toString().length > 2 ? "17.0234375" : "20.0234375"} y="19">{index}</tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default PlanEventPointIcon
