import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import StopTypesForm from './StopTypesForm'
import StopTypesTable from './StopTypesTable'

const StopTypesComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/admin/stop_types/:stopTypeId/edit' component={StopTypesForm} />
        <Route path='/:tenant/admin/stop_types/new' component={StopTypesForm} />
        <Route path='/:tenant/admin/stop_types' component={StopTypesTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default StopTypesComponent
