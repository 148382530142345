import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import danskFlag from '../../icons/den-norske-isbilen/dansk.svg'
import norskFlag from '../../icons/hjem-is/norsk.svg'
import { Check } from '@material-ui/icons'

class LanguageChanger extends React.Component {
  changeDomain(e) {
    let path = `/${e.target.value}`
    window.location = path
  }

  render() {
    return (
      <div className='languages'>
        <Select
          value={this.props.language}
          onChange={this.changeDomain}
          disableUnderline
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }}
        >
          <MenuItem value='dk'>
            <img className='flag' src={danskFlag} />
            <span>{this.props.shortForm ? 'DK' : 'Dansk'}</span>
            <Check className='checkmark' />
          </MenuItem>
          <MenuItem value='no'>
            <img src={norskFlag} className='flag' />
            <span>{this.props.shortForm ? 'NO' : 'Norsk'}</span>
            <Check className='checkmark' />
          </MenuItem>
        </Select>
      </div>
    )
  }
}

export default LanguageChanger
