import React from 'react'
import axios from 'axios'

import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import MaterialDatePicker from '../../shared/MaterialDatePicker'
import extractDataErrors from '../../support/extractDataErrors'
import FlashNotification from '../../shared/FlashNotification'
import FunTextField from '../../forms/FunTextField'
import Modal from '../../shared/Modal'

import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

class ProductMovement extends React.Component {
  state = {
    movementModalOpen: false,
    confirmationOpen: false,
    movementDate: moment(),
    movableProductSku: '',
    active_products: [],
    message: null,
    errors: {}
  }

  componentDidMount() {
    this.startLoading()
    axios.get(Routes.active_products_inventory_product_path(tenant, depoId, this.props.productId, { format: 'json' })).then(res => {
      const active_products = res.data
      this.setState({ active_products: [{ id: -1, sku: '' }, ...active_products] })
      this.stopLoading()
    }).catch(err => {
      this.setState({ message: `${err}` })
      this.stopLoading()
    })
  }

  handleDateChange    = (date)  => this.setState({ movementDate: moment(date) })
  handleProductChange = (e)     => this.setState({ movableProductSku: e.target.value })
  handleSubmitError   = (error) => this.setState(extractDataErrors(error), this.stopLoading)
  handleSubmitSuccess = ()      =>  {
    this.setState({ errors: {}, loading: false, message: this.successMessage() }, this.props.handleSuccessfulProductMovement)
  }

  openMovementModal   = () => this.setState({ movementModalOpen: true })
  closeMovementModal  = () => this.setState({ movementModalOpen: false })
  openConfirmation    = () => this.setState({ confirmationOpen: true })
  closeConfirmation   = () => this.setState({ confirmationOpen: false })

  clearErrors         = () => this.setState({ full_errors: null, errors: {}, message: null })
  stopLoading         = () => this.setState({ loading: false })
  startLoading        = () => this.setState({ loading: true })
  notificationContent = () => !isEmpty(this.state.full_errors) ? `${this.state.full_errors.join(", ")}` : this.state.message
  successMessage      = () => {
    return `Remaining product warehouse balances successfully moved to product with sku: ${this.state.movableProductSku}!`
  }

  url = () => Routes.move_remaining_product_balances_inventory_product_path(tenant, depoId, this.props.productId, { format: 'json' })

  data() {
    const { movementDate, movableProductSku, active_products } = this.state
    const movableProductId = active_products.find(p => p.sku === movableProductSku).id
    return {
      data: { date: movementDate.format(dateFormat), product_id: movableProductId },
      format: 'json'
    }
  }

  moveRemainingProductBalances = (e) => {
    e.preventDefault()
    this.startLoading()
    this.closeMovementModal()
    this.closeConfirmation()
    axios({
      method: 'patch',
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess
    ).catch(
      this.handleSubmitError
    )
  }

  renderDatePicker() {
    const { movementDate } = this.state
    return (
      <MaterialDatePicker
        fieldName="movementDate"
        label="Movement date"
        key={movementDate}
        classNames="p-0 d-block"
        value={movementDate && movementDate.format(dateFormat) || ''}
        onDateChange={this.handleDateChange} />
    )
  }

  renderMovementModal() {
    const { movementModalOpen, movableProductSku } = this.state
    return (
      <Modal open={movementModalOpen} close={this.closeMovementModal} title="Stock value movement">
        <React.Fragment>
          <div className='w-100 mb-4 text-center'>
            {this.renderDatePicker()}
            {this.renderActiveProductsDropdown()}
          </div>
          <div className='text-center'>
            <Button variant="contained" size="small" disabled={!movableProductSku} onClick={this.openConfirmation}>
              Move
            </Button>
          </div>
        </React.Fragment>
      </Modal>
    )
  }

  renderActiveProducts() {
    const { active_products } = this.state
    return (
      active_products.map(active_product => {
        const { id, sku } = active_product
        return <option key={id} value={sku}>{sku}</option>
      })
    )
  }

  renderActiveProductsDropdown() {
    const { movableProductSku } = this.state
    return (
      <div className="p-1">
        <FormControl className="fun-select text-left d-flex">
          <InputLabel>Product</InputLabel>
          <Select native value={movableProductSku} onChange={this.handleProductChange}>
            {this.renderActiveProducts()}
          </Select>
        </FormControl>
      </div>
    )
  }

  renderMovementButton() {
    if (!this.state.active_products) return
    return (
      <Button variant="contained" disabled={true} color="primary" onClick={this.openMovementModal}>
        Move stock values
      </Button>
    )
  }

  renderConfirmationDialog() {
    const { confirmationOpen, movableProductSku } = this.state
    return (
      <ConfirmationDialog
        open={confirmationOpen}
        close={this.closeConfirmation}
        leave={this.moveRemainingProductBalances}
        title="Confirm stock value movement"
        content={`All remaining warehouse balances will be transfered to product with sku: ${movableProductSku}!`} />
    )
  }

  renderLoading           = () => <LoadingWithoutRedux loading={this.state.loading} />
  renderFlashNotification = () => <FlashNotification message={this.notificationContent()} clearErrors={this.clearErrors} />

  render() {
    return (
      <React.Fragment>
        {this.renderMovementButton()}
        {this.renderMovementModal()}
        {this.renderConfirmationDialog()}
        {this.renderFlashNotification()}
        {this.renderLoading()}
      </React.Fragment>
    )
  }
}

export default ProductMovement
