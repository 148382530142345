import React from 'react'
import ReactDataGrid from 'react-data-grid'

class DataGrid extends React.Component {
  render() {
    return (
      <ReactDataGrid
        columns={this.props.columns}
        rowGetter={i => this.props.rows[i]}
        rowsCount={this.props.rows.length}
        onGridRowsUpdated={this.props.onGridRowsUpdated && this.props.onGridRowsUpdated.bind(this)}
        enableCellSelect={true}
        onCellSelected={this.props.appendEmptyRow && this.props.appendEmptyRow.bind(this)}
        getCellActions={this.props.getCellActions && this.props.getCellActions.bind(this)}
      />
    )
  }
}

export default DataGrid
