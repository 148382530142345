import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PlanEventsForm from './PlanEventsForm'
import PlanEventsMap from './PlanEventsMap'
import PlanEventsTable from './PlanEventsTable'
import PlanEventCustomers from './PlanEventCustomers'

const PlanEventsComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/depo/:depoId/plan_events/:planEventId/edit' component={PlanEventsForm} />
        <Route path='/:tenant/depo/:depoId/plan_events/new' component={PlanEventsForm} />
        <Route path='/:tenant/depo/:depoId/plan_events/:planEventId' component={PlanEventsMap} />
        <Route path='/:tenant/depo/:depoId/plan_events' component={PlanEventsTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default PlanEventsComponent
