import React from 'react'
import ArrowForward from '@material-ui/icons/ArrowForward'
import axios from 'axios'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'

class MaterialMapboxAutocomplete extends React.Component {
  state = { placesList: [], selectedItem: {} }

  componentDidMount() {
    this.closeAutocompleteOnOutsideClick()
  }

  renderSearchIcon() {
    const { showSearchIcon } = this.props

    if (showSearchIcon) {
      return (
        <div className="search-icon" onClick={this.selectClosestSuggestion.bind(this)}>
          <ArrowForward />
        </div>
      )
    }
  }

  closeAutocompleteOnOutsideClick() {
    document.querySelector('body').addEventListener('click', e => {
      if (!this.state.placesList.length) return

      const clickWithinList = e.target.className && e.target.className.length && (
        e.target.className.includes('mapbox-places-list') || e.target.className.includes('location-search-container')
      )

      if (!clickWithinList) this.setState({ placesList: [] })
    })
  }

  getPlaces() {
    if (this.props.value.length < 3) {
      this.setState({ placesList: [] })
      return
    }

    this.fetchPlaces()
  }

  fetchPlaces() {
    const { value, type } = this.props
    if (!value.length) return

    this.fetchRequest().then(res => {
      if (type === 'global') {
        if (res.data.features.length > 0) {
          this.setState({ placesList: res.data.features, showList: true })
        } else {
          this.setState({ placesList: [] })
        }
      } else {
        this.setState({ placesList: res.data, showList: true })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  fetchRequest() {
    const { value, type } = this.props

    if (type === 'global') {
      return axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`,
        {
          params: {
            access_token: this.props.accessToken,
            autocomplete: true,
            country: [isHjemis ? "dk" : "no"]
          }
        }
      )
    } else {
      return axios.get(
        Routes.search_by_address_path(tenant, { format: 'json', query: value }),
      )
    }
  }


  handleKeyUp(e) {
    const { placesList, showList } = this.state
    const { value } = this.props
    if ((!placesList.length && value.length < 3) || !showList) return

    if (e.key === 'ArrowDown') {
      if (this.state.selectedItem) {
        const index = placesList.findIndex(place => place.id === this.state.selectedItem.id)
        const nextIndex = index + 1
        if (nextIndex === placesList.length) {
          this.setState({ selectedItem: placesList[0], value: placesList[0].place_name })
        } else {
          this.setState({ selectedItem: placesList[nextIndex], value: placesList[nextIndex].place_name })
        }
      } else {
        this.setState({ selectedItem: placesList[0], value: placesList[0].place_name })
      }
    } else if (e.key === 'ArrowUp') {
      if (this.state.selectedItem) {
        const index = placesList.findIndex(place => place.id === this.state.selectedItem.id)
        const prevIndex = index - 1
        if (prevIndex === -1) {
          this.setState({ selectedItem: placesList[placesList.length - 1], value: placesList[placesList.length - 1].place_name })
        } else {
          this.setState({ selectedItem: placesList[prevIndex], value: placesList[prevIndex].place_name })
        }
      } else {
        this.setState({ selectedItem: placesList[placesList.length - 1], value: placesList[placesList.length - 1].place_name })
      }
    }

    if (e.key === 'Enter' && Object.keys(this.state.selectedItem).length) {
      this.selectSuggestion(this.state.selectedItem)
    } else if (e.key === 'Enter' && !Object.keys(this.state.selectedItem).length) {
      this.selectClosestSuggestion()
    }

    if (e.key === 'Escape') {
      this.setState({ showList: false, placesList: [] })
    }
  }

  selectClosestSuggestion() {
    const { placesList } = this.state
    if (placesList.length) {
      this.selectSuggestion(placesList[0])
    }
  }

  selectSuggestion(item) {
    if (this.props.type === 'global') {
      const coordinates = { lat: item.center[1], lng: item.center[0] }
      this.setState({ showList: false })
      this.props.selectAddress(item.place_name, coordinates)
    } else {
      this.setState({ showList: false })
      this.props.selectAddress(item.address, item.coordinates)
    }
  }

  renderPlacesList() {
    const { placesList, selectedItem } = this.state
    const { type } = this.props

    return placesList.map(place => {
      const activeClass = selectedItem && selectedItem.id === place.id ? "location-search-autocomplete-item-active" : ""
      return (
        <div
          key={place.id}
          onClick={this.selectSuggestion.bind(this, place)}
          className={`mapbox-places-list-item location-search-autocomplete-item ${activeClass}`}>
          {type === 'global' ? place.place_name : place.address_with_route}
        </div>
      )
    })
  }

  renderResult() {
    if (this.state.showList) {
      return (
        <Paper className="mapbox-places-list location-search-suggestions-container">
          {this.renderPlacesList()}
        </Paper>
      )
    }
  }

  render() {
    let containerClasses = `location-search-container ${this.props.classNames}`

    return (
      <div className={containerClasses}>
        <TextField
          autoComplete="off"
          id={this.props.inputId || "address"}
          label={this.props.label || "Address"}
          fullWidth
          placeholder="Search..."
          onChange={this.props.handleChange.bind(this, this.getPlaces.bind(this))}
          onKeyUp={this.handleKeyUp.bind(this)}
          value={this.props.value || ''}
          className="location-search-input" />
        {this.renderResult()}
      </div>
    )
  }
}

MaterialMapboxAutocomplete.defaultProps = {
  accessToken: "ACCESS_TOKEN"
}

export default MaterialMapboxAutocomplete
