import React from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import axios from 'axios'
import ModeEdit from '@material-ui/icons/Edit'
import ExportLink from '../shared/ExportLink'
import More from '@material-ui/icons/More'
import IconButton from '@material-ui/core/IconButton'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

class StockOrders extends React.Component {
  state = {
    orders: [],
    loading: false,
    paginationDisabled: false,
    currentPage: 1
  }

  componentDidMount() {
    this.loadOrders()
  }

  loadOrders() {
    this.startLoading()
    axios.get(Routes.inventory_warehouse_stock_orders_path(tenant, depoId, this.warehouseId(), { format: 'json', page: this.state.currentPage })).then(res => {
      this.setState({ orders: res.data, loading: false, paginationDisabled: false })
    })
  }

  warehouseId() {
    return this.props.warehouseId
  }

  startLoading = () => this.setState({ loading: true })

  stopLoading = () => this.setState({ loading: false })

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading}/>
  }

  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell>Ordered at</TableCell>
          <TableCell>Message</TableCell>
          <TableCell>Created by</TableCell>
          <TableCell>Created at</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderEditLink(status, id) {
    if (status !== 'pending') return
    return (
      <Link to={Routes.edit_inventory_warehouse_stock_orders_path(tenant, depoId, this.warehouseId(), id)}>
        <IconButton>
          <ModeEdit />
        </IconButton>
      </Link>
    )
  }

  renderExportLink(id, from_warehouse, to_warehouse) {
    if (!id || from_warehouse && from_warehouse.owner_type !== 'Depo' || to_warehouse && to_warehouse.owner_type !== 'Car') return
    const path = Routes.export_order_document_inventory_warehouse_stock_order_path(
      tenant, depoId, this.warehouseId(), id, { format: 'xlsx' }
    )
    return <ExportLink path={path} />
  }

  changePage(pageIncrement) {
    const { orders, currentPage } = this.state
    if (orders.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, currentPage + pageIncrement))
    if (newPage === currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadOrders)
  }

  renderTablePagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className="pagination float-right">
        <strong>Page: {currentPage}</strong>
        &nbsp;
        <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }

  renderTableData() {
    return (
      <TableBody>
        {this.state.orders.map(order => {
          const { id, status, from_warehouse, to_warehouse, ordered_at, created_by, created_at, main_storage_message } = order
          return (
            <TableRow className={status} key={id}>
              <TableCell>{from_warehouse && from_warehouse.name}</TableCell>
              <TableCell>{to_warehouse && to_warehouse.name}</TableCell>
              <TableCell>{ordered_at}</TableCell>
              <TableCell>{main_storage_message}</TableCell>
              <TableCell>{created_by}</TableCell>
              <TableCell>{created_at}</TableCell>
              <TableCell>
                {this.renderEditLink(status, id)}
                <Link to={Routes.inventory_warehouse_stock_order_path(tenant, depoId, this.warehouseId(), id)}>
                  <IconButton>
                    <More />
                  </IconButton>
                </Link>
                {this.renderExportLink(id, from_warehouse, to_warehouse)}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  render() {
    return (
      <div>
        {this.renderTablePagination()}
        <Table>
          {this.renderTableHead()}
          {this.renderTableData()}
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default StockOrders
