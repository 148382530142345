import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import EndOfDayReportsTable from './EndOfDayReportsTable'
import OrdersAndOrderLineItemsTable from './orders_and_order_line_items/OrdersAndOrderLineItemsTable'

const EndOfDayReportsComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/:tenant/depo/:depoId/end_of_day_reports' component={EndOfDayReportsTable} />
        <Route exact path='/:tenant/depo/:depoId/end_of_day_reports/:endOfDayReportId' component={OrdersAndOrderLineItemsTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default EndOfDayReportsComponent
