import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import MinimumRequiredBalance from './MinimumRequiredBalance'
import SharedTable from '../../shared/SharedTable'
import cloneDeep from 'lodash/cloneDeep'

class ProductsFormBalances extends React.Component {
  state = {
    movementStartItem: null,
    movementStartItemKey: null,
    startSingleMovementFrom: false,
    startMultipleMovementFrom: false,
    tabView: 'minimum_required_balances'
  }

  updateProductsByKeysWithValues(updatables) {
    const { minimum_required_balances } = this.props
    const minimumRequiredBalancesCopy   = cloneDeep(minimum_required_balances)

    updatables.forEach((updatable) => {
      const index = updatable.index
      updatable.data.forEach(({ key, value }) => {
        minimumRequiredBalancesCopy[index][key] = value
      })
    })

    this.props.updateMinimumRequiredBalances(minimumRequiredBalancesCopy)
  }

  updateStateByKeysWithValues(updatables) {
    updatables.forEach(updatable => this.setState({ [updatable.key]: updatable.value }))
  }

  clearMovementStates() {
    this.setState({ startSingleMovementFrom: false, movementStartItemKey: false, startMultipleMovementFrom: false })
  }

  getStateOfKey   = (key) => this.state[key]
  handleTabChange = (event, value) => this.setState({ tabView: value })

  renderTabSwitch() {
    if (!this.props.productId) return
    const { tabView } = this.state
    const warehouse_balances_disabled = this.props.remaining_warehouse_product_balances.length < 1
    return (
      <Tabs
        indicatorColor="secondary"
        textColor="secondary"
        className="w-100 mt-3"
        value={tabView}
        centered
        onChange={this.handleTabChange.bind(this)}>
        <Tab value="minimum_required_balances" label="Minimum required balances" />
        <Tab disabled={warehouse_balances_disabled} value="warehouse_balances" label="Warehouse balances" />
      </Tabs>
    )
  }

  renderTabView() {
    const { tabView } = this.state
    switch(tabView) {
      case 'minimum_required_balances':
        return this.renderMinimumRequiredBalanceTable()
      case 'warehouse_balances':
        return this.renderRemainingWarehouseProductBalances()
    }
  }

  renderMinimumRequiredBalanceTable() {
    const { minimum_required_balances } = this.props
    return (
      <MinimumRequiredBalance
        minimum_required_balances={minimum_required_balances}
        updateProductsByKeysWithValues={this.updateProductsByKeysWithValues.bind(this)}
        updateStateByKeysWithValues={this.updateStateByKeysWithValues.bind(this)}
        getStateOfKey={this.getStateOfKey.bind(this)}
        clearMovementStates={this.clearMovementStates.bind(this)} />
    )
  }

  renderRemainingWarehouseProductBalances() {
    const { remaining_warehouse_product_balances } = this.props
    return (
      <div className="remaining-warehouse-product-balances-table-wrapper">
        <SharedTable
          columns={['Warehouse type', 'Warehouse name', 'Balance amount']}
          data={remaining_warehouse_product_balances} />
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderTabSwitch()}
        {this.renderTabView()}
      </React.Fragment>
    )
  }
}

export default ProductsFormBalances
