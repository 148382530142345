import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const EndOfDayReportsTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Route</TableCell>
        <TableCell>Car</TableCell>
        <TableCell>Driver</TableCell>
        <TableCell>Total ({window.currency})</TableCell>
        <TableCell>Approved By</TableCell>
        <TableCell>Transfer</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default EndOfDayReportsTableHead
