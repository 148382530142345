import React from 'react'
import ErrorCatcher from '../../shared/ErrorCatcher'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import B2bOrdersList from './list'

const EshopB2bOrdersIndex = () => {
  return  (
    <ErrorCatcher>
      <div className="content-container">
        <BrowserRouter>
          <Switch>
            <Route path='/:tenant/eshop/depo/:depoId/b2b_orders' component={B2bOrdersList} />
          </Switch>
        </BrowserRouter>
      </div>
    </ErrorCatcher>
  )
}

export default EshopB2bOrdersIndex
