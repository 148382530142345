import React from 'react'
import omit from 'lodash/omit'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import { connect } from 'react-redux'
import { setFlash } from '../../files/actions/index'

const iconStyle = {
  color: 'gray',
  marginTop: 8,
  marginRight: 5,
  cursor: 'pointer'
}

class FunSearchFieldClass extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchPhrase: ''
    }
  }

  handleChange(e) {
    this.setState({searchPhrase: e.target.value})
  }

  clearInput() {
    this.setState({searchPhrase: ''})
    this.props.clearSearch()
  }

  handleOnKeyDown(e) {
    if(e.key === "Enter" && this.state.searchPhrase.length > 2) {
      this.props.handleSubmit(this.state.searchPhrase)
    } else if(e.key === "Enter" && this.state.searchPhrase.length < 2) {
      this.props.setFlash("You must enter more than 2 characters in search field")
    }
  }

  renderInputIcon() {
    if(this.state.searchPhrase) {
      return <Icon style={iconStyle} onClick={this.clearInput.bind(this)}>close</Icon>
    } else {
      return <Icon style={iconStyle}>search</Icon>
    }
  }

  render() {
    return (
      <Input
        id="search-field"
        value={this.state.searchPhrase}
        onChange={this.handleChange.bind(this)}
        onKeyDown={this.handleOnKeyDown.bind(this)}
        fullWidth
        margin="dense"
        placeholder="Search"
        disableUnderline={true}
        endAdornment={
          <InputAdornment position="end">
            {this.renderInputIcon()}
          </InputAdornment>
        }
      />
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setFlash: flash => dispatch(setFlash(flash))
  }
}

const FunSearchField = connect(
  mapStateToProps,
  mapDispatchToProps
)(FunSearchFieldClass)

export default FunSearchField
