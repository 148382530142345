import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

class IconButtonMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      approvalMenuAnchorEl: null
    }
  }

  handleMenuOpen(e) {
    this.setState({ approvalMenuAnchorEl: e.currentTarget })
  }

  handleMenuClose() {
    this.setState({ approvalMenuAnchorEl: null })
  }

  handleOptionSelect(e) {
    this.props.handleSelect(e, e.target.getAttribute('value'))
    this.handleMenuClose()
  }

  render() {
    const { approvalMenuAnchorEl } = this.state
    return (
      <div>
        <IconButton id="kebab-menu-icon" onClick={this.handleMenuOpen.bind(this)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={approvalMenuAnchorEl}
          open={Boolean(approvalMenuAnchorEl)}
          onClose={this.handleMenuClose.bind(this)}
        >
          {this.props.options.map(option => (
            <MenuItem value={option.value} key={option.value} onClick={this.handleOptionSelect.bind(this)}>
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

export default IconButtonMenu
