import React from 'react'
import axios from 'axios'
import LoadingWithoutRedux from '../shared/LoadingWithoutRedux'
import Select from '../shared/Select'
import ModalWrapper from '../shared/Modal'
import FunTimeInput from '../forms/FunTimeInput'
import Button from '@material-ui/core/Button'
import moment from 'moment'

export default class RouteChangeModal extends React.Component {
  state = { depos: [], modalLoading: false, selectedDepoId: '' }

  componentDidMount() {
    this.fetchDepos()
  }

  fetchDepos() {
    axios.get(Routes.all_depos_admin_depos_path(tenant, { format: 'json' })).then(res => {
      this.setState({ depos: res.data })
    }).catch(err => {
      this.props.setMessage(`${err}`)
    })
  }

  saveDepo() {
    const { order, path } = this.props
    const { selectedDepoId } = this.state

    this.setState({ modalLoading: true })
    axios.put(path(tenant, depoId, order.id, { format: 'json', new_depo_id: selectedDepoId })).then(res => {
      this.props.loadOrders()
      this.setState({ modalLoading: false })
    }).catch(err => {
      this.props.setMessage(`${err}`)
      this.setState({ modalLoading: false })
    })
  }

  handleDepoSelect(e) {
    this.setState({ selectedDepoId: e.target.value })
  }

  renderDeposSelect() {
    const { depos, selectedDepoId } = this.state

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Select
          classNames="depos-select"
          additionalStyles={{ width: 230, marginRight: 10 }}
          options={depos}
          selectedOption={selectedDepoId}
          optionLabel="title"
          label="Depo"
          handleSelect={this.handleDepoSelect.bind(this)} />
        <Button className="mt-2" onClick={this.saveDepo.bind(this)} variant="contained" color="primary">Save</Button>
      </div>
    )
  }

  render() {
    const { order } = this.props

    return (
      <ModalWrapper
        classes="order-modal"
        style={{ width: 400, height: 150 }}
        open={order.depoModalOpen || false}
        title="Change depo"
        close={this.props.handleModalClose.bind(this, order)}>
        {this.renderDeposSelect()}
        <LoadingWithoutRedux loading={this.state.modalLoading} additionalStyles={{ marginLeft: '20%' }} />
      </ModalWrapper>
    )
  }
}
