import {
  CREATE_ROUTE_POINT_FROM_MAP,
  LOAD_ROAD_POINTS,
  UPDATE_POINT,
  CLEAR_ROUTE_POINTS,
  ZOOM_ON_POINT,
  UPDATE_AFTER_MOVE,
  PLACE_ROUTE_POINTS_ON_MAP,
  CLEAR_ROUTE_POINTS_ON_MAP,
  LOAD_PRINT_PREVIEW_STATUS,
  UPDATE_PRINT_GRID_ROW_COUNT,
  UPDATE_PRINT_GRID_COLUMN_COUNT,
  SET_MAP_PRINTING_STATUS,
  CANCEL_PRINT_PREVIEW,
  LOAD_UNPLANNED_SALES,
  CLEAR_UNPLANNED_SALES,
  LOAD_ROUTE_MINIMUM_SALES_EFFICIENT_COUNT,
  UPDATE_SIBLINGS,
  LOAD_STOP_TYPES,
  LOAD_CUSTOMERS,
  CLEAR_CUSTOMERS,
  LOAD_SIMILAR_ROUTES,
} from "../constants/action-types"

import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import each from 'lodash/each'

const initialState = {
  roadPoints: [],
  zoomedPoint: null,
  placedPointsOnMap: {},
  unplannedSales: [],
  stopTypes: [],
  customers: [],
  similarRoutes: []
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const routePointsReducer = (state = initialState, action) => {
  const payload = action.payload
  let newRoadPoints, roadIndex
  switch (action.type) {
    case LOAD_ROAD_POINTS:
      return { ...state, roadPoints: payload }
    case CLEAR_ROUTE_POINTS:
      return { ...state, roadPoints: [] }
    case CREATE_ROUTE_POINT_FROM_MAP:
      newRoadPoints = state.roadPoints.slice(0)
      newRoadPoints.push(payload)
      return { ...state, roadPoints: newRoadPoints }
    case UPDATE_AFTER_MOVE:
      return { ...state, roadPoints: payload.filter(({address}) => address) }
    case UPDATE_POINT:
      newRoadPoints = state.roadPoints.slice(0)
      roadIndex = findIndex(newRoadPoints, ({ id }) => id && id == payload.id)
      if(!!newRoadPoints[roadIndex] && payload.address == newRoadPoints[roadIndex].address && newRoadPoints[roadIndex].addressChanged === payload.addressChanged) {
        return { ...state, roadPoints: state.roadPoints }
      }

      if(roadIndex == -1) {
        roadIndex = findIndex(newRoadPoints, ({ _objectId }) => _objectId == payload._objectId)
        if(!!newRoadPoints[roadIndex] && payload.address == newRoadPoints[roadIndex].address) {
          return { ...state, roadPoints: state.roadPoints }
        }

        if(roadIndex == - 1) {
          newRoadPoints.push(payload)
        } else {
          newRoadPoints[roadIndex] = merge(newRoadPoints[roadIndex], payload)
        }
      } else {
        newRoadPoints[roadIndex] = merge(newRoadPoints[roadIndex], payload)
      }

      return { ...state, roadPoints: newRoadPoints }
    case ZOOM_ON_POINT:
      newRoadPoints = state.roadPoints.slice(0)
      roadIndex = findIndex(newRoadPoints, ({ id }) => id && id == payload.id)
      const point = { lat: payload.point.coordinates[1], lon: payload.point.coordinates[0] }
      newRoadPoints[roadIndex] = merge(newRoadPoints[roadIndex], payload)

      return { ...state, zoomedPoint: point, roadPoints: newRoadPoints }
    case PLACE_ROUTE_POINTS_ON_MAP:
      const placedPointsOnMap = cloneDeep(state.placedPointsOnMap)
      const paylodPoints = payload[0]
      const payloadRouteId = payload[1]
      placedPointsOnMap[payloadRouteId] = [paylodPoints, getRandomColor()]

      return { ...state, placedPointsOnMap: placedPointsOnMap }
    case CLEAR_ROUTE_POINTS_ON_MAP:
      return { ...state, placedPointsOnMap: {} }
    case LOAD_PRINT_PREVIEW_STATUS:
      const mapPrinting = !payload ? false : state.mapPrinting

      return { ...state, printPreview: payload, mapPrinting: mapPrinting }
    case UPDATE_PRINT_GRID_ROW_COUNT:
      return { ...state, printGridRowCount: payload }
    case UPDATE_PRINT_GRID_COLUMN_COUNT:
      return { ...state, printGridColumnCount: payload }
    case SET_MAP_PRINTING_STATUS:
      return { ...state, ...payload }
    case CANCEL_PRINT_PREVIEW:
      return { ...state, printGridRowCount: 1, printGridColumnCount: 1, mapPrinting: 'ended' }
    case LOAD_UNPLANNED_SALES:
      return { ...state, unplannedSales: payload }
    case CLEAR_UNPLANNED_SALES:
      return { ...state, unplannedSales: [] }
    case LOAD_CUSTOMERS:
      return { ...state, customers: payload }
    case CLEAR_CUSTOMERS:
      return { ...state, customers: [] }
    case LOAD_ROUTE_MINIMUM_SALES_EFFICIENT_COUNT:
      return {...state, routeMinimumSalesEfficientCount: payload }
    case UPDATE_SIBLINGS:
      newRoadPoints = state.roadPoints.slice(0)
      each(newRoadPoints, point => {
        if (point.checked) point.wait_time = payload
      })

      return { ...state, roadPoints: newRoadPoints }
    case LOAD_STOP_TYPES:
      return { ...state, stopTypes: payload }
    case LOAD_SIMILAR_ROUTES:
      return { ...state, similarRoutes: payload }
    default:
      return state
  }
}

export default routePointsReducer
