import React from  'react'
import Modal from '@material-ui/core/Modal'

const styles = {
  modalContainer: {
    justifyContent: 'center'
  },
  content: {
    alignSelf: 'center',
    background: 'white',
    borderRadius: 5,
    boxSizing: 'border-box',
    padding: 20,
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.5)',
    outline: 'none',
    position: 'relative',
    top: '25%',
    left: '33%',
    width: 500
  },
  title: {
    margin: 0,
    marginBottom: 20
  }
}

class ModalWrapper extends React.Component {
  render() {
    const { open, children, title, close, style, BackdropProps, modalContainerStyles, classes } = this.props

    return(
      <Modal open={open} style={{ ...styles.modalContainer, ...modalContainerStyles }} onClose={close} BackdropProps={BackdropProps}>
        <div className={classes} style={{ ...styles.content, ...style }}>
          {title && <h5 style={styles.title}>{title}</h5>}

          {children}
        </div>
      </Modal>
   )
  }
}

export default ModalWrapper
