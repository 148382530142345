import React from 'react'
import MainTitle from './MainTitle'
import Loading from './Loading'
import Flash from './Flash'

const TitleContainer = ({children}) => {
  return (
    <div className='content-container'>
      <MainTitle />
      {children}
      <Loading />
      <Flash />
    </div>
  )
}

export default TitleContainer