import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import Typography from '@material-ui/core/Typography'
import RoutePointsTableHead from './RoutePointsTableHead'
import RoutePointsTableRow from './RoutePointsTableRow'
import MainTitle from '../shared/MainTitle'
import BackButton from '../shared/BackButton'
import IconButtonMenu from '../shared/IconButtonMenu'
import RoutePoint from '../../models/RoutePoint'
import {
  loadRoadPoints,
  setMainTitle,
  startLoading,
  stopLoading,
  clearRoutePoints,
  setFlash,
  loadRoute,
  updateAfterMove,
  loadPrintPreviewStatus,
  loadUnplannedSales,
  clearUnplannedSales,
  loadCustomers,
  clearCustomers,
  loadRouteMinimumSalesEfficientCount,
  loadSimilarRoutes
} from '../../files/actions/index'
import extractDataErrors from '../support/extractDataErrors'
import find from 'lodash/find'
import reduce from 'lodash/reduce'
import each from 'lodash/each'
import Loading from '../shared/Loading'
import SaveButton from '../shared/SaveButton'
import ConfirmationDialog from '../shared/ConfirmationDialog'
import RoutePointsPrintTable from '../shared/RoutePointsPrintTable'
import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'
import debounce from 'lodash/debounce'
import arrayMove from '../../components/support/arrayMove'
import Dragula from 'react-dragula'
import autoScroll from 'dom-autoscroller'
import RoutePointsTimeFields from './RoutePointsTimeFields'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import RoutePointsMoveForm from './RoutePointsMoveForm'
import RoutePointsPrintPreview from './RoutePointsPrintPreview'
import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'
import MaterialDatePicker from '../shared/MaterialDatePicker'
import ModalWrapper from '../shared/Modal'
import MaterialMapboxAutocomplete from '../shared/MaterialMapboxAutocomplete'

const titleStyle = {
  marginLeft: 50,
  marginTop: 15,
  marginBottom: 18,
}

class RoutePointsTableClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      removing: false,
      routePoints: [],
      checked: false,
      open: false,
      dialogOpen: false,
      forCurrentRoute: false,
      approval_status: null,
      timeFieldsToggle: '',
      salesPointDataFetched: false,
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
      customAddressesOpen: false,
      startAddress: '',
      endAddress: ''
    }
  }

  componentDidMount() {
    this.props.startLoading()
    axios.get(this.url()).then(res => {
      this.setInitialRouteAndRoutePointsState(res.data)
    }).catch(this.errorMessage.bind(this))
    this.fetchSimilarRoutes()
    this.startDrake()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.routePointsLengthChanged(props) || this.waitTimeChanged(props) || this.addressChanged(props, this.props) || this.positionChanged(props)) {
      this.setState({ routePoints: props.routePoints.concat(new RoutePoint()) })
    } else if (Object.values(props.route).join('') !== Object.values(this.props.route).join('')) {
      this.setState({ ...props.route })
    }
  }

  componentWillUnmount() {
    this.props.clearRoutePoints()
    this.props.clearUnplannedSales()
    this.props.clearCustomers()
    this.props.loadSimilarRoutes([])
  }

  addressChanged(nextProps, currentProps) {
    const currentChanged = currentProps.routePoints.map(p => p.addressChanged)
    const nextChanged = nextProps.routePoints.map(p => p.addressChanged)

    return currentChanged.join('') !== nextChanged.join('')
  }

  routePointsLengthChanged(props) {
    return props.routePoints.length !== this.routePointsWithAddress().length
  }

  waitTimeChanged(props) {
    const currentWaitTimes = this.routePointsWithAddress().map(p => p.wait_time)
    const nextWaitTimes = props.routePoints.map(p => p.wait_time)
    return currentWaitTimes.join(', ') !== nextWaitTimes.join(', ')
  }

  positionChanged(props) {
    const currentPositions = this.props.routePoints.map(p => [p.position, p.id])
    const nextPositions = props.routePoints.map(p => [p.position, p.id])
    return currentPositions.join(', ') !== nextPositions.join(', ')
  }

  setInitialRouteAndRoutePointsState(route) {
    this.props.setMainTitle(route.name)
    this.props.loadRoute(route)
    this.setState({ ...route, startAddress: route.start_address, endAddress: route.end_address }, () => {
      this.fetchRoutePoints()
      this.props.loadRouteMinimumSalesEfficientCount(route.minimum_efficient_sales_count)
    })
  }

  routesFilter() {
    return this.props.location.state ? this.props.location.state.filter : 'active'
  }

  errorMessage(err) {
    this.props.setFlash(`${err}`)
    this.props.stopLoading()
  }

  startDrake() {
    let mirrorContainer = $('.gu-mirror')
    let selectedItems
    const drake = Dragula([document.querySelector('.drag-table-body')], {
      moves: (el) => {
        this.start = this.getIndexInParent(el)
        return this.state.routePoints[this.start] && this.state.routePoints[this.start].address && this.state.routePoints[this.start].address.length > 0
      }
    })
    drake.on('cloned', (clone) => {
      const startPoint = this.state.routePoints[this.start]
      if (this.checkedRoutePoints().length > 1) {
        if (startPoint.checked) {
          mirrorContainer = $('.gu-mirror').first()
          mirrorContainer.removeClass('checked-true')
          $('.gu-transit').addClass('checked-true')
          selectedItems = $('.checked-true')
          mirrorContainer.empty()
          if (selectedItems.length > 1) {
            selectedItems.each(function(index) {
              const item = $(this)
              const mirror = item.clone(true)
              mirror.removeClass('checked-true gu-transit')
              mirrorContainer.append(mirror)
              mirrorContainer.css('background-color', 'transparent')
              item.addClass('gu-transit')
            })
          }
        }
      }
    }).on('drop', (el, target) => {
      this.end = this.getIndexInParent(el)
      if (this.checkedRoutePoints().length > 1) {
        const startPoint = this.state.routePoints[this.start]
        if (startPoint.checked) {
          this.moveRoutePoints(this.start, this.end, this.checkedRoutePoints())
        } else {
          this.updateIndexForOnePoint(this.start, this.end)
        }
      } else {
        this.updateIndexForOnePoint(this.start, this.end)
      }
      $('.checked-true').removeClass('gu-transit')
    }).on('cancel', () => {
      if (selectedItems) {
        selectedItems.removeClass('gu-transit')
      }
    }).on('dragend', () => {
      if (selectedItems) {
        selectedItems.removeClass('gu-transit')
      }
    })

    const scroll = autoScroll([document.querySelector('.map-sidebar')], {
      margin: 20,
      maxSpeed: 5,
      scrollWhenOutside: true,
      autoScroll: function() {
        return this.down && drake.dragging
      }
    })
  }

  updateIndexForOnePoint(start, end) {
    this.moveRoutePoint(start, end)
    // Bacause of dirty dragula we handle indexes manually with vanilla
    let i = 0
    const table = document.querySelector('.drag-table-body')
    // Table cell in which index is stored, needs to be the correct one always
    const tbodies = Array.from(table.childNodes).filter(a => a.tagName !== "THEAD")
    each(tbodies, (tbody) => {
      tbody.childNodes[0].childNodes[1].textContent = ++i
    })
    this.changeUpdateState()
  }

  moveRoutePoint(dragIndex, draggedIndex) {
    const routePoints = arrayMove(this.state.routePoints, dragIndex, draggedIndex).slice(0)
    this.setState({ routePoints }, () => this.props.updateAfterMove(routePoints))
    this.changeUpdateState()
  }

  moveRoutePoints(dragIndex, draggedIndex, points) {
    const movingIndexes = points.map(point => {
      return findIndex(this.routePointsWithAddress(), ({ id, _objectId }) => {
        if (id) {
          return id == point.id
        } else {
          return _objectId == point._objectId
        }
      })
    }).filter(i => i !== -1)
    const movedPoints = arrayMove(this.routePointsWithAddress(), dragIndex, draggedIndex, movingIndexes)
    this.setState({ routePoints: movedPoints.concat(new RoutePoint()) })
    this.props.loadRoutePoints(movedPoints)
    this.changeUpdateState()
  }

  checkedRoutePoints() {
    return this.state.routePoints.filter(({checked}) => checked)
  }

  routePointsWithAddress() {
    return this.state.routePoints.filter(({point}) => point)
  }

  fetchSimilarRoutes() {
    this.props.startLoading()
    axios.get(Routes.similar_routes_route_path(tenant, depoId, this.routeId(), { format: 'json' })).then(res => {
      this.props.loadSimilarRoutes(res.data)
      this.props.stopLoading()
    }).catch(this.errorMessage.bind(this))
  }

  fetchRoutePoints() {
    axios.get(Routes.route_points_path(tenant, depoId, this.routeId(), this.filterData())).then(res => {
      const routePoints = res.data.map(point => {
        point.classAndColor = this.classAndColorForMinimumEfficientSalesCount(point.sales_count)
        point.checked = false
        return point
      })

      this.setState({ updated: false, routePoints: routePoints.concat(new RoutePoint()) })
      this.props.loadRoutePoints(res.data)
      this.fetchUnplannedSales()
    }).catch(this.errorMessage.bind(this))
  }

  unplannedSalesUrl() {
    return Routes.unplanned_sales_route_points_path(tenant, depoId, this.routeId(), { format: 'json' })
  }

  fetchUnplannedSales() {
    axios.get(this.unplannedSalesUrl()).then(res => {
      this.props.loadUnplannedSales(res.data)
      this.props.stopLoading()
    }).catch(this.errorMessage.bind(this))
  }

  classAndColorForMinimumEfficientSalesCount(sales_count) {
    const { minimum_efficient_sales_count } = this.state

    if (sales_count >= minimum_efficient_sales_count) {
      return { color: 'green', class: 'sales-high' }
    } else if (sales_count > 0) {
      return { color: 'orange', class: 'sales-medium' }
    } else {
      return { color: 'red', class: 'sales-low' }
    }
  }

  getIndexInParent(el) {
    return Array.from(el.parentNode.children).filter(node => node.tagName !== "THEAD").indexOf(el)
  }

  filterData() {
    const startDateComponent = this.refs.startDate
    const endDateComponent = this.refs.endDate
    const { startDate } = startDateComponent.state
    const { endDate } = endDateComponent.state
    return {
      start_date: startDate,
      end_date: endDate,
      format: 'json'
    }
  }

  unplannedSalesUrl() {
    return Routes.unplanned_sales_route_path(tenant, depoId, this.routeId(), this.filterData())
  }

  customersUrl() {
    return Routes.customers_route_path(tenant, depoId, this.routeId(), { format: 'json' })
  }

  url() {
    return Routes.route_path(tenant, depoId, this.routeId(), { format: 'json' })
  }

  routeId() {
    return this.props.match.params.routeId
  }

  routePointAttributesData() {
    let position = 0
    const routePointsWithAddress = this.routePointsWithAddress()
    return reduce(routePointsWithAddress, (result, routePoint) => {
      if (routePoint.markedForDestruction == true) {
        routePoint.deleted_at = new Date()
        delete routePoint['markedForDestruction']
      }
      routePoint.position = (++position)
      result[position] = routePoint

      return result
    }, {})
  }

  data() {
    const {
      firstStopTime, lastStopTime, timeFieldsToggle, administrationTime, eodAdministrationTime
    } = this.refs.timeFields.state
    let routeDuration = null
    if (timeFieldsToggle === 'routeDuration') {
      const firstTime = moment(firstStopTime, 'HH:mm')
      const lastTime = moment(lastStopTime, 'HH:mm')
      routeDuration = lastTime.diff(firstTime, 'minutes') / 60
    }

    return {
      format: 'json',
      route: {
        administration_time: administrationTime,
        eod_administration_time: eodAdministrationTime,
        first_stop_arrival_time: firstStopTime,
        last_stop_arrival_time: lastStopTime,
        route_points_attributes: this.routePointAttributesData(),
        route_duration: routeDuration,
        route_type: snakeCase(timeFieldsToggle),
        should_approve: this.state.approval_status,
        start_address: this.state.startAddress,
        end_address: this.state.endAddress,
        start_coordinates: this.state.startCoordinates,
        end_coordinates: this.state.endCoordinates
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.routePointsWithAddress().length > 0) {
      this.props.startLoading()
      return axios.patch(
        this.url(),
        this.data()
      ).then(res => {
        this.fetchRoutePoints()
        this.props.loadRoute(res.data)
        this.setState({ customAddressesOpen: false })
      }).catch(error => {
        this.props.setFlash(extractDataErrors(error).full_errors[0])
        this.props.stopLoading()
      })
    }
  }

  updateRoutePoints(point) {
    const { routePoints } = this.state

    let roadIndex = findIndex(routePoints, ({ id }) => id && id == point.id)
    if(roadIndex == -1) {
      roadIndex = findIndex(routePoints, ({ _objectId }) => _objectId == point._objectId)
    }
    routePoints[roadIndex] = merge(routePoints[roadIndex], point)
    this.setState({ routePoints })
    this.changeUpdateState()
  }

  findRouteInStore() {
    return find(this.props.routes, ({ id }) => {
      return id == this.routeId()
    })
  }

  addNewRoutePoint(index) {
    const { routePoints } = this.state
    if (index == this.state.routePoints.length - 1) {
      this.setState({routePoints: routePoints.concat(new RoutePoint())})
      this.changeUpdateState()
    }
  }

  removePointFromState(index) {
    const routePoints = this.state.routePoints.slice(0)
    routePoints.splice(index - 1, 1)
    this.setState({ removing: !this.state.removing, routePoints: routePoints })
    this.props.stopLoading()
    this.changeUpdateState()
  }

  toggleDialogIfUpdated(e) {
    if (this.state.updated || (this.refs.timeFields && this.refs.timeFields.state.updated)) {
      this.toggleDialog()
      e.preventDefault()
    }
  }

  toggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen })
  }

  closeDialogAndLeave() {
    this.toggleDialog()
    this.props.history.push(Routes.filter_routes_path(tenant, depoId, this.routesFilter()))
  }

  renderDatePeriodPickers() {
    const { startDate, endDate } = this.state
    return (
      <div className="mt-2 datepicker-container">
        <MaterialDatePicker
          afterChange={this.fetchRoutePoints.bind(this)}
          fieldName="startDate"
          ref="startDate"
          value={startDate.format('DD-MM-YYYY')}
          label="Start date"
        />
        <MaterialDatePicker
          afterChange={this.fetchRoutePoints.bind(this)}
          fieldName="endDate"
          ref="endDate"
          value={endDate.format('DD-MM-YYYY')}
          label="End date" />
      </div>
    )
  }

  renderTable() {
    const { route_type } = this.state

    return (
      <Table className='drag-table-body' style={{ borderRight: '2px solid lightgray' }} style={{overflow: 'auto'}}>
        <RoutePointsTableHead checkAll={this.checkAll.bind(this)} routeType={route_type} />
        {this.renderTableBodyRows()}
        <ConfirmationDialog
          open={this.state.dialogOpen}
          close={this.toggleDialog.bind(this)}
          leave={this.closeDialogAndLeave.bind(this)}
          confirmAndCloseText="Leave"
          cancelText="Cancel"
          title='Unsaved changes detected!'
          content='There are unsaved changes which will be lost if you leave!'
        />
      </Table>
    )
  }

  updateRouteType(type, e) {
    this.setState({ route_type: type })
  }

  renderTableBodyRows() {
    const { routePoints, route_type } = this.state

    return (
      routePoints.map((routePoint, index) => {
        return <RoutePointsTableRow
          timeFields={this.refs.timeFields}
          accessToken={this.props.accessToken}
          isbilenAccessToken={this.props.isbilenAccessToken}
          routeId={this.routeId()}
          routeType={route_type}
          updateRouteType={this.updateRouteType.bind(this)}
          customers={this.props.customers}
          routePoint={routePoint}
          routePoints={routePoints}
          key={`${routePoint.id}-${index}-${routePoint._objectId}-${routePoint.checked}-${routePoint.addressChanged}-${routePoint.wait_time}-${routePoint.b2b_customers.length}`}
          updateParentPoints={(point, checked) => this.updateRoutePoints(point)}
          index={index + 1}
          addNewRoutePoint={this.addNewRoutePoint.bind(this, index)}
          removePointFromState={this.removePointFromState.bind(this)}
        />
      })
    )
  }

  renderTimeFields() {
    const {
      routePoints, first_stop_arrival_time, last_stop_arrival_time, route_type,
      administration_time, eod_administration_time
    } = this.state

    if (routePoints.length > 1) {
      return (
        <RoutePointsTimeFields
          ref='timeFields'
          routePoints={this.state.routePoints}
          updateRouteType={this.updateRouteType.bind(this)}
          routeType={route_type}
          firstStopTime={first_stop_arrival_time || ''}
          lastStopTime={last_stop_arrival_time || ''}
          administrationTime={administration_time || ''}
          eodAdministrationTime={eod_administration_time || ''}
          calculatedWaitTime={routePoints[0].wait_time || ''}
          firstStopWaitTime={routePoints[0].wait_time || ''}
          route={this.props.route}
          markAsUpdated={this.changeUpdateState.bind(this)}
        />
      )
    }
  }

  renderActionBar() {
    const checked = this.props.routePoints.filter(({checked}) => checked).length > 0
    if (checked) {
      return (
        <div className='move-route-points-button'>
          <Button onClick={this.toggleModal.bind(this, false)} variant="contained">
            <Typography>Move</Typography>
          </Button>
          <Button onClick={this.toggleModal.bind(this, true)} variant="contained">
            <Typography>Move in this route</Typography>
          </Button>
        </div>
      )
    }
  }

  toggleModal(route) {
    this.setState({ open: !this.state.open, forCurrentRoute: route })
  }

  checkAll(checked) {
    const newRoutePoints = this.state.routePoints.map(routePoint => {
      routePoint.checked = checked
      return routePoint
    })

    this.setState({ checked, routePoints: newRoutePoints })
  }

  changeUpdateState() {
    if (this.state.updated) return
    this.setState({ updated: true })
  }

  handleApprovement(e, value) {
    e.persist()
    this.setState({ approval_status: value }, () => {
      this.handleSubmit(e).then(() => {
        this.props.setFlash('Route successfully approved!')
      })
    })
  }

  renderApprovalMenu() {
    if (this.props.printPreview || this.routePointsWithAddress().length < 1) return
    const options = [
      { text: 'Print map', value: 'print_map' },
      { text: 'Print route points', value: 'print_route_points'},
      { text: 'Custom start/end address', value: 'custom_start_end_address' }
    ]
    if(this.state.approval_status !== 'approved'){
      options.unshift({ text: 'Approve', value: 'approved' })
    }
    return (
      <span style={{ marginLeft: 'auto' }}>
        <IconButtonMenu options={options} handleSelect={this.handleMenuItemSelect.bind(this)}/>
      </span>
    )
  }

  handleMenuItemSelect(e, target) {
    switch(target) {
      case 'approved':
        this.handleApprovement(e, target)
        break
      case 'print_map':
        this.togglePrintPreview()
        break
      case 'print_route_points':
        this.printRoutePoints()
        break
      case 'custom_start_end_address':
        this.openCustomAddressModal()
        break
    }
  }

  openCustomAddressModal() {
    this.setState({ customAddressesOpen: true })
  }

  closeCustomAddressModal() {
    this.setState({ customAddressesOpen: false })
  }

  printRoutePoints = () => this.refs.routePointsPrintTable.startPrinting()

  togglePrintPreview() {
    this.props.loadPrintPreviewStatus(!this.props.printPreview)
  }

  renderRoutePointsPrintMarkup() {
    if (!this.refs.timeFields) return
    const { state: { eodAdministrationTime } } = this.refs.timeFields
    const { route: { should_leave_at_time, return_to_depo } } = this.props
    const endOfDay = moment.utc(moment.duration(eodAdministrationTime).add(return_to_depo).as('milliseconds')).format("HH:mm:ss")

    return (
      <RoutePointsPrintTable
        key={`${should_leave_at_time}-${endOfDay}`}
        header={this.state.name}
        startOfDay={should_leave_at_time}
        endOfDay={endOfDay}
        ref="routePointsPrintTable"
        startLoading={this.props.startLoading}
        stopLoading={this.props.stopLoading}
        routePoints={this.routePointsWithAddress()}
      />
    )
  }

  renderRoutePointsMoveForm() {
    return (
      <RoutePointsMoveForm
        forCurrentRoute={this.state.forCurrentRoute}
        open={this.state.open}
        toggleModal={this.toggleModal.bind(this)}
        routePoints={this.state.routePoints}
        setFlash={this.props.setFlash}
        startLoading={this.props.startLoading}
        stopLoading={this.props.stopLoading}
        loadRoutePoints={this.props.loadRoutePoints}
        routeId={this.routeId()} />
    )
  }

  selectAddress(field, address, coordinates) {
    this.setState({ [`${field}Address`]: address, [`${field}Coordinates`]: coordinates })
  }

  handleAddressChange(field, getPlaces, e) {
    this.setState({ [`${field}Address`]: e.target.value }, debounce(getPlaces, 500))
  }

  renderAutocomplete(field) {
    return (
      <MaterialMapboxAutocomplete
        type="global"
        inputId={field}
        label={`${startCase(field)} address`}
        handleChange={this.handleAddressChange.bind(this, field)}
        value={this.state[`${field}Address`]}
        selectAddress={this.selectAddress.bind(this, field)}
        accessToken={this.props.accessToken} />
    )
  }

  renderCustomAddressModal() {
    return (
      <ModalWrapper
        classes="custom-address-modal"
        style={{ width: 400, height: 250 }}
        open={this.state.customAddressesOpen}
        title="Add custom start/end addresses"
        close={this.closeCustomAddressModal.bind(this)}>
        {this.renderAutocomplete("start")}
        {this.renderAutocomplete("end")}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
          <Button onClick={this.closeCustomAddressModal.bind(this)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this)}>Save</Button>
        </div>
      </ModalWrapper>
    )
  }

  renderRoutePointsForm() {
    if (this.props.printPreview) return

    return (
      <div>
        <div className='sticky padded-sticky'>
          {this.renderRoutePointsMoveForm()}
          <div className='form-actions'>
            <div className='button-container'>
              <SaveButton onClick={this.handleSubmit.bind(this)} />
              <a className="mdl-button mdl-js-button mdl-js-ripple-effect" onClick={this.fetchRoutePoints.bind(this)} >
                CANCEL
              </a>
              {this.renderDatePeriodPickers()}
            </div>
          </div>
          <div className='route-options-controller-container'>
            {this.renderTimeFields()}
            {this.renderActionBar()}
          </div>
        </div>
        {this.renderTable()}
      </div>
    )
  }

  renderPrintPreview() {
    if (this.props.printPreview) {
      return <RoutePointsPrintPreview printPreview={true} mapPrinting={this.props.mapPrinting} />
    }
  }

  renderCustomLabel() {
    if ((this.state.startAddress && this.state.startAddress.length > 0) || (this.state.endAddress && this.state.endAddress.length > 0)) {
      return <span className="tag-label">custom depo address</span>
    }
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', padding: '15px 15px 0px 15px' }}>
          <MainTitle style={titleStyle} />
          {this.renderCustomLabel()}
          {this.renderApprovalMenu()}
        </div>
        <BackButton to={Routes.filter_routes_path(tenant, depoId, this.routesFilter())} onClick={this.toggleDialogIfUpdated.bind(this)}/>
        <Loading />
        {this.renderRoutePointsForm()}
        {this.renderPrintPreview()}
        {this.renderCustomAddressModal()}
        {this.renderRoutePointsPrintMarkup()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    routes: state.resources,
    routePoints: state.roadPoints,
    printPreview: state.printPreview,
    mapPrinting: state.mapPrinting,
    customers: state.customers,
    route: state.route
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    loadRoutePoints: (routePoints) => dispatch(loadRoadPoints(routePoints)),
    clearRoutePoints: () => dispatch(clearRoutePoints()),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    setFlash: message => dispatch(setFlash(message)),
    loadRoute: route => dispatch(loadRoute(route)),
    updateAfterMove: points => dispatch(updateAfterMove(points)),
    loadPrintPreviewStatus: printPreview => dispatch(loadPrintPreviewStatus(printPreview)),
    loadUnplannedSales: unplannedSales => dispatch(loadUnplannedSales(unplannedSales)),
    loadCustomers: customers => dispatch(loadCustomers(customers)),
    clearUnplannedSales: () => dispatch(clearUnplannedSales()),
    clearCustomers: () => dispatch(clearCustomers()),
    loadRouteMinimumSalesEfficientCount: minimumSalesEfficientCount => dispatch(loadRouteMinimumSalesEfficientCount(minimumSalesEfficientCount)),
    loadSimilarRoutes: similarRoutes => dispatch(loadSimilarRoutes(similarRoutes)),
  }
}

const RoutePointsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePointsTableClass)

export default RoutePointsTable
