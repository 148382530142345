import React from 'react'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ArrowDownwardSharp from '@material-ui/icons/KeyboardArrowDown'
import MapboxAutocomplete from '../../shared/MapboxAutocomplete'
import magnifyingGlass from '../icons/magnifying_glass.svg'
import goToIcon from '../icons/go_to_icon.svg'
import moment from 'moment'
import LangaugeChanger from './shared/LanguageChanger'
import getTenantLocale from '../helpers/getTenantLocale'

class FraastMobileDrawer extends React.Component {
  state = {
    open: false,
    placesList: []
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedPlanEventRoutePoint &&
      this.props.selectedPlanEventRoutePoint != prevProps.selectedPlanEventRoutePoint
    ) {
      this.setState({ open: true })
    }
  }

  renderUpcomingPlanEventDates(upcomingDates) {
    return upcomingDates.map(({ google_estimate_time, original_google_estimate_time, date }, index) => {
      const time = moment(google_estimate_time || original_google_estimate_time)
      const planEventDate = moment(date, 'YYYY-MM-DD')
      if (index > 3 && !this.props.showAllUpcomingDates) return
      return (
        <li key={index || 0} className='modal-list-item'>
          <span className='list-item-date'>
            {planEventDate.format('dddd')}, {planEventDate.format('D. MMMM')}
          </span>
          <span className='list-item-time'>
            {time.subtract(10, 'minutes').format('H:mm')} - {time.add(20, 'minutes').format('H:mm')}
          </span>
        </li>
      )
    })
  }

  renderPlaceList(places) {
    return places.map((place) => {
      if (place.center) {
        let placeAdress = ''
        let placeCityAndCountry = ''
        if (this.props.tenant == 'hjem-is') {
          const city = place.context.find((c) => c.id.includes('place'))
          placeAdress = place.properties.address || place.place_name
          placeCityAndCountry = city ? city.text + ', Denmark' : 'Denmark'
        } else if (this.props.tenant == 'den-norske-isbilen') {
          placeAdress = place.place_name.split(',')[0]
          placeCityAndCountry = place.place_name.split(',')[1] + ', Norway'
        }
        return (
          <Button onClick={() => this.handlePlaceSelect(place)} className={'place-list'} key={place.id}>
            <div>
              <h3>{placeAdress}</h3>
              <p>{placeCityAndCountry}</p>
            </div>
            <img src={goToIcon} />
          </Button>
        )
      } else {
        return null
      }
    })
  }

  handlePlaceSelect(place) {
    const coords = { lat: place.center[1], lng: place.center[0] }
    this.props.onAddressChange(this.props.hotjarLink, coords, this.props.googleSelectAction)

    this.setState({ open: false })
  }

  fetchPlacesList(e) {
    this.props.clearAddress()
    this.setState({ placesList: e, open: true })
  }

  renderHeadingText() {
    if (tenant === 'hjem-is') {
      return (
        <div className='modal-heading'>
          <p>{this.props.translations.fraast_heading}</p>
        </div>
      )
    } else {
      return (
        <div className='modal-heading'>
          <p>{this.props.translations.fraast_heading.split(' ')[0]}</p>
          <div>
            <p>{this.props.translations.fraast_heading.split(' ')[1]}</p>
          </div>
          <p>{this.props.translations.fraast_heading.split(' ').slice(2).join(' ')}</p>
        </div>
      )
    }
  }

  handleClick = () => {
    if (this.props.tenant === 'hjem-is' && this.props.selectedPlanEventRoutePoint) {
      this.props.openSubscribeModal()
    } else {
      this.props.openSmsService()
    }
  }

  render() {
    return (
      <div className={`subscription-modal-mobile ${this.state.open ? 'open' : 'close'} ${tenant}`}>
        <div className='modal-top'>
          <button
            onClick={() => {
              this.setState({ open: !this.state.open })
            }}
          >
            <ArrowDownwardSharp />
          </button>
          {this.renderHeadingText()}

          <div className='searchbar'>
            <MapboxAutocomplete
              style={{ width: 400 }}
              accessToken={this.props.accessToken}
              isbilenAccessToken={this.props.isbilenAccessToken}
              isbillen_api_key={this.props.isbillen_api_key}
              key='nav-autocomplete'
              classNames='header-search-container'
              translations={this.props.translations}
              locationMarkerColor='black'
              hotjarLink='/type-in-address-search'
              googleAction='navBarTypeIn'
              googleSelectAction='navBarChooseAddress'
              onAddressChange={this.props.onAddressChange}
              locale={this.props.tenant}
              changeAction={this.fetchPlacesList.bind(this)}
              overrideDropdown={true}
            />
            <div className='icon'>
              <img src={magnifyingGlass} />
            </div>
          </div>
        </div>
        <Collapse variant='persistent' anchor={'bottom'} in={this.state.open}>
          <div className='modal-middle'>
            {this.props.selectedPlanEventRoutePoint && (
              <div className='timetable'>
                <div className='timetable-points'>
                {this.props.tenant === 'hjem-is' && <span className='timetable-points-title'>{this.props.translations.nearest_points_title}</span>}
                  <h1>{this.props.selectedPlanEventRoutePoint.address}</h1>
                  <p>{this.props.translations.schedule_paragraph}</p>
                  <div>
                    <ul>
                      {this.renderUpcomingPlanEventDates(
                        this.props.selectedPlanEventRoutePoint.upcoming_plan_events_dates
                      )}
                    </ul>
                  </div>
                  {!this.props.showAllUpcomingDates && (
                    <Button className='side-button' onClick={this.props.showAllDates}>
                      {this.props.translations.see_more_times}
                      <ArrowDownwardSharp />
                    </Button>
                  )}
                </div>

                {this.props.tenant === 'hjem-is' &&
                  <span className='nearest-points-subtitle'>{this.props.translations.nearest_points_subtitle}</span>
                }
              </div>
            )}

            {!this.props.selectedPlanEventRoutePoint && this.state.placesList.length <= 0 && (
              <div className='info'>
                <div className='middle-title'>
                  <h1>{this.props.translations.dont_miss}</h1>
                  <p>
                    {this.props.translations.sidebar_paragraph +
                      ' ' +
                      this.props.translations.sidebar_paragraph_addition}
                  </p>
                </div>
                <div className='middle-description'>
                  <h2>{this.props.translations.sidebar_subtitle}</h2>
                  <ul className='flower-icon'>
                    <li>{this.props.translations.point_1}</li>
                    <li>{this.props.translations.point_2}</li>
                    <li>{this.props.translations.point_3}</li>
                    <li>{this.props.translations.point_4}</li>
                  </ul>
                </div>
              </div>
            )}

            {this.state.placesList.length > 0 && !this.props.selectedPlanEventRoutePoint && (
              <div>{this.renderPlaceList(this.state.placesList)}</div>
            )}

            <div className='button-list'>
              <Button className='black-button' onClick={this.handleClick}>
                SMS-service
              </Button>
              <LangaugeChanger language={getTenantLocale(this.props.tenant)} shortForm={true} />
            </div>
          </div>
        </Collapse>
      </div>
    )
  }
}

export default FraastMobileDrawer
