import React from 'react'
import Button from '@material-ui/core/Button'
import Modal from '../shared/Modal'
import MaterialDatePicker from '../shared/MaterialDatePicker'
import moment from 'moment'
import filter from 'lodash/filter'
import { Link } from 'react-router-dom'

class ExporterModal extends React.Component {
  state = {
    open: false,
    startDate: moment().subtract(7, 'days'),
    endDate: moment()
  }

  open() {
    this.setState({ open: true })
  }

  close() {
    this.setState({ open: false })
  }

  handleClick(e) {
    if (this.props.handleClick) {
      this.close()
      this.props.handleClick()
    }
  }

  salesExportsUrl() {
    if (this.props.orderUrl) {
      return this.props.orderUrl(
        window.tenant, this.urlOptions()
      )
    } else {
      return Routes.sales_exports_path(
        window.tenant, window.depoId, this.urlOptions()
      )
    }
  }

  productTransfersExportsUrl() {
    return Routes.product_transfer_exports_path(
      window.tenant, window.depoId, this.urlOptions()
    )
  }

  urlOptions() {
    return {
      start_date: this.state.startDate.format('YYYY-MM-DD'),
      end_date: this.state.endDate.format('YYYY-MM-DD'),
      car_ids: this.props.car_ids,
      order_ids: this.props.selectedOrderIds,
      format: 'csv'
    }
  }

  selectedCars() {
    return filter(this.props.cars, car => this.props.car_ids.indexOf(car.id) > -1 ).map(
      car => car.internal_number
    ).join(', ')
  }

  dateChange(fieldName, date) {
    this.setState({ [fieldName]: moment(date) })
  }

  renderCarsBlock() {
    if (!this.props.car_ids) return

    return (
      <div className='sales-export-block'>
        Selected cars - { this.props.car_ids.length ? this.selectedCars() : 'all' }
      </div>
    )
  }

  renderSelectedOrderIds() {
    if (this.props.selectedOrderIds && this.props.selectedOrderIds.length > 0) {
      return (
        <div>Selected orders {this.props.selectedOrderIds.join(", ")}</div>
      )
    }
  }

  renderExportSalesButton() {
    const classNames = "mdl-button mdl-js-button mdl-button--raised mdl-button--colored mdl-small"
    return (
      <a data-sales target="_self" onClick={this.handleClick.bind(this)} href={this.salesExportsUrl()} className={classNames} style={{ flex: 7 }}>
        Export sales
      </a>
    )
  }

  renderTransferButton() {
    const classNames = "mdl-button mdl-js-button mdl-button--raised mdl-button--colored mdl-small"
    if (this.props.orderUrl) return
    return (
      <a data-transfers target="_self" href={this.productTransfersExportsUrl()} className={classNames} style={{ flex: 7 }}>
        Export inventory
      </a>
    )
  }

  renderDatePickers() {
    if (this.props.selectedOrderIds && this.props.selectedOrderIds.length > 0) return

    return (
      <React.Fragment>
        <MaterialDatePicker
          key={`startDate-${this.state.startDate.format()}`}
          fieldName="startDate" label="Start date"
          value={this.state.startDate.format('DD-MM-YYYY')}
          onDateChange={this.dateChange.bind(this, 'startDate')}
        />
        <MaterialDatePicker
          key={`endDate-${this.state.endDate.format()}`}
          fieldName="endDate" label="End date"
          value={this.state.endDate.format('DD-MM-YYYY')}
          onDateChange={this.dateChange.bind(this, 'endDate')}
        />
      </React.Fragment>
    )
  }

  renderErrorMessage() {
    if (this.props.onlySales) return

    return (
      <div className="mdl-textfield__error" style={{ position: 'relative', visibility: 'visible' }}>
        Maximum 30 days interval can be exported ending with "End date"
      </div>
    )
  }

  renderModal() {
    return (
      <Modal open={this.state.open} close={this.close.bind(this)} title={this.renderTitle()}>
        <React.Fragment>
          {this.renderCarsBlock()}
          {this.renderSelectedOrderIds()}
          <div className='sales-export-block'>
            {this.renderDatePickers()}
            {this.renderErrorMessage()}
          </div>
          <div style={{ display: 'flex'}}>
            {this.renderExportSalesButton()}
            <span style={{flex: 1}}></span>
            {this.renderTransferButton()}
          </div>
        </React.Fragment>
      </Modal>
    )
  }

  renderTitle() {
    return this.props.onlySales ? "Export sales" : "Export Sales/Transfers"
  }

  render() {
    if (!isHjemis) {
      return (
        <React.Fragment>
          <Button vairant="contained" color="primary" size="small" onClick={this.open.bind(this)} className="align-bottom">
            {this.renderTitle()}
          </Button>
          {this.renderModal()}
        </React.Fragment>
      )
    } else {
      return ''
    }
  }
}

export default ExporterModal
