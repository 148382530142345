import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import axios from 'axios'
import StockOrders from '../stock_orders/StockOrders'
import StockTransfers from '../stock_transfers/StockTransfers'
import ProductsBalance from '../products/ProductsBalance'
import ManualCountingDocuments from '../manual_countings/ManualCountingDocuments'
import { Link } from 'react-router-dom'
import BackButton from '../../shared/BackButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'
import extractDataErrors from '../../support/extractDataErrors'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

const tabs = { products: 0, orders: 1, transfers: 2, manual_countings: 3 }

class WarehouseShow extends React.Component {
  state = {
    selectedTab: tabs[this.props.match.params.filter],
    warehouseBalances: [],
    warehouseId: '',
    mainTitle: '',
    loading: false,
    manualCountingDate: moment().subtract(1, 'days'),
    dateModalOpen: false,
    confirmationOpen: false,
    errors: {},
    warehouseData: {}
  }

  componentDidMount() {
    axios.get(Routes.inventory_warehouse_path(tenant, depoId, this.warehouseId(), { format: 'json' })).then(res => {
      this.setState({ mainTitle: res.data.name, warehouseData: res.data })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.selectedTab != tabs[nextProps.match.params.filter]) {
      this.setState({ selectedTab: tabs[nextProps.match.params.filter] })
    }
  }

  createManualCountingDocument() {
    this.setState({ loading: true, dateModalOpen: false, confirmationOpen: false })
    axios.post(
      Routes.inventory_warehouse_manual_counting_documents_path(tenant, depoId, this.warehouseId(),
      { format: 'json', date: this.state.manualCountingDate.format(dateFormat) })
    ).then(res => {
      this.setState({ loading: false })
      this.props.history.push(Routes.inventory_warehouse_manual_counting_document_path(tenant,depoId, this.warehouseId(), res.data.id))
    }).catch(error => {
      this.setState({ ...extractDataErrors(error), loading: false })
    })
  }

  openConfirmation() {
    if (!this.state.manualCountingDate) return
    this.setState({ confirmationOpen: true })
  }

  closeConfirmation() {
    this.setState({ confirmationOpen: false })
  }

  dateChange(fieldName, date) {
    this.setState({ [fieldName]: moment(date) })
  }

  closeDateModal() {
    this.setState({ dateModalOpen: false })
  }

  openDateModal() {
    this.setState({ dateModalOpen: true })
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  clearErrors() {
    this.setState({ full_errors: null, errors: {} })
  }

  renderTabView() {
    const { selectedTab, warehouseData } = this.state
    const { warehouseId } = this.props.match.params

    switch(selectedTab) {
      case 0:
        return <ProductsBalance ref="productsBalance" warehouseId={warehouseId} warehouseData={warehouseData} />
      case 1:
        return <StockOrders warehouseId={warehouseId} />
      case 2:
        return <StockTransfers warehouseId={warehouseId} />
      case 3:
        return <ManualCountingDocuments warehouseId={warehouseId} />
    }
  }

  renderTabSwitch() {
    return (
      <Tabs
        value={this.state.selectedTab}
        indicatorColor="secondary"
        textColor="secondary"
        style={{ width: '90%' }}
        centered>
        <Tab label="Products" component={Link} to={Routes.show_inventory_warehouse_path(tenant, depoId, this.warehouseId(), 'products')} />
        <Tab label="Orders" component={Link} to={Routes.show_inventory_warehouse_path(tenant, depoId, this.warehouseId(), 'orders')} />
        <Tab label="Transfers" component={Link} to={Routes.show_inventory_warehouse_path(tenant, depoId, this.warehouseId(), 'transfers')} />
        <Tab label="Manual Counting" component={Link} to={Routes.show_inventory_warehouse_path(tenant, depoId, this.warehouseId(), 'manual_countings')} />
      </Tabs>
    )
  }

  startCreationOfNew(documentType) {
    const selectedProducts = this.refs.productsBalance ? this.refs.productsBalance.state.products : { selectedProducts: [] }
    const path = documentType == 'order' ? 'new_inventory_warehouse_stock_orders_path' : 'new_inventory_warehouse_stock_transfers_path'
    this.props.history.push({
      pathname: Routes[path](tenant, depoId, this.warehouseId()),
      state: { selectedProducts: selectedProducts }
    })
  }

  renderActionButtons() {
    return (
      <div className='warehouse-action-buttons'>
        <Button
          className="mdl-button mdl-js-button mdl-button--raised mdl-button--size-small mdl-js-ripple-effect mr-3"
          onClick={() => this.startCreationOfNew.bind(this)('order')}>
          New Order
        </Button>
        <Button
          className="mdl-button mdl-js-button mdl-button--raised mdl-button--size-small mdl-js-ripple-effect mr-3"
          onClick={() => this.startCreationOfNew.bind(this)('transfer')}>
          New Transfer
        </Button>
        <Button variant="contained" size="small" onClick={this.createManualCountingDocument.bind(this)}>
          Manual counting
        </Button>
      </div>
    )
  }

  renderMainTitle() {
    return (
      <Typography variant="h5" style={{ position: 'absolute', left: 80, top: 28 }}>{this.state.mainTitle}</Typography>
    )
  }

  renderHeader() {
    return (
      <div className="content-header sticky-header pt-3 pb-3">
        <BackButton to={Routes.inventory_warehouses_path(tenant, depoId)} />
        {this.renderMainTitle()}
        {this.renderActionButtons()}
        {this.renderTabSwitch()}
      </div>
    )
  }

  renderFlashNotification() {
    const { full_errors } = this.state
    const message = !isEmpty(full_errors) ? `${full_errors.join(", ")}` : null
    return <FlashNotification message={message} clearErrors={this.clearErrors.bind(this)} />
  }

  render() {
    return (
      <div>
        <LoadingWithoutRedux loading={this.state.loading} />
        {this.renderHeader()}
        {this.renderTabView()}
        {this.renderFlashNotification()}
      </div>
    )
  }
}

export default WarehouseShow
