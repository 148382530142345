import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core/styles'

import { setFlash, updatePoint, startLoading, stopLoading, zoomOnPoint, updateSiblings, loadStopTypes } from '../../files/actions/index'
import FunTableInput from '../forms/FunTableInput'
import DeleteIcon from '@material-ui/icons/Delete'
import Checkbox from '@material-ui/core/Checkbox'
import Close from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import floatToTimeConverter from '../support/floatToTimeConverter'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import RoutePointDeletionDialog from './RoutePointDeletionDialog'
import find from 'lodash/find'
import ModalWrapper from '../shared/Modal'
import IntegrationReactSelect from '../forms/IntegrationReactSelect'

import RoutePointAutocomplete from '../shared/RoutePointAutocomplete'

const styles = {
  row: { height: 30 },
  cell: {
    padding: '0 !important',
    border: 'solid 1px lightgray'
  },
  indexCell: {
    padding: '0px 4px',
    border: 'solid 1px lightgray'
  },
  dragCell: {
    padding: 0,
  },
  deleteCell: {
    padding: '0 !important',
    paddingLeft: 3,
    width: 32,
    textAlign: 'center',
    cursor: 'pointer'
  },
  separatorCell: {
    borderLeft: 'solid 1px lightgray',
    padding: '0 !important',
    paddingLeft: 0,
    width: 32,
    height: 32,
  },
  time: { width: 64 },
  wait: { width: 32 },
  privateComment: { maxWidth: 150 },
  addressInput: { minWidth: 150 }
}

const fullVisitTypes = { 1: "Regular", 2: "We call", 3: "They call" }

class RoutePointsTableRowClass extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      ...this.props.routePoint,
      separator: this.props.routePoint.separator,
      markedForDestruction: false,
      toggleDeletionDialog: false,
      customerModalOpen: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!!props.routePoint.google_estimate_time && this.state.google_estimate_time != props.routePoint.google_estimate_time) {
      this.setState({ google_estimate_time: props.routePoint.google_estimate_time })
    }
    this.setState({ checked: props.routePoint.checked })
  }

  handleChange(e, field) {
    this.setState({ [field]: e.target.value })
  }

  updateCoordinates(field, value, coordinates) {
    this.setState({ [field]: value })

    if (field === 'address' && coordinates) {
      this.setState({
        point: { type: 'Point', coordinates: [coordinates.lng, coordinates.lat] }
      }, () => this.props.updateRoadPoint(this.state))
    }
  }

  toggleDestruction() {
    this.setState({ markedForDestruction: !this.state.markedForDestruction }, () => {
      this.props.updateParentPoints(this.state)
    })
  }

  toggleDeletionDialogIfRoutePointHaveCustomer() {
    this.shouldOpenDeletionConfirmationDialog()
  }

  shouldOpenDeletionConfirmationDialog() {
    if (this.state.markedForDestruction) return
    const { id } = this.state
    const { routeId } = this.props

    this.props.startLoading()
    axios.get(Routes.active_customers_in_point_route_point_path(window.tenant, window.depoId, routeId, id, { format: 'json' })).then(res => {
      this.props.stopLoading()
      if (res.data > 0) {
        this.setState({ customerCount: res.data }, () => this.toggleDeletionDialog())
      } else {
        this.toggleDestruction()
      }
    })
  }

  toggleDeletionDialog() {
    this.setState({ toggleDeletionDialog: !this.state.toggleDeletionDialog })
  }

  routePointCustomer() {
    const { coordinates } = this.state.point
    return find(this.props.customers, { point : coordinates })
  }

  handleCheckboxChange(e) {
    this.setState({ separator: e.target.checked }, () => {
      this.props.updateParentPoints(this.state)
    })
  }

  useCustomAddress() {
    this.setState({ use_custom_address: !this.state.use_custom_address }, () => this.props.updateParentPoints(this.state))
  }

  renderCircle() {
    if (this.state.use_custom_address) {
      return <div className='circle circle-custom-address'>C</div>
    } else if (this.state.point) {
      return <div className='circle circle-with-coordinates'>G</div>
    } else {
      return <div className='circle circle-without-coordinates' />
    }
  }

  renderSeparator() {
    return (
      <TableCell className={classes.separatorCell}>
        <Checkbox
          style={{width: 32, height: 32, padding: 0}}
          checked={this.state.separator}
          onChange={this.handleCheckboxChange.bind(this)}
          value="checked"
        />
      </TableCell>
    )
  }

  renderDeleteButton(index) {
    if (index !== this.props.routePoints.length && this.state.id) {
      return (
        <a className='remove-icon' onClick={this.toggleDeletionDialogIfRoutePointHaveCustomer.bind(this)}>
          <DeleteIcon />
        </a>
      )
    }
  }

  handleRouteActions() {
    this.setState({ checked: !this.state.checked }, () =>  {
      this.props.updateParentPoints(this.state)
      this.props.zoomOnPoint(this.state)
    })
  }

  handleWaitTimeChange(e) {
    this.setState({ wait_time: e.target.value, showSelect: false }, () => this.props.updateParentPoints(this.state))
    if (this.props.routePoints.filter(point => point.checked).length > 0) {
      this.props.updateSiblings(e.target.value)
    }
  }

  showWaitTimeSelect() {
    const { stopTypes } = this.props
    if (stopTypes.length === 0) {
      axios.get(Routes.admin_stop_types_path(window.tenant, { format: 'json' })).then(res => {
        this.props.loadStopTypes(res.data)
        this.setState({ showSelect: true })
      })
    } else {
      this.setState({ showSelect: true })
    }
  }

  renderRoutePointDestructionModal() {
    if (!this.state.toggleDeletionDialog) return
    const { customerCount } = this.state
    const { routeId, routePoint, index, setFlash } = this.props
    return (
      <RoutePointDeletionDialog
        open={true}
        close={this.toggleDeletionDialog.bind(this)}
        toggleDestruction={this.toggleDestruction.bind(this)}
        routeId={routeId}
        routePoint={routePoint}
        customerCount={customerCount}
        position={index}
        setFlash={setFlash}
      />
    )
  }

  renderWaitTimeSelect() {
    const { showSelect, wait_time } = this.state
    const { stopTypes } = this.props

    if (showSelect) {
      return (
        <Select
          className="wait-time-select"
          value={floatToTimeConverter(wait_time) || "00:00:00"}
          onChange={this.handleWaitTimeChange.bind(this)}
          fullWidth
        >
          {stopTypes.map(stopType => {
            return (
              <MenuItem key={stopType.id} value={floatToTimeConverter(stopType.wait_time)}>
                {floatToTimeConverter(stopType.wait_time)}
              </MenuItem>
            )
          })}
        </Select>
      )
    } else {
      return (
        <div onClick={this.showWaitTimeSelect.bind(this)}>
          {floatToTimeConverter(wait_time)}
        </div>
      )
    }
  }

  renderWaitTimeTableCell() {
    const { routeType, classes } = this.props

    if (routeType === 'custom_stops') {
      return (
        <TableCell className={[classes.cell, classes.wait].join(' ')}>
          {this.renderWaitTimeSelect()}
        </TableCell>
      )
    }
  }

  renderGoogleAddressOrCustomAddress() {
    const { use_custom_address, markedForDestruction, address } = this.state
    const { addNewRoutePoint } = this.props
    if (use_custom_address) {
      return <FunTableInput
        field="custom_address"
        state={this.state}
        change={this.handleChange.bind(this)}
        blur={this.props.updateParentPoints.bind(this, this.state)}
        focus={addNewRoutePoint}
        disabled={this.state.markedForDestruction}
      />
    } else {
      return <RoutePointAutocomplete
        onFocus={addNewRoutePoint}
        onBlur={this.props.updateParentPoints.bind(this, this.state)}
        updateCoordinates={this.updateCoordinates.bind(this)}
        field="address"
        value={address}
        disabled={markedForDestruction}
        accessToken={this.props.accessToken}
        isbilenAccessToken={this.props.isbilenAccessToken}
      />
    }
  }

  renderActionsCheckbox() {
    if (this.state.address && this.state.point) {
      return (
        <Checkbox
          style={{ width: 32, height: 32, padding: 0 }}
          checked={this.state.checked}
          onChange={this.handleRouteActions.bind(this)}
          value="checked"
          disabled={this.state.markedForDestruction}
        />
      )
    }
  }

  openCustomerAdditionModal() {
    this.setState({ customerModalOpen: true })
  }

  closeCustomerAdditionModal() {
    this.setState({ customerModalOpen: false })
  }

  removeCustomerFromPoint(customer, e) {
    if (!confirm(`Are you sure you want to remove ${customer.customer_name} from point?`)) return

    axios.put(Routes.remove_b2b_customer_route_point_path(tenant, depoId, this.state.route_id, this.state.id, { format: 'json' }), { b2b_customer_id: customer.id }).then(res => {
      this.setState({ b2b_customers: res.data.b2b_customers })
    }).catch(err => {
      this.setState({ errors: err })
    })
  }

  onChange(value) {
    const state = this.state

    axios.put(Routes.add_b2b_customer_route_point_path(tenant, depoId, this.state.route_id, this.state.id, { format: 'json' }), { b2b_customer_id: value.value }).then(res => {
      this.props.timeFields.handleTimeFieldsToggle({ target: { value: "customStops" } })
      this.setState({ ...res.data, checked: state.checked }, () => this.props.updateParentPoints(this.state))
    }).catch(err => {
      this.setState({ errors: err })
    })
  }

  async loadCustomers(q, callback) {
    if (q === '') {
      return callback(null, { options: [] })
    }

    const { data } = await axios.get(Routes.admin_b2b_customers_path(tenant, { format: 'json', search_phrase: q }))
    this.options   = data.map(customer => {
      if (isIsbilen)
        return { value: customer.id, label: `${customer.customer_name} | ${customer.customer_number}` }
      else
        return { value: customer.id, label: `${customer.customer_name} | ${customer.customer_name_2} | ${customer.shop_id}` }
    })

    callback(null, { options: this.options })
  }

  renderAutocomplete() {

    return (
      <div className='react-select-wrapper'>
        <InputLabel>Customers</InputLabel>
        <IntegrationReactSelect name='customer_id'
                                loadOptions={this.loadCustomers.bind(this)}
                                cache={false}
                                autoload={false}
                                onChange={this.onChange.bind(this)} />
      </div>
    )
  }

  renderCustomerAdditionModal() {
    const { customerModalOpen, b2b_customers } = this.state
    const style = { padding: 5, border: '1px solid lightgray' }
    const headStyle = { padding: 5, textAlign: 'left', fontSize: 12 }
    return (
      <ModalWrapper
        classes="customer-modal"
        style={{ width: 400, height: 300 }}
        open={customerModalOpen}
        title="Add customer"
        close={this.closeCustomerAdditionModal.bind(this)}>
        {this.renderAutocomplete()}
        <table style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Roboto, sans-serif' }}>
          <thead>
            <tr>
              <th style={headStyle}>#</th>
              <th style={headStyle}>Name</th>
              <th style={headStyle}>Type</th>
              <th style={headStyle}>ID</th>
              <th style={headStyle}></th>
            </tr>
          </thead>
          <tbody>
            {b2b_customers && b2b_customers.map((customer, index) => {
              return (
                <tr key={customer.id}>
                  <td style={style}>{index + 1}</td>
                  <td style={style}>{customer.customer_name}</td>
                  <td style={style}>{fullVisitTypes[customer.visit_type]}</td>
                  <td style={style}>{customer.shop_id}</td>
                  <td style={style}><Close onClick={this.removeCustomerFromPoint.bind(this, customer)}/></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ModalWrapper>
    )
  }

  renderPointInfo() {
    const { b2b_customers }  = this.state

    if (b2b_customers && b2b_customers.length) {
      return (
        isIsbilen ? this.renderB2bCustomerRowIsbilen() : this.renderB2bCustomerRow()
      )
    }
  }

  renderB2bCustomerRowIsbilen() {
    const { index, classes, addNewRoutePoint, routePoint } = this.props
    const { separator, markedForDestruction, classAndColor, id, b2b_customers }  = this.state
    const separatorClass = separator ? 'last-day-point' : 'normal-day-point'
    const markedForDestructionClass = markedForDestruction ? 'marked-for-destruction' : ''
    const checkedClass = `checked-${this.state.checked}`
    const salesBorderClass = classAndColor ? classAndColor.class : ''
    const unvisitedPointClass = routePoint.point_type == 'unvisited' ? 'unvisited-point' : ''
    const style = { padding: 5, border: '1px solid lightgray' }

    return (
      <TableRow className={[classes.row, separatorClass, markedForDestructionClass, checkedClass, salesBorderClass, unvisitedPointClass].join(' ')}>
        <TableCell style={{ padding: 5 }} colSpan={10}>
          <div>
            <table style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Roboto, sans-serif' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {b2b_customers && b2b_customers.map((customer, index) => {
                  return (
                    <tr key={customer.id}>
                      <td style={{ ...style, width: '30%' }}>{customer.customer_number}</td>
                      <td style={{ ...style, width: '70%' }}>{customer.customer_name}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  renderB2bCustomerRow() {
    const { index, classes, addNewRoutePoint, routePoint } = this.props
    const { separator, markedForDestruction, classAndColor, id, b2b_customers }  = this.state
    const separatorClass = separator ? 'last-day-point' : 'normal-day-point'
    const markedForDestructionClass = markedForDestruction ? 'marked-for-destruction' : ''
    const checkedClass = `checked-${this.state.checked}`
    const salesBorderClass = classAndColor ? classAndColor.class : ''
    const unvisitedPointClass = routePoint.point_type == 'unvisited' ? 'unvisited-point' : ''
    const style = { padding: 5, border: '1px solid lightgray' }

    return (
      <TableRow className={[classes.row, separatorClass, markedForDestructionClass, checkedClass, salesBorderClass, unvisitedPointClass].join(' ')}>
        <TableCell style={{ padding: 5 }} colSpan={10}>
          <div>
            <table style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Roboto, sans-serif' }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Zip</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {b2b_customers && b2b_customers.map((customer, index) => {
                  return (
                    <tr key={customer.id}>
                      <td style={{ ...style, width: 10 }}>{customer.shop_id}</td>
                      <td style={{ ...style, width: '50%' }}>{customer.customer_name}</td>
                      <td style={style}>{fullVisitTypes[customer.visit_type]}</td>
                      <td style={style}>{customer.zip_code}</td>
                      <td style={style}>{customer.shop_open_at}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  renderCustomerModalButton() {
    const { id } = this.state
    const { classes } = this.props

    if (isHjemis || isIsbilen) {
      return (
        <TableCell className={classes.deleteCell}>
          {id && <AddIcon onClick={this.openCustomerAdditionModal.bind(this)} />}
        </TableCell>
      )
    }
  }

  render() {
    const { index, classes, addNewRoutePoint, routePoint } = this.props
    const { separator, markedForDestruction, classAndColor, b2b_customers }  = this.state
    const separatorClass = separator ? 'last-day-point' : 'normal-day-point'
    const markedForDestructionClass = markedForDestruction ? 'marked-for-destruction' : ''
    const checkedClass = `checked-${this.state.checked}`
    const salesBorderClass = classAndColor ? classAndColor.class : ''
    const unvisitedPointClass = routePoint.point_type == 'unvisited' ? 'unvisited-point' : ''
    const homeAddressPointClass = routePoint.home_customers.length > 0 ? 'home-point' : ''

    return (
      <TableBody>
        <TableRow hover className={[classes.row, separatorClass, markedForDestructionClass, checkedClass, salesBorderClass, unvisitedPointClass, homeAddressPointClass].join(' ')}>
          <TableCell className={classes.separatorCell}>
            {this.renderActionsCheckbox()}
          </TableCell>
          <TableCell className={classes.indexCell}>
            {index}
          </TableCell>
          <TableCell className={classes.dragCell}>
            <i className="material-icons drag-icon align-text-bottom">&#8661;</i>
          </TableCell>
          <TableCell onClick={this.useCustomAddress.bind(this)} className={[classes.cell, classes.wait].join(' ')}>
            {this.renderCircle()}
          </TableCell>
          <TableCell className={[classes.cell, classes.addressInput].join(' ')}>
            {this.renderGoogleAddressOrCustomAddress()}
          </TableCell>
          {this.renderWaitTimeTableCell()}
          <TableCell className={[classes.cell, classes.privateComment].join(' ')}>
            <FunTableInput
              field="private_comment"
              state={this.state}
              change={this.handleChange.bind(this)}
              blur={this.props.updateParentPoints.bind(this, this.state)}
              focus={addNewRoutePoint}
              disabled={this.state.markedForDestruction}
            />
          </TableCell>
          <TableCell className={[classes.cell, classes.time].join(' ')}>
            <FunTableInput
              field="google_estimate_time"
              state={this.state}
              disabled
            />
          </TableCell>
          {this.renderCustomerModalButton()}
          <TableCell className={[classes.cell, classes.deleteCell].join(' ')}>
            {this.renderDeleteButton(index)}
          </TableCell>
          {this.renderRoutePointDestructionModal()}
          {this.renderCustomerAdditionModal()}
        </TableRow>
        {this.renderPointInfo()}
      </TableBody>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    stopTypes: state.stopTypes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFlash: error => dispatch(setFlash(error)),
    updateRoadPoint: point => dispatch(updatePoint(point)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    zoomOnPoint: (point) => dispatch(zoomOnPoint(point)),
    updateSiblings: waitTime => dispatch(updateSiblings(waitTime)),
    loadStopTypes: stopTypes => dispatch(loadStopTypes(stopTypes))
  }
}

const RoutePointsTableRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePointsTableRowClass)

export default withStyles(styles)(RoutePointsTableRow)
