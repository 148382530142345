import React from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import MovableInput from '../shared/MovableInput'

class MinimumRequiredBalance extends React.Component {
  renderTableHead() {
    if (this.props.minimum_required_balances.length > 1) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Depo</TableCell>
            <TableCell>Minimum for Depo</TableCell>
            <TableCell>Minimum for Car</TableCell>
          </TableRow>
        </TableHead>
      )
    }
  }

  renderMovableInput(item, itemKey, tabIndex) {
    const { minimum_required_balances } = this.props
    const disabled = !isAdmin && !(isDepoAdmin && window.userDepoIds.includes(item.depo_id))

    return (
      <MovableInput
        item={item}
        items={minimum_required_balances}
        itemKey={itemKey}
        tabIndex={tabIndex}
        disabled={disabled}
        parentItemsState="minimum_required_balances"
        updateProductsByKeysWithValues={this.props.updateProductsByKeysWithValues.bind(this)}
        updateStateByKeysWithValues={this.props.updateStateByKeysWithValues.bind(this)}
        getStateOfKey={this.props.getStateOfKey.bind(this)}
        clearMovementStates={this.props.clearMovementStates.bind(this)}/>
    )
  }

  renderTableBody() {
    const { minimum_required_balances } = this.props
    return (
      <TableBody>
        {minimum_required_balances.map((minimum_required_balance, index) => {
          return (
            <TableRow key={minimum_required_balance.id} hover>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{minimum_required_balance.depo_name}</TableCell>
              <TableCell>
                {this.renderMovableInput(minimum_required_balance, "minimum_for_depo", (index * 2))}
              </TableCell>
              <TableCell>
                {this.renderMovableInput(minimum_required_balance, "minimum_for_cars", (index * 2) + 1)}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  renderTable() {
    return (
      <Table className='resources-table'>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  render() {
    return (
      this.renderTable()
    )
  }
}

export default MinimumRequiredBalance
