import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { placeRoutePointsOnMap, clearRoutePointsOnMap, setFlash } from '../../files/actions/index'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import difference from 'lodash/difference'

const MenuProps = {
  PaperProps: {
    style: {
      width: 400,
      maxHeight: 300,
    }
  }
}

class SimilarRoutesSelectClass extends React.Component {
  state = { selectedRoutes: [] }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.similarRoutes.length === 0) {
      this.setState({ selectedRoutes: [] }, this.props.clearRoutePointsOnMap)
    }
  }

  handleChange(e) {
    const { selectedRoutes } = this.state
    this.setState({ selectedRoutes: e.target.value })
    if (e.target.value.length > selectedRoutes.length) {
      this.fetchNeededPoints(difference(e.target.value, selectedRoutes)[0])
    } else {
      this.props.placeRoutePointsOnMap([], difference(selectedRoutes, e.target.value)[0])
    }
  }

  fetchNeededPoints(routeId) {
    axios.get(Routes.route_points_path(window.tenant, window.depoId, routeId, { format: 'json' })).then(res => {
      this.props.placeRoutePointsOnMap(res.data, routeId)
    }).catch(err => this.props.setFlash(`${err}`))
  }

  render() {
    const { similarRoutes } = this.props
    if (similarRoutes.length === 0) return null
    const { selectedRoutes } = this.state

    const offsetVertical = isHjemis ? 150 : 200

    return (
      <Paper className="similar-routes-container" elevation={6} style={{ position: 'absolute', zIndex: 999, marginLeft: 12, top: offsetVertical }}>
        <Select
          className="similar-routes-select"
          displayEmpty
          multiple
          value={selectedRoutes || ""}
          onChange={this.handleChange.bind(this)}
          input={<Input id="select-multiple" />}
          renderValue={selected => <div style={{padding: '4px 0px 2px 8px'}}>Selected Routes({selectedRoutes.length})</div>}
          style={{ maxWidth: 200, borderRadius: 2, backgroundColor: '#e0e0e0' }}
          MenuProps={MenuProps}>
          {similarRoutes.map(route => (
            <MenuItem key={route.id} value={route.id}>
              <Checkbox checked={selectedRoutes.indexOf(route.id) > -1} />
              <ListItemText primary={route.name} />
            </MenuItem>
          ))}
        </Select>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    similarRoutes: state.similarRoutes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    placeRoutePointsOnMap: (placedPoints, routeId) => dispatch(placeRoutePointsOnMap(placedPoints, routeId)),
    clearRoutePointsOnMap: () => dispatch(clearRoutePointsOnMap()),
    setFlash: message => dispatch(setFlash(message)),
  }
}

const SimilarRoutesSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimilarRoutesSelectClass)

export default SimilarRoutesSelect
