import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Collapse from '@material-ui/core/Collapse'
import OrderLinePaper from './OrderLinePaper'

import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

class OrdersTableRow extends React.Component {
  state = { formClosed: true }

  toggleForm(e) {
    this.setState({ formClosed: !this.state.formClosed })
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ formClosed: true })
  }

  renderForm() {
    if (!this.state.formClosed) {
      return (
        <TableRow>
          <TableCell colSpan={6} className="p-2 eod-table-row-expanded">
            <Collapse in={!this.state.formClosed} timeout="auto" unmountOnExit>
              <OrderLinePaper order={this.props.order}/>
            </Collapse>
          </TableCell>
        </TableRow>
      )
    }
  }

  render() {
    const { order } = this.props
    if (!order.time) return null

    return (
      <TableBody onClick={this.toggleForm.bind(this)}>
        <TableRow hover={this.state.formClosed}>
          <TableCell>
            {order.time}
          </TableCell>
          <TableCell>
            {order.wallmob_order_id}
          </TableCell>
          <TableCell>
            {order.total_revenue}
          </TableCell>
          <TableCell>
            <a className={`mdl-button mdl-js-button mdl-button--icon eod-order-${order.id}-edit-path`} onClick={this.toggleForm.bind(this)} >
              {this.state.formClosed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </a>
          </TableCell>
        </TableRow>
        {this.renderForm()}
      </TableBody>
    )
  }
}

export default OrdersTableRow
