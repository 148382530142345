import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class ConfirmationDialog extends React.Component {
  renderDialogTitle(title) {
    if (!title) return
    return (
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    )
  }

  renderDialogContent(content) {
    if (!content) return
    return (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
    )
  }

  renderConfirmButton() {
    const { confirmAndCloseText, leave, showConfirmButton } = this.props
    if (!showConfirmButton) return

    return (
      <Button onClick={leave} color="primary" variant="contained">
        {confirmAndCloseText}
      </Button>
    )
  }

  renderCancelButton() {
    const { close, showCancelButton, cancelText } = this.props
    if (!showCancelButton) return

    return (
      <Button onClick={close} color="secondary" variant="contained">
        {cancelText}
      </Button>
    )
  }

  render() {
    const { open, close, title, content } = this.props
    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.renderDialogTitle(title)}
        {this.renderDialogContent(content)}
        <DialogActions>
          {this.renderConfirmButton()}
          {this.renderCancelButton()}
        </DialogActions>
      </Dialog>
    )
  }
}

ConfirmationDialog.defaultProps = {
  confirmAndCloseText: "Confirm",
  cancelText: "Cancel",
  showCancelButton: true,
  showConfirmButton: true
}

export default ConfirmationDialog
