import React from 'react'
import thumbsUp from './thumbs_up.png'

const ThumbsUp = ({ selected, toggle }) => {
  const selectedClass = selected ? "selected" : ""
  return (
    <div className={`thumbs-circle positive ${selectedClass}`} onClick={toggle}>
      <img src={thumbsUp} />
    </div>
  )
}

export default ThumbsUp
