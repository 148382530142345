import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Info from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'

class PlanEventCustomersTableRow extends React.Component {
  state = { ...this.props.customer, open: false, popperClass: 'reason-popper-no-transform' }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      checked: nextProps.customer.checked
    }
  }

  copyToCliboard(id, link) {
    let tmp_element = document.createElement("input")
    tmp_element.setAttribute('style', 'left:-1000px;position:absolute;')
    tmp_element.setAttribute('value', link)
    document.body.appendChild(tmp_element)
    tmp_element.select()
    document.execCommand("copy")
    document.body.removeChild(tmp_element)
    this.props.setFlash("Copied to clipboard")
  }

  handleCheckboxChange() {
    this.props.updateCustomers(this.state.id, !this.state.checked)
  }

  handleTooltipOpening() {
    this.setState({ open: true })
  }

  handleTooltipOpeningWithDealy() {
    this.setState({ popperClass: '' })
    setTimeout(this.handleTooltipOpening.bind(this), 200)
  }

  handleTooltipClosing() {
    this.setState({popperClass: 'reason-popper-no-transform' })
  }

  handleTooltipClosingWithDealy() {
    this.setState({ open: false })
    setTimeout(this.handleTooltipClosing.bind(this), 200)
  }

  renderMoreDescriptiveInfo() {
    const { full_description_of_sms_skip } = this.state
    if (full_description_of_sms_skip) {
      return (
        <Tooltip
          classes={{ popper: this.state.popperClass, tooltip: "tooltip" }}
          placement="left-start"
          open={this.state.open}
          title={
            <React.Fragment>
              <ul style={{ fontSize: 14, width: 300 }}>
                {full_description_of_sms_skip.split(',').map(reason => {
                  return <li key={reason}>{reason}</li>
                })}
              </ul>
            </React.Fragment>
          }>
          <Info
            style={{ fill: 'red' }}
            onMouseEnter={this.handleTooltipOpeningWithDealy.bind(this)}
            onMouseLeave={this.handleTooltipClosingWithDealy.bind(this)}
          />
        </Tooltip>
      )
    }
  }

  renderReasonOfSmsSkip() {
    return (
      <div className="vertical-center-items">
        {this.renderMoreDescriptiveInfo()}
        <span style={{ marginLeft: 5, lineHeight: '12px' }}>
          {this.state.reason_of_sms_skip}
        </span>
      </div>
    )
  }

  render() {
    const { id, phone_number, location, plan_event_route_point, plan_event_route,
      sms_sent_at, car_stopped_at, google_estimate_time, sms_sent_skipped_at, sms_demo_link, checked,
      sms_stopped_record_line_item_id
    } = this.state

    return (
      <TableRow hover>
        <TableCell>
          <Checkbox
            disabled={!!sms_stopped_record_line_item_id}
            className={`check-${id}`}
            checked={checked}
            onChange={this.handleCheckboxChange.bind(this)}
            value="checked" />
        </TableCell>
        <TableCell>{id}</TableCell>
        <TableCell>{phone_number}</TableCell>
        <TableCell>{`${location.address} ${location.city ? location.city : ''}`}</TableCell>
        <TableCell>{plan_event_route.name}</TableCell>
        <TableCell>{plan_event_route_point.address}</TableCell>
        <TableCell>{sms_sent_skipped_at ? this.renderReasonOfSmsSkip() : sms_sent_at}</TableCell>
        <TableCell>{car_stopped_at ? car_stopped_at : '--:--'}</TableCell>
        <TableCell>{google_estimate_time}</TableCell>
        <TableCell id={"sms-link-"+id} onClick={() => this.copyToCliboard(id, sms_demo_link)}>
          <a>Copy link</a>
        </TableCell>
      </TableRow>
    )
  }
}

export default PlanEventCustomersTableRow
