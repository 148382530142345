const Depo = () => {
  return {
    title: '',
    description: '',
    address: '',
    address_coordinates: '',
    dashboard: '',
    sms_text: '',
    sms_route_text: '',
    sms_subscribe_text: '',
    sms_unsubscribe_text: '',
    marketing_sms: '',
    customer_axa_id: '',
    errors: {}
  }
}

export default Depo
