import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import Map, { NavigationControl } from 'react-map-gl'
import mapboxglSupported from '@mapbox/mapbox-gl-supported'

class MapboxMap extends React.Component {
  render() {
    if (mapboxglSupported.supported()) {
      return (
        <Map
          initialViewState={this.props.initialViewState}
          style={{ width: '100%', height: this.props.height || '100%' }}
          mapStyle='mapbox://styles/foodunion/cjxoepeai187z1cmmyt148do9'
          mapboxAccessToken={this.props.accessToken}
          {...this.props}
        >
          {this.props.children}
          <NavigationControl position={this.props.navPosition || 'bottom-right'} />
        </Map>
      )
    } else {
      return (
        <div>
          WebGL not enabled
        </div>
      )
    }
  }
}

MapboxMap.defaultProps = {
  accessToken: 'ACCESS TOKEN'
}

export default MapboxMap
