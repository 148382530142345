import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import L from 'leaflet'
import { Map, TileLayer, FeatureGroup, ZoomControl, CircleMarker} from 'react-leaflet'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import { startLoading, stopLoading } from '../../files/actions/index'
import flatten from 'lodash/flatten'
import difference from 'lodash/difference'
import maxBy from 'lodash/maxBy'
import { GOOGLE_STREET_LAYER, GOOGLE_SATELITE_LAYER } from '../shared/Layers'
import Loading from '../shared/Loading'
import BackButton from '../shared/BackButton'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import REGIONS from '../public/helpers/regions'

const layerTypes = ['revenue', 'visited', 'pins']
const selectedLayerTypes = ['revenue', 'pins']

const revenueGradient = {
  0.1: 'rgba(249, 247, 88, 0.75)',
  0.2: 'rgba(249, 222, 79, 0.75)',
  0.3: 'rgba(249, 197, 71, 0.75)',
  0.4: 'rgba(249, 172, 62, 0.75)',
  0.5: 'rgba(249, 148, 54, 0.75)',
  0.6: 'rgba(249, 123, 45, 0.75)',
  0.7: 'rgba(249, 74, 28, 0.75)',
  0.8: 'rgba(250, 0, 3, 0.75)',
  0.9: 'rgba(254, 57, 27, 0.75)',
  1.0: 'rgba(255, 0, 0, 0.75)'
}

const visitedGradient = {
  0.1: 'rgba(63, 75, 248, 0.1)',
  0.2: 'rgba(60, 89, 223, 0.1)',
  0.3: 'rgba(57, 103, 198, 0.1)',
  0.4: 'rgba(54, 117, 174, 0.1)',
  0.5: 'rgba(51, 131, 149, 0.1)',
  0.6: 'rgba(48, 146, 125, 0.1)',
  0.7: 'rgba(42, 174, 75, 0.1)',
  0.8: 'rgba(36, 202, 26, 0.1)',
  1.0: 'rgba(33, 217, 2, 0.1)'
}

class PlanEventHeatMapClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      zoom: 8,
      mapType: GOOGLE_STREET_LAYER,
      map: null,
      planEventHeatMapPoints: {},
      historicalPlanEvents: null,
      selectedPointSwitchState: selectedLayerTypes,
      selectedPlanEventsCalendarState: []
    }
  }

  componentDidMount() {
    const planEventId = parseInt(this.props.match.params.planEventId)
    this.setState({ selectedPlanEventsCalendarState: [planEventId] })
    this.fetchPlanEventHistoricalData(planEventId)
  }

  setMap() {
    this.setState({ map: this.refs.map.leafletElement })
  }

  fetchPlanEventHistoricalData(planEventId) {
    this.props.startLoading()
    axios.get(Routes.historical_plan_events_plan_event_path(window.tenant, window.depoId, planEventId, {format: 'json'})).then(res => {
      this.setState({ historicalPlanEvents: res.data})
      this.fetchPlanEventPointsData(planEventId, null)
    }, () => this.setMap())
  }

  fetchPlanEventPointsData(planEventId, event) {
    this.updatePlanEventsCalendarState(event)
    if (!planEventId || this.state.planEventHeatMapPoints[planEventId]) return
    this.props.startLoading()
    axios.get(Routes.heat_map_points_plan_event_path(window.tenant, window.depoId, planEventId, {format: 'json'})).then(res => {
      let planEventHeatMapPoints = Object.assign(this.state.planEventHeatMapPoints)
      planEventHeatMapPoints[planEventId] = res.data
      this.setState({ planEventHeatMapPoints })
      this.updatePlanEventsCalendarState(event)
      this.props.stopLoading()
    })
  }

  updatePlanEventsCalendarState(event) {
    if (event != null) this.setState({ selectedPlanEventsCalendarState: event.target.value })
  }

  handleLayerChange() {
    let { map, mapType } = this.state
    mapType = mapType == GOOGLE_STREET_LAYER ? GOOGLE_SATELITE_LAYER : GOOGLE_STREET_LAYER
    this.setState({ mapType: mapType, zoom: map.getZoom() })
  }

  renderLayerIcon() {
    return (
      <div className="leaflet-action-container">
        <a className="leaflet-layer-label" onClick={this.handleLayerChange.bind(this)}>
          <i className="material-icons">layers</i>
        </a>
      </div>
    )
  }

  renderBackButton() {
    if (!this.state.historicalPlanEvents) return
    return (
      <div className="heat-map-back-button">
        <BackButton to={Routes.plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId)} />
      </div>
    )
  }

  handlePointStateSwitchChange(event) {
    this.setState({ selectedPointSwitchState: event.target.value })
  }

  handlePlanEventChange(event) {
    let planEventId = difference(event.target.value, this.state.selectedPlanEventsCalendarState)[0]
    this.fetchPlanEventPointsData(planEventId, event)
  }

  renderMapLayerSwitch() {
    if (!this.state.historicalPlanEvents) return
    return (
      <div className="heat-map-point-state-switch">
        <Select
          multiple
          value={this.state.selectedPointSwitchState}
          onChange={this.handlePointStateSwitchChange.bind(this)}
          renderValue={selected => selected.join(', ')}
          disableUnderline={true}
        >
        {layerTypes.map(layerType => (
          <MenuItem key={layerType} value={layerType}>
            <Checkbox checked={this.state.selectedPointSwitchState.indexOf(layerType) > -1} />
            <ListItemText primary={layerType} />
          </MenuItem>
        ))}
        </Select>
      </div>
    )
  }

  renderPlanEventsCalendarSwitch() {
    if (!this.state.historicalPlanEvents) return
    return (
      <div className="heat-map-calendar-switch">
        <Select
          multiple
          value={this.state.selectedPlanEventsCalendarState}
          onChange={this.handlePlanEventChange.bind(this)}
          renderValue={selected => "Selected plan events (" + selected.length + ")"}
          disableUnderline={true}
        >
          {this.state.historicalPlanEvents.map(planEvent => (
            <MenuItem key={planEvent.date} value={planEvent.id}>
              <Checkbox checked={this.state.selectedPlanEventsCalendarState.indexOf(planEvent.id) > -1} />
              <ListItemText primary={planEvent.date} />
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  renderMapLayer(layerType) {
    if (!this.state.selectedPointSwitchState.includes(layerType)) return

    const gradient = layerType == 'visited' ? visitedGradient : revenueGradient
    const { selectedPlanEventsCalendarState, planEventHeatMapPoints } = this.state
    let layerData = []

    selectedPlanEventsCalendarState.forEach((planEventId) => {
      if (planEventHeatMapPoints[planEventId]) {
        switch(layerType) {
          case 'visited':
            layerData.push(planEventHeatMapPoints[planEventId].map(p => ({coordinates: p.coordinates, value: 0.3})))
            break;
          case 'revenue':
            layerData.push(
              planEventHeatMapPoints[planEventId].filter(p => parseFloat(p.revenue) > 0).map(p =>
                ({ coordinates: p.coordinates, value: parseFloat(p.revenue) })
              )
            )
            break;
          case 'pins':
            this.renderHeatMapPins(planEventHeatMapPoints, planEventId, layerData)
            break;
        }
      }
    })

    return layerType == 'pins' ? layerData : this.renderHeatMapLayer(layerData, gradient, layerType)
  }

  renderHeatMapLayer(points, gradient, layerType) {
    const refreshKey = `${layerType}-${this.state.selectedPointSwitchState.indexOf('visited') > -1}`
    return (
      <HeatmapLayer
        key={refreshKey}
        fitBoundsOnLoad
        fitBoundsOnUpdate
        points={flatten(points)}
        maxZoom={10}
        longitudeExtractor={m => m.coordinates.lng}
        latitudeExtractor={m => m.coordinates.lat}
        intensityExtractor={m => m.value}
        gradient={gradient}
      />
    )
  }

  renderHeatMapPins(planEventHeatMapPoints, planEventId, layerData) {
    planEventHeatMapPoints[planEventId].map((p, i) => {
      layerData.push(
        <CircleMarker
          center={{ lat: p.coordinates.lat, lng: p.coordinates.lng }}
          key={`pin-${planEventId}-${i}`}
          fillColor="#476797"
          fillOpacity={0.9}
          color="#476797"
          radius={3}
          opacity={0}
          weight={1}
        />
      )
    })
  }

  render() {
    const { mapType, zoom } = this.state
    return (
      <div className='plan-event-map'>
        <Map ref="map" center={REGIONS[window.tenant].coordinates} zoom={zoom} zoomControl={false} className="heat-map-container-height">
          <TileLayer url={mapType} subdomains={['mt0','mt1','mt2','mt3']} />
          <ZoomControl position='bottomright' />
          {this.renderMapLayer('visited')}
          {this.renderMapLayer('revenue')}
          {this.renderMapLayer('pins')}
        </Map>
        {this.renderPlanEventsCalendarSwitch()}
        {this.renderMapLayerSwitch()}
        {this.renderBackButton()}
        {this.renderLayerIcon()}
        <Loading />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventHeatMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEventHeatMapClass)

export default PlanEventHeatMap
