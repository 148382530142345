import React from 'react'
import Button from '@material-ui/core/Button'

const SaveButton = ({customText, color, onClick, disabled}) => {
  return (
    <Button variant="contained" color={color || "primary"} type="submit" onClick={onClick} disabled={disabled || false}>
      {customText || 'SAVE'}
    </Button>
  )
}

export default SaveButton