import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import UserHttpRequestsIndex from './UserHttpRequestsIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const UserHttpRequestsIndexWithStore = () => {
  return(
    <Provider store={store}>
      <ErrorCatcher>
        <UserHttpRequestsIndex />
      </ErrorCatcher>
    </Provider>
  )
}

export default UserHttpRequestsIndexWithStore
