import React from 'react'
import domtoimage from 'dom-to-image'
import L from 'leaflet'
import Grid from '@material-ui/core/Grid'
import 'print-js/dist/print.js'
import { connect } from 'react-redux'
import { startLoading, stopLoading, setMapPrintingStatus, setMapPrintingProgress, cancelPrintPreview, loadPrintPreviewStatus } from '../../files/actions/index'

let counter = 0
let allImages = []
let initialCenter = null
let initialZoomLevel = null
let printingStarted = false

class PrintMapWithGridClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: null,
      mapContainer: null,
      mapContainerWidth: null,
      mapContainerHeight: null,
      gridItemsCollection: null,
      printGridRowCount: 1,
      printGridColumnCount: 1,
      mapPrinting: false
    }
  }

  componentDidMount() {
    const { map } = this.props
    map.setMaxZoom(16)
    initialCenter = map.getCenter()
    initialZoomLevel = map.getZoom()
    this.setState({
      map: map,
      mapContainer: map.getContainer(),
      mapContainerWidth: map._size.x,
      mapContainerHeight: map._size.y,
    })

    map.on('resize zoom move', (e) => {
      this.setState({
        mapContainerWidth: map._size.x,
        mapContainerHeight: map._size.y
      })
    })
  }

  totalPrintingPages() {
    return this.state.printGridRowCount * this.state.printGridColumnCount
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { printGridRowCount, printGridColumnCount, mapPrinting } = props

    this.setState({ printGridRowCount, printGridColumnCount, mapPrinting })
    if (mapPrinting == 'started') this.printMap()
    if (mapPrinting == 'ended') {
      this.setToInitialState(this.state.map, this.state.mapContainer)
      this.toggleControls(true)
      this.props.loadPrintPreviewStatus(false)
    }
  }

  getTileLyer(map) {
    const keys = Object.keys(map._layers)
    return map._layers[keys[0]]
  }

  printMap() {
    const gridItemsBounds = this.calculateGridItemsBounds()
    const { map, mapContainer } = this.state
    const tileLayer = this.getTileLyer(map)
    map.setMaxZoom(18)
    map._zoomAnimated = false
    map._fadeAnimated = false
    printingStarted = true
    mapContainer.style.width = 4*210 + "px"
    mapContainer.style.height = 4*297 + "px"
    map.invalidateSize()

    this.props.setMapPrintingProgress(counter, this.totalPrintingPages())
    tileLayer.on('load', function(e) {
      if(this.state.mapPrinting == 'started'){
        domtoimage.toPng(map.getContainer(), {
          width: map.getSize().x,
          height: map.getSize().y
        }).then(dataUrl => {
          allImages.push(dataUrl)
          counter++
          this.props.setMapPrintingProgress(counter, this.totalPrintingPages())
          this.fitBoundsData(map, gridItemsBounds)
        })
      }
    }.bind(this))

    counter = 0
    allImages = []
    this.toggleControls()
    this.fitBoundsData(map, gridItemsBounds)
  }

  fitBoundsData(map, gridItemsBounds) {
    if(gridItemsBounds && counter < gridItemsBounds.length) {
      map.fitBounds(gridItemsBounds[counter].bounds)
    } else {
      this.getTileLyer(map).off('load')
      this.generateDivWithImages()
    }
  }

  generateDivWithImages() {
    let imgTagsWithImages = '<div id="printable-images">'
    for( let i = 0; i < allImages.length; i++ ) {
      imgTagsWithImages += `<img class="printable-image" src="` + allImages[i] + `">`
    }
    imgTagsWithImages += '</div>'
    this.sendImagesToPrint(imgTagsWithImages)
  }

  sendImagesToPrint(imgTagsWithImages) {
    const { map, mapContainer } = this.state

    let div = document.createElement('div')
    div.innerHTML = imgTagsWithImages
    document.body.appendChild(div)

    setTimeout(function(){
      printJS({
        printable: 'printable-images',
        type: 'html'
      })
      document.getElementById("printable-images").remove()
    }, 10);

    this.props.setMapPrintingStatus('ended')
  }

  setToInitialState(map, mapContainer) {
    map.off('resize zoom move')
    const keys = Object.keys(map._layers)
    const tileLayer = map._layers[keys[0]]
    tileLayer.off('load')
    map.setMaxZoom(18)
    map._zoomAnimated = true
    map._fadeAnimated = true
    mapContainer.style.width = "100%"
    mapContainer.style.height = "calc(100vh - 64px)"
    map.options.zoomSnap = 1
    map.setView(initialCenter, initialZoomLevel)
    map.invalidateSize()
    counter = 0
    allImages = []
    initialCenter = initialZoomLevel = null
    printingStarted = false
  }

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1])
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    let ab = new ArrayBuffer(byteString.length)
    let dw = new DataView(ab)
    for(let i = 0; i < byteString.length; i++) {
        dw.setUint8(i, byteString.charCodeAt(i))
    }
    return new Blob([ab], {type: mimeString})
  }

  toggleControls(show) {
    let controlContainer = document.getElementsByClassName("leaflet-control-container")[0]
    let displayStyle = show ? 'block' : 'none'
    controlContainer.style.display = displayStyle
  }

  calculateGridItemsBounds() {
    const { map } = this.state

    if (printingStarted) return
    const gridItemsBounds = []
    const childNodes = document.getElementById("gridContainer").childNodes

    for(let counter = 0; counter < childNodes.length; counter++){
      let currentNode = childNodes[counter]
      let width = parseInt(currentNode.style.width)
      let height = parseInt(currentNode.style.height)
      gridItemsBounds.push({
        bounds: [
          map.containerPointToLatLng([currentNode.offsetLeft, currentNode.offsetTop]),
          map.containerPointToLatLng([currentNode.offsetLeft + width, currentNode.offsetTop]),
          map.containerPointToLatLng([currentNode.offsetLeft, currentNode.offsetTop + height]),
          map.containerPointToLatLng([currentNode.offsetLeft + width, currentNode.offsetTop + height])
        ]
      })
    }
    map.options.zoomSnap = 0
    map.setZoom(1)
    return gridItemsBounds
  }

  renderGridItems() {
    const { printGridColumnCount, printGridRowCount, map, mapContainerWidth, mapContainerHeight, mapPrinting } = this.state

    if(!map || printingStarted) return
    const ratio = 0.6842
    let width, height

    if (printGridRowCount >= parseFloat(printGridColumnCount*ratio).toFixed(2)){
      height = mapContainerHeight / printGridRowCount - 1
      width = height * ratio
    } else {
      width = mapContainerWidth / printGridColumnCount - 1
      height = width / ratio
    }

    let gridItemsCollection = []
    let key = 0
    for(let rc = 0; rc < printGridRowCount; rc++) {
      for(let cc = 0; cc < printGridColumnCount; cc++) {
        gridItemsCollection.push(<Grid key={key++} style={{height: height, width: width}} className="printing-grid-item"></Grid>)
      }
    }
    return <Grid id="gridContainer" container className="printing-grid-container" style={{width: printGridColumnCount*width, height: printGridRowCount*height}}>{gridItemsCollection}</Grid>
  }

  render() {
    return(
      <div id="printing-grid-wrapper">
        {this.renderGridItems()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    printGridColumnCount: state.printGridColumnCount,
    printGridRowCount: state.printGridRowCount,
    mapPrinting: state.mapPrinting
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    cancelPrintPreview: () => dispatch(cancelPrintPreview()),
    loadPrintPreviewStatus: status => dispatch(loadPrintPreviewStatus(status)),
    setMapPrintingProgress: (printedPages, totalPrintingPages) => dispatch(setMapPrintingProgress(printedPages, totalPrintingPages)),
    setMapPrintingStatus: mapPrinting => dispatch(setMapPrintingStatus(mapPrinting))
  }
}

const PrintMapWithGrid = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintMapWithGridClass)

export default PrintMapWithGrid
