import React from 'react'
import Paper from '@material-ui/core/Paper'
import PlanEventMap from '../map/PlanEventMap'
import PlanEventsReport from './PlanEventsReport'

const PlanEventsMap = (props) => {
  return (
    <PlanEventMap {...props} />
  )
}

export default PlanEventsMap
