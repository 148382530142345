import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Link } from 'react-router-dom';

import { requestPerformer } from './helpers/userHttpRequestsHelper'

const UserHttpRequestsTableRow = ({ userHttpRequest }) => {
  const { id, performed_at, user, url, method, status, controller, action } = userHttpRequest

  return (
    <TableRow hover>
      <TableCell>{performed_at}</TableCell>
      <TableCell>{requestPerformer(userHttpRequest)}</TableCell>
      <TableCell>{user && user.role}</TableCell>
      <TableCell>{url}</TableCell>
      <TableCell>{method}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{controller}</TableCell>
      <TableCell>{action}</TableCell>
      <TableCell>
        <Link className='mdl-button mdl-js-button mdl-button--icon' to={Routes.show_admin_user_http_requests_path(window.tenant, id)} >
          <i className='material-icons'>info</i>
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default UserHttpRequestsTableRow
