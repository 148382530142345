import React from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

class materialInput extends React.Component {
  render() {
    return (
      <TextField {...this.props} className="datepicker-textfield" />
    )
  }
}

class MaterialDatePicker extends React.Component {
  state = { [this.props.fieldName]: this.props.value }

  onDateChange(fieldName, date) {
    this.setState({ [fieldName]: moment(date).format('DD-MM-YYYY') }, () => this.props.afterChange && this.props.afterChange())
  }

  render() {
    const { fieldName, label, classNames, position, onDateChange, disableBeforeDate, inputProps } = this.props
    const date = this.state[fieldName] && moment(this.state[fieldName], 'DD-MM-YYYY').toDate()
    const positionClass = position ? position : 'bottom-position'
    const disableBefore = disableBeforeDate ? moment(disableBeforeDate, 'DD-MM-YYYY').toDate() : ''

    return (
      <DayPickerInput
        formatDate={date => moment(date).format('DD-MM-YYYY')}
        placeholder='DD-MM-YYYY'
        onDayChange={onDateChange || this.onDateChange.bind(this, fieldName)}
        component={materialInput}
        inputProps={{
          label: label,
          name: fieldName,
          autoComplete: 'off',
          ...inputProps
        }}
        value={date}
        keepFocus={false}
        dayPickerProps={{ firstDayOfWeek: 1, selectedDays: [date], disabledDays: { before: disableBefore} }}
        classNames={{ overlay: `modal-datepicker-overlay ${positionClass}`, container: `material-date-picker pl-1 pr-1 d-inline-block ${classNames}` }} />
    )
  }
}

MaterialDatePicker.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onDateChange: PropTypes.func,
  afterChange: PropTypes.func,
  classNames: PropTypes.string,
  position: PropTypes.string,
}

export default MaterialDatePicker
