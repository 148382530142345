import React from 'react'
import closeCalendarOnClick from '../helpers/closeCalendarOnClick'
import isMobile from '../helpers/isMobile'
import REGIONS from '../helpers/regions'
import setGA from '../helpers/setGA'
import icons from '../helpers/icons'
import logGoogleEvent from './logGoogleEvent'
import MapboxMap from '../../shared/MapboxSimpleMap'
import FraastFoodHeader from './FraastFoodHeader'
import FraastMobileDrawer from './FraastMobileDrawer'
import locationIcon from '../icons/location_icon.svg'
import hjemisLocationIcon from '../icons/hjem-is/location_hjemis.svg'
import ModalClass from './shared/ModalClass'
import ReCAPTCHA from 'react-google-recaptcha'

import { Checkbox } from '@material-ui/core'
import { isValidNumberForRegion, AsYouType, getCountryCallingCode, findPhoneNumbers, parseNumber } from 'libphonenumber-js'
import axios from 'axios'
import { Marker } from 'react-map-gl'
import truckIconFrast from '../icons/truck_icon.svg'
import truckIconSelectedFrast from '../icons/truck_icon_selected.svg'
import truckIconHjemis from '../icons/hjem-is/truck_hjemis.svg'
import truckIconSelectedHjemis from '../icons/hjem-is/truck_icon_selected_hjemis.svg'
import FraastFoodModal from './FraastFoodModal'
import getTenantLocale from '../helpers/getTenantLocale'
import mapboxgl from 'mapbox-gl'

const calculateMapHeight = () => {
  let mapHeight = window.innerHeight
  mapHeight -= isMobile() ? 0 : 82
  return mapHeight
}

class FraastFoodSubscribeMap extends React.Component {
  state = {
    planEventRoutePoints: [],
    customerPhone: '',
    unsubscribeCustomerPhone: '',
    region: REGIONS[this.props.tenant],
    pointForSubscriptionSelected: false,
    dayFilter: 'all',
    mapHeight: calculateMapHeight(),
    noLanguageModalOpen: false,
    unsubscribeModalOpen: false,
    isMobile: isMobile(),
    showAllUpcomingDates: false,
    gdprAgreed: false,
    marketingAgreed: true,
    noPointNearModal: false,
    noNearPoints: false,
    subscribeDropdownOpen: false,
    subscriptionModalOpen: false,
    subscribedModalOpen: false,
    unsubscribedModalOpen: false,
    smsServiceModalOpen: false,
    selectedPlanEventRoutePoint: null,
    initialViewState: {
      longitude: REGIONS[this.props.tenant].coordinates.lng - 1,
      latitude: REGIONS[this.props.tenant].coordinates.lat,
      zoom: REGIONS[this.props.tenant].zoom
    }
  }

  COUNTRY_CODE = REGIONS[this.props.tenant].code

  COUNTRY_PHONE_CODE = this.COUNTRY_CODE == 'WW' ? '' : getCountryCallingCode(this.COUNTRY_CODE)

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ mapHeight: calculateMapHeight(), isMobile: isMobile() })
    })

    closeCalendarOnClick.bind(this)()
    this.setFacebookCustomerAddressAndPhone()
    setGA()
    if (this.props.tenant == 'public') {
      logGoogleEvent('firstPopupLoaded')
      this.setState({ noLanguageModalOpen: true })
    }
  }

  setFacebookCustomerAddressAndPhone() {
    const { facebook_customer } = this.props
    if (facebook_customer) {
      this.initializeMessangerExtensionsSdk()
      this.onAddressChange('/type-in-facebook-addres-change', facebook_customer.address)
      if (!facebook_customer.phone) facebook_customer.phone = ''
      if (facebook_customer.phone.charAt(0) != '+') facebook_customer.phone = '+' + facebook_customer.phone
      let parsedPhone = findPhoneNumbers(facebook_customer.phone)
      if (parsedPhone.length > 0) this.setState({ customerPhone: parsedPhone[0].phone })
    }
  }

  fitBounds() {
    const { planEventRoutePoints, coordinates } = this.state;

    if (planEventRoutePoints.length > 0 || coordinates) {
      const mappedPoints = planEventRoutePoints.map((rp) => rp.coordinates)
      mappedPoints.push(coordinates)

      const bounds = new mapboxgl.LngLatBounds(mappedPoints[0], mappedPoints[0])
      mappedPoints.forEach((coord) => {
        bounds.extend(coord)
      })

      if (this.map) {
        this.map.fitBounds(bounds, { padding: 150, offset: isMobile() ? [0, -80] : [100, 0]  })
      }
    }
  }

  onPhoneNumberChange(event) {
    const customerPhone = new AsYouType(this.COUNTRY_CODE).input(event.target.value)
    console.log(customerPhone, this.COUNTRY_CODE, isValidNumberForRegion(customerPhone, this.COUNTRY_CODE))
    this.setState({ customerPhone })
  }

  onPhoneNumberToggle() {
    this.logHotjar('/type-in-phone-number')
    logGoogleEvent('phoneNumberInput')
  }

  async subscribeForSMS() {
    const {
      customerPhone,
      marketingAgreed,
      selectedPlanEventRoutePoint: { id, coordinates, address },
      captchaValue
    } = this.state
    const { facebook_customer } = this.props
    const parsedPhone = parseNumber(customerPhone, this.COUNTRY_CODE)
    const original_address = address
    const original_coordinates = coordinates

    const params = {
      locale: getTenantLocale(this.props.tenant),
      customer: {
        phone: `${this.COUNTRY_PHONE_CODE}${parsedPhone.phone}`,
        location_attributes: {
          coordinates,
          address,
          original_address,
          original_coordinates
        },
        sms_receivers_attributes: [{ plan_event_route_point_id: id }],
        marketing_agreement: marketingAgreed
      },
      plan_event_route_point_id: id,
      facebook_messenger_id: facebook_customer ? facebook_customer.facebook_messenger_id : '',
      broadcast_message_block_id: facebook_customer ? facebook_customer.broadcast_message_block_id : '',
      captcha_value: captchaValue
    }

    await axios.post(Routes.public_customers_path(params))
    this.logHotjar('/subscribed-to-receive-sms-for-stop')
    logGoogleEvent('subscribeToReceiveSms')
    this.setState({ subscribedModalOpen: true, subscriptionModalOpen: false })

    if (facebook_customer && MessengerExtensions) {
      MessengerExtensions.requestCloseBrowser()
    }
  }

  clearDialogRelatedStates(googleEvent) {
    logGoogleEvent(googleEvent)
    this.setState({
      newModalOpen: false,
      subscriptionModalOpen: false,
      noPointNearModal: false,
      unsubscribeModalOpen: false,
      smsServiceModalOpen: false
    })
  }

  unsubscribeModalContent() {
    const { unsubscribeCustomerPhone } = this.state
    return {
      classes: 'subscribtion-modal',
      logo: icons[this.props.tenant].carPhoneLogo,
      headerTitle: this.props.translations.do_you_wish_to_unsubscribe,
      headerSubtitle: this.props.translations.unsubscribe_explanation,
      phoneInput: {
        onChange: this.handleUnsubscribePhoneChange.bind(this),
        onPhoneNumberToggle: this.onPhoneNumberToggle.bind(this),
        customerPhone: unsubscribeCustomerPhone,
        countryCode: this.COUNTRY_PHONE_CODE
      },
      actionButton: this.renderButton(
        this.props.translations.unsubscribe,
        'customer-subscribe-button black-button',
        this.handleUnsubscribtion.bind(this),
        !isValidNumberForRegion(unsubscribeCustomerPhone, this.COUNTRY_CODE) || unsubscribeCustomerPhone.length < 8
      )
    }
  }

  noNearPointsContent() {
    return {
      classes: 'no-near-points-content',
      headerIcon: icons[this.props.tenant].logo,
      logo: icons[this.props.tenant].iceCreamModal,
      headerTitle: this.props.translations.no_near_points,
      actionButton: this.renderButton('OK', 'customer-subscribe-button black-button', () =>
        this.setState({ noPointNearModal: false })
      ),
      smallParagraph: this.props.translations.no_near_points_subtext
    }
  }

  noLanguageModalContent() {
    return {
      classes: 'no-language-content',
      headerTitle: 'Change language',
      smallParagraph: 'We noticed you are visiting from outside of Denmark. Would you like to change language?',
      languageSelect: true
    }
  }

  smsServiceModalContent() {
    const list = []
    if (this.state.selectedPlanEventRoutePoint) {
      list.push({
        name: this.props.translations.subscribe,
        action: () => {
          this.setState({ subscriptionModalOpen: true })
        }
      })
    }
    list.push({
      name: this.props.translations.unsubscribe,
      action: () => {
        this.setState({ unsubscribeModalOpen: true })
      }
    })
    return {
      classes: 'sms-service-content',
      headerTitle: 'SMS-service',
      smallParagraph: this.props.translations.sms_service,
      buttonList: list
    }
  }

  handleCaptchaValueChange = (value) => {
    this.setState({ captchaValue: value })
  }

  renderCaptchaCheckbox() {
    const metaEnv = document.querySelector('meta[name=env]')
    const env = metaEnv.getAttribute('content')
    if (env !== 'test') {
      return (
        <div style={{ width: 300, margin: 'auto' }}>
          <ReCAPTCHA
            sitekey={this.props.site_key}
            onChange={this.handleCaptchaValueChange}
          />
        </div>
      )
    }
  }

  disabledSubmitButton() {
    const { customerPhone } = this.state

    return !isValidNumberForRegion(customerPhone, this.COUNTRY_CODE) ||
      customerPhone.length < 8 || !this.state.gdprAgreed ||
      !this.state.captchaValue
  }

  subscribtionModalContent() {
    const {
      customerPhone,
      selectedPlanEventRoutePoint: { address }
    } = this.state
    return {
      classes: 'subscribtion-modal',
      logo: icons[this.props.tenant].carPhoneLogo,
      headerTitle: this.props.translations.subscribe,
      headerSubtitle: address,
      smallParagraph: this.props.translations.subscribe_to_paragraph,
      policyParagraph: this.props.privacyPolicy,
      phoneInput: {
        onChange: this.onPhoneNumberChange.bind(this),
        onPhoneNumberToggle: this.onPhoneNumberToggle.bind(this),
        customerPhone: customerPhone,
        countryCode: this.COUNTRY_PHONE_CODE
      },
      actionButton: this.renderButton(
        this.props.translations.subscribe_to,
        'customer-subscribe-button black-button',
        this.subscribeForSMS.bind(this),
        this.disabledSubmitButton()
      ),
      gdprParagraph: this.props.translations.agree_to_sms_privacy,
      gdprPolicy: true,
      gdprCheckbox: this.renderGdprCheckbox(),
      captchaCheckbox: this.renderCaptchaCheckbox()
    }
  }

  subscribtionSuccessModalContent(address) {
    return {
      classes: 'subscribtion-modal-success',
      logo: icons[this.props.tenant].carPhoneLogo,
      headerTitle: this.props.translations.subscribed,
      headerSubtitle: address,
      largeParagraph: this.props.translations.receive_ice_cream,
      smallParagraph: this.props.translations.receive_ice_cream_sub,
      actionButton: this.renderButton(
        this.props.translations.got_it,
        'customer-subscribe-button success-button black-button',
        this.navigateToWebsite.bind(this)
      )
    }
  }

  unsubscribedModalContent() {
    const title = this.state.customerExists
      ? this.props.translations.customer_exists
      : this.props.translations.customer_doesnt_exist

    return {
      classes: 'subscribtion-modal',
      logo: icons[this.props.tenant].carPhoneLogo,
      headerTitle: title,
      actionButton: this.renderButton('OK', 'customer-subscribe-button black-button', this.navigateToWebsite.bind(this))
    }
  }

  navigateToWebsite() {
    if (this.props.tenant === 'hjem-is') {
      window.location = 'https://hjem-is.dk/'
    } else {
      window.location = 'https://frastfoods.com/'
    }
  }

  handleUnsubscribePhoneChange(e) {
    const customerPhone = new AsYouType(this.COUNTRY_CODE).input(e.target.value)
    this.setState({ unsubscribeCustomerPhone: customerPhone })
  }

  handleUnsubscribtion() {
    const { unsubscribeCustomerPhone } = this.state
    logGoogleEvent('subscribtionCustomerHasUnsubscribed')
    const parsedPhone = parseNumber(unsubscribeCustomerPhone, this.COUNTRY_CODE)
    const phone = parsedPhone.phone.length > 0 ? parsedPhone.phone : phone.replace(/\s+/g, '')
    const phoneNumber = `${this.COUNTRY_PHONE_CODE}${phone}`
    axios
      .delete(
        Routes.public_customer_path('1', {
          locale: getTenantLocale(this.props.tenant),
          format: 'json',
          phone_number: phoneNumber
        })
      )
      .then((res) => {
        this.setState({
          unsubscribedModalOpen: true,
          unsubscribeModalOpen: false,
          customerExists: true
        })
      })
      .catch((err) => {
        this.setState({
          unsubscribedModalOpen: true,
          unsubscribeModalOpen: false,
          customerExists: false
        })
      })
  }

  renderButton(title, className, action, disabled = false) {
    return (
      <button onClick={action} className={className} disabled={disabled}>
        {title}
      </button>
    )
  }

  renderGdprCheckbox() {
    return (
      <Checkbox
        onChange={this.handleGdprCheck.bind(this)}
        checked={this.state.gdprAgreed}
        className='gdpr-agree-check'
      />
    )
  }

  handleRender(map, e) {
    this.map = map.target
  }

  handleGdprCheck() {
    this.setState({ gdprAgreed: !this.state.gdprAgreed })
  }

  onMove(e) {
    logGoogleEvent('mapInteraction')
  }

  onAddressChange(hotjarLink, coordinates, googleAction) {
    this.setState(
      {
        searching: false,
        selectedPlanEventRoutePoint: false,
        pointForSubscriptionSelected: false,
        coordinates
      },
      () => {
        this.logHotjar(hotjarLink)
        logGoogleEvent(googleAction)
        if (this.map) {
          this.map.resize()
          this.map.flyTo({
            center: [coordinates.lng, coordinates.lat],
            offset: isMobile() ? [0, 0] : [300, 0],
            zoom: 14
          })
        }
        this.fetchNearbyRoutePoints()
      }
    )
  }

  clearAddress() {
    this.setState({
      selectedPlanEventRoutePoint: false,
      pointForSubscriptionSelected: false
    })
  }

  async fetchNearbyRoutePoints() {
    const { dayFilter, coordinates } = this.state
    if (!coordinates) return
    const todaysEventsOnly = dayFilter === 'today' ? true : null
    const params = {
      coordinates,
      today: todaysEventsOnly,
      format: 'json',
      locale: getTenantLocale(this.props.tenant)
    }
    const path = Routes.public_plan_event_route_points_path
    const { data } = await axios.get(path(params))
    if (this.noPointsNear(data)) {
      this.setState({
        noPointNearModal: true,
        newModalOpen: false,
        planEventRoutePoints: data,
        noNearPoints: true
      })
    } else {
      this.setState({
        planEventRoutePoints: data,
        selectedPlanEventRoutePoint: data[0],
        newModalOpen: false,
        noNearPoints: false
      })

      this.fitBounds()
    }
  }

  noPointsNear(data) {
    const closestPoint = data[0]
    if (!!closestPoint) {
      return closestPoint.distance > 5000
    } else {
      return true
    }
  }

  logHotjar(path) {
    window.hj && window.hj('vpv', path)
  }

  renderSearchLocation() {
    const { coordinates } = this.state
    if (coordinates) {
      return (
        <Marker longitude={coordinates.lng} latitude={coordinates.lat} anchor='bottom'>
          <img className='search-location' src={this.props.tenant === 'hjem-is' ? hjemisLocationIcon : locationIcon} />
        </Marker>
      )
    }
  }

  renderClosestRoutePoints() {
    const { planEventRoutePoints } = this.state
    if (this.state.noNearPoints) {
      alert
      return null
    }
    return planEventRoutePoints.map((planEventRoutePoint) => {
      const { id, coordinates } = planEventRoutePoint
      let selectedTruckIcon = truckIconSelectedFrast
      let truckIcon = truckIconFrast
      if (this.props.tenant === 'hjem-is') {
        selectedTruckIcon = truckIconSelectedHjemis
        truckIcon = truckIconHjemis
      }
      const icon = this.state.selectedPlanEventRoutePoint == planEventRoutePoint ? selectedTruckIcon : truckIcon

      return (
        <Marker
          onClick={() =>
            this.setState({
              selectedPlanEventRoutePoint: planEventRoutePoint,
              showAllUpcomingDates: false
            })
          }
          key={id}
          longitude={coordinates.lng}
          latitude={coordinates.lat}
          anchor='bottom'
        >
          <img className='location-marker-wrapper' src={icon} />
        </Marker>
      )
    })
  }

  renderMapContainer() {
    const { mapHeight, isMobile, pointForSubscriptionSelected } = this.state
    const adjustedMapHeight = isMobile
      ? mapHeight - (pointForSubscriptionSelected ? mapHeight * 0.45 + 50 : 0)
      : mapHeight

    return (
      <div className='map-container subscribe-map'>
        <MapboxMap
          onRender={this.handleRender.bind(this)}
          initialViewState={this.state.initialViewState}
          accessToken={this.props.access_token}
          onMove={this.onMove.bind(this)}
          height={adjustedMapHeight}
        >
          {this.renderSearchLocation()}
          {this.renderClosestRoutePoints()}
        </MapboxMap>
      </div>
    )
  }

  renderModal() {
    const {
      noLanguageModalOpen,
      selectedPlanEventRoutePoint,
      customerPhone,
      subscriptionModalOpen,
      subscribedModalOpen,
      noPointNearModal,
      unsubscribeModalOpen,
      unsubscribedModalOpen,
      smsServiceModalOpen
    } = this.state
    const { translations } = this.props
    let content = false

    if (noLanguageModalOpen) content = this.noLanguageModalContent()
    else if (subscribedModalOpen) content = this.subscribtionSuccessModalContent(selectedPlanEventRoutePoint.address)
    else if (unsubscribedModalOpen) content = this.unsubscribedModalContent()
    else if (subscriptionModalOpen) content = this.subscribtionModalContent()
    else if (noPointNearModal) content = this.noNearPointsContent()
    else if (unsubscribeModalOpen) content = this.unsubscribeModalContent()
    else if (smsServiceModalOpen) content = this.smsServiceModalContent()

    const modalCloseEvent = subscriptionModalOpen ? 'closePhonePopup' : 'closeFirstPopup'

    if (content) {
      return (
        <ModalClass
          accessToken={this.props.access_token}
          isbilenAccessToken={this.props.isbilen_access_token}
          logHotjar={this.logHotjar.bind(this)}
          open={true}
          close={this.clearDialogRelatedStates.bind(this, modalCloseEvent)}
          BackdropProps={{ classes: { root: 'modal-backdrop' } }}
          translations={translations}
          content={content}
          tenant={tenant}
        ></ModalClass>
      )
    }
  }

  openSmsService = () => this.setState({ smsServiceModalOpen: true })
  openSubscribeModal = () => this.setState({ subscriptionModalOpen: true })

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <FraastFoodHeader tenant={this.props.tenant} language={getTenantLocale(this.props.tenant)} />
        {this.props.tenant != 'public' && isMobile() == false && (
          <FraastFoodModal
            accessToken={this.props.access_token}
            isbilenAccessToken={this.props.isbilen_access_token}
            isbillen_api_key={this.props.isbillen_api_key}
            translations={this.props.translations}
            onAddressChange={this.onAddressChange.bind(this)}
            tenant={this.props.tenant}
            subscribeDropdownOpen={this.state.subscribeDropdownOpen}
            toggleDropdown={() =>
              this.setState({
                subscribeDropdownOpen: !this.state.subscribeDropdownOpen
              })
            }
            selectedPlanEventRoutePoint={this.state.selectedPlanEventRoutePoint}
            showAllUpcomingDates={this.state.showAllUpcomingDates}
            openSubscribeModal={() => {
              this.setState({ subscriptionModalOpen: true })
            }}
            showAllDates={() => {
              this.setState({ showAllUpcomingDates: true })
            }}
            unsubscribe={() => this.setState({ unsubscribeModalOpen: true })}
          />
        )}
        {this.props.tenant != 'public' && isMobile() && (
          <FraastMobileDrawer
            translations={this.props.translations}
            accessToken={this.props.access_token}
            isbilenAccessToken={this.props.isbilen_access_token}
            isbillen_api_key={this.props.isbillen_api_key}
            onAddressChange={this.onAddressChange.bind(this)}
            clearAddress={this.clearAddress.bind(this)}
            tenant={this.props.tenant}
            selectedPlanEventRoutePoint={this.state.selectedPlanEventRoutePoint}
            showAllUpcomingDates={this.state.showAllUpcomingDates}
            showAllDates={() => {
              this.setState({ showAllUpcomingDates: true })
            }}
            openSmsService={this.openSmsService}
            openSubscribeModal={this.openSubscribeModal}
          />
        )}
        {this.renderMapContainer()}
        {this.renderModal()}
      </div>
    )
  }
}

export default FraastFoodSubscribeMap
