import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import DeposForm from './DeposForm'
import DeposTable from './DeposTable'

const DeposComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/admin/depos/:depoId/edit' component={DeposForm}/>
        <Route path='/:tenant/admin/depos/new' component={DeposForm} />
        <Route path='/:tenant/admin/depos' component={DeposTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default DeposComponent
