import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'

class SelectFilter extends React.Component {
  state = { selectedCollection: [], collection: this.props.collection }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.clearFilters) {
      this.setState({ selectedCollection: [] })
    }
  }

  selectedStatuses() {
    const { selectedCollection, collection } = this.state

    return selectedCollection.map(id => {
      const index = collection.findIndex(c => c.id == id)
      return collection[index][this.props.collectionKey]
    }).join(', ')
  }

  handleChange = (e) => this.setState({ selectedCollection: e.target.value })

  render() {
    const { selectedCollection, collection } = this.state
    const { collectionKey, label } = this.props

    return  (
      <FormControl className={collectionKey} style={{ marginRight: 10 }}>
        <InputLabel htmlFor={label}>{label}</InputLabel>
        <Select
          multiple
          value={selectedCollection}
          fullWidth
          input={<Input id={label} style={{ width: 200 }} />}
          renderValue={this.selectedStatuses.bind(this)}
          onChange={this.handleChange}
        >
          {collection.map(object => {
            const checked = selectedCollection.indexOf(object.id) > -1

            return (
              <MenuItem key={object.id} value={object.id} >
                <Checkbox checked={checked} />
                <ListItemText primary={object[collectionKey]} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

export default SelectFilter
