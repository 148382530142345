import React from 'react'
import Button from '@material-ui/core/Button'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import printStyles from '../../../files/printingAssets.js.erb'
const printableContainerId = "inventory-product-print-container"

class PrintableTable extends React.Component {
  state ={ printingStarted: false, loading: false }

  printRoutePoints() {
    this.startLoading()
    this.setState({ printingStarted: true }, () => {
      const { header: { documentTitle } } = this.props
      printJS({
        printable: printableContainerId,
        type: 'html',
        documentTitle: documentTitle,
        scanStyles: false,
        css: printStyles,
        onLoadingEnd: this.stopLoading
      })
      this.setState( { printingStarted: false })
    })
  }

  startLoading = () => this.setState({ loading: true })

  stopLoading = () => this.setState({ loading: false })

  renderHeader() {
    const { header: { fromWarehouse, forWarehouse, toWarehouse, createdBy, createdAt } } = this.props
    return (
      <div className="printable-header">
        <div>
          {fromWarehouse || forWarehouse}<br/>
          {toWarehouse}
        </div>
        <div>
          {createdBy}<br/>
          {createdAt}
        </div>
      </div>
    )
  }

  renderTableHead() {

    return (
      <thead>
        <tr>
          <th className="min-width-200">SKU</th>
          <th className="min-width-450">Description</th>
          {this.renderOptionalHead()}
          <th className="min-width-85">Correction</th>
        </tr>
      </thead>
    )
  }

  renderOptionalHead() {
    if (this.props.onlyAmount) {
      return (
        <React.Fragment>
          <th className="min-width-85">Amount</th>
        </React.Fragment>
      )
    } else if (this.props.newWithCorrection){
      return (
        <React.Fragment>
          <th className="min-width-85">Amount</th>
          <th className="min-width-85">Correction</th>
          <th className="min-width-90">New Amount</th>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <th className="min-width-85">Old value</th>
          <th className="min-width-85">New value</th>
        </React.Fragment>
      )
    }
  }

  renderValues(item) {
    if (this.props.onlyAmount) {
      return (
        <React.Fragment>
          <td className="min-width-85">{item.amount}</td>
        </React.Fragment>
      )
    } else if (this.props.newWithCorrection){
      return (
        <React.Fragment>
          <td className="min-width-85">{item.amount}</td>
          <td className="min-width-85"></td>
          <td className="min-width-85">{item.new_amount}</td>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <td className="min-width-85">{item.old_value}</td>
          <td className="min-width-85">{item.new_value}</td>
        </React.Fragment>
      )
    }
  }

  renderTableBody() {
    const { items } = this.props
    if (items.length > 0) {
      return (
        items.map((item, index) => {
          return (
            <tr key={index}>
              <td className="min-width-200">{item.sku}</td>
              <td className="min-width-450">{item.description}</td>
              {this.renderValues(item)}
              <td className="min-width-85"></td>
            </tr>
          )
        })
      )
    }
  }

  renderPrintButton() {
    return (
      <Button variant="contained" color="primary" onClick={this.printRoutePoints.bind(this)}>
          Print
      </Button>
    )
  }

  renderPrintableContainer() {
    if(!this.state.printingStarted) return
    return (
      <div className="printable-container" id={printableContainerId}>
        {this.renderHeader()}
        <table>
          {this.renderTableHead()}
          <tbody>
            {this.renderTableBody()}
          </tbody>
        </table>
      </div>
    )
  }

  renderLoading() {
    return <LoadingWithoutRedux
      additionalStyles={{ top: 'calc(100vh * 0.5 - 60px)', left: '50%', marginLeft: 0 }}
      loading={this.state.loading} />
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPrintButton()}
        {this.renderPrintableContainer()}
        {this.renderLoading()}
      </React.Fragment>
    )
  }
}

export default PrintableTable;
