import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

class LoadingClass extends React.Component {
  render() {
    if (!this.props.loading && !this.props.loadingStarted) return null
    return <CircularProgress
              className="loading-spinner"
              size={60}
              style={{position: 'absolute', zIndex: '999', marginLeft: '50%', top: 'calc(50% - 20px)'}}
            />
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading
  }
}

const Loading = connect(
  mapStateToProps,
  null
)(LoadingClass)

export default Loading
