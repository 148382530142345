import React from 'react'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteForever from '@material-ui/icons/DeleteForever'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FlashNotification from '../../shared/FlashNotification'
import extractDataErrors from '../../support/extractDataErrors'
import isEmpty from 'lodash/isEmpty'

const formStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: 10,
  marginBottom: 10,
  marginLeft: 22
}

class ProductsTable extends React.Component {
  state = {
    products: [],
    productNumber: '',
    search: '',
    paginationDisabled: false,
    currentPage: 1,
    full_errors: null,
    errors: {}
  }

  componentDidMount() {
    this.loadPage()
  }

  handleChange = (field, e) => this.setState({ [field]: e.target.value })
  handleSubmit = () => {
    axios.post(Routes.wallmob_products_path(tenant, { format: 'json' }), { product: { number: this.state.productNumber }}).then(res => {
      const products = this.state.products.slice(0)
      products.unshift(res.data)
      this.setState({ products, productNumber: '' })
    }).catch(err => {
      this.setState({ ...extractDataErrors(err) })
    })
  }
  handleDestruction = (id, event) => {
    if (!confirm("Are you sure you want to delete this product?")) return

    axios.delete(Routes.wallmob_product_path(tenant, id, { format: 'json' })).then(res => {
      const products = this.state.products.slice(0)
      const index = products.findIndex(c => c.id === id)
      products.splice(index, 1)
      this.setState({ products })
    }).catch(err => {
      this.setState({ ...extractDataErrors(err) })
    })
  }

  loadPage() {
    axios.get(Routes.api_v3_wallmob_products_path(tenant, { format: 'json', query: this.state.search, page: this.state.currentPage })).then(res => {
      this.setState({ products: res.data, paginationDisabled: false })
    }).catch(err => {
      this.setState({ ...extractDataErrors(err) })
    })
  }

  renderDestroyButton(id) {
    return (
      <IconButton className={`delete-${id}`} onClick={this.handleDestruction.bind(this, id)}>
        <DeleteForever />
      </IconButton>
    )
  }

  changePage(pageIncrement) {
    if (this.state.products.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, this.state.currentPage + pageIncrement))
    if (newPage === this.state.currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadPage)
  }

  renderPagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className="right-action-container float-right">
        <div className="pagination">
          <strong>Page: {currentPage}</strong>
          &nbsp;
          <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    )
  }

  renderFlashNotification() {
    const { full_errors } = this.state
    const message = !isEmpty(full_errors) ? `${full_errors.join(", ")}` : null
    return <FlashNotification message={message} clearErrors={() => this.setState({ full_errors: null })} />
  }

  render() {
    const { products, productNumber, search, message } = this.state

    return (
      <div>
        {this.renderFlashNotification()}
        <div style={formStyle}>
          <TextField
            fullWidth
            onKeyDown={(e) => e.key == 'Enter' && this.handleSubmit()}
            style={{ width: '30%' }}
            onChange={this.handleChange.bind(this, 'productNumber')}
            label="Product Number"
            value={productNumber} />
          <Button style={{ marginLeft: 10 }} onClick={this.handleSubmit.bind(this)} variant="contained" color="primary">Submit</Button>
          <TextField
            onChange={this.handleChange.bind(this, 'search')}
            style={{ marginLeft: 20 }}
            label="Search..."
            value={search}
            onKeyDown={(e) => e.key == 'Enter' && this.loadPage()} />
          <Button style={{ marginLeft: 10 }} onClick={this.loadPage.bind(this)} variant="contained" color="primary">Search</Button>
        </div>
        {this.renderPagination()}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product number</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(product => {
              return (
                <TableRow key={product.id}>
                  <TableCell>{product.number}</TableCell>
                  <TableCell>{this.renderDestroyButton(product.id)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default ProductsTable
