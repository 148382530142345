import React from 'react'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { FormControl, FormHelperText } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import axios from 'axios'
import find from 'lodash/find'
import filter from 'lodash/filter'

import { setMainTitle, startLoading, stopLoading, setFlash, loadDepos } from '../../../files/actions/index'

import User from '../../../models/User'
import GridWrapper from '../../shared/GridWrapper'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FunTextField from '../../forms/FunTextField'
import IntegrationReactSelect from '../../forms/IntegrationReactSelect'
import extractDataErrors from '../../support/extractDataErrors'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

class UsersFormClass extends React.Component {
  state = {
    generatedPasswordUrl: '',
    generatePasswordToken: false,
    ...new User()
  }

  componentDidMount() {
    this.props.startLoading()

    if (this.props.depos.length === 0) {
      this.loadDepos()
    }

    const user = this.findUserInStore()

    if (user) {
      this.setUserAndMainTitle(user)
      this.props.stopLoading()
    } else if(this.props.match.params.userId) {
      axios.get(this.loadUrl()).then(res => {
        this.setUserAndMainTitle(res.data)
      }).catch(error => {
        this.props.setFlash(`${error} (${this.loadUrl()})`)
      }).then(
        this.props.stopLoading
      )
    } else {
      this.setMainTitle()
      this.props.stopLoading()
    }
  }

  findUserInStore() {
    return find(this.props.users, ({ id }) => {
      return id == this.props.match.params.userId
    })
  }

  setUserAndMainTitle(user) {
    this.setUser(user)
  }

  setUser(user) {
    this.setState({
      id: user.id,
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      email: user.email || '',
      role: user.role || '',
      depo_id: user.depo_id || '',
      depo_ids: user.depo_ids || [],
      wallmob_id: user.wallmob_id || null,
      lakrids_driver_id: user.lakrids_driver_id || null,
      lakrids_driver_name: user.lakrids_driver_name || null,
      wallmob_name: user.wallmob_name || null,
      axapta_code: user.axapta_code || '',
      status: user.status || 'active',
      franchise_driver: user.franchise_driver,
    }, () => this.setMainTitle())
  }

  setMainTitle() {
    this.props.setMainTitle(!!this.state.id ? `Edit user ${this.state.email}` : 'New user')
  }

  setStateAfterSave(data) {
    this.setState({
      id: data.id,
      password: '',
      password_confirmation: '',
      generatedPasswordUrl: data.generated_password_url,
      franchise_driver: data.franchise_driver,
      errors: {}
    })
  }

  loadDepos() {
    axios.get(this.deposUrl()).then(res => {
      this.props.loadDepos(res.data)
    }).catch(error => {
      this.props.setFlash(`${error} (${this.deposUrl()})`)
    }).then(
      this.props.stopLoading()
    )
  }

  data() {
    return { user: this.state, format: 'json' }
  }

  loadUrl() {
    return window.Routes.admin_user_path(window.tenant, window.depoId, this.props.match.params.userId, {format: 'json'})
  }

  url() {
    return this.state.id ? window.Routes.admin_user_path(window.tenant, window.depoId, this.state.id) : Routes.admin_users_path(window.tenant, window.depoId)
  }

  deposUrl() {
    return window.Routes.admin_depos_path(window.tenant, window.depoId, {format: 'json'})
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  submit(e) {
    e.preventDefault()
    this.props.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    ).then(
      this.props.stopLoading()
    )
  }

  handleSubmitSuccess(response) {
    this.setStateAfterSave(response.data)
    this.props.history.push(window.Routes.edit_admin_users_path(window.tenant, window.depoId, response.data.id))
    this.props.setFlash("User successfully updated")
    this.setMainTitle()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  handleChange(e, value) {
    this.setState({[value]: e.target.value})
  }

  deposSelectValue(fieldName) {
    return filter(this.props.depos, (depo) => {
      return fieldName === 'depo_ids' ? this.state.depo_ids.indexOf(depo.id) > -1 : this.state.depo_id === depo.id
    }).map((depo) => depo.title)
  }

  async loadWallmobUserOptions(q, callback) {
    if (q === '') {
      return callback([])
    }

    const { data } = await axios.get(Routes.wallmob_users_path(window.tenant), { params: { q } })
    this.options   = data.map(user => {
      return { value: user.id, label: user.full_name }
    })

    callback(this.options)
  }

  onWallmobUserChange(wallmob_id) {
    let wallmob_name = null
    wallmob_id = wallmob_id && wallmob_id.value
    if (wallmob_id) {
      wallmob_name = this.options && this.options.find(option => option.value === wallmob_id).label
    }

    this.setState({ wallmob_id, wallmob_name })
  }

  renderWallmobUserSelect(wallmob_id, wallmob_name) {
    const selectedUser = wallmob_id ? { value: wallmob_id, label: wallmob_name } : null
    return (
      <div className='react-select-wrapper'>
        <InputLabel>Wallmob User</InputLabel>
        <IntegrationReactSelect name='wallmob_id'
                                loadOptions={this.loadWallmobUserOptions.bind(this)}
                                onChange={this.onWallmobUserChange.bind(this)}
                                value={selectedUser} />
      </div>
    )
  }

  onLakridsUserChange(lakrids_driver_id) {
    let lakrids_driver_name = null
    lakrids_driver_id = lakrids_driver_id.value
    if (lakrids_driver_id) {
      lakrids_driver_name = this.options && this.options.find(option => option.value === lakrids_driver_id).label
    }
    this.setState({ lakrids_driver_id, lakrids_driver_name })
  }

  async loadLakridsUserOptions(driver_name, callback) {
    if (driver_name === '') {
      return callback([])
    }

    const { data } = await axios.get(`${this.props.lakrids_host}/api/v1/users/search`, { params: { driver_name } })
    this.options   = data.data.map(user => {
      return { value: user.driver_number, label: user.name }
    })

    callback(this.options)
  }

  renderLakridsDriverSelect() {
    const { lakrids_driver_id, lakrids_driver_name } = this.state
    const selectedUser = lakrids_driver_id ? { label: lakrids_driver_name, value: lakrids_driver_id } : null
    return (
      <div className='react-select-wrapper'>
        <InputLabel>Lakrids driver number {window.rendercount}</InputLabel>
        <IntegrationReactSelect
          name='lakrids_driver_id'
          placeholder="Driver"
          loadOptions={this.loadLakridsUserOptions.bind(this)}
          onChange={this.onLakridsUserChange.bind(this)}
          value={selectedUser}
        />
      </div>
    )
  }

  renderDepoSelect() {
    if (this.state.role !== 'admin') {
      const fieldName = this.state.role === 'depo_admin' ? 'depo_ids' : 'depo_id'
      const label = this.state.role === 'depo_admin' ? 'Depos' : 'Depo'
      const multiple = this.state.role === 'depo_admin'
      return (
        <FormControl fullWidth error={!!this.state.errors.depo_ids}>
          <InputLabel htmlFor={fieldName}>{label}</InputLabel>
          <Select
            multiple={multiple}
            value={this.state[fieldName]}
            fullWidth
            input={<Input id={fieldName} />}
            renderValue={(selected) => this.deposSelectValue(fieldName).join(', ')}
            onChange={ e => this.handleChange(e, fieldName)}
          >
            {this.props.depos.map((depo) => {
              const checked = this.state.role === 'depo_admin' ? this.state.depo_ids.indexOf(depo.id) > -1 : this.state.depo_id === depo.id
              return (
                <MenuItem key={`depo-${depo.id}`} value={depo.id} >
                  <Checkbox checked={checked} />
                  <ListItemText primary={depo.title} />
                </MenuItem>
              )
            })}
          </Select>
          {!!this.state.errors.depo_ids && <FormHelperText>{this.state.errors.depo_ids[0]}</FormHelperText>}
        </FormControl>
      )
    }
  }

  handleCheckboxChange(field) {
    this.setState({ [field]: !this.state[field] })
  }

  copyToCliboard() {
    const copyText = document.getElementById("password-url")
    copyText.select()
    document.execCommand("Copy")
    this.props.setFlash("Copied to clipboard")
  }

  handleRadioButtonChange(e) {
    this.setState({ status: e.target.value })
  }

  renderUserStatusCheckboxes() {
    return (
      <div>
        <RadioGroup
            style={{display: 'block'}}
            aria-label="status"
            name="status"
            value={this.state.status || null}
            onChange={this.handleRadioButtonChange.bind(this)}
          >
            <FormControlLabel value="active" control={<Radio />} label="Active" />
            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
        </RadioGroup>
      </div>
    )
  }

  renderGeneratedUrl() {
    if(this.state.generatedPasswordUrl && this.state.generatedPasswordUrl.length > 0) {
      return (
        <div>
          <input id="password-url" className="generated-password-url-input" readOnly value={this.state.generatedPasswordUrl} />
          <i className="material-icons copy-icon" onClick={this.copyToCliboard.bind(this)}>content_paste</i>
        </div>
      )
    }
  }

  renderGenerationCheckbox() {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              id="generate-url"
              checked={this.state.generatePasswordToken}
              onChange={this.handleCheckboxChange.bind(this, 'generatePasswordToken')}
            />
          }
          label="Generate Password Link"
        />
      </div>
    )
  }

  renderFranchiseUserCheckbox() {
    if (this.state.role === 'driver') {
      return (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="franchise-driver"
                checked={this.state.franchise_driver}
                onChange={this.handleCheckboxChange.bind(this, 'franchise_driver')}
              />
            }
            label="Franchise driver"
          />
        </div>
      )
    }
  }

  renderForm() {
    const { state, state: { wallmob_id, wallmob_name } } = this

    return (
      <form noValidate autoComplete="off" onSubmit={this.submit.bind(this)}>
        <FunTextField field="email" state={state} handleChange={this.handleChange.bind(this)} />
        <FunTextField className="fun-select" select field="role" state={state} handleChange={this.handleChange.bind(this)} >
          <MenuItem id="admin" key="admin" value="admin">Admin</MenuItem>
          <MenuItem id="depo-admin" key="depo-admin" value="depo_admin">Depo admin</MenuItem>
          <MenuItem id="support" key="support" value="support">Support</MenuItem>
          <MenuItem id="driver" key="driver" value="driver">Driver</MenuItem>
        </FunTextField>
        {this.renderDepoSelect()}
        {this.renderWallmobUserSelect(wallmob_id, wallmob_name)}
        {(tenant === 'premier-is' || tenant === 'hjem-is') && this.renderLakridsDriverSelect()}
        <FunTextField field="first_name" state={state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="last_name" state={state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="axapta_code" state={state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="password" state={state} handleChange={this.handleChange.bind(this)} type="password" />
        <FunTextField field="password_confirmation" state={state} handleChange={this.handleChange.bind(this)} type="password" />
        {this.renderGenerationCheckbox()}
        {this.renderGeneratedUrl()}
        {this.renderUserStatusCheckboxes()}
        {this.renderFranchiseUserCheckbox()}
        <SaveButton />
        <CancelButton to={window.Routes.admin_users_path(window.tenant, window.depoId)} />
      </form>
    )
  }

  renderLoading() {
    return (
      <div>
        <Box justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      </div>
    )
  }

  render() {
    return (
      <GridWrapper>
        {this.renderForm()}
      </GridWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    mainTitle: state.mainTitle,
    users: state.resources,
    depos: state.depos,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: mainTitle => dispatch(setMainTitle(mainTitle)),
    setFlash: flash => dispatch(setFlash(flash)),
    loadDepos: depos => dispatch(loadDepos(depos)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const UsersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersFormClass)

export default UsersForm
