import React from 'react'
import StopTypesComponent from './StopTypesComponent'
import TitleContainer from '../../shared/TitleContainer'

const AdminStopTypesIndex = () => {
  return (
    <TitleContainer>
      <StopTypesComponent />
    </TitleContainer>
  )
}

export default AdminStopTypesIndex
