import React from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { stopLoading, setMainTitle } from '../../../files/actions/index'
import { requestPerformer } from './helpers/userHttpRequestsHelper'

class UserHttpRequestShow extends React.Component {
  state = { userHttpRequest: {} }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    let userHttpRequest
    if (this.props.userHttpRequest) {
      userHttpRequest = this.props.userHttpRequest
    } else {
      userHttpRequest = await this.fetchUserHttpRequest()
    }

    this.setState({ userHttpRequest }, this.onDataLoad.bind(this))
  }

  onDataLoad() {
    const request   = this.state.userHttpRequest
    const performer = requestPerformer(request, { includeRole: true })

    this.props.setMainTitle(`${performer} @ ${request.performed_at}`)
    this.props.stopLoading()
  }

  async fetchUserHttpRequest() {
    const { data } = await axios.get(
      Routes.data_admin_user_http_requests_path(window.tenant, this.props.match.params.userHttpRequestId, { format: 'json' })
    )

    return data
  }

  renderAttribute(title, value) {
    return(
      <div className='padded-block'>
        <Typography variant='h5'>{title}</Typography>
        <Typography variant='subtitle2'>{value}</Typography>
      </div>
    )
  }

  render() {
    const { url, referer, format, tenant, controller, action, params, status, method } = this.state.userHttpRequest

    return(
      <div className='user-http-request-container flexbox'>
        <Paper className='paper-container attributes-container'>

          {this.renderAttribute('Request URL', url)}
          {referer && this.renderAttribute('Referer URL', referer)}

          <div className='flexbox'>
            <div style={{ flex: 1 }}>
              {this.renderAttribute('Controller', controller)}
              {this.renderAttribute('Action', action)}
              {this.renderAttribute('Request Format', format)}
            </div>

            <div style={{ flex: 0.75 }}>
              {this.renderAttribute('Tenant', tenant)}
              {this.renderAttribute('Status', status)}
              {this.renderAttribute('Method', method)}
            </div>
          </div>
        </Paper>

        <Paper className='paper-container'>
          <Typography variant='h5' className='padded-block'>
            Request Parameters
          </Typography>

          <pre className='request-parameters-block'>
            {JSON.stringify(params, undefined, 2)}
          </pre>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const userHttpRequestId = props.match.params.userHttpRequestId
  const userHttpRequest   = state.userHttpRequests.find(r => r.id == userHttpRequestId)

  return { userHttpRequest }
}

const mapDispatchToProps = dispatch => {
  return {
    stopLoading: () => dispatch(stopLoading()),
    setMainTitle: title => dispatch(setMainTitle(title))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHttpRequestShow)
