import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const FeedbackTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Route</TableCell>
        <TableCell>Driver name</TableCell>
        <TableCell>Planned arrival time</TableCell>
        <TableCell>Actual arrival time</TableCell>
        <TableCell>Stop duration</TableCell>
        <TableCell>Feedback</TableCell>
        <TableCell>Phone</TableCell>
        <TableCell>Comment</TableCell>
        <TableCell>Manager comment</TableCell>
        <TableCell>Handled by</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default FeedbackTableHead
