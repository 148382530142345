const floatToTimeConverter = (value) => {
  if (value.includes(":")) return value
  const seconds = String(Math.floor((value % 1) * 60)).toString()
  const minutes = Math.floor(value).toString()
  const stringMinutes = minutes.length === 1 ? `0${minutes}` : minutes
  const stringSeconds = seconds.length === 1 ? `0${seconds}` : seconds
  return `${stringMinutes}:${stringSeconds}`
}

export default floatToTimeConverter
