import React from 'react'
import Button from '@material-ui/core/Button'
import LoadingWithoutRedux from '../shared/LoadingWithoutRedux'
import printStyles from '../../files/printingAssets.js.erb'
const printableContainerId = "inventory-product-print-container"
import moment from 'moment'
import 'moment/locale/da'

class PrintHtml extends React.Component {
  state = { printingStarted: false, loading: false }

  print() {
    this.startLoading()
    this.setState({ printingStarted: true }, () => {
      printJS({
        printable: printableContainerId,
        type: 'html',
        documentTitle: this.props.title,
        scanStyles: false,
        css: printStyles,
        onLoadingEnd: this.stopLoading
      })
      this.setState( { printingStarted: false })
      this.props.updateOrdersAsPrinted()
    })
  }

  startLoading = () => this.setState({ loading: true })

  stopLoading = () => this.setState({ loading: false })

  renderHeader(header) {
    const { eshopId, deliveryDate, comment, orderComment } = header

    return (
      <div style={{ width: 1000 }} className="printable-header">
        <div>
          <div className="title">Ordre #{eshopId} ({deliveryDate})</div>
        </div>
        <div>{comment}</div>
        {orderComment && <div>{orderComment}</div>}
      </div>
    )
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th className="min-width-200">Product</th>
          <th className="min-width-85">Quantity</th>
          <th className="min-width-450">Price</th>
        </tr>
      </thead>
    )
  }

  renderTableBody(order, header) {
    if (!order.line_items) return

    const itemHtml = order.line_items.map((item, index) => {
      return (
        <tr key={index}>
          <td className="min-width-200">{item.product_description}</td>
          <td className="min-width-85">{item.quantity}</td>
          <td className="min-width-450">{item.price}</td>
        </tr>
      )
    })
    let paymentType
    if (header.payment_type) {
      paymentType = (
        <tr>
          <td colSpan="2"><b>Betalingsmetode: </b></td>
          <td>{header.paymentType}</td>
        </tr>
      )
    }
    itemHtml.push(
      <React.Fragment key={100}>
        <tr>
          <td><b>Subtotal: </b></td>
          <td>{order.total_quantity}</td>
          <td>{header.total}</td>
        </tr>
        {paymentType}
      </React.Fragment>
    )
    return itemHtml
  }

  renderPrintButton() {
    return (
      <Button style={{ marginRight: 10 }} variant="contained" color="primary" onClick={this.print.bind(this)}>Print</Button>
    )
  }

  renderFooter(header) {
    const {
      customerAddress, route, eshopId, deliveryDate, deliveryTime, paymentType,
      comment, total, customerNumber, customerName, deliverySurcharge, cashCustomer
    } = header
    moment.locale("da")
    let time = moment(deliveryTime, "DD-MM-YYYY HH:mm")
    if (!time.isValid()) {
      time = moment(`${deliveryDate} ${deliveryTime}`, "DD-MM-YYYY HH:mm")
    }
    time = time.format("LLLL")

    return (
      <div className="footer">
        <div style={{ width: 1000, marginTop: 5 }}><b>Levering: </b> {time}</div>
        <div style={{ width: 1000, marginTop: 5 }}><b>Rute navn: </b> {route}</div>
        {customerNumber && <div style={{ width: 1000, marginTop: 5 }}><b>Kunder: </b> {customerNumber} {customerName}</div>}
        {cashCustomer && <div style={{ width: 1000, marginTop: 5 }}><b>Cash customer: </b> {cashCustomer === true && <b>YES - IMPORTANT!</b>}</div>}
        {deliverySurcharge && <div style={{ width: 1000, marginTop: 5 }}><b>Delivery charge: </b> YES</div>}
        <div style={{ width: 1000 }}>
          <h3>Faktureringsadresse</h3>
          <b>{customerAddress}</b>
        </div>
      </div>
    )
  }

  renderPrintableContainer(order) {
    if(!this.state.printingStarted) return

    return (
      <div className="printable-container order-print" key={order.id}>
        {this.renderHeader(order.header)}
        <table>
          {this.renderTableHead(order.header)}
          <tbody>
            {this.renderTableBody(order, order.header)}
          </tbody>
        </table>
        {this.renderFooter(order.header)}
        <div>
          <span>Underskrift</span>
          <div className="line">
            <hr />
          </div>
        </div>
      </div>
    )
  }

  renderLoading() {
    return <LoadingWithoutRedux
      additionalStyles={{ top: 'calc(100vh * 0.5 - 60px)', left: '50%', marginLeft: 0 }}
      loading={this.state.loading} />
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPrintButton()}
        {this.renderLoading()}
        <div id={printableContainerId}>
          {this.props.orders.map(order => {
            return this.renderPrintableContainer(order)
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default PrintHtml
