import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import find from 'lodash/find'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loading from '../shared/Loading'
import IconButtonMenu from '../shared/IconButtonMenu'

import { stopLoading, startLoading, loadCarStops, loadRoadPoints, loadCarRoutes, loadPlanEvent } from '../../files/actions/index'

import PlanEvent from '../../models/PlanEvent'
import MainTitle from '../shared/MainTitle'

import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import pickBy from 'lodash/pickBy'
import currentUser from '../support/currentUser'

const titleStyle = {
  marginLeft: 50,
  marginTop: 10,
  marginBottom: 10,
}

class PlanEventsReportClass extends React.Component {
  state = {
    planEvent: new PlanEvent(),
    tabIndex: 0,
    mainTitle: '',
    roadPoints: []
  }

  componentDidMount() {
    this.props.startLoading()
    const planEvent = this.findPlanEventInStore()
    if (planEvent) {
      this.setPlanEventAndMainTitle(planEvent)
      this.props.stopLoading()
    } else if (this.props.match.params.planEventId) {
      axios.get(this.loadUrl()).then(res => {
        this.setPlanEventAndMainTitle(res.data)
      }).catch(error => {
        this.props.setFlash(`${error} (${this.loadUrl()})`)
      }).then(
        this.props.stopLoading()
      )
    } else {
      this.setMainTitle()
      this.props.stopLoading()
    }
    this.loadData()
  }

  loadData() {
    this.setMainTitle()
    this.props.startLoading()
    const planEventId = this.props.match.params.planEventId
    axios.all([
      axios.get(Routes.car_stops_with_route_points_plan_event_path(tenant, depoId, planEventId, {format: 'json'})),
      axios.get(Routes.car_routes_plan_event_path(tenant, depoId, planEventId, {format: 'json'}))
    ]).then(axios.spread((carStopsWithRoutePoints, carRoutes) => {
      this.props.loadRoutePoints(carStopsWithRoutePoints.data)
      this.props.loadCarRoutes(carRoutes.data)
      this.setState({roadPoints: carStopsWithRoutePoints.data})
      this.props.stopLoading()
    }))
  }

  findPlanEventInStore() {
    return find(this.props.planEvents, ({ id }) => {
      return id == this.props.match.params.planEventId
    })
  }

  setPlanEventAndMainTitle(planEvent) {
    this.setPlanEvent(planEvent)
    this.props.loadPlanEvent(planEvent)
  }

  setPlanEvent(planEvent) {
    const planEventToSet = {
      id: planEvent.id,
      user_id: planEvent.user_id || '',
      car_id: planEvent.car_id || '',
      route_id: planEvent.route_id || '',
      date: planEvent.date || '',
      route_name: planEvent.route_name || '',
      car_internal_number: planEvent.car_internal_number || '',
      converted_at: planEvent.converted_at || '',
      converted_by: planEvent.converted_by || '',
      converted_route_id: planEvent.converted_route_id
    }
    this.setState({ planEvent: planEventToSet }, () => this.setMainTitle())
  }

  setMainTitle() {
    const { date, route_name, car_internal_number } = this.state.planEvent
    this.setState({ mainTitle: [date, route_name, car_internal_number].join(' | ') })
  }

  loadUrl() {
    return Routes.plan_event_path(
      tenant, depoId, this.props.match.params.planEventId, {format: 'json'}
    )
  }

  onTabSwitch(_event, tabIndex) {
    const { roadPoints } = this.props
    let filteredPoints
    switch (tabIndex) {
      case 1:
        filteredPoints = roadPoints.filter(point => point.point_visited)
        break;
      case 2:
        filteredPoints = roadPoints.filter(point => point.point_visited !== undefined)
        break;
      case 3:
        filteredPoints = roadPoints.filter(point => point.b2b_customer_ids && point.b2b_customer_ids.length > 0)
        break;
      default:
        filteredPoints = roadPoints
    }
    this.setState({ tabIndex: tabIndex, roadPoints: filteredPoints })
  }

  renderPointStatus(routePoint) {
    let status
    let eshopCircle
    let b2bCircle
    if (routePoint.point_visited !== undefined) {
      status = routePoint.point_visited ? "visited-circle" : "skipped-circle"
    } else {
      status = "unplanned-circle"
    }
    if (routePoint.stop_type === "eshop") {
      eshopCircle = <span className="tag-label eshop-label">ESHOP</span>
    }
    if (routePoint.b2b_customer_ids && routePoint.b2b_customer_ids.length > 0) {
      b2bCircle = <span className="tag-label b2b-label">B2B</span>
    }

    return (
      <div className="circle-label">
        <div className={`circle ${status}`} />
        {eshopCircle}
        {b2bCircle}
        <label>{routePoint.total_revenue} kr</label>
      </div>
    )
  }

  renderRoutePointTimes(routePoint) {
    let actualArrivalTime, actualStopDuration, expectedArrivalTime
    if (routePoint.point_visited !== undefined) {
      actualArrivalTime = routePoint.actual_arrival_time ? routePoint.actual_arrival_time : "--:--"
      actualStopDuration = routePoint.duration ? routePoint.duration : "0:00"
      expectedArrivalTime = routePoint.original_google_estimate_time
    } else {
      actualArrivalTime = routePoint.start_at
      actualStopDuration = routePoint.duration
      expectedArrivalTime = routePoint.start_at
    }
    return (
      `Exp. ETA: ${expectedArrivalTime} / Act. ETA: ${actualArrivalTime} / Stop duration ${actualStopDuration}`
    )
  }

  // Probably there is much beautifuler way to do this
  // TODO: Try to gather markers in hash, and call popup from that hash
  openPopup(routePoint) {
    const { map } = this.props
    const layers = map.leafletElement._map._layers
    const point = routePoint.stop_location ? routePoint.stop_location : routePoint.point
    const marker = pickBy(layers, (value, key) => {
      const latlng = value._latlng
      return value._latlng && latlng.lat === point.coordinates[1] && latlng.lng === point.coordinates[0]
    })
    if (marker) {
      const keys = Object.keys(marker)
      marker[keys[0]].openPopup()
      map.leafletElement._map.setView({ lat: point.coordinates[1], lon: point.coordinates[0]}, 25)
    }
  }

  convertPlanEventToRoute(options) {
    this.props.startLoading()
    axios.post(
      Routes.convert_to_route_plan_event_path(tenant, depoId, this.state.planEvent.id, { format: 'json' }),
      { options }
    ).then(res => {
      this.setState({ planEvent: res.data })
      this.props.stopLoading()
      this.props.setFlash('Route successfully converted!')
    }).catch(err => {
      this.props.setFlash(`Route has already been created or another one exists with name ${this.state.mainTitle}`)
      this.props.stopLoading()
    })
  }

  renderPointList() {
    const { roadPoints } = this.state
    return (
      roadPoints.map((routePoint, index) => {
        return (
          <ListItem button className="list-item" divider key={index} onClick={this.openPopup.bind(this, routePoint)}>
            <ListItemText>
              {`${index + 1}. ${routePoint.address}`}
            </ListItemText>
            <div className="secondary-list-item">
              {this.renderPointStatus(routePoint)}
            </div>
            <div className="third-list-item">
              {this.renderRoutePointTimes(routePoint)}
            </div>
          </ListItem>
        )
      })
    )
  }

  renderLegend() {
    return (
      <Paper className="plan-event-footer">
        <div className="circle-label">
          <div className='circle visited-circle' />
          <label>Visited stops</label>
        </div>
        <div className="circle-label">
          <div className='circle skipped-circle' />
          <label>Skipped stops</label>
        </div>
        <div className="circle-label">
          <div className='circle unplanned-circle' />
          <label>Unplanned stops</label>
        </div>
      </Paper>
    )
  }

  availableRoleActions() {
    if (currentUser().role == "admin") {
      return [
        { text: 'Heatmap', value: 'heat_map' },
        { text: 'Convert to route', value: 'convert_to_route' },
        { text: 'Convert to route with unvisited stops', value: 'convert_to_route_with_unvisited_stops' },
        { text: 'Stop SMS', value: 'stop_sms' }
      ]
    } else {
      return [
        { text: 'Heatmap', value: 'heat_map' },
        { text: 'Stop SMS', value: 'stop_sms' }
      ]
    }
  }

  renderMenuButtons() {
    const options = this.availableRoleActions()

    if (options.length > 0) {
      return (
        <span style={{ marginLeft: 'auto' }}>
          <IconButtonMenu options={options} handleSelect={this.handleMenuItemSelect.bind(this)}/>
        </span>
      )
    }
  }

  handleMenuItemSelect(e, target) {
    switch(target) {
      case 'heat_map':
        this.handleHeatmapRender()
        break
      case 'convert_to_route':
        this.convertPlanEventToRoute()
        break
      case 'convert_to_route_with_unvisited_stops':
        this.convertPlanEventToRoute('with_unvisited_stops')
        break
      case 'stop_sms':
        window.location = Routes.sms_customers_plan_event_path(tenant, depoId, this.state.planEvent.id)
        break
    }
  }

  handleHeatmapRender() {
    this.props.history.push(
      Routes.heat_map_plan_event_path(tenant, depoId, this.props.match.params.planEventId)
    )
  }

  renderConvertedByHeading() {
    if (!this.state.planEvent.converted_route_id) return

    return (
      <div className="mb-1 mr-5 ml-4 text-center">
        <span className='mdl-chip w-100' >
          <span className="mdl-chip__text">
            <a href={Routes.route_points_path(tenant, depoId, this.state.planEvent.converted_route_id)}>
              Converted route
            </a>
            &nbsp;({this.state.planEvent.converted_by}, {this.state.planEvent.converted_at})
          </span>
        </span>
      </div>
    )
  }

  goBack = () => this.props.history.goBack()

  render () {
    const { roadPoints } = this.props
    const { planEvent } = this.state
    const tabIndex = this.state.tabIndex
    const allPointsCount = roadPoints.length
    const visitedPointsCount = roadPoints.filter(point => point.point_visited).length
    const unplannedPointsCount = roadPoints.filter(point => point.point_visited !== undefined).length
    const b2bPointsCount = roadPoints.filter(point => point.b2b_customer_ids && point.b2b_customer_ids.length > 0).length
    const planEventId = this.props.match.params.planEventId

    return (
      <div className="report-container">
        <Loading />
        <Paper className="plan-event-header sticky">
          <div className="plan-event-title-container">
            <IconButton onClick={this.goBack.bind(this)}>
              <i className="material-icons">
                chevron_left
              </i>
            </IconButton>
            <span className="mdl-layout-title">
              {this.state.mainTitle}
            </span>
            {this.renderMenuButtons()}
          </div>
          {this.renderConvertedByHeading()}
          <Divider />
          <Tabs value={this.state.tabIndex} onChange={this.onTabSwitch.bind(this)} classes={{ root: 'sidebar-tabs' }} fullWidth>
            <Tab label={`All (${allPointsCount})`} />
            <Tab label={`Visited (${visitedPointsCount})`} />
            <Tab label={`Planned (${unplannedPointsCount})`} />
            <Tab label={`B2B (${b2bPointsCount})`} />
          </Tabs>
        </Paper>

        <List className="route-points-list">
          {this.renderPointList()}
        </List>
        {this.renderLegend()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    carStops: state.carStops,
    roadPoints: state.roadPoints
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    loadRoutePoints: routePoints => dispatch(loadRoadPoints(routePoints)),
    loadCarRoutes: carRoutes => dispatch(loadCarRoutes(carRoutes)),
    loadPlanEvent: planEvent => dispatch(loadPlanEvent(planEvent))
  }
}

const PlanEventsReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEventsReportClass)

export default PlanEventsReport
