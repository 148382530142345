import React from 'react'
import Close from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import ChevronRight from '@material-ui/icons/ChevronRight'
import logGoogleEvent from './logGoogleEvent'

export default class TrackingMenu extends React.Component {
  openUnsubscribe = () => {
    logGoogleEvent('trackingUnsubscribeClicked')
    this.props.closeMenu()
    this.props.openUnsubscribe()
  }

  closeMenu = () => {
    logGoogleEvent('trackingMenuClosed')
    this.props.closeMenu()
  }
  navigate = (page, googleEvent) => {
    logGoogleEvent(googleEvent)
    window.location = page
  }

  renderUnsubscribeItem() {
    const {
      translations: { unsubscribe },
      customerUuid
    } = this.props

    if (customerUuid) {
      return (
        <div className='menu-list-item' onClick={this.openUnsubscribe}>
          {unsubscribe}
          <ChevronRight className='arrow-icon' />
        </div>
      )
    }
  }

  render() {
    const {
      translations: { change_stop, shop_homepage, public_homepage, shop }
    } = this.props

    return (
      <div className='menu-container'>
        <div className='menu-list'>
          <div className='menu-list-header'>
            <Close onClick={this.closeMenu} />
          </div>
          <div className='menu-list-item' onClick={() => this.navigate(public_homepage, 'trackingChangeStopClicked')}>
            {change_stop}
            <Divider />
            <ChevronRight className='arrow-icon' />
          </div>
          <div className='menu-list-item' onClick={() => this.navigate(shop_homepage, 'trackingProductLinkClicked')}>
            {shop}
            <Divider />
            <ChevronRight className='arrow-icon' />
          </div>
          {this.renderUnsubscribeItem()}
        </div>
      </div>
    )
  }
}
