import React from 'react'
import { connect } from 'react-redux'

import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

import { setFlash } from '../../files/actions/index'

class FlashClass extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message: this.props.flash
    }
  }

  handleClose() {
    this.setState({ message: this.props.flash })
    this.props.setFlash(null)
  }

  handleExit() {
    this.setState({ message: null })
  }

  render() {
    return (
      <Snackbar
        open={!!this.props.flash}
        onClose={this.handleClose.bind(this)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={this.state.message || this.props.flash}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    flash: state.flash
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFlash: flash => dispatch(setFlash(flash))
  }
}

const Flash = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashClass)

export default Flash
