const User = () => {
  return {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    password: '',
    password_confirmation: '',
    axapta_code: '',
    errors: {},
    depo_id: '',
    franchise_driver: false,
    depo_ids: [],
    lakrids_driver_id: '',
    lakrids_driver_name: ''
  }
}

export default User
