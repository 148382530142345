import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import FeedbackTable from './FeedbackTable'

const FeedbackComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/:tenant/depo/:depoId/feedbacks' component={FeedbackTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default FeedbackComponent
