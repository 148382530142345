import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Map, Marker, ZoomControl, LayersControl, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import axios from 'axios'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Modal from '../../shared/Modal'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import locationMarker from '../icons/location_marker.png'
import locationMarkerCircle from '../icons/location_marker_circle.svg'
import closeIcon from '../icons/cross.png'
import backArrow from '../icons/back_arrow.png'
import icons from '../helpers/icons'
import isMobile from '../helpers/isMobile'
import closeCalendarOnClick from '../helpers/closeCalendarOnClick'
import 'react-day-picker/lib/style.css'
import WEBSITE_LINKS from '../helpers/websiteLinks'
import REGIONS from '../helpers/regions'
import setGA from '../helpers/setGA'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'

const calculateMapHeight = () => {
  let mapHeight = window.innerHeight
  mapHeight -= isMobile() ? 115 : 70
  return mapHeight
}

class SupportPlanEventRoutePointsMap extends React.Component {
  state = {
    planEventRoutePoints: [],
    selectedDay: null,
    searchPhrase: '',
    region: REGIONS[window.tenant],
    modalOpen: false,
    helpTextVisible: true,
    dayFilter: 'all',
    mapHeight: calculateMapHeight(),
    nextPlanEvents: []
  }

  SEARCH_LOCATION_ZINDEX_OFFSET = 400
  ROUTE_POINT_ZINDEX_OFFSET     = 200

  TruckIcon = icons[window.tenant].truckIcon

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ mapHeight: calculateMapHeight() })
    })
    closeCalendarOnClick.bind(this)()
    setGA()
  }

  logHotjar(path) {
    window.hj && window.hj('vpv', path)
  }

  onSearchPhraseChange(searchPhrase) {
    this.setState({ searchPhrase })
  }

  toggleModal(selectedPlanEventRoutePoint) {
    const modalOpen = !!selectedPlanEventRoutePoint
    if (!this.state.subscribed && modalOpen) {
      this.logHotjar('/click-on-stop-to-open-subscribe-form')
    }
    this.setState({ modalOpen, selectedPlanEventRoutePoint, subscribed: false, nextPlanEvents: [], showPlanEvents: false })
  }

  toggleDatePicker() {
    this.setState({ datePickerVisible: !this.state.datePickerVisible })
  }

  onDayClick(dateString) {
    const date = moment(dateString).toDate()
    this.setState({ selectedDay: date, datePickerVisible: false }, () => {
      this.fetchNearbyRoutePoints()
    })
  }

  onDayFilterChange(value) {
    let fetchNearbyRoutePoints = true

    if (this.props.support) {
      if (value == 'today') {
        this.toggleDatePicker()
        fetchNearbyRoutePoints = false
      } else {
        this.setState({ selectedDay: null })
      }
    }

    this.setState({ dayFilter: value }, () => {
      if (fetchNearbyRoutePoints) this.fetchNearbyRoutePoints()
    })
  }

  onSearchInputFocusToggle(isFocused) {
    this.setState({ searching: isFocused })
  }

  async onAddressChange(address) {
    if (!address) return
    const results     = await geocodeByAddress(address)
    const coordinates = await getLatLng(results[0])
    this.setState({ searching: false, searchPhrase: address, coordinates }, () => {
      this.logHotjar('/type-in-address-search')
      this.fetchNearbyRoutePoints()
      $('input').blur()
    })
  }

  async fetchNearbyRoutePoints() {
    const { dayFilter, selectedDay, coordinates } = this.state
    if (!coordinates) return
    const todaysEventsOnly = dayFilter === 'today' ? true : null
    const params = { coordinates, today: todaysEventsOnly, specific_day: selectedDay, format: 'json' }
    const path = this.props.support ? Routes.customer_support_plan_event_route_points_path : Routes.public_plan_event_route_points_path
    const { data } = await axios.get(path(window.tenant, params))
    this.setState({ planEventRoutePoints: data })
  }

  formattedDate(date) {
    return date.isSame(moment(), 'day') ? 'I dag' : date.format('D. MMM').toUpperCase()
  }

  fetchAndShowNextPlanEvents(routePoint) {
    axios.get(
      Routes.next_events_plan_events_path(
        window.tenant, routePoint.plan_event.depo_id, { format: 'json', coordinates: routePoint.coordinates }
      )
    ).then(res => {
      if (res.data.length > 0) {
        this.setState({ nextPlanEvents: res.data, showPlanEvents: true })
      }
    })
  }

  renderClosestRoutePoints() {
    return this.state.planEventRoutePoints.map(planEventRoutePoint => {
      const { id, coordinates, original_google_estimate_time, arrival_date } = planEventRoutePoint
      const date = moment(original_google_estimate_time || arrival_date)

      let time
      if (original_google_estimate_time) time = date.format('HH:mm')

      const icon = L.divIcon({
        className: '',
        html: ReactDOMServer.renderToString(<this.TruckIcon day={this.formattedDate(date)} time={time} color='#37ADE9' size={1.2} />),
        iconAnchor: window.tenant === 'hjem-is' ? [34, 70] : [38, 60]
      })

      return (
        <Marker
          position={coordinates}
          icon={icon}
          key={id}
          onClick={this.toggleModal.bind(this, planEventRoutePoint)}
          zIndexOffset={this.ROUTE_POINT_ZINDEX_OFFSET}
        />
      )
    })
  }

  renderSearchLocation() {
    if (!this.state.coordinates) return

    return (
      <Marker
        position={this.state.coordinates}
        icon={
          L.divIcon({
            className: '',
            html: ReactDOMServer.renderToString(<img className='location-with-animated-pulse' src={locationMarkerCircle} />),
            iconAnchor: [10,10]
          })
        }
        zIndexOffset={this.SEARCH_LOCATION_ZINDEX_OFFSET}
      />
    )
  }

  planEventPath(planEvent) {
    return Routes.plan_event_path(window.tenant, planEvent.depo_id, planEvent)
  }

  renderPlanEventLink(planEvent) {
    return (
      <div className='modal-buttons-container'>
        <a href={this.planEventPath(planEvent)} target='_blank'>Go to route</a>
      </div>
    )
  }

  renderInfoAboutPoint(routePoint) {
    const {
      google_estimate_time, arrival_date, original_google_estimate_time,
      plan_event, depo_name, route_name, driver_name, phone, internal_number, address
    } = routePoint
    const { showPlanEvents } = this.state
    const date = moment(google_estimate_time || original_google_estimate_time || arrival_date)
    let time

    const wideClass = showPlanEvents ? 'super-wide' : ''
    const gridWidth = showPlanEvents ? 6 : 12

    if (google_estimate_time || original_google_estimate_time) {
      time = date.format('HH:mm')
    }

    return (
      <div className={`modal-content ${wideClass}`}>
        <Grid container>
          <Grid item xs={12} sm={12} md={gridWidth} lg={gridWidth}>
            <div className='car-icon-wrapper'>
              <div className='car-icon'>
                <this.TruckIcon color='#37ADE9' size={1.4} day={this.formattedDate(date)} time={time} modal={true} />
              </div>
            </div>
            <div className='modal-title'>
              <p>{date.locale('en-gb').format('LLLL')}</p>
            </div>
            <div className='info-container'>
              <p>Address: {address}</p>
              <p>Depo: {depo_name}</p>
              <p>Route: {route_name}</p>
              <p>Driver: {driver_name}</p>
              <p>Phone number: {phone || ''}</p>
              <p>License number: {internal_number}</p>
            </div>
            {this.renderPlanEventLink(plan_event)}
            <Button onClick={this.fetchAndShowNextPlanEvents.bind(this, routePoint)} variant="contained" color='primary'>
              Show Next Plan Events
            </Button>
          </Grid>
          {this.renderNextPlanEventsForPoint()}
        </Grid>
      </div>
    )
  }

  renderNextPlanEventsForPoint() {
    const { showPlanEvents, nextPlanEvents } = this.state

    if (showPlanEvents) {
      return (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <List>
            {nextPlanEvents.map(planEvent => {
              return (
                <a key={planEvent.id} href={this.planEventPath(planEvent)} target="_blank">
                  <ListItem button className='route-name-heading'>
                    <ListItemText secondary={planEvent.route_name} primary={planEvent.date} />
                  </ListItem>
                </a>
              )
            })}
          </List>
        </Grid>
      )
    }
  }

  renderModal() {
    const { modalOpen, selectedPlanEventRoutePoint } = this.state

    if (this.props.support && modalOpen) {
      return (
        <Modal open={true} close={this.toggleModal.bind(this, null)} BackdropProps={{ classes: { root: 'modal-backdrop' } }}>
          {this.renderInfoAboutPoint(selectedPlanEventRoutePoint)}
        </Modal>
      )
    }
  }

  renderSearchBar() {
   const { translations } = this.props
    const inputProps = {
      onChange: this.onSearchPhraseChange.bind(this),
      value: this.state.searchPhrase,
      placeholder: translations.search_for_an_address,
      spellCheck: false,
      autoFocus: true,
      onFocus: this.onSearchInputFocusToggle.bind(this, true),
      onBlur: this.onSearchInputFocusToggle.bind(this, false)
    }

    let containerClasses = 'location-search-container'
    //if (this.state.searching) containerClasses += ' searching'

    const classNames = {
      root: '',
      input: 'location-search-input',
      autocompleteContainer: 'location-search-suggestions-container',
      autocompleteItem: 'location-search-autocomplete-item',
      autocompleteItemActive: 'location-search-autocomplete-item-active'
    }

    return(
      <div className={containerClasses}>
        <PlacesAutocomplete onSelect={this.onAddressChange.bind(this)}
                            onEnterKeyDown={this.onAddressChange.bind(this)}
                            shouldFetchSuggestions={({ value }) => value.length > 3}
                            inputProps={inputProps}
                            classNames={classNames} />
        <img src={locationMarker} className='location-search-input-icon marker-icon' />
        <img src={closeIcon} className='location-search-input-icon clear-icon' onClick={this.onSearchPhraseChange.bind(this, '')} />
        <img src={backArrow} className='location-search-input-icon back-icon' />
      </div>
    )
  }

  renderFilterSwitch() {
    const { translations } = this.props
    const text = this.props.support ? translations.calendar : translations.today
    return(
      <div className='day-filter-switch'>
        <div className={`day-filter-button left ${this.state.dayFilter === 'all' && 'active'}`} onClick={this.onDayFilterChange.bind(this, 'all')}>
          {translations.all_stops}
        </div>
        <div className={`day-filter-button right ${this.state.dayFilter === 'today' && 'active'}`} onClick={this.onDayFilterChange.bind(this, 'today')}>
          {text}
        </div>
        {this.renderDatePicker()}
      </div>
    )
  }

  renderBackButton(){
    if (!this.props.support) return
    return(
      <a href={Routes.admin_customers_path(window.tenant)} className='mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored back-button'>
        <i className="material-icons">keyboard_backspace</i>
      </a>
    )
  }

  renderDatePicker() {
    let { datePickerVisible, selectedDay } = this.state
    if (!datePickerVisible || !this.props.support) return

    return(
      <div className='date-picker-container'>
        <div className='date-picker'>
          <DayPicker
            onDayClick={this.onDayClick.bind(this)}
            selectedDays={[selectedDay]}
            firstDayOfWeek={1} />
        </div>
      </div>
    )
  }

  renderNavBar() {
    return (
      <nav className='nav-bar'>
        {this.renderBackButton()}
        <a href={WEBSITE_LINKS[window.tenant].url}>
          <img src={icons[window.tenant].logo} className='logo' />
        </a>
      </nav>
    )
  }

  calculateBounds() {
    const { planEventRoutePoints, coordinates } = this.state
    let bounds
    if (planEventRoutePoints.length > 0) {
      const padding               = isMobile() ? 0.4 : 0.2
      const additionalCoordinates = planEventRoutePoints.map(rp => rp.coordinates)
      if (coordinates) additionalCoordinates.push(coordinates)
      bounds = L.latLngBounds(additionalCoordinates)
      bounds = bounds.pad(padding)
    }
    return bounds
  }

  renderMapContainer() {
    const { region, mapHeight } = this.state
    const bounds = this.calculateBounds()

    return (
      <div className='map-container' style={{ height: mapHeight }}>
        <Map style={{ height: mapHeight }} center={region.coordinates} zoom={region.zoom} bounds={bounds} zoomControl={false}>
          <TileLayer
            url={"https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"}
            subdomains={['mt0','mt1','mt2','mt3']}
          />
          {this.renderClosestRoutePoints()}
          {this.renderSearchLocation()}
          {!isMobile() && <ZoomControl position='bottomright' />}
        </Map>
        {this.renderSearchBar()}
      </div>
    )
  }

  render() {
    return (
      <div className='support main-container'>
        {this.renderNavBar()}
        {this.renderMapContainer()}
        {this.renderFilterSwitch()}
        {this.renderModal()}
      </div>
    )
  }
}

export default SupportPlanEventRoutePointsMap
