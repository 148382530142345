import React from 'react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import Button from '@material-ui/core/Button'
import Modal from '../shared/Modal'
import axios from 'axios'

import { destroyPlanEvents, setFlash } from '../../files/actions/index'

const styles = {
  datePicker: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid lightgray',
    padding: 5,
    boxSizing: 'border-box'
  },
  modal: {
    height: 160
  },
  modalContent: {
    display: 'flex'
  },
  dateSpacer: {
    width: 20,
    fontSize: 18,
    textAlign: 'center'
  },
  button: {
    marginTop: 20
  }
}

class PlanEventDestroyModal extends React.Component {
  state = {}

  onDateChange(field, date) {
    this.setState({ [field]: moment(date) })
  }

  async destroyEvents() {
    const { startDate, endDate } = this.state
    const { close, dispatch } = this.props
    const params = { start_date: startDate, end_date: endDate, car_id: this.props.car.id }

    const { data } = await axios.delete(Routes.destroy_multiple_plan_events_path(window.tenant, window.depoId, { format: 'json' }), { params }).catch(err => {
      dispatch(setFlash(err.response.data.error))
      close()
    })

    this.handlePlanEventDeletion(data, startDate, endDate)
  }

  handlePlanEventDeletion(planEvents, startDate, endDate) {
    const { car, close, dispatch } = this.props

    close()

    if (planEvents) {
      dispatch(destroyPlanEvents(planEvents))
      dispatch(setFlash(flashText(planEvents.length, car.internal_number, formatDate(startDate), formatDate(endDate))))

      this.setState({ startDate: '', endDate: '' })
    }
  }

  renderDayPickerInput(fieldName) {
    const date = this.state[fieldName] && this.state[fieldName].toDate()

    return(
      <DayPickerInput formatDate={date => formatDate(moment(date))}
                      placeholder='DD-MM-YYYY'
                      onDayChange={this.onDateChange.bind(this, fieldName)}
                      value={date}
                      dayPickerProps={{ firstDayOfWeek: 1, selectedDays: [date] }}
                      inputProps={{ style: styles.datePicker }}
                      classNames={{ container: 'modal-datepicker-input', overlay: 'modal-datepicker-overlay' }} />
    )
  }

  render() {
    const { open, close, car } = this.props
    const title = `Destroy ${car.internal_number} planned events`

    return(
      <Modal open={open} close={close} title={title} style={styles.modal}>
        <div style={styles.modalContent}>
          {this.renderDayPickerInput('startDate')}
          <div style={styles.dateSpacer}>-</div>
          {this.renderDayPickerInput('endDate')}
        </div>

        <div>
          <Button color='secondary'
                  variant="contained"
                  size='small'
                  style={styles.button}
                  fullWidth
                  onClick={this.destroyEvents.bind(this)}>
            Destroy events
          </Button>
        </div>
      </Modal>
    )
  }
}

const formatDate = (date) => {
  return date.format('DD-MM-YYYY')
}

const flashText = (eventsCount, carNumber, startDate, endDate) => {
  return `All plan events (${eventsCount}) for car ${carNumber} within interval ${startDate} - ${endDate} have been deleted`
}

export default PlanEventDestroyModal
