import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Map, TileLayer, Marker, Popup, FeatureGroup, WMSTileLayer, GeoJSON, Polyline, ZoomControl, CircleMarker } from 'react-leaflet'
import L from 'leaflet'
import { EditControl } from 'react-leaflet-draw'
import axios from 'axios'
import { connect } from 'react-redux'
import Flash from '../shared/Flash'
import TooltipModal from '../shared/TooltipModal'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import { startLoading, stopLoading, createRoutePointFromMap, updatePoint, loadCustomers } from '../../files/actions/index'
import pointIcon from './icons/pointIcon'
import 'leaflet-textpath'
import { GOOGLE_STREET_LAYER, GOOGLE_SATELITE_LAYER } from '../shared/Layers'
import RoutePoint from '../../models/RoutePoint'
import each from 'lodash/each'
import PrintMapWithGrid from './PrintMapWithGrid'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import Button from '@material-ui/core/Button'
import MoveCustomersToPointModal from './MoveCustomersToPointModal'
import maleIcon from '../../files/images/maleIcon'
import ConfirmationDialog from '../shared/ConfirmationDialog'
import SimilarRoutesSelect from './SimilarRoutesSelect'
import Modal from '../shared/Modal'
import REGIONS from '../public/helpers/regions'

const styles = {
  editLocationButton: {
    position: 'absolute',
    zIndex: 999,
    marginLeft: 13,
    top: 100,
    padding: 10
  },
  toggleMapMarkerButton: {
    position: 'absolute',
    zIndex: 999,
    marginLeft: 13,
    top: 150,
    padding: 10
  }
}

class ReactLeafletMapClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editableFG: null,
      mapType: GOOGLE_STREET_LAYER,
      layerChange: false,
      editingMode: false,
      zoomedPoint: {},
      printPreview: null,
      mapPrinting: null,
      draggable: false,
      toggleTooltip: false,
      routeMinimumSalesEfficientCount: null,
      pointToMove: {},
      modalOpen: false,
      customerView: false,
      dialogOpen: false,
      currentPoint: {},
      marker: {},
      point: {},
      customers: [],
      phoneModalOpen: false,
      useNewMapMarkers: false,
    }
    this.onCreated = this.onCreated.bind(this)
    this._onFeatureGroupReady = this._onFeatureGroupReady.bind(this)
  }

  componentDidMount() {
    axios.get(Routes.admin_depo_path(window.tenant, window.depoId, { format: 'json' })).then(res => {
      const depoCoordinates = res.data.address_coordinates
      if(depoCoordinates.length > 0) {
        this.setState({ lat: parseFloat(depoCoordinates[0]), lng: parseFloat(depoCoordinates[1]), zoom: 10 })
      }
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { printPreview , mapPrinting, routeMinimumSalesEfficientCount } = props
    this.setState({ printPreview, mapPrinting, routeMinimumSalesEfficientCount }, () => this.renderGridPrinter())

    if (this.state.editableFG && props.zoomedPoint && props.zoomedPoint !== this.state.zoomedPoint) {
      this.setState({ zoomedPoint: props.zoomedPoint }, () => {
        this.state.editableFG.leafletElement._map.setView({...this.state.zoomedPoint}, 25)
      })
    } else if (this.props.roadPoints.length === props.roadPoints.length && !props.zoomedPoint && !this.state.draggable) {
      this.fitBounds(this.props.roadPoints)
    }
  }

  routeChanged(oldRoadPoints, newRoadPoints) {
    const oldRoadPointsRouteId = [ ...new Set(oldRoadPoints.map(p => p.route_id)) ]
    const newRoadPointsRouteId = [ ...new Set(newRoadPoints.map(a => a.route_id)) ]
    return oldRoadPointsRouteId[0] !== newRoadPointsRouteId[0]
  }

  coordinatesChanged(oldRoadPoints, newRoadPoints) {
    const oldPropsCoordinates = map(oldRoadPoints, e => JSON.stringify(e.point)).toString()
    const newPropsCoordinates = map(newRoadPoints, e => JSON.stringify(e.point)).toString()
    return oldPropsCoordinates !== newPropsCoordinates
  }

  onCreated(e) {
    const latLng = e.layer._latlng
    const point = e.layer.toGeoJSON().geometry
    // Remove created blank marker
    e.target.removeLayer(e.layer)
    const newRoutePoint = new RoutePoint()
    newRoutePoint.point = point
    if (isIsbilen){
      this.isbillencoordinatesToAddress(point, latLng, this.createPointCallback.bind(this), newRoutePoint)
    } else {
      this.coordinatesToAddress(point, latLng, this.createPointCallback.bind(this), newRoutePoint)
    }
  }

  updatePointFromMap(geoJsonPoint, marker, e) {
    const routePoints = this.props.roadPoints
    const clonedPoints = cloneDeep(routePoints)
    const id = geoJsonPoint.pointId
    const _objectId = geoJsonPoint._objectId
    const currentPoint = clonedPoints.filter(point => point.id ? point.id === id : point._objectId === _objectId)
    const originalPoint = cloneDeep(currentPoint[0])

    const position = marker.getLatLng()
    const point = { type: "Point", coordinates: [position.lng, position.lat] }
    if (currentPoint[0].use_custom_address) {
      this.toggleDialog()
      this.setState({ currentPoint: currentPoint[0], marker, point })
    } else {
      currentPoint[0].point = point
      marker.setLatLng(position, { draggable: 'true' })

      if (isIsbilen){
        this.isbillencoordinatesToAddress(point, position, this.updatePointCallback.bind(this), currentPoint[0], originalPoint)
      } else {
        this.coordinatesToAddress(point, position, this.updatePointCallback.bind(this), currentPoint[0], originalPoint)
      }
    }
  }

  cancelMove() {
    const { marker } = this.state
    const { coordinates } = this.state.currentPoint.point
    const position = { lat: coordinates[1], lng: coordinates[0] }
    marker.setLatLng(position, { draggable: 'true' })
    this.toggleDialog()
  }

  toggleDialog() {
    this.setState({ marker: {}, currentPoint: {}, dialogOpen: !this.state.dialogOpen })
  }

  closeDialogAndMove() {
    const { marker, currentPoint, point } = this.state
    const position = { lat: point.coordinates[1], lng: point.coordinates[0] }
    currentPoint.point = point
    marker.setLatLng(position, { draggable: 'true' })
    if (isIsbilen){
      this.isbillencoordinatesToAddress(point, position, this.updatePointCallback.bind(this), currentPoint)
    } else {
      this.coordinatesToAddress(point, position, this.updatePointCallback.bind(this), currentPoint)
    }
    this.toggleDialog()
  }

  createPointCallback(address, routePoint) {
    routePoint.address = address
    this.props.createRoutePointFromMap(routePoint)
  }

  updatePointCallback(address, routePoint) {
    const { addressChanged } = routePoint
    routePoint.address = address
    routePoint.addressChanged = addressChanged === undefined ? 0 : addressChanged + 1
    this.props.updatePoint(routePoint)
  }

  isbillencoordinatesToAddress(point, latLng, callback, routePoint = null, originaRoutePoint = null) {
    axios.get(
      Routes.api_isbilen_coordinates_path(window.tenant,
        {
          X: latLng.lat,
          Y: latLng.lng
        }
      )
    ).then(res => {
      if (res.status == 200){
        const address = res.data
        if (address != null) {
          callback(address, routePoint)
        } else {
          alert('No results found')
        }
      } else {
        if (originaRoutePoint) {
          callback(originaRoutePoint.address, originaRoutePoint)
        }
        alert('1881 call failed with code ' + res.status)
      }
    })
  }

  coordinatesToAddress(point, latLng, callback, routePoint = null, originaRoutePoint = null) {
    axios.get(
      Routes.api_hjemis_coordinates_path(window.tenant,
        {
          latitude: latLng.lat,
          longitude: latLng.lng
        }
      )
    ).then(res => {
      if (res.status == 200){
        const address = res.data
        if (address != null) {
          callback(address, routePoint)
        } else {
          alert('No results found')
        }
      } else {
        if (originaRoutePoint) {
          callback(originaRoutePoint.address, originaRoutePoint)
        }
        alert('mapbox call failed with code ' + res.status)
      }
    })
  }

  extractPoints(roadPoints) {
    return roadPoints.filter(({ point }) => point).map(roadPoint => roadPoint.point)
  }

  fitBounds(points) {
    let leafletFG = this.state.editableFG
    leafletFG = leafletFG == null ? reactFGref.leafletElement : leafletFG.leafletElement
    // Need to switch coordinates around cause, for bounds lat is first and lng is second
    const routePoints = this.extractPoints(points)
    if (routePoints.length > 0) {
      const coordinatesArray = routePoints.map(point => [point.coordinates[1], point.coordinates[0]])
      const bounds = new L.LatLngBounds(coordinatesArray)
      leafletFG._map.fitBounds(bounds)
    }
  }

  _onFeatureGroupReady(reactFGref) {
    let leafletFG = this.state.editableFG
    leafletFG = leafletFG == null ? reactFGref.leafletElement : leafletFG.leafletElement

    if (!leafletFG._map) return

    if(this.props.roadPoints.length == 0) {
      leafletFG.clearLayers()
    }

    this.state.editableFG = reactFGref
  }

  pointData(point, index) {
    const geoJson = point.point
    geoJson.properties = index + 1
    geoJson.address = point.address
    geoJson.pointId = point.id
    geoJson._objectId = point._objectId
    geoJson.color = point.classAndColor ? point.classAndColor.color : 'cadetblue'
    geoJson.opacity = this.state.customerView ? 0.3 : 1
    geoJson.homePoint =  point.home_customers.length > 0
    return geoJson
  }

  mapIcon(number, color) {
    return new L.AwesomeNumberMarkers({
      number: number,
      markerColor: color,
    })
  }

  homeIcon(number) {
    return (
      L.divIcon({
        className: '',
        html: ReactDOMServer.renderToString(
          <div className='location-marker-wrapper'>
            {pointIcon(number)}
          </div>
        ),
        iconAnchor: [24, 46]
      })
    )
  }

  geoJsonMarker(geoJsonPoint, latLng) {
    const { draggable } = this.state
    let icon = this.mapIcon(geoJsonPoint.properties, geoJsonPoint.color)
    if (geoJsonPoint.homePoint) {
      icon = this.homeIcon(geoJsonPoint.properties)
    }
    const marker = L.marker(latLng, { icon: icon, draggable: draggable, autoPan: true, opacity: geoJsonPoint.opacity})
    marker.on('dragend', this.updatePointFromMap.bind(this, geoJsonPoint, marker)).on('click', this.openMovingModal.bind(this, geoJsonPoint))
    return marker
  }

  renderRoadPoints() {
    const { draggable, customerView } = this.state
    return this.props.roadPoints.map((routePoint, index) => {
      if (routePoint && routePoint.point) {
        const { id, addressChanged, point, _objectId, sales_count } = routePoint
        const key = [
          id, index, addressChanged, point.coordinates, _objectId, draggable, customerView, sales_count, this.props.roadPoints.length
        ].join('-')

        return <GeoJSON
          key={key}
          data={this.pointData(routePoint, index)}
          pointToLayer={this.geoJsonMarker.bind(this)} />
      }
    })
  }

  customersUrl() {
    return window.Routes.customers_route_path(window.tenant, window.depoId, this.props.route.id, { format: 'json' })
  }

  fetchCustomers() {
    this.props.startLoading()
    return axios.get(this.customersUrl()).then(res => {
      this.props.loadCustomers(res.data)
      this.props.stopLoading()
    })
  }

  fetchSelectedCustomerRoutes(geoJsonPoint) {
    const selectedCustomers = this.props.customers.filter(c => c.selected)
    const { route } = this.props
    this.props.startLoading()
    axios.get(
      Routes.get_similar_routes_admin_customers_path(window.tenant, { format: 'json', selected_customers: selectedCustomers, route_id: route.id }),
    ).then(res => {
      this.setState({ selectedCustomerRoutes: res.data, modalOpen: true, pointToMove: geoJsonPoint })
      this.props.stopLoading()
    })
  }

  openMovingModal(geoJsonPoint) {
    const selectedCustomers = this.props.customers.filter(c => c.selected)
    if (selectedCustomers.length > 0) {
      this.fetchSelectedCustomerRoutes(geoJsonPoint)
    }
  }

  closeMovingModal() {
    this.setState({ modalOpen: false, pointToMove: {} })
  }

  renderUnplannedSales() {
    const fillOpacity = this.state.customerView ? 0.3 : 1
    return this.props.unplannedSales.map((carStopPoint, index) => {
      if (carStopPoint) {
        return <CircleMarker
          key={index}
          center={{ lat: carStopPoint.coordinates[1], lng: carStopPoint.coordinates[0] }}
          fillColor="#F3942F"
          fillOpacity={fillOpacity}
          stroke={true}
          color="black"
          radius={7}
          weight={1}
        />
      }
    })
  }

  toggleCustomer(index) {
    const customers = this.props.customers.slice(0)
    customers[index].selected = !customers[index].selected
    this.props.startLoading()
    axios.get(Routes.get_phones_admin_customers_path(window.tenant, { selected_address: customers[index].address, route_id: this.props.route.id, format: 'json' })).then(res => {
      this.setState({ customers: res.data, phoneModalOpen: true })
      this.props.stopLoading()
    }).catch(err => {
      this.props.stopLoading()
    })

    this.props.loadCustomers(customers)
  }

  stringifyRoutePoints() {
    return this.props.roadPoints.filter(({point}) => point).map(roadPoint =>
      JSON.stringify(roadPoint.point.coordinates)
    )
  }

  customerHaveRoutePoint(stringifiedRoutePoints, customerCoordinates) {
    return stringifiedRoutePoints.includes(JSON.stringify(customerCoordinates))
  }

  customerBackgroundColor(customer) {
    let customerColor
    if (customer.exists_in_current_route) {
      customerColor = "#35c900"
    } else if (customer.exists_in_other_route) {
      customerColor = "#0052cc"
    } else if (customer.route_number) {
      customerColor = "black"
    } else {
      customerColor = "red"
    }
    return customerColor
  }

  renderCustomers() {
    const stringifiedRoutePoints = this.stringifyRoutePoints()

    return this.props.customers.map((customer, index) => {
      const customerHasPoint = this.customerHaveRoutePoint(stringifiedRoutePoints, customer.point)
      const styleClass = !customerHasPoint && this.state.customerView ? "customer-without-route-point" : ""

      const customerColor = { fill: this.customerBackgroundColor(customer) }
      const selectedStyle = customer.selected ? { border: "3px solid #FF5722", height: 28, width: 28 } : { border: "3px solid white", height: 28, width: 28 }
      const iconStyles = { height: 17, width: 18 }

      const icon = L.divIcon({
        className: `customer-location ${styleClass}`,
        html: ReactDOMServer.renderToString(<div style={{ ...selectedStyle }}>{maleIcon(iconStyles, customerColor)}</div>),
        iconAnchor: [14, 14]
      })

      return (
        <Marker
          position={{ lat: customer.point[1], lng: customer.point[0] }}
          icon={icon}
          key={`customer-${index}-${customer.address}`}
          zIndexOffset={-10}
          onClick={this.toggleCustomer.bind(this, index)} />
      )
    })
  }

  renderCustomerPhoneModal() {
    return (
      <Modal open={this.state.phoneModalOpen} close={() => this.setState({ phoneModalOpen: false })} title="Selected customer phones">
        {this.state.customers.map(customer => {
          return (
            <p key={customer.id}>
              <a href={Routes.edit_admin_customers_path(window.tenant, customer.id)} target="_blank">{customer.phone}</a>
              {customer.other_route && ` - ${customer.other_route.name}`}
            </p>
          )
        })}
      </Modal>
    )
  }

  placedPointsOnMap() {
    const keys = Object.keys(this.props.placedPointsOnMap)
    const mappedPoints = []
    each(keys, key => {
      each(this.props.placedPointsOnMap[key][0], (routePoint, index) => {
        if (routePoint && routePoint.point) {
          const icon = L.divIcon({
            className: 'customer-location',
            html: ReactDOMServer.renderToString(
              <div className="other-routes-point" style={{ backgroundColor: this.props.placedPointsOnMap[key][1] }}>{index}</div>
            ),
            iconAnchor: [14, 14]
          })

          let marker

          if (isIsbilen && this.state.useNewMapMarkers) {
            marker =
              <CircleMarker
                key={routePoint.id}
                center={{ lat: routePoint.point.coordinates[1], lng: routePoint.point.coordinates[0] }}
                fillColor={this.props.placedPointsOnMap[key][1]}
                fillOpacity={1}
                color={this.props.placedPointsOnMap[key][1]}
                stroke={true}
                radius={3}
                weight={1}>
                  <Popup autoPan>
                    <span>
                      <strong>{routePoint.route_name}</strong><br/>
                      {routePoint.position}. {routePoint.address}
                    </span>
                  </Popup>
                </CircleMarker>
          } else {
            marker = <Marker
              key={routePoint.id}
              position={{ lat: routePoint.point.coordinates[1], lng: routePoint.point.coordinates[0] }}
              icon={icon}
              fillColor={this.props.placedPointsOnMap[key][1]}
              fillOpacity={1}
              color={this.props.placedPointsOnMap[key][1]}
              stroke={true}
              radius={3}
              weight={1} />
          }

          mappedPoints.push(marker)
        }
      })
    })
    return mappedPoints
  }

  renderMarkers() {
    return [
      ...this.renderRoadPoints(),
      ...this.renderUnplannedSales(),
      ...this.renderCustomers(),
      ...this.placedPointsOnMap()
    ]
  }

  handleLayerChange() {
    const map = this.state.editableFG.leafletElement._map
    if(this.state.mapType == GOOGLE_STREET_LAYER) {
      this.setState({ mapType: GOOGLE_SATELITE_LAYER, zoom: map.getZoom() })
    } else {
      this.setState({ mapType: GOOGLE_STREET_LAYER, zoom: map.getZoom() })
    }
  }

  toggleTooltip() {
    this.setState({ toggleTooltip: !this.state.toggleTooltip })
  }

  renderLayerIcon() {
    return (
      <div className="leaflet-action-container">
        <a className="leaflet-layer-label" onClick={this.handleLayerChange.bind(this)}>
          <i className="material-icons">layers</i>
        </a>
      </div>
    )
  }

  renderTooltipIcon() {
    const { route: { minimum_efficient_sales_count } } = this.props

    if (minimum_efficient_sales_count) {
      return (
        <div className="leaflet-action-container map-tooltip">
          <a className="leaflet-layer-label" onClick={this.toggleTooltip.bind(this)}>
            <i className="material-icons">info</i>
          </a>
        </div>
      )
    }
  }

  renderTooltipModal() {
    const { toggleTooltip, routeMinimumSalesEfficientCount } = this.state
    return (
      <TooltipModal
        open={toggleTooltip}
        close={this.toggleTooltip.bind(this)}
        routeMinimumSalesEfficientCount={routeMinimumSalesEfficientCount}
      />
    )
  }

  renderPointMovingConfirmationModal() {
    return (
      <ConfirmationDialog
        open={this.state.dialogOpen}
        close={this.cancelMove.bind(this)}
        leave={this.closeDialogAndMove.bind(this)}
        confirmAndCloseText="Confirm"
        cancelText="Cancel"
        title='Custom address detected!'
        content='The point you are moving has a custom address, do you want to move it?'
      />
    )
  }

  renderGridPrinter() {
    if (!this.state.editableFG || !this.state.printPreview) return
    const map = this.state.editableFG.leafletElement._map
    return (
      <PrintMapWithGrid map={map} />
    )
  }

  renderPrintingProgress() {
    if (this.state.mapPrinting != 'started') return
    const { gridItemsBounds } = this.state
    return (
      <div className="printingProccessingInfoWrapper">
        <div className="printingProccessingInfoBlock">
          <CircularProgress size={32} className="printingProccessingSpinner" />
          <span>Printing in progress, this can take up to few minutes!</span>
          <br />
          <br />
          <LinearProgress variant="determinate" value={(this.props.printedPages) / (this.props.totalPrintingPages) * 100} />
          <div style={{textAlign: 'right', fontSize: 18}}>
            <small>{this.props.printedPages} of {this.props.totalPrintingPages} pages printed.</small>
          </div>
        </div>
      </div>
    )
  }

  toggleDragging() {
    const { draggable } = this.state
    this.setState({ draggable: !draggable })
  }

  renderDraggableToggle() {
    const { draggable } = this.state
    const dragText = draggable ? "DRAG ON" : "DRAG OFF"
    const color = draggable ? "green" : "unset"

    return (
      <Button variant="contained" onClick={this.toggleDragging.bind(this)} style={{ ...styles.editLocationButton, color: color }}>
        <i className="material-icons" style={{ fontSize: 16 }}>edit_location</i>
        <span style={{ lineHeight: '18px' }}>{dragText}</span>
      </Button>
    )
  }

  toggleMapMarkersStyle() {
    const { useNewMapMarkers } = this.state
    this.setState({ useNewMapMarkers: !useNewMapMarkers })
  }

  renderMapMarkersStyleToggle() {
    const { useNewMapMarkers } = this.state
    const text = "FAST MAP"
    const color = useNewMapMarkers ? "green" : "unset"

    if (isIsbilen) {
      return (
        <Button vairant="contained" onClick={this.toggleMapMarkersStyle.bind(this)} style={{ ...styles.toggleMapMarkerButton, color: color }}>
          <i className="material-icons" style={{ fontSize: 16 }}>room</i>
          <span style={{ lineHeight: '18px' }}>{text}</span>
        </Button>
      )
    }
  }

  renderMoveCustomersModal() {
    const { modalOpen, pointToMove, selectedCustomerRoutes } = this.state
    const selectedCustomers = this.props.customers.filter(c => c.selected)

    if (Object.keys(pointToMove).length > 0) {
      return (
        <MoveCustomersToPointModal
          key={pointToMove.pointId}
          open={modalOpen}
          close={this.closeMovingModal.bind(this)}
          customers={this.props.customers}
          loadCustomers={this.props.loadCustomers}
          selectedPoint={pointToMove}
          selectedCustomers={selectedCustomers}
          selectedCustomerRoutes={selectedCustomerRoutes || []}
          routePoints={this.props.roadPoints} />
      )
    }
  }

  toggleCustomerView() {
    this.setState({ customerView: !this.state.customerView })
  }

  toggleViewRenderingSwitch() {
    if (this.props.customers.length > 0) {
      this.toggleCustomerView()
    } else {
      this.fetchCustomers().then(this.toggleCustomerView.bind(this))
    }
  }

  renderViewRenderingSwitchButton() {
    const { customerView, routeMinimumSalesEfficientCount } = this.state
    const icon = customerView ? "people" : "people_outline"

    if (routeMinimumSalesEfficientCount) {
      return (
        <div className="leaflet-action-container map-switch">
          <a className="leaflet-layer-label" onClick={this.toggleViewRenderingSwitch.bind(this)}>
            <i className="material-icons">{icon}</i>
          </a>
        </div>
      )
    }
  }

  render() {
    const { mapType } = this.state
    let load
    return (
      <div className='map-view-container'>
        {this.renderMoveCustomersModal()}
        <div className='map-sidebar-wrapper'>
          {this.props.children}
        </div>
        <div className="map-container">
          <Map center={REGIONS[window.tenant].coordinates} zoom={REGIONS[window.tenant].zoom} zoomControl={false} preferCanvas={true}>
            <TileLayer
              onLoad={(e) => load = true}
              url={mapType}
              subdomains={['mt0','mt1','mt2','mt3']}
            />
            <FeatureGroup ref={(reactFGref) => this._onFeatureGroupReady(reactFGref)}>
              <EditControl
                onCreated={this.onCreated}
                draw={{
                  polygon: false,
                  polyline: false,
                  rectangle: false,
                  circle: false,
                  marker: {
                    icon: this.mapIcon('', 'green')
                  },
                  circlemarker: false
                }}
                edit={{edit: false}}
              />
            </FeatureGroup>
            {this.renderMarkers()}
            <ZoomControl position='bottomright' />
            {this.renderGridPrinter()}
          </Map>
          {this.renderLayerIcon()}
          {this.renderPrintingProgress()}
          {this.renderDraggableToggle()}
          {this.renderMapMarkersStyleToggle()}
          {this.renderTooltipIcon()}
          {this.renderViewRenderingSwitchButton()}
          {this.renderTooltipModal()}
          {this.renderPointMovingConfirmationModal()}
          {this.renderCustomerPhoneModal()}
          <SimilarRoutesSelect />
        </div>
        <Flash />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    roadPoints: state.roadPoints,
    routes: state.resources,
    orderTransactions: state.orderTransactions,
    carStops: state.carStops,
    route: state.route,
    carRoutes: state.carRoutes,
    planEvent: state.planEvent,
    zoomedPoint: state.zoomedPoint,
    placedPointsOnMap: state.placedPointsOnMap,
    printPreview: state.printPreview,
    mapPrinting: state.mapPrinting,
    printedPages: state.printedPages,
    totalPrintingPages: state.totalPrintingPages,
    unplannedSales: state.unplannedSales,
    customers: state.customers,
    routeMinimumSalesEfficientCount: state.routeMinimumSalesEfficientCount
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    createRoutePointFromMap: point => dispatch(createRoutePointFromMap(point)),
    updatePoint: point => dispatch(updatePoint(point)),
    loadRoadPoints: points => dispatch(loadRoadPoints(points)),
    loadCustomers: customers => dispatch(loadCustomers(customers))
  }
}

const ReactLeafletMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactLeafletMapClass)

export default ReactLeafletMap
