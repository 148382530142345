import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const SmsCampaignsTableRow = ({ smsCampaign, index, classes }) => {
  return (
    <TableRow hover >
      <TableCell>{smsCampaign.id}</TableCell>
      <TableCell>{smsCampaign.name}</TableCell>
      <TableCell>{smsCampaign.text}</TableCell>
      <TableCell>{smsCampaign.include_subscribe_info ? 'Yes' : 'No'}</TableCell>
      <TableCell>{smsCampaign.start_date || ''}</TableCell>
      <TableCell>{smsCampaign.end_date  || ''}</TableCell>
      <TableCell>
        <Link className="mdl-button mdl-js-button mdl-button--icon" to={Routes.edit_admin_sms_campaigns_path(tenant, smsCampaign.id)} >
          <ModeEdit />
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(SmsCampaignsTableRow)
