import React from 'react'
import { Map, TileLayer, FeatureGroup, GeoJSON, ZoomControl } from 'react-leaflet'
import L from 'leaflet'
import axios from 'axios'
import customerIcon from '../../files/images/male.svg'
import { GOOGLE_STREET_LAYER, GOOGLE_SATELITE_LAYER } from '../shared/Layers'
import REGIONS from '../public/helpers/regions'
import Modal from '../shared/Modal'

class PlanEventCustomersMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lat: 56.2639,
      lng: 9.5018,
      zoom: 8,
      editableFG: null,
      mapType: GOOGLE_STREET_LAYER,
      layerChange: false,
      customers: [],
      routePoints: [],
      planEvent: {},
      foundCustomer: {},
      phoneModalOpen: false,
      polygonPlaced: false
    }
    this.onFeatureGroupReady = this.onFeatureGroupReady.bind(this)
  }

  componentDidMount() {
    axios.all([
      axios.get(Routes.sms_customer_map_plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, {format: 'json'})),
      axios.get(Routes.route_points_plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, {format: 'json'})),
      axios.get(Routes.plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, {format: 'json'}))
    ]).then(axios.spread((customers, routePoints, planEvent) => {
      this.setState({customers: customers.data, routePoints: routePoints.data, planEvent: planEvent.data})
    }))
  }

  customerIcon() {
    return L.icon({
      iconUrl: customerIcon,
      iconSize: [25, 35],
      iconAnchor: [12, 37],
      popupAnchor: [1, -32],
    })
  }

  mapIcon(number, color) {
    return new L.AwesomeNumberMarkers({
      number: number,
      markerColor: color,
    })
  }

  extractPoints(roadPoints) {
    return roadPoints.filter(({ point }) => point).map(roadPoint => roadPoint.point)
  }

  onFeatureGroupReady(reactFGref) {
    let leafletFG = this.state.editableFG
    leafletFG = leafletFG == null ? reactFGref.leafletElement : leafletFG.leafletElement

    if (!leafletFG._map) return

    const routePoints = this.extractPoints(this.state.routePoints)
    if (routePoints.length > 0 && !this.state.layerChange && !this.state.foundCustomer.id) {
      // Need to switch coordinates around cause, for bounds lat is first and lng is second
      const coordinatesArray = routePoints.map(point => point.coordinates.slice().reverse())
      const bounds = new L.LatLngBounds(coordinatesArray)
      leafletFG._map.fitBounds(bounds)
    }

    this.state.editableFG = reactFGref
  }

  pointData({ point }, index) {
    return { ...point, properties: index + 1, color: 'cadetblue' }
  }

  customerData(customer) {
    return { ...customer.coordinates, customer: customer }
  }

  geoJsonMarker(geoJsonPoint, latLng) {
    return L.marker(latLng, {icon: this.mapIcon(geoJsonPoint.properties, geoJsonPoint.color)})
  }

  handleCustomerClick(e, customer) {
    this.setState({ foundCustomer: customer, phoneModalOpen: true })
  }

  renderRouteNumber() {
    return this.state.foundCustomer.route_number && <b>- {this.state.foundCustomer.route_number}</b>
  }

  renderCustomerPhoneModal() {
    return (
      <Modal open={this.state.phoneModalOpen} close={() => this.setState({ phoneModalOpen: false })} title="Selected customer phone">
        <p>{this.state.foundCustomer.phone} {this.renderRouteNumber()}</p>
      </Modal>
    )
  }

  geoJsonCustomerMarker(geoJsonPoint, coordinates) {
    const marker = L.marker(coordinates, { icon: this.customerIcon() })
    marker.on('click', (e) => this.handleCustomerClick(e, geoJsonPoint.customer))
    return marker
  }

  renderCustomerPoints() {
    return this.state.customers.map((customer, index) => {
      if(customer.coordinates) {
        return <GeoJSON key={`customer_${customer.id}`} data={this.customerData(customer)} pointToLayer={this.geoJsonCustomerMarker.bind(this)} />
      }
    })
  }

  renderRoutePoints() {
    return this.state.routePoints.map((routePoint, index) => {
      if (routePoint != undefined && routePoint.point) {
        return <GeoJSON key={`plan_event_route_point_${routePoint.id}`} data={this.pointData(routePoint, index)} pointToLayer={this.geoJsonMarker.bind(this)} />
      }
    })
  }

  geoJsonPolygon(latlng) {
    return L.polygon(latLng)
  }

  renderPolygon() {
    const { planEvent } = this.state
    if (planEvent.id) {
      return <GeoJSON key={`polygon_${planEvent.id}`} data={this.state.planEvent.polygon} pointToLayer={this.geoJsonPolygon.bind(this)} />
    }
  }

  renderMarkers() {
    return [
      this.renderPolygon(),
      ...this.renderRoutePoints(),
      ...this.renderCustomerPoints()
    ]
  }

  handleLayerChange() {
    const map = this.state.editableFG.leafletElement._map
    if(this.state.mapType == GOOGLE_STREET_LAYER) {
      this.setState({ mapType: GOOGLE_SATELITE_LAYER, zoom: map.getZoom(), layerChange: true })
    } else {
      this.setState({ mapType: GOOGLE_STREET_LAYER, zoom: map.getZoom(), layerChange: true })
    }
  }

  renderLayerIcon() {
    return (
      <div className="leaflet-action-container">
        <a className="leaflet-layer-label" onClick={this.handleLayerChange.bind(this)}>
          <i className="material-icons">layers</i>
        </a>
      </div>
    )
  }

  render() {
    const position = [this.state.lat, this.state.lng]
    const { mapType } = this.state
    return (
      <div className='plan-event-map'>
        <div className="map-container">
          <Map center={REGIONS[window.tenant].coordinates} zoom={this.state.zoom} zoomControl={false}>
            <TileLayer
              url={mapType}
              subdomains={['mt0','mt1','mt2','mt3']}
            />
            <FeatureGroup ref={(reactFGref) => this.onFeatureGroupReady(reactFGref)}/>
            <ZoomControl position='bottomright' />
            {this.renderMarkers()}
          </Map>
          {this.renderCustomerPhoneModal()}
          {this.renderLayerIcon()}
        </div>
      </div>
    )
  }
}

export default PlanEventCustomersMap
