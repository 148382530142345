import React from 'react'
import ErrorCatcher from '../../shared/ErrorCatcher'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import WallmobIndex from './WallmobIndex'

const AdminWallmobIndex = () => {
  return (
    <ErrorCatcher>
      <BrowserRouter>
        <Switch>
          <Route path='/:tenant/admin/wallmob' component={WallmobIndex} />
        </Switch>
      </BrowserRouter>
    </ErrorCatcher>
  )
}

export default AdminWallmobIndex
