import React from 'react'
import { Provider } from 'react-redux'
import store from '../../files/store'
import PlanEventsIndex from './PlanEventsIndex'

const PlanEventsIndexWithStore = () => {
  return  (
    <Provider store={store}>
      <PlanEventsIndex />
    </Provider>
  )
}

export default PlanEventsIndexWithStore
