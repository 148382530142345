import React from 'react'
import ThumbsUp from '../icons/ThumbsUp'
import ThumbsDown from '../icons/ThumbsDown'
import TextField from '@material-ui/core/TextField'
import Close from '@material-ui/icons/Close'
import axios from 'axios'
import getTenantLocale from '../helpers/getTenantLocale'

export default class FeedbackModal extends React.Component {
  state = { comments: '', positive: null }

  closeFeedback = () => this.props.closeFeedbackModal()
  handleChange = (e) => this.setState({ comments: e.target.value })
  togglePositive = () => this.setState({ positive: true })
  toggleNegative = () => this.setState({ positive: false })

  data = () => {
    const { comments, positive } = this.state
    const { customerUuid, planEventRoutePoint } = this.props

    return {
      customer_uuid: customerUuid,
      plan_event_route_point_id: planEventRoutePoint.id,
      comment: comments,
      positive: positive
    }
  }

  submit = () => {
    axios
      .post(
        Routes.public_feedbacks_path({
          locale: getTenantLocale(this.props.tenant),
          format: 'json'
        }),
        this.data()
      )
      .then((res) => {
        this.props.showSuccessPage()
      })
      .catch((err) => console.log(err))
  }

  renderSubmitButton() {
    const {
      translations: { send }
    } = this.props
    const { positive } = this.state
    const opacity = positive !== null ? 'visible' : 'invisible'

    return (
      <div className={`submit-button ${opacity}`} onClick={this.submit}>
        {send}
      </div>
    )
  }

  renderTextField() {
    const {
      translations: { tell_us_more }
    } = this.props
    const { comments, positive } = this.state
    const opacity = positive !== null ? 'visible' : 'invisible'

    return (
      <div className={`feedback-input ${opacity}`}>
        <TextField fullWidth multiline placeholder={tell_us_more} onChange={this.handleChange} value={comments} />
      </div>
    )
  }

  render() {
    const {
      translations: { were_you_satisfied, tell_us_more }
    } = this.props
    const { positive } = this.state
    return (
      <div className='tracking-modal-wrapper'>
        <div className='tracking-modal'>
          <div className='tracking-modal-header'>
            <Close onClick={this.closeFeedback} />
          </div>
          <div className='tracking-modal-title'>{were_you_satisfied}</div>
          <div className='feedback-buttons'>
            <ThumbsDown selected={positive !== null && !positive} toggle={this.toggleNegative} />
            <ThumbsUp selected={positive !== null && positive} toggle={this.togglePositive} />
          </div>
          {this.renderTextField()}
          <div className='feedback-submit'>{this.renderSubmitButton()}</div>
        </div>
      </div>
    )
  }
}
