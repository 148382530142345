import React from 'react'
import MapboxAutocomplete from '../../shared/MapboxAutocomplete'
import ArrowDownwardSharp from '@material-ui/icons/KeyboardArrowDown'
import magnifyingGlass from '../icons/magnifying_glass.svg'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core'
import moment from 'moment'

class FraastFoodModal extends React.Component {
  renderUpcomingPlanEventDates(upcomingDates) {
    return upcomingDates.map(({ google_estimate_time, original_google_estimate_time, date }, index) => {
      const time = moment(google_estimate_time || original_google_estimate_time)
      const planEventDate = moment(date, 'YYYY-MM-DD').locale(this.props.tenant == 'hjem-is' ? 'da' : 'no')
      if (index > 3 && !this.props.showAllUpcomingDates) return
      return (
        <li key={index || 0} className='modal-list-item'>
          <span className='list-item-date'>
            {planEventDate.format('dddd')}, {planEventDate.format('D. MMMM')}
          </span>
          <span className='list-item-time'>
            {time.subtract(10, 'minutes').format('H:mm')} - {time.add(20, 'minutes').format('H:mm')}
          </span>
        </li>
      )
    })
  }

  renderHeadingText() {
    if (tenant === 'hjem-is') {
      return (
        <div className='modal-heading'>
          <p>{this.props.translations.fraast_heading}</p>
        </div>
      )
    } else {
      return (
        <div className='modal-heading'>
          <p>{this.props.translations.fraast_heading.split(' ')[0]}</p>
          <div>
            <p>{this.props.translations.fraast_heading.split(' ')[1]}</p>
          </div>
          <p>{this.props.translations.fraast_heading.split(' ').slice(2).join(' ')}</p>
        </div>
      )
    }
  }

  renderSubscribeHeading() {
    return <h3 className='subscribe-heading'>{this.props.translations.subscribe_heading}</h3>
  }

  renderTimetable() {
    if (this.props.selectedPlanEventRoutePoint) {
      return (
        <div className='timetable'>
          <div className='timetable-points'>
            {this.props.tenant === 'hjem-is' && <span className='timetable-points-title'>{this.props.translations.nearest_points_title}</span>}
            <h1>{this.props.selectedPlanEventRoutePoint.address}</h1>
            <p>{this.props.translations.schedule_paragraph}</p>
            <div>
              <ul>
                {this.renderUpcomingPlanEventDates(
                  this.props.selectedPlanEventRoutePoint.upcoming_plan_events_dates
                )}
              </ul>
            </div>
            {!this.props.showAllUpcomingDates && (
              <Button className='side-button' onClick={this.props.showAllDates}>
                {this.props.translations.see_more_times}
                <ArrowDownwardSharp />
              </Button>
            )}
          </div>
          <div className='modal-actions'>
            {this.props.tenant === 'den-norske-isbilen' && this.renderSubscribeHeading()}
            {this.props.tenant === 'hjem-is' &&
              <span className='nearest-points-subtitle'>{this.props.translations.nearest_points_subtitle}</span>
            }
            <Button className='black-button' onClick={this.props.openSubscribeModal}>
              {this.props.translations.subscribe}
            </Button>
            {this.props.tenant === 'hjem-is' && this.renderSubscribeHeading()}
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className={`subscription-modal ${tenant}`}>
        <div className='modal-top'>
          {this.renderHeadingText()}

          <div className='searchbar'>
            <MapboxAutocomplete
              style={{ width: 400 }}
              accessToken={this.props.accessToken}
              isbilenAccessToken={this.props.isbilenAccessToken}
              isbillen_api_key={this.props.isbillen_api_key}
              key='nav-autocomplete'
              classNames='header-search-container'
              translations={this.props.translations}
              locationMarkerColor='black'
              hotjarLink='/type-in-address-search'
              googleAction='navBarTypeIn'
              googleSelectAction='navBarChooseAddress'
              onAddressChange={this.props.onAddressChange}
              locale={this.props.tenant}
            />
            <div className='icon'>
              <img src={magnifyingGlass} />
            </div>
          </div>
        </div>
        <div className='modal-middle'>
          {this.renderTimetable()}

          {!this.props.selectedPlanEventRoutePoint && (
            <div>
              <div className='middle-title'>
                <h1>{this.props.translations.dont_miss}</h1>
                <p>
                  {this.props.translations.sidebar_paragraph + ' ' + this.props.translations.sidebar_paragraph_addition}
                </p>
              </div>
              <div className='middle-description'>
                <h2>{this.props.translations.sidebar_subtitle}</h2>
                <ul className='flower-icon'>
                  <li>{this.props.translations.point_1}</li>
                  <li>{this.props.translations.point_2}</li>
                  <li>{this.props.translations.point_3}</li>
                  <li>{this.props.translations.point_4}</li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <Accordion className='modal-bottom' square expanded={this.props.subscribeDropdownOpen} onChange={this.props.toggleDropdown}>
          <AccordionSummary id='subscribeDropdown' expandIcon={<ArrowDownwardSharp />}>
            <h1>{this.props.translations.do_you_wish_to_unsubscribe}</h1>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <p className='bottom-details'>{this.props.translations.unsubscribe_prompt}</p>
            <Button className='bottom-button' onClick={this.props.unsubscribe}>{this.props.translations.unsubscribe}</Button>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
}

export default FraastFoodModal
