import React from 'react'
import { Link } from 'react-router-dom'

const CancelButton = ({onClick, to, children}) => {
  return (
    <Link onClick={onClick} className="mdl-button mdl-js-button mdl-js-ripple-effect" to={to}>
      {children || 'BACK'}
    </Link>
  )
}

export default CancelButton