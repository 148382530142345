import mergeable from 'redux-merge-reducers'

import appReducer from './app'
import resourcesReducer from './resources'
import routePointsReducer from './routePoints'
import carStopsReducer from './carStops'
import planEventsReducer from './planEvents'

const rootReducer =  mergeable(appReducer).merge(
  mergeable(resourcesReducer).merge(
    mergeable(routePointsReducer).merge(
      mergeable(carStopsReducer).merge(
        planEventsReducer
      )
    )
  )
)

export default rootReducer
