import React from 'react'
import axios from 'axios'
import ModalWrapper from '../shared/Modal'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import DateRangeFilter from '../feedbacks/DateRangeFilter'

export default class CleanupModal extends React.Component {
  state = {
    dates: [],
    modalLoading: false,
    selectedDepoId: '',
    startDate: moment().subtract(1, 'years').startOf('year').format('DD-MM-YYYY'),
    endDate: moment().subtract(1, 'years').endOf('year').format('DD-MM-YYYY')
  }

  handleDateChange(field, value) {
    this.setState({ [field]: value })
  }

  submit() {
    const { path } = this.props
    axios.post(path(tenant, depoId, {start_date: this.state.startDate, end_date: this.state.endDate})).then(res => {
      if (typeof res.data == "number"){
        this.props.setFlash(res.data + " Order closed")
      }
    }).catch(err => {
      this.props.setFlash(err)
    })
  }

  render() {
    const { open } = this.props

    return (
      <ModalWrapper
        classes="order-modal"
        style={{ width: 400, height: 150 }}
        open={open}
        title="Select interval to cleanup orders"
        close={this.props.handleModalClose.bind(this)}>
          <DateRangeFilter
            handleChange={this.handleDateChange.bind(this)}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
          <Button style={{ marginTop: 10, marginBottom: 10 }} variant="raised" color="primary" onClick={this.submit.bind(this)}> Cleanup orders </Button>
      </ModalWrapper>
    )
  }
}
