class RoutePoint {
  static objectId(object) {
    if (!RoutePoint.objectMap.has(object)) {
      RoutePoint.objectMap.set(object, ++RoutePoint.objectCount)
    }
    return RoutePoint.objectMap.get(object)
  }

  constructor() {
    this.address = ''
    this.wait_time = '2'
    this.public_comment = ''
    this.private_comment = ''
    this.google_estimate_time = ''
    this.point = ''
    this.checked = false
    this.separator = false
    this.errors = {}
    this.b2b_customers = []
    this.home_customers = []
    this._objectId = RoutePoint.objectId(this)
  }
}

RoutePoint.objectCount = 0
RoutePoint.objectMap = new WeakMap()

export default RoutePoint
