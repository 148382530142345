import React                 from 'react'
import Select                from '@material-ui/core/Select'
import MenuItem              from '@material-ui/core/MenuItem'
import ListItemText          from '@material-ui/core/ListItemText'
import FormControl           from '@material-ui/core/FormControl'
import { Input, InputLabel } from '@material-ui/core'
import Checkbox              from '@material-ui/core/Checkbox'
import startCase             from 'lodash/startCase'

class MultiSelect extends React.Component {
  constructor(props) {
    super(props)
  }

  uppercaseValues() {
    return this.props.selectedValues.map(opt => startCase(opt)).join(', ')
  }

  handleChange(e) {
    this.props.handleChange(e, this.props.inputName)
  }

  render() {
    const { handleChange, values, selectedValues, inputName, label, selectClassName } = this.props

    return  (
      <FormControl>
        <InputLabel htmlFor={inputName}>{label}</InputLabel>
        <Select
          className={selectClassName}
          multiple
          value={selectedValues}
          fullWidth
          input={<Input id={inputName} style={{ width: 130 }} />}
          renderValue={this.uppercaseValues.bind(this)}
          onChange={this.handleChange.bind(this)}
        >
          {values.map((value) => {
            const checked = selectedValues.indexOf(value) > -1
            return (
              <MenuItem key={`status-${value}`} value={value} >
                <Checkbox checked={checked} />
                <ListItemText primary={startCase(value)} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

export default MultiSelect
