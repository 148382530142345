import React from 'react'
import axios from 'axios'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Chip from '@material-ui/core/Chip'
import { render } from 'react-dom'
import MainTitle from '../shared/MainTitle'
import RouteForm from './RouteForm'
import { connect } from 'react-redux'
import { setMainTitle, loadResources, startLoading, stopLoading } from '../../files/actions/index'
import NewButton from '../shared/NewButton'
import Loading from '../shared/Loading'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import filter from 'lodash/filter'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import invert from 'lodash/invert'
import RoutesRow from './RoutesRow'
import { Link } from 'react-router-dom'

const FILTERS = {
  0: 'active',
  1: 'tagged',
  2: 'deleted'
}

const style = {
  chips: { padding: 12, borderBottom: 'solid 1px rgba(0,0,0,0.12)'},
  chipSelected: { marginRight: 4, backgroundColor: '#ff4081', color: 'white' },
  chip: { marginRight: 4 }
}

class RoutesClass extends React.Component {
  state = { showRoutes: false, depos: [], filter: 'active'}

  componentDidMount() {
    this.props.startLoading()
    this.props.setMainTitle('Routes')
    this.fetchDepos()
    this.loadRoutes(this.filter())
  }

  filter() {
    return this.props.match.params.filter || FILTERS[0]
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.filter !== nextProps.match.params.filter) {
      this.setState({ showRoutes: false })
      this.loadRoutes(nextProps.match.params.filter || FILTERS[0])
    }
  }

  url(filter) {
    return window.Routes.filter_routes_path(window.tenant, window.depoId, filter, { format: 'json' })
  }

  loadRoutes(filter) {
    const { route } = this.props
    this.props.startLoading()
    axios.get(this.url(filter)).then(res => {
      this.props.loadRoutes(res.data)
      this.setState({ showRoutes: true })
      this.props.stopLoading()
      if (route.id) {
        const routeRowContainer = document.getElementById(`route-${route.id}`)
        if (routeRowContainer) {
          routeRowContainer.classList.add('row-class')
          routeRowContainer.scrollIntoView({ block: 'center', behaviour: 'instant' })
          routeRowContainer.classList.add("scrolled")
        }
      }
    })
  }

  fetchDepos() {
    axios.get(window.Routes.admin_depos_path(window.tenant, window.depoId, { format: 'json' })).then(res => {
      this.setState({ depos: res.data })
    })
  }

  filteredRoutes() {
    const { routes } = this.props
    const { tag } = this.state
    switch(this.filter()) {
      case 'active':
        return filter(routes, ({deleted_at}) => !deleted_at)
      case 'tagged':
        return tag ? filter(routes, ({tag}) => tag === this.state.tag) : routes
      case 'deleted':
        return filter(routes, ({deleted_at}) => deleted_at)
    }
  }

  renderRoutes() {
    const { depos } = this.state
    return this.filteredRoutes().map((route, index) => {
      return <RoutesRow route={route} key={route.id} depos={depos} filter={this.filter()} />
    })
  }

  routeListDisplayStyle() {
    return this.state.showRoutes ? 'block' : 'none'
  }

  setTag(tag) {
    if (tag === this.state.tag) {
      this.setState({ tag: null })
    } else {
      this.setState({ tag })
    }
  }

  renderTags() {
    if (this.props.match.params.filter === "tagged" && this.state.showRoutes) {
      const f = route => route.tag
      const tags = uniq(map(filter(this.props.routes, f), f), f)

      if (tags.length === 0) return

      const tagChips = tags.map(tag => {
        const tagStyle = this.state.tag === tag ? style.chipSelected : style.chip
        return <Chip key={tag} label={tag} style={tagStyle} onClick={this.setTag.bind(this, tag)} />
      })

      return <div style={style.chips}>{tagChips}</div>
    }
  }

  render() {
    const selectedTab = invert(FILTERS)[this.filter()]
    return (
      <div>
        <div className='sticky'>
          <div className='routes-header'>
            <MainTitle />
            <NewButton to={window.Routes.new_routes_path(window.tenant, window.depoId)} />
          </div>
          <Tabs value={selectedTab} textColor="secondary">
            <Tab classes={{ root: 'routes-tab' }} value={'0'} label='Active Routes' component={Link} to={window.Routes.filter_routes_path(window.tenant, window.depoId, 'active')}/>
            <Tab classes={{ root: 'routes-tab' }} value={'1'} label='Tagged Routes' component={Link} to={window.Routes.filter_routes_path(window.tenant, window.depoId, 'tagged')}/>
            <Tab classes={{ root: 'routes-tab' }} value={'2'} label='Deactivated Routes' component={Link} to={window.Routes.filter_routes_path(window.tenant, window.depoId, 'deleted')}/>
          </Tabs>
          {this.renderTags()}
        </div>
        <List disablePadding={true} style={{display: this.routeListDisplayStyle()}}>
          {this.renderRoutes()}
        </List>
        <Loading />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    routes: state.resources,
    route: state.route,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    loadRoutes: routes => dispatch(loadResources(routes)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const Routes = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesClass)

export default Routes
