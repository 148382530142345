import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Map, TileLayer, Marker, Popup, FeatureGroup, WMSTileLayer, GeoJSON, Polyline, ZoomControl } from 'react-leaflet'
import L from 'leaflet'
import { EditControl } from 'react-leaflet-draw'
import axios from 'axios'
import { connect } from 'react-redux'
import Flash from '../shared/Flash'
import { startLoading, stopLoading, createRoutePointFromMap, loadRoadPoints, loadCarRoutes, setFlash } from '../../files/actions/index'
import flatten from 'lodash/flatten'
import polyline from '@mapbox/polyline'
import 'leaflet-textpath'
import { GOOGLE_STREET_LAYER, GOOGLE_SATELITE_LAYER } from '../shared/Layers'
import PlanEventPointIcon from '../shared/PlanEventPointIcon'
import PlanEventPopup from './PlanEventPopup'
import Paper from '@material-ui/core/Paper'
import PlanEventsReport from '../planEvents/PlanEventsReport'
import REGIONS from '../public/helpers/regions'

class PlanEventMapClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lat: REGIONS[tenant].coordinates.lat,
      lng: REGIONS[tenant].coordinates.lng,
      zoom: 8,
      editableFG: null,
      mapType: GOOGLE_STREET_LAYER,
      layerChange: false,
      roadPoints: null
    }
    this.onFeatureGroupReady = this.onFeatureGroupReady.bind(this)
  }

  componentDidMount() {
    axios.get(Routes.admin_depo_path(tenant, depoId, {format: 'json'})).then(res => {
      const depoCoordinates = res.data.address_coordinates
      if(depoCoordinates.length > 0) {
        this.setState({lat: parseFloat(depoCoordinates[0]), lng: parseFloat(depoCoordinates[1]), zoom: 10})
      }
    })
  }

  componentWillUnmount() {
    this.props.loadCarRoutes([])
    this.props.loadRoutePoints([])
  }

  extractPoints(roadPoints) {
    return roadPoints.filter(({ point }) => point).map(roadPoint => roadPoint.point)
  }

  setFlashFromChild(message) {
    this.props.setFlash(message)
  }

  onFeatureGroupReady(reactFGref) {
    let leafletFG = this.state.editableFG
    leafletFG = leafletFG == null ? reactFGref.leafletElement : leafletFG.leafletElement

    if (!leafletFG._map) return

    const routePoints = this.extractPoints(this.props.roadPoints)
    if (routePoints.length > 0 && !this.state.layerChange) {
      // Need to switch coordinates around cause, for bounds lat is first and lng is second
      const coordinatesArray = routePoints.map(point => [point.coordinates[1], point.coordinates[0]])
      const bounds = new L.LatLngBounds(coordinatesArray)
      leafletFG._map.fitBounds(bounds)
    }

    if (routePoints.length == 0) {
      leafletFG.clearLayers()
    }
    this.state.editableFG = reactFGref
  }

  pointData(point, index) {
    const geoJson = point.point ? point.point : point.stop_location
    geoJson.properties = index + 1
    geoJson.revenue = point.total_revenue
    geoJson.data = {
      plannedTime: point.google_estimate_time || point.start_at,
      visitedTime: point.actual_arrival_time || point.start_at,
      duration: point.duration || 0,
      clients: point.subscribers_count || 0,
      totalRevenue: point.total_revenue || 0
    }

    if (point.point_visited !== undefined) {
      geoJson.color = point.point_visited ? "#57C200" : "#D0021B"
    } else {
      geoJson.color = "#F5A623"
    }

    if (point.stop_type === "eshop") {
      geoJson.color = "#91D4F5"
    }
    return geoJson
  }

  geoJsonToPoint(point) {
    const coordinates = point.coordinates
    return [coordinates[1], coordinates[0]]
  }

  renderMarker(routePoint, index) {
    const geoJsonPoint = this.pointData(routePoint, index)
    const pointIndex = geoJsonPoint.properties
    let icon = L.divIcon({
      className: '',
      html: ReactDOMServer.renderToString(<PlanEventPointIcon revenue={geoJsonPoint.revenue} index={pointIndex} color={geoJsonPoint.color} />),
      iconAnchor: [38, 60],
      popupAnchor: [-7, -48]
    })
    const position = this.geoJsonToPoint(routePoint.stop_location || routePoint.point)

    return (
      <Marker key={pointIndex} position={position} icon={icon}>
        <Popup autoPan={true}>
          <PlanEventPopup
            planEvent={this.props.planEvent}
            routePoint={routePoint}
            index={pointIndex}
            color={geoJsonPoint.color}
            setFlash={this.setFlashFromChild.bind(this)}
          />
        </Popup>
      </Marker>
    )
  }

  renderRoadPoints() {
    let routePoints = this.state.roadPoints ? this.state.roadPoints : this.props.roadPoints
    return routePoints.map((routePoint, index) => {
      if (routePoint && (routePoint.point || routePoint.stop_location)) {
        return this.renderMarker(routePoint, index)
      }
    })
  }

  extractedCarStopPolylines(carRoutes) {
    return carRoutes.filter(({ encoded_polyline }) => encoded_polyline).map(carStopRoute => polyline.decode(carStopRoute.encoded_polyline))
  }

  renderRoutePolyline() {
    const { carRoutes } = this.props
    if (carRoutes.length > 0) {
      const decodedPolylines = this.extractedCarStopPolylines(carRoutes)
      if (decodedPolylines.length > 0) {
        const map = this.state.editableFG.leafletElement._map
        const layer = L.polyline(flatten(decodedPolylines), { color: 'green' })
        layer.addTo(map)
        layer.setText('   ►   ', { repeat: true, attributes: { fill: 'red' } })
      }
    }
  }

  renderMarkers() {
    this.renderRoutePolyline()
    return this.renderRoadPoints()
  }

  handleLayerChange() {
    const map = this.state.editableFG.leafletElement._map
    if (this.state.mapType == GOOGLE_STREET_LAYER) {
      this.setState({ mapType: GOOGLE_SATELITE_LAYER, zoom: map.getZoom(), layerChange: true })
    } else {
      this.setState({ mapType: GOOGLE_STREET_LAYER, zoom: map.getZoom(), layerChange: true })
    }
  }

  renderLayerIcon() {
    return (
      <div className="leaflet-action-container">
        <a className="leaflet-layer-label" onClick={this.handleLayerChange.bind(this)}>
          <i className="material-icons">layers</i>
        </a>
      </div>
    )
  }

  reRenderOnPlanEventsReportTabSwitch(roadPoints) {
    this.setState({roadPoints: roadPoints})
    this.renderMarkers()
  }

  render() {
    const position = [this.state.lat, this.state.lng]
    const { mapType } = this.state

    return (
      <div className='map-view-container'>
        <div className='map-sidebar-wrapper'>
          <Paper className='map-sidebar'>
            <PlanEventsReport {...this.props} map={this.state.editableFG} reRenderOnPlanEventsReportTabSwitch={this.reRenderOnPlanEventsReportTabSwitch.bind(this)}/>
          </Paper>
        </div>
        <div className="map-container">
          <Map center={position} zoom={this.state.zoom} zoomControl={false}>
            <TileLayer
              url={mapType}
              subdomains={['mt0','mt1','mt2','mt3']}
            />
            <FeatureGroup ref={(reactFGref) => this.onFeatureGroupReady(reactFGref)} />
            <ZoomControl position='bottomright' />
            {this.renderMarkers()}
          </Map>
          {this.renderLayerIcon()}
        </div>
        <Flash />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    roadPoints: state.roadPoints,
    carRoutes: state.carRoutes,
    planEvent: state.planEvent
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    loadRoutePoints: routePoints => dispatch(loadRoadPoints(routePoints)),
    loadCarRoutes: carRoutes => dispatch(loadCarRoutes(carRoutes)),
    setFlash: flash => dispatch(setFlash(flash))
  }
}

const PlanEventMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEventMapClass)

export default PlanEventMap
