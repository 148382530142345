import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import WarehouseOrdersTable from './WarehouseOrdersTable'

const WarehouseOrdersIndex = () => {
  return (
    <div className="content-container">
      <BrowserRouter>
        <Switch>
          <Route path='/:tenant/inventory/depo/:depoId/warehouse_orders' component={WarehouseOrdersTable} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default WarehouseOrdersIndex
