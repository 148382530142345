import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import SmsCampaignsForm from './SmsCampaignsForm'
import SmsCampaignsTable from './SmsCampaignsTable'

const UsersComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/admin/sms_campaigns/:smsCampaignId/edit' component={SmsCampaignsForm} />
        <Route path='/:tenant/admin/sms_campaigns/new' component={SmsCampaignsForm} />
        <Route path='/:tenant/admin/sms_campaigns' component={SmsCampaignsTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default UsersComponent
