import React from 'react'
import SuccessCar from '../icons/success_car.svg'
import Close from '@material-ui/icons/Close'

export default class SuccessModal extends React.Component {
  closeThankyou = () => this.props.closeSuccessPage()

  render() {
    const {
      translations: { thank_you_help_us_improve }
    } = this.props

    return (
      <div className='tracking-modal-wrapper'>
        <div className='tracking-modal thank-you-modal'>
          <div className='tracking-modal-header'>
            <Close onClick={this.closeThankyou} />
          </div>
          <img src={SuccessCar} />
          <div className='tracking-modal-title'>{thank_you_help_us_improve}</div>
        </div>
      </div>
    )
  }
}
