import { compose, createStore, applyMiddleware } from 'redux'

import rootReducer from '../reducers'

let composeEnhancers = compose
if (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
}

const store = createStore(
  rootReducer,
  composeEnhancers()
)

export default store
