import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const OrderLineItemsTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Product category</TableCell>
        <TableCell>Product name</TableCell>
        <TableCell>SKU</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Total ({window.currency})</TableCell>
        <TableCell>Discount ({window.currency})</TableCell>
        <TableCell>Transfer</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default OrderLineItemsTableHead
