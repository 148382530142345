import React from 'react'
import PlanEventsComponent from './PlanEventsComponent'
import ErrorCatcher from '../shared/ErrorCatcher'

const PlanEventsIndex = () => {
  return (
    <ErrorCatcher>
      <PlanEventsComponent />
    </ErrorCatcher>
  )
}

export default PlanEventsIndex
