import React from 'react'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import axios from 'axios'
import find from 'lodash/find'
import omit from 'lodash/omit'
import Grid from '@material-ui/core/Grid'
import CustomersSmsReceiversReport from './CustomersSmsReceiversReport'
import { setMainTitle, startLoading, stopLoading, setFlash } from '../../../files/actions/index'

import Customer from '../../../models/Customer'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'

import FunTextField from '../../forms/FunTextField'
import extractDataErrors from '../../support/extractDataErrors'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaterialMapboxAutocomplete from '../../shared/MaterialMapboxAutocomplete'
import debounce from 'lodash/debounce'
import capitlize from 'lodash/capitalize'

class CustomersFormClass extends React.Component {
  constructor(props) {
    super(props)

    this.state = new Customer()
  }

  componentDidMount() {
    this.props.startLoading()

    const customer = this.findCustomerInStore()

    if (customer) {
      this.setCustomerAndMainTitle(customer)
      this.fetchCustomerReport(res.data)
      this.props.stopLoading()
    } else if(this.props.match.params.customerId) {
      axios.get(this.loadUrl()).then(res => {
        this.setCustomerAndMainTitle(res.data)
        this.fetchCustomerReport(res.data)
      }).catch(error => {
        this.props.setFlash(`${error} (${this.loadUrl()})`)
      }).then(
        this.props.stopLoading()
      )
    } else {
      this.setMainTitle()
      this.props.stopLoading()
    }
  }

  findCustomerInStore() {
    return find(this.props.customers, ({ id }) => {
      return id == this.props.match.params.customerId
    })
  }

  fetchCustomerReport({ id }) {
    this.props.startLoading()
    axios.get(Routes.sms_receivers_admin_customer_path(window.tenant, id, { format: 'json' })).then(res => {
      this.setState({ smsReceivers: res.data })
      this.props.stopLoading()
    })
  }

  setCustomerAndMainTitle(customer) {
    this.setCustomer(customer)
    this.setMainTitle()
  }

  setCustomer(customer) {
    this.setState({
      id: customer.id,
      first_name: customer.first_name || '',
      last_name: customer.last_name || '',
      email: customer.email || '',
      username: customer.username || '',
      name: customer.name || '',
      phone: customer.phone || '',
      gender: customer.gender || '',
      sms_permission: customer.sms_permission || false,
      route_number: customer.route_number || '',
      home_address: customer.home_address || '',
      location_attributes: {
        address: customer.location_address || '',
        id: customer.location_id,
        address_type: customer.address_type,
        home_address_type: customer.home_address_type,
        home_address: customer.home_address
      }
    }, () => this.setMainTitle())
  }

  setMainTitle() {
    this.props.setMainTitle(!!this.state.id ? `Edit customer ${this.state.email || this.state.phone}` : 'New customer')
  }

  setStateAfterSave(data) {
    this.setState({
      id: data.id,
      email: data.email,
      errors: {}
    })
  }

  data() {
    return { customer: omit(this.state, 'smsReceivers'), format: 'json' }
  }

  loadUrl() {
    return Routes.admin_customer_path(window.tenant, this.props.match.params.customerId, {format: 'json'})
  }

  url() {
    return this.state.id ? Routes.admin_customer_path(window.tenant, this.state.id) : Routes.admin_customers_path(window.tenant)
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  submit(e) {
    e.preventDefault()
    this.props.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    ).then(
      this.props.stopLoading()
    )
  }

  handleSubmitSuccess(response) {
    this.setStateAfterSave(response.data)
    this.props.setFlash(`Customer succesfully updated`)
    this.setMainTitle()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  handleChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  handleCheckboxChange(e) {
    this.setState({ sms_permission: e.target.checked })
  }

  handleRadioButtonChange(e) {
    this.setState({ gender: e.target.value })
  }

  renderGenderCheckboxes() {
    return (
      <div style={{ marginBottom: '5px' }}>
        <RadioGroup
            style={{ display: 'block' }}
            aria-label="gender"
            name="gender"
            value={this.state.gender}
            onChange={this.handleRadioButtonChange.bind(this)}
          >
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
        </RadioGroup>
      </div>
    )
  }

  handleGoogleAutocompleteError() {
    if (this.state.errors && this.state.errors['location.address']) {
      return (
        <p className="google-autocomplete-address-error">{this.state.errors['location.address'][0]}</p>
      )
    }
  }

  renderSMSsenderPermissionCheckbox() {
    return (
      <FormGroup style={{ width: '100%' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="sms-permission"
              checked={this.state.sms_permission}
              onChange={this.handleCheckboxChange.bind(this)}
            />
          }
          label="SMS permission"
        />
        <FormHelperText>{!this.state.sms_permission && "By unchecking this, system will delete this customer at night"}</FormHelperText>
      </FormGroup>
    )
  }

  renderCustomerReport() {
    return (
      <Grid item xs={12} sm={8} md={6} lg={6}>
        <CustomersSmsReceiversReport smsReceivers={this.state.smsReceivers} />
      </Grid>
    )
  }

  errorClass() {
    if (this.state.errors && this.state.errors['location.address']) {
      return "location-error"
    } else {
      return ""
    }
  }

  selectAddress(field, address, coordinates) {
    if (field === 'home_address') {
      this.setState({ location_attributes: {
          ...this.state.location_attributes, home_address: address, home_coordinates: coordinates
        }
      })
    } else {
      this.setState({ location_attributes: {
          ...this.state.location_attributes, address, coordinates,
          realocation_original_address: address, realocation_original_coordinates: coordinates
        }
      })
    }
  }

  handleAddressChange(field, getPlaces, e) {
    this.setState({ location_attributes: { ...this.state.location_attributes, [field]: e.target.value } }, debounce(getPlaces, 500))
  }

  handleSwitchChange(field, e) {
    this.setState({ location_attributes: { ...this.state.location_attributes, [`${field}_type`]: e.target.value } })
  }

  renderAddressAutocompleteInput(field) {
    const label = capitlize(field.split("_").join(" "))

    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ width: '100%', marginRight: 25 }}>
          <MaterialMapboxAutocomplete
            type={this.state.location_attributes[`${field}_type`]}
            handleChange={this.handleAddressChange.bind(this, field)}
            value={this.state.location_attributes[field]}
            selectAddress={this.selectAddress.bind(this, field)}
            accessToken={this.props.accessToken}
            label={label} />
          {this.handleGoogleAutocompleteError()}
        </div>
        <div>
          {this.renderSelectSwitch(field)}
        </div>
      </div>
    )
  }

  renderSelectSwitch(field) {
    return (
      <Select className="switch-select" label="Search Type" value={this.state.location_attributes[`${field}_type`] || ''} onChange={this.handleSwitchChange.bind(this, field)}>
        <MenuItem value="global">Global</MenuItem>
        <MenuItem value="local">Local</MenuItem>
      </Select>
    )
  }

  renderForm() {
    return (
      <div className='customer-form'>
        <FunTextField field="email" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="first_name" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="last_name" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="username" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="name" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="phone" state={this.state} handleChange={this.handleChange.bind(this)} />
        {!window.isHjemis && <FunTextField field="route_number" state={this.state} handleChange={this.handleChange.bind(this)} />}
        {this.renderAddressAutocompleteInput('address')}
        {!window.isHjemis && this.renderAddressAutocompleteInput('home_address')}
        {this.renderGenderCheckboxes()}
        {this.renderSMSsenderPermissionCheckbox()}
        <SaveButton onClick={this.submit.bind(this)} />
        <CancelButton to={Routes.admin_customers_path(window.tenant)} />
      </div>
    )
  }

  render() {
    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          {this.renderForm()}
        </Grid>
        {this.renderCustomerReport()}
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
    mainTitle: state.mainTitle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: mainTitle => dispatch(setMainTitle(mainTitle)),
    setFlash: flash => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const CustomersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersFormClass)

export default CustomersForm
