import React from 'react'
import { Link } from 'react-router-dom'

const buttonStyle = {
  position: 'absolute',
  right: 20,
  top: 20,
}

const NewButton = ({to, id, customStyle, className}) => {
  return (
    <Link id={id}
          className={`mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored ${className}`}
          to={to}
          style={{...buttonStyle, ...customStyle}}>
      <i className="material-icons">add</i>
    </Link>
  )
}

export default NewButton
