import React       from 'react'
import { connect } from 'react-redux'
import axios       from 'axios'

import Table       from '@material-ui/core/Table'
import TableBody   from '@material-ui/core/TableBody'
import TableCell   from '@material-ui/core/TableCell'
import TableHead   from '@material-ui/core/TableHead'
import TableRow    from '@material-ui/core/TableRow'
import { setMainTitle, startLoading, stopLoading, loadResources, setFlash } from '../../../files/actions/index'

import UsersTableHead from './UsersTableHead'
import UsersTableRow  from './UsersTableRow'
import NewButton      from '../../shared/NewButton'
import Button         from '@material-ui/core/Button'
import MultiSelect    from '../../shared/MultiSelect'

class UsersTableClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statuses: ['active', 'inactive'],
      filteredStatuses: ['active'],
    }
  }

  componentDidMount() {
    this.props.setMainTitle('Users')
    this.loadPage()
  }

  loadPage() {
    this.props.startLoading()

    axios.get(this.url()).then(res => {
      this.props.loadUsers(res.data)
    }).catch(error => {
      this.props.setFlash(`${error} (${this.url()})`)
    }).then(
      this.props.stopLoading()
    )
  }

  handleFilterChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  refilter() {
    this.loadPage()
  }

  url() {
    return window.Routes.admin_users_path(
      window.tenant,
      window.depoId,
      {
        format: 'json',
        statuses: this.state.filteredStatuses
      }
    )
  }

  renderTableBody() {
    return (
      <TableBody>
        {this.props.users.map((user, index) => {
          return <UsersTableRow user={user} key={index} />
        })}
      </TableBody>
    )
  }

  renderFilters() {
    return (
      <div className="users-filter float-left">
        <MultiSelect
          selectedValues={this.state.filteredStatuses}
          values={this.state.statuses}
          inputName='filteredStatuses'
          label='Status'
          selectClassName='status-filter'
          handleChange={this.handleFilterChange.bind(this)}
        />
        &nbsp;
        <Button variant="contained" color="primary" size="small" style={{verticalAlign: "bottom"}} onClick={this.refilter.bind(this)}>
          Filter
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div>
          <NewButton id='new-user' to={window.Routes.new_admin_users_path(window.tenant, window.depoId)} />
          {this.renderFilters()}
        </div>
        <Table className='resources-table'>
          <UsersTableHead />
          {this.renderTableBody()}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    users: state.resources
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    setFlash: flash => dispatch(setFlash(flash)),
    loadUsers: users => dispatch(loadResources(users)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const UsersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTableClass)

export default UsersTable
