const WEBSITE_LINKS = {
  'hjem-is': {
    title: 'Go to Hjem-IS website',
    url: 'http://hjem-is.dk'
  },
  'den-norske-isbilen': {
    title: 'Go to ISBILEN website',
    url: 'http://isbilen.no'
  }
}

export default WEBSITE_LINKS
