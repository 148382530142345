import React from 'react'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CancelIcon from '@material-ui/icons/Cancel'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ClearIcon from '@material-ui/icons/Clear'

class Option extends React.Component {
  handleClick(e) {
    this.props.onSelect(this.props.option, e)
  }

  render() {
    const { children, isSelected } = this.props

    return (
      <MenuItem
        onClick={this.handleClick.bind(this)}
        component="div"
        style={{ fontWeight: isSelected ? 'bold' : 'normal' }}>
        {children}
      </MenuItem>
    )
  }
}

const SelectWrapped = (props) => {
  const { classes, ...other } = props

  return (
    <Select
      optionComponent={Option}
      noResultsText={<Typography>{'No results found'}</Typography>}
      arrowRenderer={arrowProps => arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
      clearRenderer={() => <ClearIcon />}
      classes={classes}
      valueComponent={valueProps => {
        const { value, children, onRemove } = valueProps

        const onDelete = (event) => {
          event.preventDefault()
          event.stopPropagation()
          onRemove(value)
        }

        if (onRemove) {
          return (
            <Chip
              tabIndex={-1}
              label={children}
              className={classes.chip}
              deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
              onDelete={onDelete}
            />
          )
        }

        return <div className="Select-value">{children}</div>
      }}
      {...other}
    />
  )
}

class MultipleAutoCompleteSelect extends React.Component {
  state = { selectedValues: [] }

  handleChange(name, value) {
    this.setState({ [name]: value }, () => {
      this.state.selectedValues !== null ?
        this.props.onChange(this.props.type, this.state.selectedValues.map(value => value.value).join()) :
        this.props.onChange(this.props.type, this.state.selectedValues)
    })
  }

  render() {
    const { options, placeholder, label, additionalClasses } = this.props

    return (
      <div className={additionalClasses}>
        <TextField
          fullWidth
          value={this.state.selectedValues}
          onChange={this.handleChange.bind(this, 'selectedValues')}
          placeholder={placeholder}
          name="react-select-chip-label"
          label={label}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: SelectWrapped,
            inputProps: {
              isMulti: true,
              instanceId: 'react-select-chip-label',
              id: 'react-select-chip-label',
              simpleValue: true,
              options: options,
            },
          }}
        />
      </div>
    )
  }
}

export default MultipleAutoCompleteSelect
