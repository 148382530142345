import React from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import axios from 'axios'
import More from '@material-ui/icons/More'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DataGrid from '../../shared/DataGrid'
import FlashNotification from '../../shared/FlashNotification'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import BackButtons from '../shared/BackButtons'
import WarehouseSwitcher from '../shared/WarehouseSwitcher'
import PrintableTable from '../shared/PrintableTable'

class StockOrder extends React.Component {
  state = { stock_order: {}, warehouse: {}, edit: false, rows: [], message: null, loading: false }

  componentDidMount() {
    this.fetchWarehouse()
    this.fetchStockOrder()
  }

  fetchStockOrder() {
    this.startLoading()
    axios.get(
      Routes.inventory_warehouse_stock_order_path(tenant, depoId, this.warehouseId(), this.stockOrderId(), { format: 'json' })
    ).then(res => {
      this.setState({ stock_order: res.data, rows: res.data.stock_order_line_items })
      this.stopLoading()
      this.setStockTransferMessage()
    }).catch(err => {
      this.setState({ message: `${err}` })
    })
  }

  fetchWarehouse() {
    this.startLoading()
    axios.get(Routes.inventory_warehouse_path(tenant, depoId, this.warehouseId(), { format: 'json' })).then(res => {
      this.setState({ warehouse: res.data })
      this.stopLoading()
    }).catch(err => {
      this.setState({ message: `${err}` })
    })
  }

  startLoading = () => this.setState({ loading: true })

  stopLoading = () => this.setState({ loading: false })

  stockOrderId() {
    return this.props.match.params.stockOrderId
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading}/>
  }

  renderBackButtons() {
    return (
      <BackButtons warehouseId={this.warehouseId()} parent="orders"/>
    )
  }

  setStockTransferMessage() {
    const message = this.props.location.state && this.props.location.state.transferSuccessMessage
    if (message) {
      this.setState({ message }, () => {
        this.props.history.replace({ pathname: this.props.location.pathname, state: {} })
      })
    }
  }

  renderMainTitle() {
    if (!this.state.warehouse) return
    const { warehouse: { owner, owner_type } } = this.state
    return <Typography variant="h5">Warehouse - {owner} ({owner_type}) </Typography>
  }

  renderOrderHeader() {
    const {
      stock_order: {
        id, ordered_at, created_by, from_warehouse, to_warehouse,
        kind, main_storage_message
      }
    } = this.state

    return (
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {this.renderGridItem(12, 8, 6, 6, 'Order Nr:', id)}
        {this.renderGridItem(12, 8, 6, 6, 'Ordered at:', ordered_at)}
        {this.renderGridItem(12, 8, 6, 6, 'Ordered by:', created_by)}
        {this.renderGridItem(12, 8, 6, 6, 'From warehouse:', from_warehouse && from_warehouse.name)}
        {this.renderGridItem(12, 8, 6, 6, 'To warehouse:', to_warehouse && to_warehouse.name)}
        {this.renderGridItem(12, 8, 6, 6, 'To warehouse:', to_warehouse && to_warehouse.name)}
        {this.renderGridItem(12, 8, 6, 6, 'Main storage message:', main_storage_message)}
        {this.renderGridItem(12, 8, 6, 6, 'Kind:', kind && kind.toUpperCase())}
        {this.renderStockTransferData()}
      </Grid>
    )
  }

  renderGridItem(xs, sm, md, lg, title, value) {
    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <strong>{title}</strong>  {value}
      </Grid>
    )
  }

  renderStockTransferData() {
    const { stock_order: { stock_transfer_id, stock_transfer_created_at } } = this.state

    if (stock_transfer_id) {
      return (
        <React.Fragment>
          {this.renderGridItem(12, 12, 12, 12, 'Stock transfer:',
            <Link to={Routes.inventory_warehouse_stock_transfer_path(tenant, depoId, this.warehouseId(), stock_transfer_id)}>
              #{stock_transfer_id}
            </Link>
          )}
          {this.renderGridItem(12, 12, 12, 12, 'Approved at:', stock_transfer_created_at)}
        </React.Fragment>
      )
    }
  }

  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>SKU</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Packages (amount)</TableCell>
          <TableCell>Received amount</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderTableBody() {
    const { stock_order } = this.state
    if (!this.state.stock_order.stock_order_line_items) return
    return (
      <TableBody>
        {stock_order.stock_order_line_items.map(({ sku, description, amount, received_amount, packaged_amount }, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{sku}</TableCell>
              <TableCell>{description}</TableCell>
              <TableCell>{`${packaged_amount} (${amount})`}</TableCell>
              <TableCell>{stock_order.status === 'received' ? received_amount : 0}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  url() {
    return Routes.inventory_warehouse_stock_transfers_path(tenant, depoId, this.warehouseId(), { format: 'json' })
  }

  transferLineItemData() {
    return this.state.rows.map(r => { return { product_id: r.product_id, amount: r.received_amount, stock_order_line_item_id: r.id } })
  }

  data() {
    const { stock_order: { ordered_at, to_warehouse, from_warehouse, id, main_storage_message, kind } } = this.state
    return {
      stock_transfer: {
        stock_transfer_line_items_attributes: this.transferLineItemData(),
        to_warehouse_id: to_warehouse && to_warehouse.id,
        from_warehouse_id: from_warehouse && from_warehouse.id,
        stock_order_id: id,
        stock_order_attributes: { id: id, status: "received" },
        main_storage_message: main_storage_message,
        kind: kind
      },
      format: 'json',
    }
  }

  handleSubmitSuccess(response) {
    this.fetchStockOrder()
    this.setState({ message: "Stock Transfer succesfuly created" })
    this.stopLoading()
  }

  handleSubmitError(error) {
    this.setState({ message: error.response.data.full_errors && `${error.response.data.full_errors.join(', ')}` })
    this.stopLoading()
  }

  handleSubmit() {
    this.startLoading()
    axios({
      method: 'post',
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    )
  }

  handleDeletement() {
    this.startLoading()
    axios.delete(
      Routes.inventory_warehouse_stock_order_path(tenant, depoId, this.warehouseId(), this.state.stock_order.id, { format: 'json' })
    ).then(res => {
      this.stopLoading()
      this.props.history.push(Routes.show_inventory_warehouse_path(tenant, depoId, this.warehouseId(), 'orders'))
    }).catch(err => {
      this.setState({ message: `${err}` })
    })
  }

  renderPrintMarkupWithButton() {
    if (Object.keys(this.state.stock_order).length === 0) return

    const { stock_order: { stock_order_line_items, from_warehouse,
        to_warehouse, created_at, created_by, ordered_at
      }
    } = this.state

    const header = {
      documentTitle: `order-document-${created_at}`,
      fromWarehouse: `From: ${from_warehouse && from_warehouse.owner} (${from_warehouse && from_warehouse.owner_type})`,
      toWarehouse: `To: ${to_warehouse.owner} (${to_warehouse.owner_type})`,
      createdBy: `Ordered by: ${created_by}`,
      createdAt: `Ordered at: ${ordered_at}`
    }

    return (
      <PrintableTable
        newWithCorrection={true}
        items={stock_order_line_items.map(({ amount, new_amount, description, sku }) => ({ amount, new_amount, description, sku }))}
        header={header} />
    )
  }

  onGridRowsUpdated({ fromRow, toRow, updated }) {
    this.setState(state => {
      const rows = state.rows.slice()
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated }
      }
      return { rows }
    })
  }

  returnGridColumns() {
    return [
      { key: "id", name: "ID", editable: false },
      { key: "sku", name: "Sku", editable: false },
      { key: "description", name: "Description", editable: false },
      { key: "amount", name: "Order amount", editable: false },
      { key: "received_amount", name: "Received amount", editable: true },
    ]
  }

  renderTableOrEdit() {
    if (this.state.edit) {
      return <DataGrid
        onGridRowsUpdated={this.onGridRowsUpdated.bind(this)}
        columns={this.returnGridColumns()}
        rows={this.state.rows}
      />
    } else {
      return (
        <Table>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      )
    }
  }

  renderEditButton() {
    if (this.state.stock_order.status === 'received') return

    if (this.state.stock_order.id) {
      return (
        <Link to={Routes.edit_inventory_warehouse_stock_orders_path(tenant, depoId, this.warehouseId(), this.state.stock_order.id)}>
          <Button variant="contained" color="primary">Edit</Button>
        </Link>
      )
    }
  }

  renderApproveButton() {
    if (this.state.stock_order.status === 'received') return

    if (this.state.stock_order.id) {
      return (
        <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this)}>Approve</Button>
      )
    }
  }

  renderDeleteButton() {
    if (this.state.stock_order.status === 'received') return

    return (
      <Button className="mr-3" variant="contained" color="secondary" onClick={this.handleDeletement.bind(this)}>
        Delete
      </Button>
    )
  }

  renderExportButton() {
    const { stock_order: { id, status, from_warehouse, to_warehouse } } = this.state
    if (!id) return
    const path = Routes.export_order_document_inventory_warehouse_stock_order_path(
      tenant, depoId, this.warehouseId(), id, { format: 'xlsx' }
    )

    return (
      <Link style={{ textDecoration: "none" }} to={path} target="_blank" >
        <Button className="xlsx-export" variant="contained" color="primary">Export</Button>
      </Link>
    )
  }

  clearErrors() {
    this.setState({ full_errors: null, errors: null })
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderHeader() {
    const { stock_order: { status } } = this.state
    return (
      <React.Fragment>
        <div className="content-header sticky-header">
          <div className="content-header-title mt-3">
            {this.renderBackButtons()}
            {this.renderMainTitle()}
            <div className={`order-status-circle ${status}`} />
          </div>
          <div className="action-buttons mt-3">
            <WarehouseSwitcher currentWarehouse={this.warehouseId()} history={this.props.history} />
            {this.renderApproveButton()}
            {this.renderExportButton()}
            {this.renderPrintMarkupWithButton()}
            {this.renderEditButton()}
            {this.renderDeleteButton()}
          </div>
          <div className="content-subheader">
            <div className="content-subheader-main">
              {this.renderOrderHeader()}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderTableOrEdit()}
        {this.renderFlashNotification()}
        {this.renderLoading()}
      </div>
    )
  }
}

export default StockOrder
