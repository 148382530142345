import React from 'react'
import ErrorCatcher from '../../shared/ErrorCatcher'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import B2bTransactionsTable from './list'

const AdminB2bOrdersIndex = () => {
  return (
    <ErrorCatcher>
      <BrowserRouter>
        <Switch>
          <Route path='/:tenant/admin/b2b_orders' component={B2bTransactionsTable} />
        </Switch>
      </BrowserRouter>
    </ErrorCatcher>
  )
}

export default AdminB2bOrdersIndex
