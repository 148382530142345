const Product = () => {
  return {
    sku: '',
    description: '',
    sequence_number: '',
    packaging_size: '',
    minimum_required_balances: [],
    status: 'active',
    sales_price: '0.0',
    storage_unit: 'stk',
    tax_amount: '',
    tax_type: '',
    volume: '',
    b2b_product: false,
    errors: {},
  }
}

export default Product
