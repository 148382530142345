import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  row: { height: 32 },
  cell: { padding: 4 },
  wait: { width: 32 },
}

class RoutePointsTableHead extends React.Component {
  state = { checked: false }

  handleRouteActions() {
    this.setState({ checked: !this.state.checked }, () => this.props.checkAll(this.state.checked))
  }

  renderWaitTimeHeadCell() {
    const { routeType, classes } = this.props

    if (routeType === 'custom_stops') {
      return (
        <TableCell className={[classes.cell, classes.wait].join(' ')}>Wait time</TableCell>
      )
    }
  }

  renderAddHeadCell() {
    const { classes } = this.props
    if (isHjemis || isIsbilen) {
      return <TableCell className={classes.cell}></TableCell>
    }
  }

  render() {
    const { classes } = this.props

    return (
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell style={{ padding: 0 }} className={classes.cell}>
            <Checkbox
              style={{width: 32, height: 32, padding: 0}}
              checked={this.state.checked}
              onChange={this.handleRouteActions.bind(this)}
              value="checked"
            />
          </TableCell>
          <TableCell className={classes.cell}>#</TableCell>
          <TableCell className={classes.cell}></TableCell>
          <TableCell className={classes.cell}></TableCell>
          <TableCell className={classes.cell}>Address</TableCell>
          {this.renderWaitTimeHeadCell()}
          <TableCell className={classes.cell}>Private com.</TableCell>
          <TableCell className={classes.cell}>Est.</TableCell>
          {this.renderAddHeadCell()}
          <TableCell className={classes.cell}></TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

export default withStyles(styles)(RoutePointsTableHead)
