const SmsCampaign = () => {
  return {
    id: '',
    name: '',
    text: '',
    start_date: '',
    end_date: '',
    include_subscribe_info: false,
    errors: {},
  }
}

export default SmsCampaign
