import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import NewButton from '../../shared/NewButton'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Assignment from '@material-ui/icons/Assignment'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FlashNotification from '../../shared/FlashNotification'
import MaterialDatePicker from '../../shared/MaterialDatePicker'
import MultipleAutoCompleteSelect from '../shared/MultipleAutoCompleteSelect'
import moment from 'moment'

class WarehouseOrdersTable extends React.Component {
  state = {
    orders: [],
    warehouses: [],
    toWarehouses: "",
    fromWarehouses: "",
    orderDirection: "DESC",
    orderKey: "ordered_at",
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    loading: false,
    paginationDisabled: false,
    message: null,
    currentPage: 1
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.startLoading()
    axios.all([
      axios.get(this.ordersUrl(), this.ordersParams()),
      axios.get(this.warehousesUrl())
    ]).catch(error => {
      this.setState({ message: `${error}` })
      this.stopLoading()
    }).then(axios.spread((orders, warehouses) => {
      this.setState({
        orders: orders.data,
        warehouses: warehouses.data.map(w => ({ value: w.id, label: w.title })),
        loading: false
      })
    }))
  }

  loadOrders() {
    this.startLoading()
    axios.get(this.ordersUrl(), this.ordersParams()).then(res => {
      this.setState({ orders: res.data, loading: false, paginationDisabled: false })
    }).catch(error => {
      this.setState({ message: `${error}` })
    })
  }

  ordersUrl() {
    return Routes.inventory_warehouse_orders_path(tenant, depoId, { format: 'json' })
  }

  warehousesUrl() {
    return Routes.owners_list_inventory_warehouses_path(tenant, depoId, { format: 'json' })
  }

  writeoffUrl() {
    return Routes.writeoff_exports_path(tenant, depoId, 'xlsx', this.writeoffParams())
  }

  writeoffParams() {
    const { fromWarehouses, startDate, endDate } = this.state
    return {
      from_warehouses: fromWarehouses,
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD")
    }
  }

  ordersParams() {
    const { fromWarehouses, currentPage, toWarehouses, startDate, endDate, orderKey, orderDirection } = this.state
    return {
      params: {
        page: currentPage,
        from_warehouses: fromWarehouses,
        to_warehouses: toWarehouses,
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
        order_key: orderKey,
        order_direction: orderDirection
      }
    }
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  clearErrors() {
    this.setState({ message: null })
  }

  reOrderTable(orderKey) {
    if (this.state.loading) return
    const orderDirection = this.state.orderDirection == "ASC" ? "DESC" : "ASC"
    this.setState({ orderKey, orderDirection }, this.loadOrders )
  }

  onChange(type, data) {
    this.setState({ [type]: data })
  }

  dateChange(fieldName, date) {
    this.setState({ [fieldName]: moment(date) })
  }

  changePage(pageIncrement) {
    const { orders, currentPage } = this.state
    if (orders.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, currentPage + pageIncrement))
    if (newPage === currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadOrders)
  }

  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell onClick={this.reOrderTable.bind(this, "from_warehouse_id")}>
            Ordered From {this.renderOrderIcon("from_warehouse_id")}
          </TableCell>
          <TableCell onClick={this.reOrderTable.bind(this, "to_warehouse_id")}>
            Ordered To {this.renderOrderIcon("to_warehouse_id")}
          </TableCell>
          <TableCell onClick={this.reOrderTable.bind(this, "ordered_at")}>
            Ordered at {this.renderOrderIcon("ordered_at")}
          </TableCell>
          <TableCell>Message</TableCell>
          <TableCell>Created by</TableCell>
          <TableCell>Order</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderOrderIcon(key) {
    const { orderKey, orderDirection } = this.state
    if (orderKey === key) {
      return orderDirection == "ASC" ? <KeyboardArrowUp /> : <KeyboardArrowDown />
    }
  }

  renderTableBody() {
    const { orders } = this.state
    return (
      <TableBody>
        {orders.map((order, index) => {
          return (
            <TableRow className={order.status} key={order.id}>
              <TableCell>{order.from_warehouse && order.from_warehouse.name}</TableCell>
              <TableCell>{order.to_warehouse.name}</TableCell>
              <TableCell>{order.ordered_at}</TableCell>
              <TableCell>{order.main_storage_message}</TableCell>
              <TableCell>{order.created_by}</TableCell>
              <TableCell>
               {this.renderWarehouseOrdersLink(order.to_depo_id, order.to_warehouse, order.id)}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  renderWarehouseOrdersLink(toDepoId, toWarehouse, orderId) {
    return (
      <Link
        target="_blank"
        className="mdl-button mdl-js-button mdl-button--icon"
        to={Routes.inventory_warehouse_stock_order_path(tenant, toDepoId, toWarehouse, orderId)}>
        <Assignment />
      </Link>
    )
  }

  renderDatePicker(fieldName, label) {
    return (
      <MaterialDatePicker
        key={`${fieldName}-${this.state[fieldName].format()}`}
        value={this.state[fieldName] && this.state[fieldName].format('DD-MM-YYYY')}
        position="right-position"
        fieldName={fieldName}
        label={label}
        onDateChange={this.dateChange.bind(this, fieldName)} />
    )
  }

  renderTablePagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className="pagination float-right">
        <strong>Page: {currentPage}</strong>
        &nbsp;
        <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }

  renderFilters() {
    return (
      <div className="warehouse-orders-filters">
        <div className="multiple-select">
          {this.renderMultipleAutoCompleteSelect("From warehouse", "fromWarehouses", "Select multiple From warehouses")}
          {this.renderMultipleAutoCompleteSelect("To warehouse", "toWarehouses", "Select multiple To warehouses")}
        </div>
        <div className="date-select">
          <div>
            {this.renderDatePicker("startDate", "Start date")}
            {this.renderDatePicker("endDate", "End date")}
            {this.renderFilterButton()}
            {isIsbilen && this.renderWriteoffButton()}
          </div>
          {this.renderTablePagination()}
        </div>
      </div>
    )
  }

  renderMultipleAutoCompleteSelect(label, type, placeholder) {
    const { warehouses } = this.state
    return (
      <MultipleAutoCompleteSelect
        onChange={this.onChange.bind(this)}
        additionalClasses="mb-3 w-50 ml-1 mr-1"
        label={label}
        type={type}
        placeholder={placeholder}
        options={warehouses} />
    )
  }

  renderFilterButton() {
    return (
      <Button variant="contained" color="primary" size="small" style={{verticalAlign: "bottom"}} onClick={this.loadOrders.bind(this)}>
        Filter
      </Button>
    )
  }

  renderWriteoffButton() {
    const classNames = "mdl-button mdl-js-button mdl-button--raised mdl-button--colored mdl-small"
    return (
      <a data-sales target="_self" href={this.writeoffUrl()} className={classNames} style={{ marginLeft: 10, fontSize: 12 }}>
        Write off export
      </a>
    )
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  render() {
    return (
      <div>
        {this.renderFlashNotification()}
        {this.renderFilters()}
        <Table className="warehouse-orders-table">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default WarehouseOrdersTable
