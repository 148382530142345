import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { setMainTitle, startLoading, stopLoading, loadResources, setFlash } from '../../files/actions/index'
import PlanEventsTableHead from './PlanEventsTableHead'
import PlanEventsTableRow from './PlanEventsTableRow'
import TitleContainer from '../shared/TitleContainer'
import NewButton from '../shared/NewButton'

class PlanEventsTableClass extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.setMainTitle('Plan events')
    this.props.startLoading()

    axios.get(this.url()).then(res => {
      this.props.loadPlanEvents(res.data)
    }).catch(error => {
      this.props.setFlash(`${error} (${this.url()})`)
    }).then(
      this.props.stopLoading
    )
  }

  url() {
    return Routes.plan_events_path(window.tenant, window.depoId, {format: 'json'})
  }

  renderTableBody() {
    return (
      <TableBody>
        {this.props.planEvents.map((planEvent, index) => {
          return <PlanEventsTableRow planEvent={planEvent} key={index} history={this.props.history} />
        })}
      </TableBody>
    )
  }

  render() {
    return (
      <TitleContainer>
        <div>
          <NewButton to={Routes.new_plan_events_path(window.tenant, window.depoId)} />
          <Table className='resources-table'>
            <PlanEventsTableHead />
            {this.renderTableBody()}
          </Table>
        </div>
      </TitleContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    planEvents: state.resources
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    setFlash: flash => dispatch(setFlash(flash)),
    loadPlanEvents: planEvents => dispatch(loadResources(planEvents)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEventsTableClass)

export default PlanEventsTable
