import React from 'react'
import axios from 'axios'
import More from '@material-ui/icons/More'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ModeEdit from '@material-ui/icons/Edit'
import FunTextField from '../../forms/FunTextField'
import Check from '@material-ui/icons/Check'
import extractDataErrors from '../../support/extractDataErrors'

class ProductTableRow extends React.Component {
  state = { editMode: false, product: { ...this.props.product, errors: {} } }

  openEditMode() {
    this.setState({ editMode: true })
  }

  submit() {
    const { product } = this.state
    axios.put(
      Routes.inventory_product_path(window.tenant, window.depoId, this.state.product.id, { format: 'json' }),
      {
        product: {
          ...product,
          minimum_required_balances_attributes: [{
            id: product.minimum_id, minimum_for_depo: product.minimum_for_depo, minimum_for_cars: product.minimum_for_cars
          }]
        }
      }
    ).then(res => {
      if (res.status === 200) {
        this.setState({ editMode: false }, () => this.props.openMessage(`Product with sku: ${res.data.sku} has been updated`))
      }
    }).catch(error => {
      const clonedProduct = Object.assign({}, this.state.product)
      clonedProduct.errors = extractDataErrors(error) && extractDataErrors(error).errors
      this.setState({ product: clonedProduct })
      this.props.openMessage(error.response.data.error)
    })
  }

  handleChange(e, field) {
    const clonedProduct = Object.assign({}, this.state.product)
    clonedProduct[field] = e.target.value
    this.setState({ product: clonedProduct })
  }

  renderEditOrShow(field) {
    if (this.state.editMode) {
      const editable = this.state.product[`${field}_editable`]
      return (
        <FunTextField
          style={{ marginTop: 0, marginBottom: 0 }}
          state={this.state.product}
          field={field}
          disabled={editable === false}
          label=""
          handleChange={this.handleChange.bind(this)} />
      )
    } else {
      return this.state.product[field]
    }
  }

  renderEditOrSuccess() {
    const { editMode } = this.state
    const icon    = editMode ? <Check className="submit-icon"/> : <ModeEdit className="edit-icon" />
    const onClick = editMode ? this.submit.bind(this) : this.openEditMode.bind(this)
    return this.renderIconButton(icon, false, onClick)
  }

  renderIconButton(icon, disabled = false, onClick = false) {
    return (
      <IconButton disabled={disabled} onClick={onClick}>
        {icon}
      </IconButton>
    )
  }

  renderActions(productId) {
    return (
      <div style={{ display: 'flex' }}>
        {this.renderEditOrSuccess()}
        <Link to={Routes.edit_inventory_products_path(window.tenant, window.depoId, productId)}>
          <IconButton>
            <More />
          </IconButton>
        </Link>
      </div>
    )
  }

  renderB2bLabel() {
    if (isHjemis && this.state.product.b2b_product) {
      return <span className="tag-label b2b-label">B2B</span>
    }
  }

  render() {
    const { product } = this.state
    return (
      <TableRow>
        <TableCell style={{ width: 75 }}>{product.id} {this.renderB2bLabel()}</TableCell>
        <TableCell>{this.renderEditOrShow("sku")}</TableCell>
        <TableCell>{this.renderEditOrShow("description")}</TableCell>
        <TableCell>{this.renderEditOrShow("sequence_number")}</TableCell>
        <TableCell>{this.renderEditOrShow("packaging_size")}</TableCell>
        <TableCell>{this.renderEditOrShow("sales_price") || '-'}</TableCell>
        <TableCell>{this.renderEditOrShow("minimum_for_depo")}</TableCell>
        <TableCell>{this.renderEditOrShow("minimum_for_cars")}</TableCell>
        <TableCell>{this.renderActions(product.id)}</TableCell>
      </TableRow>
    )
  }
}

export default ProductTableRow
