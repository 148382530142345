const arrayMove = (array, fromIndex, toIndex, movingIndexes) => {
  let newArray = array.slice(0)
  let element
  if (movingIndexes) {
    const sortedMovingIndexes = movingIndexes.sort((a, b) => a - b)
    newArray = array.filter(point => !point.checked)
    const indexesAboveToIndex = sortedMovingIndexes.filter(i => i < toIndex)
    sortedMovingIndexes.forEach((index, i) => {
      element = array[index]
      if (fromIndex > toIndex) {
        newArray.splice((toIndex - indexesAboveToIndex.length) + i, 0, element)
      } else {
        newArray.splice((toIndex - indexesAboveToIndex.length + 1) + i, 0, element)
      }
    })
  } else {
    element = array[fromIndex]
    newArray.splice(fromIndex, 1)
    newArray.splice(toIndex, 0, element)
  }
  return newArray
}

export default arrayMove
