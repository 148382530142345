const requestPerformer = (request, options = { includeRole: false }) => {
  if (!request.user) return 'Unregistered user'

  const { first_name, last_name, email, role } = request.user

  let userTitle
  if (first_name && last_name) {
    userTitle = `${first_name} ${last_name}`
  } else {
    userTitle = email
  }

  if (options.includeRole) {
    userTitle = `${userTitle} (${role})`
  }

  return userTitle
}

export { requestPerformer }
