import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import UserHttpRequestShow from './UserHttpRequestShow'
import UserHttpRequestsTable from './UserHttpRequestsTable'

const UserHttpRequestsComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/admin/user_http_requests/:userHttpRequestId' component={UserHttpRequestShow} />
        <Route path='/:tenant/admin/user_http_requests' component={UserHttpRequestsTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default UserHttpRequestsComponent
