import React from 'react'
import Button from '@material-ui/core/Button'
import MaterialDatePicker from '../shared/MaterialDatePicker'
import moment from 'moment'
import startCase from 'lodash/startCase'

class DateRangeFilter extends React.Component {
  dateChange(fieldName, date) {
    this.props.handleChange(fieldName, moment(date).format(dateFormat))
  }

  componentDidUpdate() {
    const { startDate, endDate } = this.props
    if (moment(startDate, dateTimeFormat).diff(moment(endDate, dateTimeFormat), 'days') > 0) {
      this.props.handleChange('endDate', startDate)
    }
  }

  renderDatePicker(fieldName, disableBeforeDate) {
    return (
      <MaterialDatePicker
        key={`${fieldName}-${this.props[fieldName]}`}
        fieldName={fieldName} label={startCase(fieldName)}
        value={this.props[fieldName]}
        disableBeforeDate={this.props[disableBeforeDate]}
        onDateChange={this.dateChange.bind(this, fieldName)} />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDatePicker('startDate')}
        {this.renderDatePicker('endDate', 'startDate')}
      </React.Fragment>
    )
  }
}

export default DateRangeFilter
