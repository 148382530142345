import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import AdminCarsIndex from './AdminCarsIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const AdminCarsIndexWithStore = () => {
  return  (
    <Provider store={store}>
      <ErrorCatcher>
        <AdminCarsIndex />
      </ErrorCatcher>
    </Provider>
  )
}

export default AdminCarsIndexWithStore
