import React from 'react'

import EndOfDayReportsComponent from './EndOfDayReportsComponent'
import TitleContainer from '../shared/TitleContainer'

const EndOfDayReporsIndex = () => {
  return (
    <TitleContainer>
      <EndOfDayReportsComponent />
    </TitleContainer>
  )
}

export default EndOfDayReporsIndex
