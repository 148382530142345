import React from 'react'
import { setFlash } from '../../files/actions/index'

class ErrorCatcher extends React.Component {
  state = { hasError: false, error: null }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error })

    window.Sentry.captureException(error, {
      extra: {
        info: info,
        session: window.session,
      }
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert alert-danger">
          Unexpected script error: {`${this.state.error}`}
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorCatcher
