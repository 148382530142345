import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const CustomersTableHeading = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Client Address</TableCell>
        <TableCell>Username</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>First Name</TableCell>
        <TableCell>Last Name</TableCell>
        <TableCell>Phone</TableCell>
        <TableCell>Gender</TableCell>
        <TableCell>SMS permission</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(CustomersTableHeading)
