const Customer = () => {
  return {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    name: '',
    username: '',
    phone: '',
    gender: '',
    address: '',
    sms_permission: false,
    route_number: '',
    location_attributes: { address_type: "global", home_address_type: "global" },
    errors: {}
  }
}

export default Customer
