import React from 'react'
import TimePicker from 'rc-time-picker'
import Close from '@material-ui/icons/Close'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import moment from 'moment'

import 'react-day-picker/lib/style.css'
import 'rc-time-picker/assets/index.css'

function OverlayComponent({ classNames, selectedDay, children, ...props }) {
  const { field } = this.props
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        <Close
          style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }}
          onClick={(e) => this.dayPicker.hideDayPicker()} />
        <div className="time-input">
          <TimePicker
            defaultValue={moment(this.state[field], dateTimeFormat)}
            showSecond={false}
            minuteStep={1}
            onChange={this.onTimeChange.bind(this, field)} />
        </div>
        { children }
      </div>
    </div>
  )
}

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = { [props.field]: props.value }
  }

  onDateChange(field, inputDate) {
    const date = moment(inputDate, dateTimeFormat).format(dateFormat)
    const time = moment(this.state[field], dateTimeFormat).format(timeFormat)
    this.updateDateTime(date, time, field)
  }

  onTimeChange(field, inputTime) {
    const date = moment(this.state[field], dateTimeFormat).format(dateFormat)
    const time = moment(inputTime ? inputTime : this.state[field], dateTimeFormat).format(timeFormat)
    this.updateDateTime(date, time, field)
  }

  updateDateTime(date, time, field) {
    const dateTime = moment(`${date} ${time}`, dateTimeFormat).format(dateTimeFormat)
    this.setState({ [field]: dateTime }, () => {
      this.props.handleChange(field, dateTime)
    })
  }

  formatDate(field, inputDate) {
    const date = moment(inputDate, dateTimeFormat).format(dateFormat)
    const time = moment(this.state[field], dateTimeFormat).format(timeFormat)
    return moment(`${date} ${time}`, dateTimeFormat).format(dateTimeFormat)
  }

  render() {
    const { field, className } = this.props
    return (
      <div className={`DayPicker-element ${className}`}>
        <div className='DayPicker-element'>
          <DayPickerInput ref={el => this.dayPicker = el}
                          inputProps={{ className: 'DayPicker-element' }}
                          placeholder={dateTimeFormat}
                          formatDate={date => this.formatDate(field, date)}
                          keepFocus={false}
                          parseDate={parseDate}
                          format={dateTimeFormat}
                          onDayChange={this.onDateChange.bind(this, field)}
                          value={moment(this.state[field], dateTimeFormat).format(dateTimeFormat)}
                          dayPickerProps={{ firstDayOfWeek: 1 }}
                          overlayComponent={OverlayComponent.bind(this)} />
        </div>
      </div>
    )
  }
}

export default DateTimePicker
