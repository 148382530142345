import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import PlanEventTemplatesTable from './PlanEventTemplatesTable'
import PlanEventTemplatesCalendarControls from './PlanEventTemplatesCalendarControls'
import TitleContainer from '../shared/TitleContainer'
import queryString from 'query-string'

import {
  setMainTitle,
  loadResources,
  loadCars,
  loadUsers,
  loadRoutes,
  setFlash,
  startLoading,
  stopLoading,
  loadPlanEventsForCalendar,
} from '../../files/actions/index'

class PlanEventTemplatesIndexClass extends React.Component {
  constructor(props) {
    super(props)
    const week = props.match.params.week
    const splittedWeek = week && week.split('-')

    this.state = {
      year: parseInt(splittedWeek && splittedWeek[0]) || Number(moment().format("YYYY")),
      week: parseInt(splittedWeek && splittedWeek[1]) || this.correctWeek()
    }
  }

  correctWeek() {
    const weekCountInYear = moment().isoWeeksInYear()
    const lastYearWeekCount = moment().set('year', moment().year() - 1).isoWeeksInYear()
    let week = moment().week()

    if (lastYearWeekCount > weekCountInYear && moment().isoWeek() === lastYearWeekCount) {
      if (week > 52) {
        week = 1
      } else {
        week += 1
      }
    }
    return week
  }

  componentDidMount() {
    this.props.setMainTitle('Calendar')
    this.loadData()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const previousValues = this.props.match.params.week
    const currentValues = props.match.params.week
    if (previousValues !== currentValues && currentValues) {
      const splittedWeek = currentValues.split('-')
      let weekToSet = parseInt(splittedWeek[1])
      let yearToSet = parseInt(splittedWeek[0])

      this.setState({
        week: weekToSet,
        year: yearToSet,
      }, this.loadCarsAndPlanEvents.bind(this))
    }
  }

  loadCarsAndPlanEvents() {
    this.props.startLoading()
    const { year, week } = this.state
    axios.get(this.carsUrl()).then(res => {
      this.props.loadCars(res.data)
      this.loadPlanEvents()
    }).catch(error => {
      this.props.setFlash(`${error} (${error.url})`)
    })
  }

  loadData() {
    this.props.startLoading()
    axios.all([
      axios.request({ method: 'GET', url: this.carsUrl() }),
      axios.request({ method: 'GET', url: this.usersUrl() }),
      axios.request({ method: 'GET', url: this.routesUrl() }),
    ]).catch(error => {
      this.props.setFlash(`${error} (${error.url})`)
    }).then(axios.spread((carsRes, usersRes, routesRes) => {
      this.props.loadCars(carsRes && carsRes.data)
      this.loadUsers(usersRes)
      this.loadRoutes(routesRes)
      this.loadPlanEvents()
    })).catch(error => {
      this.props.setFlash(`${error} (${error.url})`)
    })
  }

  loadUsers({ data }) {
    this.props.loadUsers(
      data.filter(({status}) => status == 'active').map(
        ({ id, first_name, last_name, email }) => ({ value: id, label: first_name && `${first_name} ${last_name}` ||  email })
      )
    )
  }

  loadRoutes({ data }) {
    this.props.loadRoutes(
      data.filter(({deleted_at}) => !deleted_at).map(route => ({ value: route.id, label: route.name }))
    )
  }

  loadPlanEvents() {
    this.props.startLoading()

    axios.get(
      this.planEventsUrl()
    ).catch(error => {
      this.props.setFlash(`${error} (${error && error.url})`)
    }).then(planEventsRes => {
      this.props.loadPlanEventsForCalendar(planEventsRes && planEventsRes.data)
      this.props.stopLoading()
    }).catch(error => {
      this.props.setFlash(`${error} (${error && error.url})`)
    })
  }

  carsUrl() {
    return Routes.filter_admin_cars_path(window.tenant, window.depoId, 'car_calendar', 'active', { week: `${this.state.year}-${this.state.week}`, format: 'json' })
  }

  usersUrl() {
    return Routes.admin_users_path(window.tenant, window.depoId, { format: 'json' })
  }

  routesUrl() {
    return Routes.routes_path(window.tenant, window.depoId, { for_collection: 1, format: 'json' })
  }

  planEventsUrl() {
    return Routes.filter_plan_events_path(
      window.tenant, window.depoId, 'plan_event_calendar', { week: `${this.state.year}-${this.state.week}`, format: 'json' }
    )
  }

  render () {
    const { week, year } = this.state
    return (
      <TitleContainer>
        <PlanEventTemplatesCalendarControls
          week={week}
          year={year}
        />
        <PlanEventTemplatesTable week={week} year={year} />
      </TitleContainer>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    setFlash: flash => dispatch(setFlash(flash)),
    loadCars: cars => dispatch(loadCars(cars)),
    loadRoutes: routes => dispatch(loadRoutes(routes)),
    loadUsers: users => dispatch(loadUsers(users)),
    loadPlanEventTemplates: planEventTemplates => dispatch(loadResources(planEventTemplates)),
    loadPlanEventsForCalendar: planEvents => dispatch(loadPlanEventsForCalendar(planEvents)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventTemplatesIndex = connect(
  null,
  mapDispatchToProps
)(PlanEventTemplatesIndexClass)

export default PlanEventTemplatesIndex
