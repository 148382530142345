import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

const GoogleAutocompleteInput = (props) => {
  const { onAddressChange, children, address, onBlur, onFocus, additionalClasses, placeholder, id, onEnter, disabled } = props

  const onKeyDown = (e) => {
    const key = e.keyCode

    // Up, Down, Left, Right and Shift keys
    if (e.key == 'Enter') {
      onEnter()
    } else if (key != 16 && key != 37 && key != 38 && key != 39 && key != 40) {
      let sideBar = document.getElementsByClassName('map-sidebar')[0]
      if(sideBar && sideBar.scrollHeight - 300 <= sideBar.scrollTop + sideBar.clientHeight) {
        sideBar.scrollTop = sideBar.scrollHeight - sideBar.clientHeight
      }
    }
  }

  const inputProps = {
    onChange: onAddressChange,
    onBlur: onBlur,
    onFocus: onFocus,
    value: address || '',
    onKeyDown: onKeyDown,
    disabled,
    placeholder,
    id
  }

  const cssClasses = {
    input: `google-autocomplete`,
    ...additionalClasses
  }

  const autocompleteStyle = {
    root: { display: 'block', width: '100%' },
    autocompleteContainer: { position: 'absolute', backgroundColor: 'green', width: '450px', zIndex: '999' },
    autocompleteItem: { color: 'black' },
    autocompleteItemActive: { color: 'blue' }
  }

  return (
    <PlacesAutocomplete
      id={id}
      debounce={300}
      classNames={cssClasses}
      styles={autocompleteStyle}
      inputProps={inputProps}
      onSelect={onEnter}
      shouldFetchSuggestions={({ value }) => value.length > 3}>
      { children }
    </PlacesAutocomplete>
  )
}

export default GoogleAutocompleteInput
