import React from 'react'
import UsersComponent from './UsersComponent'
import TitleContainer from '../../shared/TitleContainer'

const AdminUsersIndex = (props) => {
  return (
    <TitleContainer>
      <UsersComponent lakrids_host={props.lakrids_host} />
    </TitleContainer>
  )
}

export default AdminUsersIndex
