import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import AdminSmsCampaignsIndex from './AdminSmsCampaignsIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const AdminSmsCampaignsIndexWithStore = () => {
  return  (
    <Provider store={store}>
      <ErrorCatcher>
        <AdminSmsCampaignsIndex />
      </ErrorCatcher>
    </Provider>
  )
}

export default AdminSmsCampaignsIndexWithStore
