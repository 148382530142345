import React from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import axios from 'axios'
import find from 'lodash/find'

import { setMainTitle, startLoading, stopLoading, setFlash } from '../../files/actions/index'

import PlanEvent from '../../models/PlanEvent'
import GridWrapper from '../shared/GridWrapper'
import SaveButton from '../shared/SaveButton'
import CancelButton from '../shared/CancelButton'
import TitleContainer from '../shared/TitleContainer'

import FunTextField from '../forms/FunTextField'
import extractDataErrors from '../support/extractDataErrors'

class PlanEventsFormClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = new PlanEvent()
  }

  componentDidMount() {
    this.props.startLoading()

    const planEvent = this.findPlanEventInStore()

    if (planEvent) {
      this.setPlanEventAndMainTitle(planEvent)
      this.props.stopLoading()
    } else if(this.props.match.params.planEventId) {
      axios.get(this.loadUrl()).then(res => {
        this.setPlanEventAndMainTitle(res.data)
      }).catch(error => {
        this.props.setFlash(`${error} (${this.loadUrl()})`)
      }).then(
        this.props.stopLoading()
      )
    } else {
      this.setMainTitle()
      this.props.stopLoading()
    }
  }

  findPlanEventInStore() {
    return find(this.props.planEvents, ({ id }) => {
      return id == this.props.match.params.planEventId
    })
  }

  setPlanEventAndMainTitle(planEvent) {
    this.setPlanEvent(planEvent)
  }

  setPlanEvent(planEvent) {
    this.setState({
      id: planEvent.id,
      user_id: planEvent.user_id || '',
      car_id: planEvent.car_id || '',
      route_id: planEvent.route_id || '',
      date: planEvent.date || ''
    }, () => this.setMainTitle())
  }

  setMainTitle() {
    this.props.setMainTitle(!!this.state.id ? `Edit plan event #${this.state.routeId}[${this.state.date}]` : 'New plan event')
  }

  setStateAfterSave(data) {
    this.setState({
      id: data.id,
      errors: {}
    })
  }

  data() {
    return { plan_event: this.state, format: 'json' }
  }

  loadUrl() {
    return Routes.plan_event_path(
      window.tenant, window.depoId, this.props.match.params.planEventId, { format: 'json' }
    )
  }

  url() {
    if (this.state.id) {
      return Routes.plan_event_path(window.tenant, window.depoId, this.state.id, { format: 'json' })
    }
    return Routes.plan_events_path(window.tenant, window.depoId, { format: 'json' })
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  submit(e) {
    e.preventDefault()
    this.props.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    ).then(
      this.props.stopLoading
    )
  }

  handleSubmitSuccess(response) {
    this.setStateAfterSave(response.data)
    this.props.history.push(Routes.edit_plan_events_path(window.tenant, window.depoId, response.data.id))
    this.setMainTitle()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  handleChange(e, value) {
    this.setState({[value]: e.target.value})
  }

  renderForm() {
    return (
      <TitleContainer>
        <form noValidate autoComplete="off" onSubmit={this.submit.bind(this)}>
          <FunTextField field="route_id" state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField field="car_id" state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField field="user_id" state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField field="date" state={this.state} handleChange={this.handleChange.bind(this)} type="date"/>
          <SaveButton />
          <CancelButton to={Routes.plan_events_path(window.tenant, window.depoId)} />
        </form>
      </TitleContainer>
    )
  }

  render() {
    return (
      <GridWrapper>
        {this.renderForm()}
      </GridWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    mainTitle: state.mainTitle,
    planEvents: state.resources
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: mainTitle => dispatch(setMainTitle(mainTitle)),
    setFlash: flash => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEventsFormClass);

export default PlanEventsForm
