import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import moment from 'moment'

const previousWeek = (week, year) => {
  let correctYear = year
  let prevWeek = week - 1
  if (prevWeek < 1) {
    correctYear -= 1
    let weekCountInYear = moment().set('year', correctYear).isoWeeksInYear()
    prevWeek = weekCountInYear
  }
  return `${correctYear}-${prevWeek}`
}

const nextWeek = (week, year) => {
  const weekCountInYear = moment().set('year', year).isoWeeksInYear()
  let correctYear = year
  let nextWeek = week + 1
  if (nextWeek > weekCountInYear) {
    nextWeek = 1
    correctYear += 1
  }
  return `${correctYear}-${nextWeek}`
}

const PlanEventTemplatesCalendarControls = ({ year, week }) => {
  return (
    <div className='text-center'>
      <Link className="mdl-navigation__link" to={`${Routes.plan_event_templates_path(window.tenant, window.depoId)}/${previousWeek(week, year)}`}>
        <Button variant="contained" color="primary" size="small" style={{verticalAlign: "bottom"}}>
          <i className='material-icons'>chevron_left</i>
        </Button>
      </Link>
      <TextField inputProps={{ className: 'text-center' }} value={`${year}-${week}`} />
      <Link className="mdl-navigation__link" to={`${Routes.plan_event_templates_path(window.tenant, window.depoId)}/${nextWeek(week, year)}`}>
        <Button variant="contained" color="primary" size="small" style={{verticalAlign: "bottom"}}>
          <i className='material-icons'>chevron_right</i>
        </Button>
      </Link>
    </div>
  )
}

export default PlanEventTemplatesCalendarControls
