import React from 'react'
import { Provider } from 'react-redux'
import store from '../../files/store'
import EndOfDayReportsIndex from './EndOfDayReportsIndex'
import ErrorCatcher from '../shared/ErrorCatcher'

const EndOfDayReportsIndexWithStore = () => {
  return  (
    <Provider store={store}>
      <ErrorCatcher>
        <EndOfDayReportsIndex />
      </ErrorCatcher>
    </Provider>
  )
}

export default EndOfDayReportsIndexWithStore
