import React from 'react'

class CorrectLocationMarker extends React.Component {
  render() {
    return (
      <svg width='40' height='61' viewBox='0 0 40 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <ellipse cx='16.1174' cy='52.8333' rx='10.1174' ry='4.83333' fill='#666666' fillOpacity='0.2' />
        <path d='M7.72068 49.8241C7.63853 49.6407 7.56882 49.4526 7.51196 49.2609L7.72068 49.8241Z' fill='white' />
        <path
          d='M39.1207 19.8788L38.7688 20.4912C36.8775 23.8008 33.9946 25.8004 31.2196 27.5607C26.8932 30.2943 22.7667 31.9491 18.5963 32.6143C17.8857 32.7361 17.1688 32.816 16.4491 32.8536H16.0892C16.6064 35.7522 17.1208 38.6535 17.6326 41.5575C17.7966 42.4742 17.9605 43.3948 18.1205 44.3115C18.1485 44.4575 18.1764 44.6075 18.2004 44.7576L18.3684 45.6742L18.4683 46.2502V46.2867C18.4963 46.457 18.5283 46.6274 18.5523 46.7937C18.5523 46.8788 18.5803 46.964 18.5963 47.0492C18.6483 47.3574 18.6962 47.6697 18.7442 47.982C18.9157 49.0492 18.6806 50.1427 18.0866 51.0405C17.4925 51.9383 16.5841 52.5733 15.5454 52.8167C15.2381 52.8847 14.9243 52.9187 14.6098 52.9181C13.6576 52.9195 12.7337 52.5897 11.9922 51.9837C11.2507 51.3777 10.7365 50.5322 10.5353 49.5882C10.4113 49.0366 10.3154 48.5012 10.2234 47.9658L10.1274 47.4304C10.1293 47.394 10.1293 47.3574 10.1274 47.3209V47.2966L9.92351 46.1285L9.69159 44.8306L8.99985 40.9126L6.29285 25.7639L5.45317 21.0347C5.00533 18.4957 4.53751 15.8675 4.08567 13.2839C3.96831 12.6846 3.97156 12.0674 4.09524 11.4694C4.21892 10.8714 4.46048 10.3049 4.80541 9.80398C5.12649 9.35022 5.53458 8.96689 6.00523 8.67696C6.47588 8.38704 6.99939 8.19648 7.54439 8.11673C7.76257 8.08027 7.98306 8.05994 8.20414 8.05589C8.75334 8.05415 9.29726 8.16449 9.80355 8.38036C10.5951 7.68915 11.6021 7.30413 12.6465 7.29338C18.2414 7.13671 23.7813 6.12769 29.0804 4.30014C29.632 4.1052 30.2116 4.0038 30.7958 4C31.7688 4.00214 32.7182 4.30431 33.518 4.86642C34.3178 5.42853 34.9303 6.22403 35.2741 7.14737C36.4137 9.98649 37.5133 12.8946 38.4729 15.466C38.6557 15.9587 38.8027 16.4644 38.9127 16.9788C38.9527 17.1532 38.9887 17.3155 39.0287 17.4615L39.1127 17.786V19.8788H39.1207Z'
          fill='white'
        />
        <path
          d='M36.6018 18.9419C35.0144 21.7161 32.4993 23.4682 29.9163 25.0906C26.3176 27.366 22.491 29.1019 18.2606 29.7792C17.6451 29.884 17.0244 29.9544 16.4013 29.9901C15.8802 30.0356 15.3622 29.872 14.9586 29.5346C14.5549 29.1972 14.2977 28.7128 14.2421 28.1852C13.7743 25.4029 13.3944 22.5922 12.8026 19.8179C12.3588 17.6927 11.679 15.6201 11.0793 13.5313C10.7976 12.7505 10.7612 11.9004 10.9753 11.0978C11.0705 10.683 11.3048 10.3147 11.6381 10.0558C11.9714 9.79682 12.383 9.66338 12.8026 9.67823C18.6566 9.51541 24.4529 8.45847 29.9962 6.54303C30.2773 6.42499 30.5791 6.36659 30.8833 6.37143C31.1874 6.37627 31.4873 6.44425 31.7646 6.57118C32.0418 6.6981 32.2905 6.88128 32.4952 7.10943C32.7 7.33757 32.8565 7.60584 32.9551 7.89769C34.0547 10.6395 35.1024 13.4015 36.154 16.1676C36.3394 16.7346 36.4942 17.3114 36.6178 17.8955L36.6018 18.9419Z'
          fill='#E84433'
        />
        <path
          d='M16.1139 46.4651C16.2138 47.0207 16.3218 47.5723 16.4178 48.1239C16.5005 48.5319 16.4255 48.9565 16.2081 49.3098C15.9908 49.6632 15.6478 49.9182 15.2502 50.0221C15.0419 50.0781 14.8245 50.0909 14.6112 50.0595C14.3979 50.0282 14.193 49.9535 14.0089 49.8398C13.8248 49.7262 13.6652 49.576 13.5398 49.3982C13.4143 49.2204 13.3256 49.0188 13.2789 48.8053C13.107 48.0874 12.979 47.3655 12.8311 46.6435C11.4183 39.489 10.0081 32.333 8.60065 25.1757C7.78496 21.0441 6.96926 16.9071 6.15356 12.7647C5.93764 11.6818 6.47744 10.7854 7.41709 10.6232C8.35674 10.461 9.12046 11.0572 9.33238 12.1117L15.2022 41.7886C15.5008 43.3461 15.8046 44.9049 16.1139 46.4651Z'
          fill='#E84634'
        />
      </svg>
    )
  }
}

export default CorrectLocationMarker
