import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'

class SelectWithLabel extends Component {
  renderOptions() {
    const { options, optionLabel } = this.props

    return options.map(option => {
      return (
        <MenuItem key={option.id} value={option.id}>{option[optionLabel]}</MenuItem>
      )
    })
  }

  render() {
    const { label, selectedOption, classNames, additionalStyles } = this.props

    return (
      <FormControl className={classNames} style={{ display: 'flex', ...additionalStyles }}>
        <InputLabel>{label}</InputLabel>
        <Select value={selectedOption} onChange={this.props.handleSelect.bind(this)}>
          {this.renderOptions()}
        </Select>
      </FormControl>
    )
  }
}

// options={} selectedOption={} optionLabel={} label={} handleSelect={}
SelectWithLabel.propTypes = {
  options: PropTypes.array.isRequired,
  optionLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleSelect: PropTypes.func,
}

export default SelectWithLabel
