import React from 'react'
import moment from 'moment'
import menu from '../icons/menu.svg'
import logGoogleEvent from './logGoogleEvent'
import hjemisLogo from '../icons/hjem-is/logo_hjemis.svg'
import isbilenLogo from '../icons/den-norske-isbilen/isbilen_logo.svg'

export default class NavBar extends React.Component {
  openMenu = () => {
    logGoogleEvent('trackingMenuOpened')
    this.props.showMenu()
  }

  renderSuffixContent(address) {
    return address
  }

  renderSubHeaderContent(timeFrom, timeTo, pointVisited) {
    const { translations } = this.props

    return (pointVisited && translations.finished) || `${timeFrom} - ${timeTo}`
  }

  renderCenterContent(planEventRoutePoint) {
    if (planEventRoutePoint) {
      const { google_estimate_time, original_google_estimate_time, point_visited } = planEventRoutePoint
      const { translations } = this.props
      const { timeFrom, timeTo } = this.timeToTimeFrom(google_estimate_time || original_google_estimate_time)

      return (
        <React.Fragment>
          <div className='header-title'>{!point_visited && translations.expected_arrival_at_your_stop}</div>
          <div className='subheader-title'>{this.renderSubHeaderContent(timeFrom, timeTo, point_visited)}</div>
        </React.Fragment>
      )
    }
  }

  renderSuffix(planEventRoutePoint) {
    if (planEventRoutePoint) {
      const { address } = planEventRoutePoint

      return (
        <div className='navbar-suffix'>
          <div className='content'>{this.renderSuffixContent(address)}</div>
        </div>
      )
    }
  }

  timeToTimeFrom(time) {
    const timeFrom = moment(time).subtract(10, 'minutes').format('HH:mm')
    const timeTo = moment(time).add(10, 'minutes').format('HH:mm')

    return { timeFrom, timeTo }
  }

  render() {
    return (
      <div>
        <div className='tracking-navbar'>
          <div className='left-content'>{this.renderCenterContent(this.props.planEventRoutePoint)}</div>
          <div className='center-content'>
            <div className='logos'>
              <img
                src={this.props.tenant === 'hjem-is' ? hjemisLogo : isbilenLogo}
                height={this.props.tenant === 'hjem-is' ? 100 : 70}
                style={{ position: 'relative', right: 50 }} />
            </div>
          </div>
          <div className='right-content'>
            <div className='tracking-menu' onClick={this.openMenu}>
              <img src={menu} />
            </div>
          </div>
        </div>
        {this.renderSuffix(this.props.planEventRoutePoint)}
      </div>
    )
  }
}
