import {
  LOAD_CAR_STOPS,
  LOAD_CAR_ROUTES
} from "../constants/action-types"

import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'

const initialState = {
  carStops: [],
  carRoutes: []
}

const carStopsReducer = (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case LOAD_CAR_STOPS:
      return {...state, carStops: payload }
    case LOAD_CAR_ROUTES:
      return {...state, carRoutes: payload }
    default:
      return state
  }
}

export default carStopsReducer
