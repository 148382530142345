import React from 'react'
import ErrorCatcher from '../../shared/ErrorCatcher'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import OrdersList from './list'

const EshopOrdersIndex = () => {
  return  (
    <ErrorCatcher>
      <div className="content-container">
        <BrowserRouter>
          <Switch>
            <Route path='/:tenant/eshop/depo/:depoId/orders' component={OrdersList} />
          </Switch>
        </BrowserRouter>
      </div>
    </ErrorCatcher>
  )
}

export default EshopOrdersIndex
