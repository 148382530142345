import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import CustomersForm from './CustomersForm'
import CustomersTable from './CustomersTable'

const CustomersComponent = (componentProps) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/admin/customers/:customerId/edit' render={(props) => <CustomersForm {...props} accessToken={componentProps.accessToken} />} />
        <Route path='/:tenant/admin/customers/new' render={(props) => <CustomersForm {...props} accessToken={componentProps.accessToken} />} />
        <Route path='/:tenant/admin/customers' component={CustomersTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default CustomersComponent
