import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Checkbox from '@material-ui/core/Checkbox'
import ModeEdit from '@material-ui/icons/Edit'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'
import ProductsBalanceRow from './ProductsBalanceRow'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'

class ProductsBalance extends React.Component {
  state = {
    products: [],
    checked: false,
    loading: false,
    movementStartItemKey: false,
    startMultipleMovementFrom: false,
    startSingleMovementFrom: false,
    message: null
  }

  componentDidMount() {
    this.startLoading()

    axios.get(Routes.get_products_balance_inventory_warehouse_path(window.tenant, window.depoId, this.props.warehouseId, { format: 'json' })).then(res => {
      this.setState({
        products: res.data.map(p => {
          p.filledAmount   = false
          p.filledPackages = false
          return p
        }),
        loading: false
      })
    }).catch(err => {
      this.setState({ message: `${err}` })
      this.stopLoading()
    })
  }

  setFlashMessage(message) {
    this.setState({ message })
  }

  updateProductsByKeysWithValues(updatables) {
    const { products } = this.state
    const productsCopy = cloneDeep(products)

    updatables.forEach((updatable) => {
      const index = updatable.index
      updatable.data.forEach(({key, value}) => {
        if (key === "recalculateFilledAmount") {
          productsCopy[index].filledAmount = productsCopy[index].filledPackages * productsCopy[index].packaging_size
        } else if (key === "filledAmount") {
          productsCopy[index].filledAmount = value
          productsCopy[index].filledPackages = Math.ceil(productsCopy[index].filledAmount / productsCopy[index].packaging_size)
        } else {
          productsCopy[index][key] = value
        }
      })
    })
    this.setState({ products: productsCopy })
  }

  updateStateByKeysWithValues(updatables) {
    updatables.forEach((updatable) => {
      this.setState({ [updatable.key]: updatable.value })
    })
  }

  getStateOfKey(key) {
    return this.state[key]
  }

  clearMovementStates() {
    this.setState({ startSingleMovementFrom: false, movementStartItemKey: false, startMultipleMovementFrom: false })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  clearErrors() {
    this.setState({ message: null })
  }

  toggleAllProductCheck() {
    const { products, checked } = this.state
    this.setState({
      products: products.map(p => {
        if (!p.checked || p.checked && checked) {
          this.prepareProduct(p, !checked)
        }
        return p
      }),
      checked: !checked
    })
  }

  prepareProduct(product, checked) {
    product.filledPackages = checked && product.missing_balance_packages_count
    product.filledAmount   = checked && product.missing_balance_amount
    product.checked        = checked
  }

  toggleSingleProductCheck({ id, checked }) {
    if (!checked) this.setState({ checked: checked })
    const { products } = this.state
    const productsCopy = cloneDeep(products)
    const product      = find(products, { id: id })
    const index        = findIndex(products, product)

    this.prepareProduct(product, checked)
    productsCopy[index] = product
    this.setState({ products: productsCopy })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading}/>
  }

  renderCheckbox() {
    const { checked } = this.state
    return (
      <Checkbox
        className="all-product-checkbox"
        checked={checked}
        onChange={this.toggleAllProductCheck.bind(this)} />
    )
  }

  renderProducts() {
    const { products } = this.state
    if (products.length === 0) return

    return products.map((product, index) => {
      return (
        <ProductsBalanceRow
          warehouseData={this.props.warehouseData}
          setFlashMessage={this.setFlashMessage.bind(this)}
          tabIndex={index}
          key={product.id}
          toggleSingleProductCheck={this.toggleSingleProductCheck.bind(this)}
          product={product}
          products={products}
          updateProductsByKeysWithValues={this.updateProductsByKeysWithValues.bind(this)}
          updateStateByKeysWithValues={this.updateStateByKeysWithValues.bind(this)}
          getStateOfKey={this.getStateOfKey.bind(this)}
          clearMovementStates={this.clearMovementStates.bind(this)}/>
      )
    })
  }

  renderDepoFutureColumns() {
    if (this.props.warehouseData.owner_type === 'Depo') {
      return (
        <>
          <TableCell>Future quantity</TableCell>
          <TableCell>Future date</TableCell>
        </>
      )
    }
  }

  render() {
    return (
      <div>
        <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
        <Table className='resources-table'>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 10}}>{this.renderCheckbox()}</TableCell>
              <TableCell>Sku</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Minimum amount</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Actual Balance</TableCell>
              {this.renderDepoFutureColumns()}
              <TableCell>Packages</TableCell>
              <TableCell>Order/Transfer amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderProducts()}
          </TableBody>
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default ProductsBalance
