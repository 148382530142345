import React, { Component } from 'react'
import moment from 'moment'
import MaterialDatePicker from '../shared/MaterialDatePicker'
import LoadingWithoutRedux from '../shared/LoadingWithoutRedux'
import Select from '../shared/Select'
import ModalWrapper from '../shared/Modal'
import Button from '@material-ui/core/Button'
import axios from 'axios'

const initialState = {
  date: moment().format("DD-MM-YYYY"),
  selectedPlanEventId: '',
  loading: false,
  selectedCashRegisterId: ''
}

export default class EndOfDayReportCreateModal extends Component {
  state = { ...initialState, avaliablePlanEvents: [], cashRegisters: [] }

  componentDidMount() {
    this.fetchAvaliablePlanEvents()
    this.fetchCashRegisters()
  }

  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })
  handleCashSelect = (e) => this.setState({ selectedCashRegisterId: e.target.value })

  handlePlanEventSelect(e) {
    const { avaliablePlanEvents } = this.state
    const selectedPlanEventId = e.target.value
    const index = avaliablePlanEvents.findIndex(pe => pe.id == selectedPlanEventId)
    const selectedPlanEvent = avaliablePlanEvents[index]
    this.setState({ selectedPlanEventId, selectedCashRegisterId: selectedPlanEvent.cash_register_id || '' })
  }

  handleDateChange(date) {
    this.setState(
      {
        date: moment(date).format(dateFormat),
        selectedPlanEventId: '',
        selectedCashRegisterId: '',
      },
      this.fetchAvaliablePlanEvents.bind(this)
    )
  }

  handleSubmit() {
    const { date, selectedPlanEventId, selectedCashRegisterId } = this.state
    this.startLoading()
    if (selectedPlanEventId && selectedCashRegisterId) {
      axios.post(
        Routes.end_of_day_reports_path(tenant, depoId, { format: 'json' }),
        { end_of_day_report: { plan_event_id: selectedPlanEventId, cash_register_id: selectedCashRegisterId } }
      ).then(res => {
        this.props.setFlash("You succesfuly created end of day report")
        this.stopLoading()
        this.props.updateEndOfDayReport(res.data)
        this.props.handleClose()
        this.setState({ ...initialState }, this.fetchAvaliablePlanEvents.bind(this))
      }).catch(error => {
        this.props.setFlash(`Error occured ${error}`)
        this.stopLoading()
      })
    } else {
      this.props.setFlash("You must select plan event and cash register to continue")
      this.stopLoading()
    }
  }

  fetchCashRegisters() {
    this.startLoading()
    axios.get(Routes.cash_registers_path(tenant, { format: 'json' })).then(res => {
      this.setState({ cashRegisters: res.data })
      this.stopLoading()
    }).catch(error => {
      this.props.setFlash(`Error occured ${error}`)
      this.stopLoading()
    })
  }

  fetchAvaliablePlanEvents() {
    const { date } = this.state
    this.startLoading()
    axios.get(Routes.possible_plan_events_end_of_day_reports_path(tenant, depoId, { format: 'json' }), { params: { date } }).then(res => {
      this.setState({ avaliablePlanEvents: res.data, selectedPlanEventId: '' })
      this.stopLoading()
    }).catch(error => {
      this.props.setFlash(`Error occured ${error}`)
      this.stopLoading()
    })
  }

  renderPlanEventSelect() {
    const { avaliablePlanEvents, selectedPlanEventId } = this.state

    return (
      <Select
        classNames="plan-event-select"
        options={avaliablePlanEvents}
        selectedOption={selectedPlanEventId}
        optionLabel="title"
        label="Plan event"
        handleSelect={this.handlePlanEventSelect.bind(this)} />
    )
  }

  renderCashRegisterSelect() {
    const { cashRegisters, selectedCashRegisterId } = this.state

    return (
      <Select
        classNames="cash-register-select"
        options={cashRegisters}
        selectedOption={selectedCashRegisterId}
        optionLabel="name"
        label="Cash register"
        handleSelect={this.handleCashSelect.bind(this)} />
    )
  }

  renderDatePicker() {
    const { date } = this.state
    return (
      <MaterialDatePicker
        onDateChange={this.handleDateChange.bind(this)}
        key={`date-${date}`}
        fieldName="date"
        label="Date"
        classNames="full-width"
        value={date} />
    )
  }

  renderSubmitButton() {
    return (
      <Button style={{ width: '100%', marginTop: 20 }} variant="contained" color="primary" onClick={this.handleSubmit.bind(this)}>
        Create EOD
      </Button>
    )
  }

  render() {
    const { handleClose, open } = this.props

    return (
      <ModalWrapper
        classes="eod-modal"
        style={{ width: 400, height: 300 }}
        open={open}
        title="Create new EOD"
        close={handleClose.bind(this)}>
        {this.renderDatePicker()}
        {this.renderPlanEventSelect()}
        {this.renderCashRegisterSelect()}
        {this.renderSubmitButton()}
        <LoadingWithoutRedux additionalStyles={{ marginLeft: 0, left: '40%', top: 'calc(50% - 50px)' }} loading={this.state.loading} />
      </ModalWrapper>
    )
  }
}
