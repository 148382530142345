import TruckIconHjemis from '../icons/hjem-is/TruckIcon'
import TruckIconDennorskeisbilen from '../icons/den-norske-isbilen/TruckIcon'
import logoHjemis from '../icons/hjem-is/logo_hjemis.svg'
import logoDennorskeisbilen from '../icons/den-norske-isbilen/isbilen_logo.svg'
import carPhoneLogo from '../icons/hjem-is/hjemis_truck_logo.png'
import isbilenTruckLogo from '../icons/den-norske-isbilen/isbilen_truck_logo.png'
import isbilenKlikHer from '../icons/den-norske-isbilen/klik_her.svg'
import hjemisKlikkHer from '../icons/hjem-is/klikk_her.svg'

export default {
  'hjem-is': {
    logo: logoHjemis,
    truckIcon: TruckIconHjemis,
    iceCreamModal: carPhoneLogo,
    carPhoneLogo: carPhoneLogo,
    clickHere: hjemisKlikkHer
  },
  'den-norske-isbilen': {
    logo: logoDennorskeisbilen,
    truckIcon: TruckIconDennorskeisbilen,
    iceCreamModal: logoDennorskeisbilen,
    carPhoneLogo: isbilenTruckLogo,
    clickHere: isbilenKlikHer
  }
}
