import React from 'react'
import axios from 'axios'
import debounce from 'lodash/debounce'

const REQUEST_TIME_WAIT = 250

class RoutePointAutocomplete extends React.Component {
  state = { searchPhrase: this.props.value, placesList: [], selectedItem: {} }

  componentDidMount() {
    this.scrollToRef = React.createRef()
    this.closeAutocompleteOnOutsideClick()
  }

  componentDidUpdate(){
    if (this.scrollToRef.current) {
      this.scrollToBottom()
    }
  }

  closeAutocompleteOnOutsideClick() {
    document.querySelector('body').addEventListener('click', e => {
      if (!this.state.placesList.length) return

      const clickWithinList = e.target.className && e.target.className.length && (
        e.target.className.includes('mapbox-places-list') || e.target.className.includes('location-search-container')
      )

      if (!clickWithinList) this.setState({ placesList: [] })
    })
  }

  getPlaces(e) {
    if (this.state.searchPhrase.length < 3) {
      this.setState({ placesList: [] })
      return
    }

    // Do not trigger places fetch too often in a minute
    const fetchWithDebounce = debounce(this.fetchPlaces.bind(this), REQUEST_TIME_WAIT)
    fetchWithDebounce()
  }

  fetchMapboxAddresses() {
    const { searchPhrase } = this.state

    axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchPhrase}.json`,
      {
        params: {
          access_token: this.props.accessToken,
          autocomplete: true,
          country: window.tenant === 'romania' ? ["ro"] : ["dk"]
        }
      }
    ).then(res => {
      if (res.data.features.length > 0) {
        this.setState({ placesList: res.data.features, showList: true })
      } else {
        this.setState({ placesList: [] })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  fetchIsbilenAddresses() {
    const { searchPhrase } = this.state

    axios.get(
      Routes.api_isbillen_addresss_path(window.tenant,
        {
          Query: searchPhrase,
          Size: 5
        }
      )
    ).then(res => {
      if (res.data.SearchResults.length > 0) {
        res.data.SearchResults.forEach(result => {
          result.place_name = result.Text
          result.center = [result.Source.Posisjon.X, result.Source.Posisjon.Y]
          result.id = result.Id
        })
        this.setState({ placesList: res.data.SearchResults, showList: true })
      } else {
        this.setState({ placesList: [] })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  fetchPlaces() {
    const { searchPhrase } = this.state
    if (!searchPhrase.length) return

    if (isIsbilen) {
      this.fetchIsbilenAddresses()
    } else {
      this.fetchMapboxAddresses()
    }
  }

  handleChange(e) {
    this.setState({ searchPhrase: e.target.value }, () => {
      this.getPlaces()
    })
  }

  scrollToBottom() {
    this.scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  }

  handleKeyUp(e) {
    const { placesList, searchPhrase, showList } = this.state
    if ((!placesList.length && searchPhrase.length < 3) || !showList) return

    if (e.key === 'ArrowDown') {
      if (this.state.selectedItem) {
        const index = placesList.findIndex(place => place.id === this.state.selectedItem.id)
        const nextIndex = index + 1
        if (nextIndex === placesList.length) {
          this.setState({ selectedItem: placesList[0], searchPhrase: placesList[0].place_name })
        } else {
          this.setState({ selectedItem: placesList[nextIndex], searchPhrase: placesList[nextIndex].place_name })
        }
      } else {
        this.setState({ selectedItem: placesList[0], searchPhrase: placesList[0].place_name })
      }
    } else if (e.key === 'ArrowUp') {
      if (this.state.selectedItem) {
        const index = placesList.findIndex(place => place.id === this.state.selectedItem.id)
        const prevIndex = index - 1
        if (prevIndex === -1) {
          this.setState({ selectedItem: placesList[placesList.length - 1], searchPhrase: placesList[placesList.length - 1].place_name })
        } else {
          this.setState({ selectedItem: placesList[prevIndex], searchPhrase: placesList[prevIndex].place_name })
        }
      } else {
        this.setState({ selectedItem: placesList[placesList.length - 1], searchPhrase: placesList[placesList.length - 1].place_name })
      }
    }

    if (e.key === 'Enter' && Object.keys(this.state.selectedItem).length) {
      this.selectSuggestion(this.state.selectedItem)
    } else if (e.key === 'Enter' && !Object.keys(this.state.selectedItem).length) {
      this.selectClosestSuggestion()
    }

    if (e.key === 'Escape') {
      this.setState({ showList: false, placesList: [] })
    }
  }

  onBlur() {
    if (this.state.showList) {
      this.selectClosestSuggestion()
    }
    this.props.onBlur()
  }

  selectClosestSuggestion() {
    const { placesList } = this.state
    if (placesList.length) {
      this.selectSuggestion(placesList[0])
    }
  }

  selectSuggestion(item) {
    const correctCoordinates = { lat: item.center[1], lng: item.center[0] }
    this.setState({ showList: false, searchPhrase: item.place_name })
    this.props.updateCoordinates(this.props.field, item.place_name, correctCoordinates)
  }

  renderPlacesList() {
    const { placesList, selectedItem } = this.state

    return placesList.map(place => {
      const activeClass = selectedItem && selectedItem.id === place.id ? "location-search-autocomplete-item-active" : ""
      return (
        <div
          key={place.id}
          onClick={this.selectSuggestion.bind(this, place)}
          className={`mapbox-places-list-item location-search-autocomplete-item ${activeClass}`}>
          {place.place_name}
        </div>
      )
    })
  }

  renderResult() {
    if (this.state.showList) {
      return (
        <div ref={this.scrollToRef} style={{ width: '50%' }} className="mapbox-places-list location-search-suggestions-container">
          {this.renderPlacesList()}
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <input
          style={{ width: '100%', height: 28, boxSizing: 'border-box', padding: 3, border: 'none' }}
          onChange={this.handleChange.bind(this)}
          onKeyUp={this.handleKeyUp.bind(this)}
          onFocus={this.props.onFocus.bind(this)}
          disabled={this.props.disabled}
          onBlur={this.onBlur.bind(this)}
          value={this.state.searchPhrase}
          className="location-search-input" />
        {this.renderResult()}
      </div>
    )
  }
}

RoutePointAutocomplete.defaultProps = {
  accessToken: "ACCESS_TOKEN"
}

export default RoutePointAutocomplete
