import React from 'react'
import axios from 'axios'
import LoadingWithoutRedux from '../shared/LoadingWithoutRedux'
import MaterialDatePicker from '../shared/MaterialDatePicker'
import Select from '../shared/Select'
import ModalWrapper from '../shared/Modal'
import FunTimeInput from '../forms/FunTimeInput'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import CheckboxWithLabel from '../shared/CheckboxWithLabel'

export default class RouteChangeModal extends React.Component {
  state = {
    date: moment().format("DD-MM-YYYY"),
    selectedPlanEventId: '',
    avaliablePlanEvents: [],
    routePoints: [],
    selectedRoutePoint: '',
    modalLoading: false,
    firstStop: false
  }

  componentDidMount() {
    this.fetchAvaliablePlanEvents()
  }

  startLoading() {
    this.setState({ modalLoading: true })
  }

  stopLoading() {
    this.setState({ modalLoading: false })
  }

  fetchAvaliablePlanEvents() {
    const { date } = this.state
    this.startLoading()
    axios.get(Routes.possible_plan_events_end_of_day_reports_path(tenant, depoId, { format: 'json' }), { params: { date } }).then(res => {
      this.setState({ avaliablePlanEvents: res.data, selectedPlanEventId: '' })
      this.stopLoading()
    }).catch(error => {
      this.props.setMessage(`Error occured ${error}`)
      this.stopLoading()
    })
  }

  fetchCalculatedDeliveryTime() {
    const { selectedRoutePoint } = this.state
    const { order, fetchPath } = this.props
    this.setState({ modalLoading: true })
    axios.get(fetchPath(tenant, depoId, order.id, { point_id: selectedRoutePoint, format: 'json' })).then(res => {
      this.setState({ alternate_delivery_time: res.data, modalLoading: false })
    }).catch(error => {
      this.props.setMessage(`Error occured ${error}`)
      this.stopLoading()
    })
  }

  fetchRoutePoints() {
    const { selectedPlanEventId } = this.state
    const { type } = this.props
    this.startLoading()
    axios.get(Routes.small_route_points_data_plan_event_path(tenant, depoId, selectedPlanEventId, { format: 'json', type })).then(res => {
      this.setState({ routePoints: res.data })
      this.stopLoading()
    }).catch(error => {
      this.props.setMessage(`Error occured ${error}`)
      this.stopLoading()
    })
  }

  saveNewRoute() {
    const { order, updatePath } = this.props
    const { selectedRoutePoint, selectedPlanEventId, firstStop } = this.state
    this.startLoading()
    axios.put(updatePath(tenant, depoId, order.id, { point_id: selectedRoutePoint, plan_event_id: selectedPlanEventId, first_stop: firstStop, format: 'json' })).then(res => {
      this.startLoading()
      this.props.loadOrders()
    }).catch(error => {
      this.props.setMessage(`Error occured ${error}`)
      this.stopLoading()
    })
  }

  renderDatePicker() {
    const { date } = this.state

    return (
      <MaterialDatePicker
        onDateChange={this.handleDateChange.bind(this)}
        key={`date-${date}`}
        fieldName="date"
        label="Date"
        classNames="full-width"
        value={date} />
    )
  }

  handleRoutePointChange(e) {
    this.setState({ selectedRoutePoint: e.target.value }, () => this.fetchCalculatedDeliveryTime())
  }

  handleDateChange(date) {
    this.setState(
      {
        date: moment(date).format(dateFormat),
        selectedPlanEventId: '',
      },
      this.fetchAvaliablePlanEvents.bind(this)
    )
  }

  handlePlanEventSelect(e) {
    this.setState({ selectedPlanEventId: e.target.value }, () =>  this.fetchRoutePoints())
  }

  handleCheckboxChange(e) {
    this.setState({ firstStop: e.target.checked })
  }

  renderRoutePointSelect(order) {
    const { routePoints, selectedRoutePoint, firstStop } = this.state
    if (firstStop) return

    const label = isHjemis ? "Choose stop (new stop will be added AFTER chosen stop)" : "Choose stop (order will be assigned to chosen stop)"
    return (
      <Select
        classNames="route-point-select"
        options={routePoints}
        selectedOption={selectedRoutePoint}
        optionLabel="title"
        label={label}
        handleSelect={this.handleRoutePointChange.bind(this)} />
    )
  }

  renderPlanEventSelect() {
    const { avaliablePlanEvents, selectedPlanEventId } = this.state

    return (
      <Select
        classNames="plan-event-select"
        options={avaliablePlanEvents}
        selectedOption={selectedPlanEventId}
        optionLabel="title"
        label="Choose new route"
        handleSelect={this.handlePlanEventSelect.bind(this)} />
    )
  }

  renderFirstStopCheckbox() {
    if (!isHjemis) return

    return (
      <CheckboxWithLabel
        id="first-stop-checkbox"
        label="Make order as first stop"
        handleChange={this.handleCheckboxChange.bind(this)}
        checked={this.state.firstStop}
        additionalStyles={{ display: 'block' }} />
    )
  }

  renderDeliveryTime() {
    const { firstStop } = this.state
    if (!isHjemis || firstStop) return

    return (
      <FunTimeInput
        id="alt-delivery-time"
        disabled
        label="Delivery time"
        settings={{
          value: this.state.alternate_delivery_time || '',
          inputProps: { disabled: true }
        }}
        containerStyles={{ width: '100%', marginTop: 5 }} />
    )
  }

  render() {
    const { order } = this.props

    return (
      <ModalWrapper
        classes="order-modal"
        style={{ width: 450, height: 370 }}
        open={order.modalOpen || false}
        title="Change route"
        close={this.props.handleModalClose.bind(this, order)}>
        {this.renderDatePicker()}
        {this.renderPlanEventSelect()}
        {this.renderRoutePointSelect(order)}
        {this.renderDeliveryTime()}
        {this.renderFirstStopCheckbox()}
        <Button className="mt-2" onClick={this.saveNewRoute.bind(this)} vairant="contained" color="primary">Save</Button>
        <LoadingWithoutRedux loading={this.state.modalLoading} additionalStyles={{ marginLeft: '20%' }} />
      </ModalWrapper>
    )
  }
}
