import React from 'react'

import CarsComponent from './CarsComponent'
import TitleContainer from '../../shared/TitleContainer'

const AdminCarsIndex = () => {
  return (
    <TitleContainer>
      <CarsComponent />
    </TitleContainer>
  )
}

export default AdminCarsIndex
