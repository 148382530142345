import React from 'react'
import Close from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import phoneIcon from '../../icons/phone_icon.svg'
import hjemisPhoneIcon from '../../icons/hjem-is/phone_icon.svg'
import Modal from '../../../shared/Modal'
import MapboxAutocomplete from '../../../shared/MapboxAutocomplete'
import danskFlag from '../../icons/den-norske-isbilen/dansk.svg'
import norskFlag from '../../icons/hjem-is/norsk.svg'
import arrowIcon from '../../icons/arrow_icon.svg'
import { Button, MenuItem, Select } from '@material-ui/core'
import { Check } from '@material-ui/icons'

class ModalClass extends React.Component {
  state = {
    selectedLanguage: 'no'
  }
  openPromoLink = (e) => {
    const {
      content: { classes }
    } = this.props
    if (isHjemis() && !classes) {
      window.open(
        'https://hjem-is.dk/dit-hjem-is/gratis-is#utm_source=sms&utm_medium=gratis-is&utm_campaign=sms',
        '_blank'
      )
    }
  }

  renderLogo() {
    const { logo } = this.props.content
    if (logo) {
      return (
        <div>
          <img src={logo} className='logo' />
        </div>
      )
    }
  }

  renderCloseIcon() {
    if (!this.props.content.languageSelect) {
      return (
        <IconButton className='modal-close-button' onClick={this.props.close}>
          <Close />
        </IconButton>
      )
    }
  }

  renderHeaderLabel() {
    const { headerTitle, headerSubtitle } = this.props.content
    return (
      <div className='header-label'>
        <div className='header-title'>{headerTitle}</div>
        <div className='header-subtitle'>{headerSubtitle}</div>
      </div>
    )
  }

  renderParagraphs() {
    const { largeParagraph, smallParagraph, bottomParagraph } = this.props.content
    return (
      <div className='modal-paragraphs'>
        {largeParagraph ? <p className='large-paragraph'>{largeParagraph}</p> : ''}
        {smallParagraph ? <span>{smallParagraph}</span> : ''}
      </div>
    )
  }

  renderLanguageSelect() {
    if (this.props.content.languageSelect) {
      const { languageButton } = this.props.content
      return (
        <div className='language-select'>
          <Select
            value={this.state.selectedLanguage}
            onChange={(e) => this.setState({ selectedLanguage: e.target.value })}
            disableUnderline
            className='lang-select'
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }
            }}
          >
            <MenuItem value='dk'>
              <img className='flag' src={danskFlag} />
              Dansk
              <Check className='checkmark' />
            </MenuItem>
            <MenuItem value='no'>
              <img className='flag' src={norskFlag} /> Norsk
              <Check className='checkmark' />
            </MenuItem>
          </Select>
          <Button className='black-button' onClick={() => (window.location = `/${this.state.selectedLanguage}`)}>
            Continue
          </Button>
        </div>
      )
    } else {
      return null
    }
  }

  renderSearchInput() {
    const { searchInput } = this.props.content
    if (searchInput) {
      const { translations, accessToken, isbilenAccessToken } = this.props
      if (this.props.logHotjar) {
        return (
          <MapboxAutocomplete
            isbilenAccessToken={isbilenAccessToken}
            accessToken={accessToken}
            key='modal-autocomplete'
            classNames='modal-search-container'
            translations={translations}
            locationMarkerColor='blue'
            hotjarLink={hotjarLink}
            googleAction='popupTypeIn'
            googleSelectAction='popupChooseAddress'
            showSearchIcon={showSearchIcon}
            onAddressChange={onAddressChange.bind(this)}
          />
        )
      }
    }
  }

  renderActionButton() {
    const { actionButton } = this.props.content
    if (actionButton) return actionButton
  }

  renderPhoneInput() {
    const { phoneInput } = this.props.content
    if (phoneInput) {
      const {
        translations: { phone_number }
      } = this.props
      const { onChange, customerPhone, onPhoneNumberToggle, countryCode } = phoneInput
      return (
        <div className='modal-phone-input-container'>
          <div className='country-code-container'>
            <img src={tenant === 'hjem-is' ? hjemisPhoneIcon : phoneIcon} />
            +{countryCode}
          </div>
          <input
            onChange={onChange}
            value={customerPhone}
            placeholder={phone_number}
            onFocus={onPhoneNumberToggle}
            spellCheck={false}
            type='phone'
          />
        </div>
      )
    }
  }

  renderPolicyParagraph() {
    const { policyParagraph, gdprCheckbox, gdprParagraph, gdprPolicy, marketingCheckbox } = this.props.content
    if (policyParagraph) {
      return (
        <>
          <div className='policy-paragraph-with-gdpr-checkbox'>
            <div>
              {gdprCheckbox}
              <p className='policy-paragraph'>{gdprParagraph}</p>
            </div>
            {gdprPolicy && <p className='policy-paragraph' dangerouslySetInnerHTML={{ __html: policyParagraph }} />}
          </div>
        </>
      )
    }
  }

  renderBottomParagraph() {
    const { bottomParagraph } = this.props.content

    if (bottomParagraph) {
      return <p className='bottom-paragraph'>{bottomParagraph}</p>
    }
  }

  renderButtonList() {
    const { buttonList } = this.props.content

    if (buttonList) {
      return buttonList.map((button, index) => {
        return (
          <Button key={`button-list-item-${index}`} className='button-list-item' onClick={button.action}>
            <p>{button.name}</p>
            <img src={arrowIcon} />
          </Button>
        )
      })
    }
  }

  renderCaptchaButton() {
    const { captchaCheckbox } = this.props.content

    if (captchaCheckbox) return captchaCheckbox
  }

  renderModalContent() {
    return (
      <div className={`${tenant} modal-container`}>
        {this.renderCloseIcon()}
        {tenant === 'hjem-is' && this.renderLogo()}
        {this.renderHeaderLabel()}
        {tenant === 'den-norske-isbilen' && this.renderLogo()}
        {tenant === 'den-norske-isbilen' && this.renderParagraphs()}
        {this.renderSearchInput()}
        {this.renderPhoneInput()}
        {this.renderActionButton()}
        {this.renderCaptchaButton()}
        {this.renderLanguageSelect()}
        {this.renderPolicyParagraph()}
        {this.renderBottomParagraph()}
        {this.renderButtonList()}
      </div>
    )
  }

  render() {
    const { open, close, modalContainerStyles } = this.props
    const { classes } = this.props.content
    return (
      <Modal
        open={open}
        close={close}
        classes={`${classes} modal-block ${tenant}`}
        BackdropProps={{ classes: { root: 'modal-backdrop' } }}
        modalContainerStyles={modalContainerStyles}
      >
        {this.renderModalContent()}
      </Modal>
    )
  }
}

export default ModalClass
