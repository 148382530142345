import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    padding: '4px',
  },
}

const PlanEventsTableHeading = ({classes}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.root}>#</TableCell>
        <TableCell className={classes.root}>Route</TableCell>
        <TableCell className={classes.root}>Car</TableCell>
        <TableCell className={classes.root}>User</TableCell>
        <TableCell className={classes.root}>Date</TableCell>
        <TableCell className={classes.root}>Total revenue</TableCell>
        <TableCell className={classes.root}>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(PlanEventsTableHeading)
