import React from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import axios from 'axios'
import find from 'lodash/find'

import { setMainTitle, startLoading, stopLoading, setFlash } from '../../../files/actions/index'

import Car from '../../../models/Car'
import GridWrapper from '../../shared/GridWrapper'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FunTextField from '../../forms/FunTextField'
import extractDataErrors from '../../support/extractDataErrors'

class CarsFormClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = new Car()
  }

  componentDidMount() {
    this.props.startLoading()
    this.fetchDepos()
    const car = this.findCarInStore()

    if (car) {
      this.setCarAndMainTitle(car)
      this.props.stopLoading()
    } else if (this.props.match.params.carId) {
      axios.get(this.loadUrl()).then(res => {
        this.setCarAndMainTitle(res.data)
      }).catch(error => {
        this.props.setFlash(`${error} (${this.loadUrl()})`)
      }).then(
        this.props.stopLoading
      )
    } else {
      this.setMainTitle()
      this.props.stopLoading()
    }
  }

  fetchDepos() {
    axios.get(Routes.admin_depos_path(tenant, depoId, {format: 'json'})).then(res => {
      this.setState({depos: res.data})
    })
  }

  renderDepoOptions() {
    const { depos } = this.state
    if(depos) {
      return (
        depos.map((depo, index) => {
          return <MenuItem key={index} value={depo.id}>{depo.title}</MenuItem>
        })
      )
    } else {
      return <MenuItem value='Blank'>Blank</MenuItem>
    }
  }

  findCarInStore() {
    return find(this.props.cars, ({ id }) => {
      return id == this.props.match.params.carId
    })
  }

  clearErrors = () => this.setState({ errors: {} })

  existingCar = () => !!this.state.id

  setCarAndMainTitle(car) {
    this.setCar(car)
  }

  setCar(car) {
    this.setState({
      id: car.id,
      internal_number: car.internal_number || '',
      mobile_number: car.mobile_number || '',
      plate_number: car.plate_number || '',
      depo_id: car.depo_id || '',
      status: car.status || '',
      axapta_code: car.axapta_code || '',
      franchise_car: car.franchise_car,
      lakrids_route_number: car.lakrids_route_number,
      send_to_lakrids: car.send_to_lakrids || false
    }, () => this.setMainTitle())
  }

  setMainTitle() {
    this.props.setMainTitle(this.existingCar() ? `Edit car ${this.state.internal_number}` : 'New car')
  }

  data() {
    return { car: this.state, format: 'json' }
  }

  loadUrl() {
    return Routes.admin_car_path(tenant, depoId, this.props.match.params.carId, { format: 'json' })
  }

  url() {
    return this.existingCar() ? Routes.admin_car_path(tenant, depoId, this.state.id) : Routes.admin_cars_path(tenant, depoId)
  }

  method() {
    return this.existingCar() ? 'patch' : 'post'
  }

  submit(e) {
    e.preventDefault()
    this.props.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    ).then(
      this.props.stopLoading()
    )
  }

  handleSubmitSuccess(response) {
    const { data, data: { internal_number } } = response
    this.props.setFlash(`Car: ${internal_number} successfully ${this.existingCar() ? 'updated' : 'created'}!`)
    this.setCar(data)
    this.setMainTitle()
    this.clearErrors()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  handleChange(e, value) {
    this.setState({[value]: e.target.value})
  }

  handleRadioButtonChange(e) {
    this.setState({ status: e.target.value })
  }

  handleCheckboxChange(field, e) {
    this.setState({ [field]: !this.state[field] })
  }

  renderCarStatusCheckboxes() {
    return (
      <div>
        <RadioGroup
          className="d-block"
          aria-label="status"
          name="status"
          value={this.state.status}
          onChange={this.handleRadioButtonChange.bind(this)}
        >
          <FormControlLabel value="active" control={<Radio />} label="Active" />
          <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
        </RadioGroup>
      </div>
    )
  }

  renderFranchiseUserCheckbox() {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              id="franchise-car"
              checked={this.state.franchise_car}
              onChange={this.handleCheckboxChange.bind(this, 'franchise_car')}
            />
          }
          label="Franchise car"
        />
      </div>
    )
  }

  renderSendToLakridsCheckbox() {
    if (isHjemis) {
      return (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="send_to_lakrids"
                checked={this.state.send_to_lakrids}
                onChange={this.handleCheckboxChange.bind(this, 'send_to_lakrids')}
              />
            }
            label="Send to lakrids"
          />
        </div>
      )
    }
  }

  renderLakridsRouteNumber() {
    if (isHjemis) {
      return <FunTextField field="lakrids_route_number" state={this.state} handleChange={this.handleChange.bind(this)} />
    }
  }

  renderForm() {
    return (
      <form noValidate autoComplete="off" onSubmit={this.submit.bind(this)}>
        <FunTextField field="internal_number" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="mobile_number" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="plate_number" disabled={this.existingCar()} state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="axapta_code" state={this.state} handleChange={this.handleChange.bind(this)} />
        {this.renderLakridsRouteNumber()}
        <FunTextField select label="Depo" className="depo-dropdown" field="depo_id" state={this.state} handleChange={this.handleChange.bind(this)}>
          {this.renderDepoOptions()}
        </FunTextField>
        {this.renderCarStatusCheckboxes()}
        {this.renderFranchiseUserCheckbox()}
        {this.renderSendToLakridsCheckbox()}
        <SaveButton />
        <CancelButton to={Routes.admin_cars_path(tenant, depoId)} />
      </form>
    )
  }

  render() {
    return (
      <GridWrapper>
        {this.renderForm()}
      </GridWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    mainTitle: state.mainTitle,
    cars: state.resources
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: mainTitle => dispatch(setMainTitle(mainTitle)),
    setFlash: flash => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const CarsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarsFormClass)

export default CarsForm
