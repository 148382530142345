import React from 'react'

const pointIcon = (icon) => {
  return (
    <svg width="46px" height="56px" viewBox="0 0 56 66">
      <defs>
          <path d="M20,48.6413433 C20,48.6413433 31.7180757,36.8818358 35.3866937,32.5056621 C38.2675465,29.0691877 40,24.6522293 40,19.8339933 C40,8.87998129 31.045695,-2.84217094e-14 20,-2.84217094e-14 C8.954305,-2.84217094e-14 0,8.87998129 0,19.8339933 C0,24.5496174 1.6594486,28.8808735 4.43081758,32.2847783 C8.09711386,36.787868 20,48.6413433 20,48.6413433 Z" id="path-1"></path>
          <filter x="-25.8%" y="-19.1%" width="151.5%" height="145.6%" filterUnits="objectBoundingBox" id="filter-2">
            <feMorphology radius="3.80952381" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
      </defs>
      <g id="sms-subscription-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="selected-stop" transform="translate(-861.000000, -301.000000)">
          <g id="Group" transform="translate(869.000000, 308.000000)">
            <g id="Oval-2">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)"></use>
              <path stroke="#FFFFFF" strokeWidth="3.80952381" d="M18.6559342,49.9910118 C18.5246841,49.8603066 18.2797114,49.6156599 17.9372405,49.2725408 C17.3670606,48.7012819 16.7318672,48.062524 16.047872,47.3717226 C14.0932188,45.3976192 12.1383854,43.4012803 10.3126159,41.5058496 C10.1252808,41.3113669 9.94007492,41.118739 9.75708787,40.9280514 C6.6180564,37.6569211 4.26573066,35.0988639 2.95371573,33.4873954 C-0.170622078,29.6499605 -1.9047619,24.8714492 -1.9047619,19.8339933 C-1.9047619,7.82444005 7.90587158,-1.9047619 20,-1.9047619 C32.0941284,-1.9047619 41.9047619,7.82444005 41.9047619,19.8339933 C41.9047619,24.9801604 40.0946789,29.8545701 36.8463888,33.7293484 C35.2003775,35.6928211 31.953078,39.148065 27.6837003,43.5419446 C26.1975922,45.0713901 24.6919532,46.6068278 23.2437618,48.0737369 C22.7368631,48.5871871 22.2732917,49.0554576 21.8626867,49.4692148 C21.6343057,49.6992541 21.6343057,49.6992541 21.4830479,49.8513874 C21.4227838,49.9119712 21.4227838,49.9119712 21.3836086,49.9513276 C21.3680883,49.9669162 21.3680883,49.9669162 21.3580662,49.9769791 C21.3541003,49.9809608 21.3541003,49.9809608 21.3515372,49.9835336 C21.3505204,49.9845542 21.3505204,49.9845542 21.3498579,49.9852192 C21.3493731,49.9857058 21.3493731,49.9857058 21.3492448,49.9858344 L20.0051774,51.3346541 L18.6559342,49.9910118 Z" fill="#27AAE1" fillRule="evenodd"></path>
              <div style={{ width: 47, color: 'white', marginTop: -44, fontSize: 14, fontWeight: 'bold' }}>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {icon}
                </div>
              </div>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default pointIcon
