const REGIONS = {
  "hjem-is": {
    coordinatesArray: [10.108, 56.2],
    coordinates: { lat: 56.2, lng: 10.108 },
    zoom: 7,
    code: "DK",
  },
  romania: {
    coordinatesArray: [24.9668, 45.9432],
    coordinates: { lat: 45.9432, lng: 24.9668 },
    zoom: 7,
    code: "RO",
  },
  "den-norske-isbilen": {
    coordinatesArray: [8.905936, 61.248023],
    coordinates: { lat: 61.248023, lng: 8.905936 },
    zoom: 5.5,
    code: "NO",
  },
  public: {
    coordinatesArray: [0, 0],
    coordinates: { lat: 0, lng: 0 },
    zoom: 1,
    code: "WW",
  },
};

export default REGIONS;
