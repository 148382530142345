import React from 'react'
import { Provider } from 'react-redux'
import store from '../../files/store'
import PlanEventTemplatesIndex from './PlanEventTemplatesIndex'
import PlanEventsMap from '../planEvents/PlanEventsMap'
import PlanEventCustomers from '../planEvents/PlanEventCustomers'
import PlanEventCustomersMap from '../planEvents/PlanEventCustomersMap'
import PlanEventsHeatMap from '../map/PlanEventHeatMap'
import ErrorCatcher from '../shared/ErrorCatcher'
import PlanEventCustomersFullDetails from '../planEvents/PlanEventCustomersFullDetails'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

const PlanEventTemplatesIndexWithStore = () => {
  return  (
    <Provider store={store}>
      <ErrorCatcher>
        <BrowserRouter>
          <Switch>
            <Route path='/:tenant/depo/:depoId/plan_events/all_customers/:weekday?' component={PlanEventCustomers} />
            <Route path='/:tenant/depo/:depoId/plan_events/:planEventId/sms_customers/full_details' component={PlanEventCustomersFullDetails} />
            <Route path='/:tenant/depo/:depoId/plan_events/:planEventId/sms_customers' component={PlanEventCustomers} />
            <Route path='/:tenant/depo/:depoId/plan_events/:planEventId/sms_customer_map' component={PlanEventCustomersMap} />
            <Route path='/:tenant/depo/:depoId/plan_events/:planEventId/heat_map' component={PlanEventsHeatMap} />
            <Route path='/:tenant/depo/:depoId/plan_events/:planEventId' component={PlanEventsMap} />
            <Route path='/:tenant/depo/:depoId/plan_event_templates/:week?' component={PlanEventTemplatesIndex} />
          </Switch>
        </BrowserRouter>
      </ErrorCatcher>
    </Provider>
  )
}

export default PlanEventTemplatesIndexWithStore
