import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  headCell: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  revenueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5
  },
  revenueSpan: {
    textAlign: 'center',
    fontSize: '0.8125rem',
    fontWeight: 400,
    color: 'black'
  }
}

const PlanEventTemplatesTableHead = ({ classes, year, week, planEvents }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {[0, 1, 2, 3, 4, 5, 6].map((wday) => {
          const isoWeekday = wday + 1 // This is to maintain that tests run properly
          const weekDay = moment(`${year} ${week}`, "GGGG WW").add(wday, 'days')
          let currentDateEvents = []
          let currentDateSum = 0.0
          let transactionCount = 0
          if (planEvents) {
            currentDateEvents = planEvents.filter(p => p.date === weekDay.format("YYYY-MM-DD"))
            currentDateEvents.forEach(planEvent => {
              currentDateSum += parseFloat(planEvent.total_revenue) || 0.0
              transactionCount += planEvent.order_transactions_count
            })
          }

          return (
            <TableCell className={[classes.headCell, 'text-center'].join(' ')} key={`y${year}-w${week}-d${isoWeekday}`}>
              {weekDay.format('ddd DD/MM')}
              <div className={classes.revenueContainer}>
                <span className={classes.revenueSpan}>AVG: {((currentDateSum / transactionCount) || 0.0).toFixed(2)}</span>
                <span className={classes.revenueSpan}>AVG trip: {((currentDateSum / currentDateEvents.length) || 0.0).toFixed(2)}</span>
              </div>
              <span className={classes.revenueSpan}>Total: {currentDateSum.toFixed(2)}</span>
              <Link className="customers-sms-report" to={Routes.all_customers_plan_events_path(window.tenant, window.depoId, { weekday: weekDay.format('DD/MM/YYYY') })}>
                <i className="fa fa-file" />
              </Link>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(PlanEventTemplatesTableHead)
