import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import AdminUsersIndex from './AdminUsersIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const AdminUsersIndexWithStore = (props) => {
  return  (
    <Provider store={store}>
      <ErrorCatcher>
        <AdminUsersIndex lakrids_host={props.lakrids_host} />
      </ErrorCatcher>
    </Provider>
  )
}

export default AdminUsersIndexWithStore
