import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import More from '@material-ui/icons/More'
import IconButton from '@material-ui/core/IconButton'
import ExportLink from '../shared/ExportLink'
import SharedTable from '../../shared/SharedTable'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'

class ManualCountingDocuments extends React.Component {
  state = { manualCountingDocuments: [], loading: false, message: null }

  componentDidMount() {
    this.startLoading()
    axios.get(this.documentsUrl()).then(res => {
      this.setState({ manualCountingDocuments: res.data, loading: false })
    }).catch(error => {
      this.setState({ message: error, loading: false })
    })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  clearErrors() {
    this.setState({ message: null })
  }

  warehouseId() {
    return this.props.warehouseId
  }

  documentsUrl() {
    return Routes.inventory_warehouse_manual_counting_documents_path(tenant, depoId, this.warehouseId(), { format: 'json' })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderExportLink(id) {
    if (!id) return
    const path = Routes.export_manual_counting_document_inventory_warehouse_manual_counting_document_path(
      tenant, depoId, this.warehouseId(), id, { format: 'xlsx' }
    )
    return <ExportLink path={path} />
  }

  renderShowLink(documentId) {
    if (!documentId) return false
    return (
      <Link to={Routes.show_inventory_warehouse_manual_counting_documents_path(tenant, depoId, this.warehouseId(), documentId)}>
        <IconButton>
          <More />
        </IconButton>
      </Link>
    )
  }

  renderTable() {
    const { manualCountingDocuments } = this.state
    if (!manualCountingDocuments) return
    return (
      <SharedTable
        columns={['Created by', 'Date', 'Created at', 'Approved at', 'Actions']}
        data={manualCountingDocuments.map(({ id, status, created_by, date, approved_at, created_at }) => {
          return {
            className: status,
            id: id,
            created_by: created_by,
            created_at: created_at,
            date: date,
            approved_at: approved_at,
            actions: [this.renderShowLink(id), this.renderExportLink(id)]
          }
        })} />
    )
  }

  render() {
    return (
      <div>
        {this.renderTable()}
        {this.renderFlashNotification()}
        {this.renderLoading()}
      </div>
    )
  }
}

export default ManualCountingDocuments
