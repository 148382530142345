import React from 'react'
import thumbsDown from './thumbs_down.png'

const ThumbsDown = ({ selected, toggle }) => {
  const selectedClass = selected ? "selected" : ""
  return (
    <div className={`thumbs-circle negative ${selectedClass}`} onClick={toggle}>
      <img src={thumbsDown} />
    </div>
  )
}

export default ThumbsDown
