import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

class IntegrationReactSelect extends React.PureComponent {
  state = { options: [], open: false }

  inputOnchange(e) {
    const { loadOptions } = this.props

    const search = e.target.value

    loadOptions(search, (data, data2) => {
      if (data) {
        this.setState({ options: data })
      } else if (data2) {
        this.setState({ options: data2.options })
      }
    })
  }

  render() {
    const { additionalStyles, name, onChange, specificWidth, placeholder, value, ...other } = this.props

    const inputwidth = specificWidth ? specificWidth : "100%"

    return (
      <div  style={additionalStyles}>
        <Autocomplete
          onChange={(event, newValue) => onChange(newValue)}
          options={this.state.options}
          getOptionLabel={(option) => option.label}
          value={value}
          open={this.state.open}
          onOpen={() => this.setState({ open: true })}
          onClose={() => this.setState({ open: false })}
          renderInput={params => (
              <TextField {...params} variant='standard' onChange={this.inputOnchange.bind(this)} name={name} label={placeholder} style={{ width: inputwidth }} />
          )}
        />
      </div>
    );
  }
}

export default IntegrationReactSelect
