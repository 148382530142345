import React from 'react'

const AutoMarker = (props) => {
  return (
    <svg width='50' height='50' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle className='pulsing-circle' fill='#90a0f4' cx='32' cy='32' r='32'></circle>
      <path
        d='M32 57C45.8071 57 57 45.8071 57 32C57 18.1929 45.8071 7 32 7C18.1929 7 7 18.1929 7 32C7 45.8071 18.1929 57 32 57Z'
        fill='#90a0f4'
      />
      <path
        d='M24.2344 35.8799C22.6745 35.8799 21.4114 37.1298 21.4114 38.6717C21.4114 40.2148 22.6745 41.4635 24.2344 41.4635C25.7933 41.4635 27.0573 40.2148 27.0573 38.6717C27.0573 37.1298 25.7933 35.8799 24.2344 35.8799ZM24.2344 39.8703C23.5657 39.8703 23.0234 39.3337 23.0234 38.6717C23.0234 38.0102 23.5657 37.4737 24.2344 37.4737C24.9036 37.4737 25.4462 38.0102 25.4462 38.6717C25.4462 39.334 24.9036 39.8703 24.2344 39.8703Z'
        fill='white'
      />
      <path
        d='M41.6926 35.8799C40.1328 35.8799 38.8696 37.1298 38.8696 38.6717C38.8696 40.2148 40.1328 41.4635 41.6926 41.4635C43.2515 41.4635 44.515 40.2148 44.515 38.6717C44.515 37.1298 43.2515 35.8799 41.6926 35.8799ZM41.6926 39.8703C41.0231 39.8703 40.4816 39.3337 40.4816 38.6717C40.4816 38.0102 41.0231 37.4737 41.6926 37.4737C42.3612 37.4737 42.9036 38.0102 42.9036 38.6717C42.9036 39.334 42.3612 39.8703 41.6926 39.8703Z'
        fill='white'
      />
      <path
        d='M47.4672 36.3626H47.0791V31.7785L42.7155 29.3196L40.1847 22.282C40.1847 21.9912 39.9129 21.822 39.6183 21.822H19.4106C19.1169 21.822 18.8823 21.9241 18.8823 22.2146V36.3626H18.5134C18.2185 36.3626 17.9824 36.6585 17.9824 36.949V38.1449C17.9824 38.436 18.2185 38.7366 18.5134 38.7366H19.4106H20.5726C20.635 36.3627 22.2479 35.2243 24.2343 35.2243C26.2213 35.2243 27.8342 36.3627 27.8966 38.7366H38.0306C38.093 36.3627 39.7053 35.2243 41.6926 35.2243C43.6781 35.2243 45.2919 36.3627 45.3543 38.7366H46.5142H47.4138C47.7075 38.7366 47.9469 38.4684 47.9469 38.1773L48 36.9348C48 36.6446 47.7603 36.3626 47.4672 36.3626ZM28.7812 23.3058H30.8809V25.2017C30.8809 25.7263 30.443 26.1516 29.832 26.1516C29.2209 26.1516 28.7812 25.726 28.7812 25.2017V23.3058ZM24.8816 23.3058H26.9814V25.2017C26.9814 25.7263 26.5416 26.1516 25.9294 26.1516C25.3205 26.1516 24.8816 25.726 24.8816 25.2017V23.3058ZM21.282 25.2017V23.3058H23.3818V25.2017C23.3818 25.7263 22.9427 26.1516 22.3316 26.1516C21.7203 26.1516 21.282 25.726 21.282 25.2017ZM22.4819 30.9984V27.3278H33.4414V30.9984H22.4819ZM34.7805 25.2017C34.7805 25.7263 34.1905 26.1516 33.5797 26.1516C32.9687 26.1516 32.3808 25.726 32.3808 25.2017V23.3058H34.7805V25.2017ZM36.8802 28.944V23.3058H38.0303C38.2418 23.3058 38.4116 23.5758 38.4116 23.7832L40.233 28.944H36.8802Z'
        fill='white'
      />
    </svg>
  )
}

export default AutoMarker
