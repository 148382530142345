import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import Table from '@material-ui/core/Table'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { setMainTitle, startLoading, stopLoading, loadResources, setFlash, deleteResource, updateResource } from '../../files/actions/index'

import EndOfDayReportsTableHead from './EndOfDayReportsTableHead'
import UnmappedEndOfDayReportsTableHead from './UnmappedEndOfDayReportsTableHead'
import EndOfDayReportsTableRow from './EndOfDayReportsTableRow'
import UnmappedEndOfDayReportsTableRow from './UnmappedEndOfDayReportsTableRow'
import extractDataErrors from '../support/extractDataErrors'

import ExporterModal from './ExporterModal'
import DateRangeCarFilter from './DateRangeCarFilter'

import moment from 'moment'
import EndOfDayReportCreateModal from './EndOfDayReportCreateModal'

class EndOfDayReportsTableClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      paginationDisabled: true,
      viewChanging: false,
      cars: [],
      car_ids: [],
      statuses: ['pending', 'pending_edited', 'approved', 'approved_edited'],
      filteredStatuses: [],
      startDate: moment().startOf('month').format('DD-MM-YYYY'),
      endDate: moment().endOf('month').format('DD-MM-YYYY'),
      eodView: 0,
      creationModal: false,
      users: [],
      routes: []
    }
  }

  componentDidMount() {
    this.props.setMainTitle('End of day reports')
    this.fetchCars()
    this.fetchUsers()
    this.fetchRoutes()
  }

  fetchCars() {
    axios.get(this.carsUrl()).then(res => {
      this.setState({cars: res.data})
      this.loadPage()
    }).catch(error => {
      this.props.setFlash(`${error} (${this.carsUrl()})`)
      this.props.stopLoading()
    })
  }

  fetchUsers() {
    axios.get(Routes.admin_users_path(tenant, depoId, { format: 'json', statuses: ['active'] })).then(res => {
      this.setState({ users: res.data })
    }).catch(error => {
      this.setState({ errors: extractDataErrors(error) && extractDataErrors(error).errors })
    })
  }

  fetchRoutes() {
    axios.get(Routes.routes_path(window.tenant, window.depoId, { for_collection: 1, format: 'json' })).then(res => {
      this.setState({ routes: res.data })
    }).catch(error => {
      this.setState({ errors: extractDataErrors(error) && extractDataErrors(error).errors })
    })
  }

  loadPage() {
    this.props.startLoading()

    axios.get(this.url()).then(res => {
      this.props.loadEndOfDayReports(res.data)
      this.setState({ paginationDisabled: false, viewChanging: false })
      this.props.stopLoading()
    }).catch(error => {
      this.props.setFlash(`${error} (${this.url()})`)
      this.setState({ paginationDisabled: false, viewChanging: false })
      this.props.stopLoading()
    })
  }

  changePage(pageIncrement) {
    if (this.props.endOfDayReports.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, this.state.currentPage + pageIncrement))
    if (newPage === this.state.currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadPage)
  }

  destroyRow(row) {
    this.props.deleteEndOfDayReport(row)
    this.props.setFlash("End of day report succesfully deleted")
    if (this.props.endOfDayReports.length === 0) {
      this.setState({ currentPage: this.state.currentPage, paginationDisabled: true }, this.loadPage)
    }
  }

  url() {
    const { eodView, currentPage, filteredStatuses, car_ids, startDate, endDate } = this.state
    let url
    if (eodView === 0) {
      url = 'end_of_day_reports_path'
    } else if (eodView === 1) {
      url = 'history_end_of_day_reports_path'
    } else {
      url = 'unmapped_end_of_day_reports_end_of_day_reports_path'
    }
    return Routes[url](
      tenant,
      depoId,
      {
        format: 'json',
        page: currentPage,
        statuses: filteredStatuses,
        start_date: startDate,
        end_date: endDate,
        car_ids: car_ids
      }
    )
  }

  carsUrl() {
    return Routes.admin_cars_path(tenant, depoId, { format: 'json' })
  }

  handleChange(field, value) {
    this.setState({ [field]: value })
  }

  handleTabChange(event, value) {
    const { eodView } = this.state
    if (eodView === value) return
    this.setState({ eodView: value, currentPage: 1, paginationDisabled: true, viewChanging: true }, () => {
      this.loadPage()
    })
  }

  refilter() {
    this.setState({ currentPage: 1, paginationDisabled: true }, this.loadPage)
  }

  showEodModal() {
    this.setState({ creationModal: true })
  }

  closeEodModal() {
    this.setState({ creationModal: false })
  }

  renderFilters() {
    const { car_ids, cars, eodView, startDate, endDate } = this.state
    const disabled = eodView === 2
    return (
      <div className="left-action-container float-left">
        <DateRangeCarFilter
          car_ids={car_ids}
          cars={cars}
          startDate={startDate}
          endDate={endDate}
          disabled={disabled}
          refilter={this.refilter.bind(this)}
          handleChange={this.handleChange.bind(this)}
        />
        <ExporterModal car_ids={car_ids} cars={cars} />
      </div>
    )
  }

  renderPagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className="right-action-container float-right">
        {this.renderNewEodButton()}
        <div className="pagination ">
          <strong>Page: {currentPage}</strong>
          &nbsp;
          <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    )
  }

  renderTabSwitch() {
    const { eodView, viewChanging } = this.state
    return (
      <Tabs
        indicatorColor="secondary"
        textColor="secondary"
        className="w-100 mt-3 mb-2"
        value={eodView}
        centered
        onChange={this.handleTabChange.bind(this)}>
        <Tab className="mapped-tab" value={0} label="Unapproved" disabled={viewChanging} />
        <Tab className="approved-tab" value={1} label="Approved" disabled={viewChanging} />
        <Tab className="unmapped-tab" value={2} label="Unmapped" disabled={viewChanging} />
      </Tabs>
    )
  }

  renderTableHead() {
    if (this.state.eodView !== 2) {
      return <EndOfDayReportsTableHead />
    } else {
      return <UnmappedEndOfDayReportsTableHead />
    }
  }

  renderNewEodButton() {
    return (
      <React.Fragment>
        <Button className="mr-3" variant="contained" color="primary" onClick={this.showEodModal.bind(this)}>
          Create EOD
        </Button>
      </React.Fragment>
    )
  }

  renderModal() {
    return (
      <EndOfDayReportCreateModal
        startLoading={this.props.startLoading}
        stopLoading={this.props.stopLoading}
        setFlash={this.props.setFlash}
        open={this.state.creationModal}
        handleClose={this.closeEodModal.bind(this)}
        updateEndOfDayReport={this.props.updateEndOfDayReport} />
    )
  }

  renderTableRows() {
    if (this.state.viewChanging) return
    return this.props.endOfDayReports.map((endOfDayReport, index) => {
      if (this.state.eodView !== 2) {
        return <EndOfDayReportsTableRow
          endOfDayReport={endOfDayReport}
          key={endOfDayReport.id}
          startLoading={this.props.startLoading}
          stopLoading={this.props.stopLoading}
          destroyRow={this.destroyRow.bind(this)}
          setFlash={this.props.setFlash} />
      } else {
        return <UnmappedEndOfDayReportsTableRow
          endOfDayReport={endOfDayReport}
          cars={this.state.cars.filter(c => !c.deleted_at)}
          routes={this.state.routes.filter(r => !r.deleted_at)}
          users={this.state.users}
          key={endOfDayReport.id}
          startLoading={this.props.startLoading}
          stopLoading={this.props.stopLoading}
          removeRow={this.props.deleteEndOfDayReport.bind(this)}
          destroyRow={this.destroyRow.bind(this)}
          setFlash={this.props.setFlash} />
      }
    })
  }

  renderTable() {
    return (
      <Table className='resources-table without-cursor'>
        {this.renderTableHead()}
        {this.renderTableRows()}
      </Table>
    )
  }

  render() {
    return (
      <div>
        {this.renderModal()}
        {this.renderPagination()}
        {this.renderFilters()}
        {this.renderTabSwitch()}
        {this.renderTable()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    endOfDayReports: state.resources
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMainTitle: title => dispatch(setMainTitle(title)),
    setFlash: flash => dispatch(setFlash(flash)),
    loadEndOfDayReports: endOfDayReports => dispatch(loadResources(endOfDayReports)),
    deleteEndOfDayReport: endOfDayReport => dispatch(deleteResource(endOfDayReport)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    updateEndOfDayReport: endOfDayReport => dispatch(updateResource(endOfDayReport))
  }
}

const EndOfDayReportsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(EndOfDayReportsTableClass)

export default EndOfDayReportsTable
