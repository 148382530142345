import React from 'react'
import fraastLogo from '../icons/fraast.svg'
import hjemisLogo from '../icons/hjem-is/logo_hjemis.svg'
import isbilenLogo from '../icons/den-norske-isbilen/isbilen_logo.svg'
import slash from '../icons/slash.svg'
import LanguageChanger from './shared/LanguageChanger'
import isMobile from '../helpers/isMobile'

class FraastFoodHeader extends React.Component {
  renderLogo() {
    if (this.props.tenant == 'public') return

    if (this.props.tenant === 'hjem-is') {
      return (
        <a href='https://hjem-is.dk'>
          <img src={hjemisLogo}></img>
        </a>
      )
    } else {
      return (
        <div>
          <img className='slash' src={slash}></img>
          <div>
            <img src={isbilenLogo}></img>
            <p>En del av Fråst Foods</p>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className={`header ${this.props.tenant}`}>
        <div className='logos'>
          {this.props.tenant === 'den-norske-isbilen' && (
            <a href='https://frastfoods.com/'>
              <img className='fraast' src={fraastLogo}></img>
            </a>
          )}
          {this.renderLogo()}
        </div>

        {this.props.tenant != 'public' && isMobile() == false && (
          <LanguageChanger language={this.props.language} shortForm={false} />
        )}
      </div>
    )
  }
}

export default FraastFoodHeader
