const Warehouse = () => {
  return {
    code: '',
    name: '',
    owner_type: '',
    owner_id: '',
    primary: false,
    errors: {}
  }
}

export default Warehouse
