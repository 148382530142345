import React from 'react'
import floatToTimeConverter from '../support/floatToTimeConverter'
import printStyles from '../../files/printingAssets.js.erb'
import { translations } from '../../files/translations'

const printableContainerId = "route-points-print-container"

class RoutePointsPrintTable extends React.Component {
  state = { printingStarted: false, loading: false }

  printRoutePoints() {
    this.props.startLoading()
    this.setState({ printingStarted: true }, () => {
      const { header } = this.props
      printJS({
        printable: printableContainerId,
        type: 'html',
        header: header,
        documentTitle: header,
        scanStyles: false,
        css: printStyles,
        onLoadingEnd: this.props.stopLoading

      })
      this.setState( { printingStarted: false })
    })
  }

  startPrinting = () => this.setState({ printingStarted: true }, this.printRoutePoints)

  renderHeader() {
    const { printing: { end_of_working_day, start_of_working_day } } = translations
    const { startOfDay, endOfDay } = this.props
    if (!startOfDay || !endOfDay) return
    return (
      <div className="printable-header">
        <div>
          {start_of_working_day}: {startOfDay}<br/>
          {end_of_working_day}: {endOfDay}
        </div>
      </div>
    )
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th className="min-width-50">#</th>
          <th className="min-width-300">Address</th>
          <th className="min-width-85">Wait Time</th>
          <th className="min-width-85">Arrival Time</th>
          <th className="min-width-250">Comment</th>
        </tr>
      </thead>
    )
  }

  b2bCustomers(b2b_customers) {
    if (b2b_customers.length > 0) {
      return (
        <tr>
          <td colSpan={6}>{this.b2bCustomerNumbers(b2b_customers)}</td>
        </tr>
      )
    }
  }

  b2bCustomerNumbers(b2b_customers) {
    return b2b_customers.map(customer => `${customer.shop_id} ${customer.customer_name}`).join(', ')
  }

  renderTableBody() {
    const { routePoints } = this.props
    return (
      <tbody>
        {routePoints.map(({ position, address, custom_address, use_custom_address, wait_time, google_estimate_time, private_comment, classAndColor, b2b_customers }, index) => {
          const correctAddress = use_custom_address ? custom_address : address
          return (
            <React.Fragment key={index}>
              <tr key={index}>
                <td className={`min-width-50 ${classAndColor && classAndColor.class}`}>{index + 1}</td>
                <td className="min-width-300">{correctAddress}</td>
                <td className="min-width-85">{floatToTimeConverter(wait_time)}</td>
                <td className="min-width-85">{google_estimate_time}</td>
                <td className="min-width-250">{private_comment}</td>
              </tr>
              {this.b2bCustomers(b2b_customers)}
            </React.Fragment>
          )
        })}
      </tbody>
    )
  }

  renderTable() {
    return (
      <table>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </table>
    )
  }

  render() {
    if(!this.state.printingStarted) return null
    return (
      <div className="printable-container" id={printableContainerId}>
        {isHjemis && this.renderHeader()}
        {this.renderTable()}
      </div>
    )
  }
}

export default RoutePointsPrintTable;
