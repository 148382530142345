import React from 'react'
import Modal from '../shared/Modal'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IntegrationReactSelect from '../forms/IntegrationReactSelect'
import InputLabel from '@material-ui/core/InputLabel'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'

class RoutePointsMoveForm extends React.Component {
  state = { position: '', route_id: this.props.routeId, keepPoints: this.props.forCurrentRoute }

  async loadRouteOptions(q, callback) {
    if (q === '') return callback(null, { options: [] })

    const { data } = await axios.get(Routes.routes_path(window.tenant, window.depoId, { format: 'json' }), { params: { q }})
    this.options   = data.filter(({deleted_at}) => !deleted_at).map(route => ({ value: route.id, label: route.name }))

    callback(this.options)
  }

  onRouteChange(route_id) {
    this.setState({ route_id: route_id && route_id.value })
  }

  movedPointsAttributes() {
    const { position } = this.state
    const filteredPoints = this.clonedRoutePoints().filter(point => point.address && point.checked)
    const mappedAttributes = filteredPoints.map((point, index) => {
      point.position = parseInt(position) + index
      point.b2b_customer_ids = point.b2b_customers.map(customer => customer.id)
      return point
    })

    return mappedAttributes
  }

  clonedRoutePoints() {
    return cloneDeep(this.props.routePoints)
  }

  updatePoints(callback, error) {
    this.props.startLoading()

    axios.put(
      Routes.move_route_points_route_path(window.tenant, window.depoId, this.state.route_id, { format: 'json' }),
      {
        route: {
          keep_points: this.state.keepPoints,
          current_route_id: this.props.routeId,
          route_points_attributes: this.movedPointsAttributes()
        }
      }
    ).then(callback).catch(error)
  }

  error(err) {
    this.props.setFlash(`${err}`)
  }

  copiedAndMoved() {
    this.toggleModal()
    this.props.stopLoading()
    this.props.setFlash("Selected route points moved")
  }

  movedAndRemovedFromCurrentRoute(res) {
    this.loadPointsAfterMove(res)
  }

  loadPointsAfterMove(res) {
    this.props.loadRoutePoints(res.data)
    this.copiedAndMoved()
  }

  copyAndMoveRoutePoints(res) {
    this.setState({ keepPoints: true }, () => this.updatePoints(this.copiedAndMoved.bind(this), this.error.bind(this)))
  }

  moveRoutePoints() {
    this.setState({ keepPoints: false }, () => this.updatePoints(this.movedAndRemovedFromCurrentRoute.bind(this), this.error.bind(this)))
  }

  movePointsIntoNewPosition() {
    this.updatePoints(this.loadPointsAfterMove.bind(this), this.error.bind(this))
  }

  toggleModal() {
    this.props.toggleModal()
  }

  render() {
    const { open, forCurrentRoute } = this.props

    if (forCurrentRoute) {
      return (
        <Modal open={open} close={this.toggleModal.bind(this)} title="Move points in another position" style={{width: 400}}>
          <i
            onClick={this.toggleModal.bind(this)}
            className="material-icons close-icon"
          >
            close
          </i>
          <div className="position-container">
            <TextField
              id="new-position"
              label="New position"
              value={this.state.position}
              onChange={e => this.setState({ position: e.target.value })}
              fullWidth
              margin="normal" />
          </div>
          <Button onClick={this.movePointsIntoNewPosition.bind(this)}>
            Move points into position
          </Button>
        </Modal>
      )
    } else {
      return (
        <Modal open={open} close={this.toggleModal.bind(this)} title="Move points" style={{width: 800}}>
          <i
            onClick={this.toggleModal.bind(this)}
            className="material-icons close-icon"
          >
            close
          </i>
          <div className="route-select-container">
            <div className='react-select-wrapper'>
              <InputLabel>Route name</InputLabel>
              <IntegrationReactSelect
                name='route_id'
                loadOptions={this.loadRouteOptions.bind(this)}
                onChange={this.onRouteChange.bind(this)} />
            </div>
          </div>
          <div className="position-container">
            <TextField
              id="new-position"
              label="New position"
              value={this.state.position}
              onChange={e => this.setState({ position: e.target.value })}
              fullWidth
              margin="normal" />
          </div>
          <Button onClick={this.copyAndMoveRoutePoints.bind(this)}>
            Copy and Move
          </Button>
          <Button onClick={this.moveRoutePoints.bind(this)}>
            Move and Delete
          </Button>
        </Modal>
      )
    }
  }
}

export default RoutePointsMoveForm
